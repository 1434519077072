<style lang="less" scoped>
.delivery {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #fff;

  .main_button {
    margin-left: 36px;
  }

  .box {
    width: 100%;
    padding-top: 12px;

    .main_button {
      margin-left: 36px;
    }

    .baowei {
      width: 100%;

      .chongzhi {
        margin-left: 40px;
      }

      @media (max-width: 1400px) {
        .chongzhi {
          margin-left: 26px;
        }
      }

      @media (max-width: 1300px) {
        .chongzhi {
          margin-left: 26px;
        }
      }

      @media (max-width: 1270px) {
        .chongzhi {
          margin-left: 17px;
        }
      }
    }

    .wrap {
      width: 100%;
      margin-top: 22px;
      /* // padding: 0px 30px; */
      padding: 12px;
      box-sizing: border-box;

      .wraped {
        width: 100%;
      }
    }
  }
}
</style>
<style>
.repairOrder .el-table__fixed-right {
  right: 0 !important;
}

.repairOrder .el-.el-upload-list__item-name-right {
  width: 400px !important;
}
</style>


<template>
  <div class="delivery">
    <div class="box">
      <el-form ref="releaseForm" :model="releaseForm" label-width="120px">
        <el-row>
          <el-col :lg="18" :md="20">
            <el-row>
              <el-col :lg="8" :md="12">
                <el-form-item label="服务商名称：">
                  <el-select v-model="releaseForm.serviceId" @change="serviceIdChange" filterable placeholder="请输入服务商名称"
                    :loading="loading" style="width: 100%" size="small">
                    <el-option v-for="item in serviceList" :key="item.serviceId" :label="item.serviceName"
                      :value="item.serviceId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12">
                <el-form-item label="渠道商名称：">
                  <el-select v-model="releaseForm.agentId" filterable placeholder="请输入渠道商名称" :loading="loading"
                    style="width: 100%" @change="agentInfoChange" size="small">
                    <el-option v-for="(item, index) in agentList" :key="index" :label="item.agentName"
                      :value="item.agentId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="渠道商名称：">
                                    <el-input v-model="releaseForm.agentName"
                                              size="small"
                                              placeholder="请输入渠道商名称"></el-input>
                                </el-form-item>
                            </el-col> -->
              <el-col :lg="8" :md="12">
                <el-form-item label="统计周期：" prop="month">
                  <el-date-picker v-model="releaseForm.month" type="month" placeholder="选择统计周期" value-format="yyyy-MM"
                    size="small">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label=" ">
              <el-button type="primary" size="small" icon="el-icon-search" @click="search()">查询</el-button>
              <el-button size="small" icon='el-icon-refresh-left' class="chongzhi" @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="wrap">
        <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }" fit border :render-header="renderHeader" show-overflow-tooltip
          style="width: 100%">
          <el-table-column show-overflow-tooltip prop="agentName" label="渠道商名称">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="agentId" label="渠道商ID">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="totalNum" label="交易笔数">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.totalNum }}笔</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="totalAmt" label="应发金额（原交易金额）">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.totalAmt }}</span>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="month" label="统计周期"> </el-table-column> -->
          <el-table-column show-overflow-tooltip prop="sumDay" label="统计周期">
          </el-table-column>
        </el-table>
        <div class="wraped">
          <pagination :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
          </pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from '@/components/pagination.vue';
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
import { f2y, y2f } from '@/util/util.js';
export default {
  data() {
    return {
      serviceList: [],
      loading: false,
      total: null,
      pageSize: 10,
      releaseForm: {
        // createDate: ["", ""],
        agentId: '',
        // 统计周期
        month: '',
        serviceId: '',
        agentName: ''
      },
      shenheLoadinged: false,
      tableData: [],
      //   渠道商名称
      agentList: []
    };
  },
  mounted() {
    this.serviceInfo();
  },
  created() {
    this.defaultDate();
  },
  methods: {
    serviceIdChange(e) {
      this.releaseForm.serviceId = e;
      this.releaseForm.agentId = '';
      this.releaseForm.agentName = '';
      // 渠道商名称
      this.selectNameAgent();
    },
    agentInfoChange(e) {
      let obj = {};
      obj = this.agentList.find(item => {
        return item.agentId == e;
      });
      this.releaseForm.agentName = obj.agentName;
    },
    serviceInfo() {
      post(api.queryServiceList).then(res => {
        if (res.code == 0) {
          this.releaseForm.serviceId = res.data[0].serviceId;
          this.serviceList = res.data;
          this.queryPageData(); //查看渠道商列表
          // 渠道商名称
          this.selectNameAgent();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //   渠道商名称
    selectNameAgent() {
      post(api.selectNameAgentNew, { agentId: this.$cookies.get("mchcompanyId"), serviceId: this.releaseForm.serviceId }).then(res => {
        // console.log(res, 6666);
        if (res.code == 0) {
          this.agentList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 设置默认日期当月
    defaultDate() {
      // 获取最新时间
      let date = new Date();
      // 获取当前时间的年份转为字符串
      let year = date.getFullYear().toString(); //2021
      // 获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接0
      let month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1).toString()
          : (date.getMonth() + 1).toString();
      // 字符串拼接
      let monthString = year + '-' + month;
      // 赋值
      this.releaseForm.month = monthString;
    },
    // 表格
    renderHeader(h, data) {
      return h('span', [
        h('el-tooltip'),
        {
          attrs: {
            class: 'item',
            effect: 'dark',
            content: data.column.label
          }
        },
        [h('span', data.column.label)]
      ]);
    },
    queryPageData(size) {
      if (true) {
        // 数字
        const loading = this.$loading({
          lock: true,
          spinner: 'loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        post(api.agentTransCountPage, {
          currentPage: size || 1,
          pageSize: this.pageSize,
          agentName: this.releaseForm.agentName,
          month: this.releaseForm.month,
          serviceId: this.releaseForm.serviceId
        }).then(
          d => {
            loading.close();
            if (d.code === 0) {
              if (d.hasOwnProperty('data')) {
                this.total = d.data.count;
                this.currentPage = d.data.currentPage;
                this.tableData = d.data.rows;
                this.tableData.map(item => {
                  item.totalAmt = f2y(item.totalAmt);
                });
              } else {
                this.total = 0;
                this.currentPage = 1;
                this.pageSize = 10;
                this.tableData = [];
              }
            } else {
              this.total = 0;
              this.currentPage = 1;
              this.pageSize = 10;
              this.tableData = [];
            }
          },
          err => {
            //error callback
          }
        );
      } else {
        //字符
        this.total = 0;
        this.currentPage = 1;
        this.pageSize = 10;
        this.tableData = [];
      }
    },
    search() {
      this.queryPageData();
    },
    reset() {
      this.releaseForm = {
        agentName: '',
        // month: "",
        serviceId: this.releaseForm.serviceId
      };
      this.defaultDate();
      this.queryPageData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log(`每页 ${val} 条aaaaa`);
      this.queryPageData();
    },
    handleCurrentChange(size) {
      // console.log(size);
      this.queryPageData(size);
    }
  },
  components: {
    pagination: pagination
  }
};
</script>
