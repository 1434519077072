<style lang="less" scoped>
.account {
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    text-align: left;
    .box {
        width: 100%;
        padding-top: 12px;
        .main_button {
            margin-left: 36px;
        }
        .wrap {
            clear: both;
            width: 100%;
            margin-top: 22px;
            padding: 12px;
            box-sizing: border-box;
            .wraped {
                width: 100%;
            }
        }
    }
    .account-menu2 {
        display: inline-block;

        width: 120px;
        height: 30px;
        border-radius: 5px;
        color: #409eff;
        background: #ecf5ff;
        line-height: 30px;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
    }
    .account-menu1 {
        float: right;
        margin-right: 10px;
        cursor: pointer;
        width: 120px;
        height: 34px;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        line-height: 34px;
        background-color: #1592fe;
        text-align: center;
        font-weight: 600;
        display: inline-block;
        margin-left: 30px;
    }
    /* // 我的账户上半部分样式 */
    .accountNew {
        margin-left: 10px;
        width: 480px;
        min-width: 480px;
        height: 200px;
        box-sizing: border-box;
        background-color: #eef5ff;
        margin-bottom: 30px;
        .accountThird {
            text-align: center;
            .account-menu {
                cursor: pointer;
                width: 120px;
                height: 34px;
                border-radius: 5px;
                color: #fff;
                font-size: 14px;
                line-height: 34px;
                background-color: #1592fe;
                text-align: center;
                font-weight: 600;
                display: inline-block;
                margin-left: 30px;
            }
        }
        .accountFirst {
            img {
                padding-right: 10px;
            }
            display: flex;
            line-height: 30px;
            text-decoration: underline;
            font-size: 14px;
            font-weight: 600;
            padding-left: 20px;
            padding-top: 20px;
        }
        .accountSecond {
            margin-top: 10px;
            display: flex;
            /* // justify-content: center; */
            width: 600px;
            line-height: 10px;
            padding-left: 5px;
            .one {
                padding-left: 40px;
                text-align: left;
                .num {
                    /* // color:#1A92FF; */
                    font-weight: 600;
                    font-size: 26px;
                }
                .font {
                    color: #9199a2;
                    font-size: 14px;
                }
            }
        }
    }
    /* // 提现 */
    .withdraw {
        .sign-input {
            padding-left: 1%;

            .sign-el-input {
                width: 300px;
            }
        }
        .back {
            cursor: pointer;
            display: inline-block;
            padding: 30px 20px 0px 30px;
        }
        .title span {
            padding-right: 20px;
            color: #d8dce5;
        }
        .withdraw-all {
            padding-top: 30px;
            width: 50%;
            margin: 0 auto;
            /* // 上传职业营照 */
            .uploading-camp {
                width: 100%;
                border: 1px solid #f5f5f5;
                border-right: none;
                margin-bottom: 15px;
                height: 100px;
                display: flex;
                .uploading-left {
                    width: 100px;
                    height: 100px;
                    background-color: #f5f5f5;
                    position: relative;
                    i {
                        position: absolute;
                        top: 50%;
                        margin-top: -25%;
                        left: 50%;
                        margin-left: -40%;
                    }
                }
                .uploading-right {
                    padding-left: 30px;
                    /* // padding-top: 15px; */
                    line-height: 10px;
                    .right-one {
                        font-weight: 600;
                        color: #666666;
                    }
                    .right-two {
                        color: #9999a6;
                    }
                    .right-three {
                        color: #0079fe;
                    }
                }
            }
        }
    }
    /* // 查看明细 */
    .lookDetail {
        /* // 增加商户 */
        .add-agent {
            float: right;
            margin-right: 30px;
            margin-bottom: 30px;
            cursor: pointer;
        }
        .back {
            cursor: pointer;
            display: inline-block;
            padding: 30px 20px 0px 30px;
        }
        .title span {
            padding-right: 20px;
            color: #d8dce5;
        }
        .wrap {
            width: 100%;
            margin-top: 22px;
            padding: 12px;
            box-sizing: border-box;
            .wraped {
                width: 100%;
            }
        }
    }
    /* // 分润计算 */
    .share {
        width: 100%;
        .list {
            width: 100%;
            text-align: left;
            padding: 20px;
            box-sizing: border-box;
            font-size: 14px;
            color: rgba(16, 16, 16, 100);
            .grid-content {
                width: 100%;
                margin-bottom: 20px;
                display: inline-block;
            }
            .baoguo {
                width: 100%;
                overflow: hidden;
                .zongji {
                    float: left;
                    font-weight: 500;
                    font-size: 22px;
                    color: #303133;
                }
                .anniu {
                    float: right;
                }
            }
            .biankuang {
                width: 336px;
                height: 169px;
                border: 1px solid #6666;
                .dian {
                    background-color: #52c41a;
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-left: 30px;
                }
                .jinqian {
                    font-size: 20px;
                    line-height: 89px;
                    vertical-align: middle;
                    margin-left: 20px;
                }
                .wenzi {
                    margin-left: 89px;
                    font-size: 22px;
                    color: #666;
                }
            }
        }
        .back {
            cursor: pointer;
            display: inline-block;
            padding: 30px 20px 0px 30px;
        }
        .title span {
            padding-right: 20px;
            color: #d8dce5;
        }
        /* // 上传职业营照 */
        .uploading-camp {
            width: 80%;
            margin: 0 auto;
            border: 1px solid #f5f5f5;
            border-right: none;
            margin-bottom: 15px;
            height: 100px;
            display: flex;
            .uploading-left {
                width: 100px;
                height: 100px;
                background-color: #f5f5f5;
                position: relative;
                i {
                    position: absolute;
                    top: 50%;
                    margin-top: -40%;
                    left: 50%;
                    margin-left: -40%;
                }
            }
            .uploading-right {
                padding-left: 30px;
                /* // padding-top: 15px; */
                line-height: 10px;
                .right-one {
                    font-weight: 600;
                    color: #666666;
                }
                .right-two {
                    color: #9999a6;
                }
                .right-three {
                    color: #0079fe;
                }
            }
        }
        .addGroup-allOne {
            margin-top: 15px;
            .uploading-choose {
                width: 80%;
                border: 1px solid #f5f5f5;
                border-right: none;
                margin-bottom: 15px;
                height: 100px;
                margin: 0 auto;
                display: flex;
                .uploading-left {
                    width: 100px;
                    height: 100px;
                    background-color: #f5f5f5;
                    position: relative;
                    i {
                        position: absolute;
                        top: 50%;
                        margin-top: -40%;
                        left: 50%;
                        margin-left: -40%;
                    }
                }
                .uploading-right {
                    padding-left: 30px;
                    /* // padding-top: 15px; */
                    line-height: 10px;
                    .right-one {
                        font-weight: 600;
                        color: #666666;
                    }
                    .add-c {
                        display: flex;
                        align-items: center;
                        .right-two {
                            display: flex;
                            color: #9999a6;
                            font-size: 12px;
                        }
                        .add-cancel {
                            width: 100px;
                            height: 34px;
                            border-radius: 2px;
                            border: 1px solid #1890ff;
                            text-align: center;
                            color: #1890ff;
                            vertical-align: middle;
                            cursor: pointer;
                            font-size: 12px;
                            line-height: 34px;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        .nextSetp {
            width: 150px;
            height: 36px;
            font-size: 12px;
            color: #fff;
            line-height: 36px;
            text-align: center;
            border-radius: 5px;
            background-color: #1890ff;
            margin-left: 45%;
            margin-top: 30px;
        }
    }

    .block {
        width: 100%;
        .main {
            width: 100%;
            box-sizing: border-box;
            font-size: 14px;
            .grid-content {
                width: 100%;
                margin-bottom: 20px;
                display: inline-block;
            }
            .grid-left {
                float: left;
                margin-bottom: 20px;
            }
            .grid-img {
                float: left;
                width: 160px;
                height: 97px;
                margin-right: 22px;
            }
            .wrap {
                width: 100%;
                margin-top: 22px;
                padding: 30px;
                box-sizing: border-box;
            }
            .footer {
                padding: 30px;
            }
            .col {
                width: 102%;
                height: 10px;
                background-color: #f2f2f2;
                margin-left: -12px;
            }
        }
    }
    .add-table {
        p {
            margin-left: 10%;
            font-weight: 600;
            margin-top: 30px;
        }
    }
    /* // 查看结果 */
    .add-submit1 {
        width: 10%;
        border-radius: 5px;
        height: 34px;
        background-color: #1890ff;
        color: #fff;
        text-align: center;
        margin-right: 30px;
        cursor: pointer;
        line-height: 34px;
        font-size: 12px;
        margin: 0 auto;
        margin-top: 20px;
    }
    /* // 底部 */
    .add-bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 50px;
        margin-top: 50px;
        .add-submit {
            width: 10%;
            border-radius: 5px;
            height: 34px;
            background-color: #1890ff;
            color: #fff;
            text-align: center;
            margin-right: 30px;
            cursor: pointer;
            line-height: 34px;
        }
        .add-cancel {
            width: 10%;
            height: 34px;
            border-radius: 5px;
            border: 1px solid #1890ff;
            text-align: center;
            color: #1890ff;
            cursor: pointer;
            line-height: 34px;
        }
    }
    .success {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        margin-top: 30px;
    }
}
</style>
<style>
.el-table th.is-leaf,
.el-table th {
    background-color: #f5f5f5;
    border-bottom: none;
}
/* .main .el-tabs__nav-scroll {
    padding-left: 34px !important;
} */
.main .el-tabs__header {
    margin: 0px !important;
}
.delivery .el-table__fixed-right {
    right: 0 !important;
    border: none;
}
.delivery .el-.el-upload-list__item-name-right {
    width: 400px !important;
}
</style>

<template>
    <div class="account">
        <div class="box"
             v-if="drawStatus == 1">
            <div style="display:flex;overflow: scroll;width: 100%;margin-bottom: 20px;">
                <div class="accountNew"
                     v-for="(item,index) in AccountInfo"
                     :key="index">
                    <div class="accountFirst">
                        <img src="../../assets/img/qudao .png"
                             alt=""
                             width="30px"
                             height="30px" />
                        <template v-if="item.serviceName">
                            {{ item.agentName }} - {{item.serviceName}}
                        </template>
                        <template v-if="!item.serviceName">
                            {{ item.agentName }}
                        </template>
                    </div>
                    <div class="accountSecond">
                        <div class="one">
                            <p class="font">可提现金额(元)</p>
                            <p class="num">{{ item.cashAmtStr }}</p>
                        </div>
                        <div class="one">
                            <p class="font">冻结金额(元)</p>
                            <p class="num">{{ item.cashFreezeStr }}</p>
                        </div>
                        <div class="one"
                             @click="widthClose(item.serviceId)"
                             style="cursor: pointer">
                            <p class="font"
                               style="text-decoration: underline">待结算金额(元)</p>
                            <p class="num"
                               style="color: #1a92ff">
                                {{ item.settleAmtStr }}
                            </p>
                        </div>
                    </div>
                    <div class="accountThird">
                        <div class="account-menu"
                             @click="withdraw(item)">提现</div>
                    </div>
                </div>
                <!-- <div class="account-menu1"
             @click="accountSetup()">提现账户设置</div> -->
            </div>
            <el-form label-width="120px">
                <el-row>
                    <el-col :lg="18"
                            :md="20">
                        <el-col :lg="8"
                                :md="12">
                            <el-form-item label="提现单号：">
                                <el-input v-model="applyForm.cashNo"
                                          size="small"
                                          placeholder="请输入提现单号"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :lg="8"
                                :md="12">
                            <el-form-item label="创建时间：">
                                <el-date-picker v-model="applyForm.createDate"
                                                type="datetimerange"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                                format="yyyy 年 MM 月 dd 日 HH 时 mm 分 ss 秒"
                                                value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :lg="6"
                            :md="8">
                            <el-button type="primary"
                                       size="small"
                                       icon="el-icon-search"
                                       @click="search()">查询</el-button>
                            <el-button size="small"
                                       icon='el-icon-refresh-left'
                                       class="chongzhi"
                                       @click="reset()">重置</el-button>
                            <el-button type="primary"
                                       size="small"
                                       icon="el-icon-finished"
                                       @click="accountSetup()">提现账户设置</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <!-- <div class="account-menu1"
                 @click="accountSetup()">提现账户设置</div> -->
            <div class="wrap">
                <el-table :data="tableDataApply"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                          border
                          :render-header="renderHeader"
                          fit
                          style="width: 100%">
                    <el-table-column fixed
                                     prop="accountBankName"
                                     label="收款账户银行名称"
                                     min-width="130"
                                     show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="accountName"
                                     label="收款账户名称"
                                     width="150px">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="accountNo"
                                     label="收款账户"
                                     min-width="160">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="aduitStatus"
                                     width="100px"
                                     label="审核状态">
                        <template slot-scope="{ row }">
                            <div>
                                <el-tag :type="(row.aduitStatus) | waitFlagTag">
                                    {{ row.aduitStatus|aduitStatusInfo }}
                                </el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="agentName"
                                     label="渠道商名称"
                                     min-width="150">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="cashAmt"
                                     label="提现金额(元)"
                                     min-width="120">
                        <template slot-scope="scope"> {{ scope.row.cashAmt }} </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="cashNo"
                                     label="提现单号"
                                     min-width="150">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="createdTime"
                                     label="创建时间"
                                     min-width="150">
                    </el-table-column>
                    <el-table-column label="操作"
                                     fixed="right"
                                     min-width="120">
                        <template slot-scope="scope">
                            <el-button @click="queryBankOrder(scope.row)"
                                       type="text"
                                       size="small">
                                查看银行订单
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wraped">
                    <pagination :total="total"
                                :currentPage="currentPage"
                                @handleSizeChange="handleSizeChangeIndex"
                                @handleCurrentChange="handleCurrentIndex"></pagination>
                </div>
            </div>
        </div>
        <!-- 提现 -->
        <div class="withdraw"
             v-if="drawStatus == 2">
            <div class="back"
                 @click="backdraw()">
                <i class="iconfont iconfanhui"></i>
                返回
            </div>
            <span class="title"> <span>|</span> 提现 </span>
            <div class="withdraw-all">
                <div class="add-left">
                    <div class="basics"
                         style="margin-left: 20%">
                        <el-form :model="ruleForm"
                                 :rules="rules"
                                 ref="ruleForm"
                                 label-width="110px"
                                 class="demo-ruleForm">
                            <el-form-item prop="amt"
                                          label="提现金额(元)"
                                          class="sign-input">
                                <el-input size="small"
                                          type="number"
                                          class="sign-el-input"
                                          placeholder="请输入提现金额"
                                          step="0.01"
                                          v-model="ruleForm.amt"></el-input>
                            </el-form-item>
                            <el-form-item label="提现绑定卡"
                                          prop="agentAccountInfoId">
                                <el-select v-model="ruleForm.agentAccountInfoId"
                                           placeholder="请选择提现绑定卡"
                                           size="small"
                                           style="width: 310px">
                                    <el-option v-for="(item, index) in binding"
                                               :key="index"
                                               :label="item.accountNo"
                                               :value="item.id">
                                    </el-option>
                                </el-select>
                                <span class="account-menu2"
                                      @click="accountSetup()">
                                    提现账户设置
                                </span>
                            </el-form-item>
                            <el-form-item prop="appendixUrl"
                                          label="发票">
                                <el-upload :action="uploadUrl"
                                           :headers="myHeaders"
                                           :on-success="handleAvatarSuccessPersonal"
                                           :before-upload="beforeUploadPersonal"
                                           multiple
                                           :limit="1"
                                           :disabled="!uploading"
                                           :on-remove="handleRemove"
                                           :before-remove="beforeRemove"
                                           style="margin-right: -100px">
                                    <div class="uploading-camp">
                                        <div class="uploading-left">
                                            <img v-if="ruleForm.appendixUrl"
                                                 class="el-upload-list__item-thumbnail"
                                                 :src="ruleForm.appendixUrl"
                                                 width="100px"
                                                 height="100px"
                                                 alt="" />
                                            <i v-else
                                               class="iconfont iconyunshangchuan"
                                               style="font-size: 80px; color: #cccccc"></i>
                                        </div>
                                        <div class="uploading-right">
                                            <p class="right-one">上传附件</p>
                                            <p class="right-two">文件上传不得大于10M</p>
                                            <p class="right-three">上传文件</p>
                                        </div>
                                    </div>
                                </el-upload>
                                <!-- <span style="color: #f56c6c"
                                      v-show="isShow">请上传附件</span> -->
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary"
                                           @click="withSubmit('ruleForm')">确认提交</el-button>
                                <el-button @click="widthCancel()">取消</el-button>
                            </el-form-item>
                        </el-form>
                        <!-- <div class="add-bottom">
              <div
                style="margin-left: -20%"
                class="add-submit"
                @click="withSubmit('ruleForm')"
              >
                确定
              </div>
              <div class="add-cancel" @click="widthCancel()">取消</div>
            </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- 渠道商分润明细 -->
        <div class="withdraw"
             v-if="drawStatus == 6">
            <div class="back"
                 @click="backdraw()">
                <i class="iconfont iconfanhui"></i>
                返回
            </div>
            <span class="title"> <span>|</span> 渠道商分润明细 </span>
            <div class="box">
                <el-form ref="releaseForm"
                         :model="releaseForm"
                         label-width="120px">
                    <el-row>
                        <el-col :lg="18"
                                :md="20">
                            <el-row>
                                <el-col :lg="8"
                                        :md="12">
                                    <el-form-item label="分润方名称：">
                                        <el-input v-model="releaseForm.profitName"
                                                  size="small"
                                                  placeholder="请输入分润方名称"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8"
                                        :md="12">
                                    <el-form-item label="服务商名称：">
                                        <el-select v-model="releaseForm.serviceId"
                                                   filterable
                                                   placeholder="请输入服务商名称"
                                                   style="width: 100%"
                                                   size="small">
                                            <el-option v-for="item in serviceList"
                                                       :key="item.serviceId"
                                                       :label="item.serviceName"
                                                       :value="item.serviceId">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8"
                                        :md="12">
                                    <el-form-item label="日期："
                                                  prop="day">
                                        <el-date-picker v-model="releaseForm.day"
                                                        type="month"
                                                        style="width: 100%"
                                                        placeholder="选择日期"
                                                        format="yyyy 年 MM 月"
                                                        value-format="yyyy-MM"
                                                        size="small">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :md="6"
                                :sm="8">
                            <el-form-item label=" ">
                                <el-button type="primary"
                                           size="small"
                                           @click="searchDay()">搜索</el-button>
                                <el-button size="small"
                                           @click="resetDay()"
                                           class="chongzhi">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>

                <div class="wrap">
                    <el-table :data="tableDataDetail"
                              :header-cell-style="{'text-align':'center'}"
                              :cell-style="{'text-align':'center'}"
                              border
                              :render-header="renderHeader"
                              show-overflow-tooltip
                              fit
                              style="width: 100%">
                        <af-table-column show-overflow-tooltip
                                         fixed
                                         prop="agentName"
                                         label="渠道商名称"
                                         width="180px">
                        </af-table-column>
                        <af-table-column show-overflow-tooltip
                                         prop="agentFee"
                                         label="渠道商费率"
                                         width="180px">
                        </af-table-column>
                        <af-table-column show-overflow-tooltip
                                         prop="createTime"
                                         label="创建时间"
                                         width="180px">
                        </af-table-column>
                        <af-table-column show-overflow-tooltip
                                         prop="day"
                                         width="150px"
                                         label="分润日期">
                        </af-table-column>
                        <af-table-column show-overflow-tooltip
                                         prop="profitAmt"
                                         label="分润金额"
                                         width="180px">
                            <template slot-scope="scope">
                                <span>{{ scope.row.profitAmt }}元</span>
                            </template>
                        </af-table-column>

                        <af-table-column show-overflow-tooltip
                                         prop="profitName"
                                         label="分润方名称"
                                         width="180px">
                        </af-table-column>
                        <af-table-column show-overflow-tooltip
                                         prop="profitType"
                                         label="分润类型"
                                         width="180px">
                            <template slot-scope="scope">
                                <span>{{
                  scope.row.profitType == "agent" ? "渠道商分润" : "商户分润"
                }}</span>
                            </template>
                        </af-table-column>
                        <af-table-column show-overflow-tooltip
                                         prop="transAmt"
                                         label="应发金额（原交易金额）"
                                         width="180px">
                            <template slot-scope="scope">
                                <span>{{ scope.row.transAmt }}元</span>
                            </template>
                        </af-table-column>
                        <af-table-column show-overflow-tooltip
                                         fixed="right"
                                         prop="transNum"
                                         label="交易笔数">
                            <template slot-scope="scope">
                                <span>{{ scope.row.transNum }}笔</span>
                            </template>
                        </af-table-column>
                    </el-table>
                    <div class="wraped">
                        <pagination :total="totalInfo"
                                    :currentPage="currentPageMath"
                                    @handleSizeChange="handleSizeChangeMath"
                                    @handleCurrentChange="handleCurrentChangeMath"></pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 查看明细 -->
        <div class="lookDetail"
             v-if="drawStatus == 3">
            <div class="back"
                 @click="backdraw()">
                <i class="iconfont iconfanhui"></i>
                返回
            </div>
            <span class="title"> <span>|</span> 分润明细 </span>
            <div class="lookDetail-all">
                <div class="account">
                    <p>汉唐融科科技有限公司</p>
                    <div class="account-left">
                        <h4>￥200,000.00</h4>
                        <div class="account-font">可提现金额</div>
                    </div>
                    <div class="account-left">
                        <h4>￥200,000.00</h4>
                        <div class="account-font">待结算金额</div>
                    </div>
                </div>
                <el-form label-width="120px">
                    <el-row>
                        <el-col :lg="18"
                                :md="20">
                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="商户名称：">
                                    <el-input size="small"
                                              placeholder="请输入商户名称"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="商户ID：">
                                    <el-input size="small"
                                              placeholder="请输入商户ID"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-col>
                        <el-col :lg="6"
                                :md="8">
                            <el-form-item label=" ">
                                <el-button type="primary"
                                           size="small"
                                           icon="el-icon-search">查询</el-button>
                                <el-button size="small"
                                           icon='el-icon-refresh-left'
                                           class="chongzhi">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
        <!-- 提现账户设置 -->
        <div class="lookDetail"
             v-if="drawStatus == 5">
            <div class="back"
                 @click="backdraw()">
                <i class="iconfont iconfanhui"></i>
                返回
            </div>
            <span class="title"> <span>|</span> 提现账户设置 </span>
            <div class="lookDetail-all"
                 style="margin-top: 30px">
                <div class="add-agent"
                     @click="accountSetupAdd()">
                    <el-button type="primary"
                               icon='el-icon-plus'
                               size="small">增加</el-button>
                </div>
                <div class="wrap">
                    <el-table :data="tableDataInfo"
                              :header-cell-style="{'text-align':'center'}"
                              :cell-style="{'text-align':'center'}"
                              fit
                              border
                              :render-header="renderHeader"
                              style="width: 100%">
                        <!-- <el-table-column show-overflow-tooltip
                             fixed
                             prop="applyTime"
                             label="序号"
                             min-width="130">
            </el-table-column> -->
                        <el-table-column show-overflow-tooltip
                                         prop="accountName"
                                         label="收款账户"
                                         width="150px">
                        </el-table-column>
                        <el-table-column show-overflow-tooltip
                                         prop="accountNo"
                                         label="收款账号"
                                         min-width="160">
                        </el-table-column>
                        <el-table-column show-overflow-tooltip
                                         prop="accountBankName"
                                         label="开户行"
                                         min-width="150">
                        </el-table-column>
                        <el-table-column label="操作"
                                         fixed="right"
                                         min-width="120">
                            <template slot-scope="scope">
                                <el-dropdown>
                                    <span class="el-dropdown-link"
                                          style="cursor: pointer">
                                        更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>
                                            <el-button @click="updateRow(1, scope.row)"
                                                       size="mini"
                                                       class="my-2 w_fluid"
                                                       plain>
                                                删除
                                            </el-button>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <el-button @click="updateRow(2, scope.row)"
                                                       size="mini"
                                                       class="my-2 w_fluid"
                                                       plain>
                                                编辑
                                            </el-button>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- <div class="wraped">
            <pagination
              :total="total"
              :currentPage="currentPage"
            ></pagination>
          </div> -->
                </div>
            </div>
        </div>
        <!-- 增加提现账户设置 -->
        <el-dialog :title="titleInfo ? '添加配置列表' : '修改配置列表'"
                   :visible.sync="dialogVisible"
                   width="40%"
                   :before-close="handleClose">
            <el-form ref="accountForm"
                     :model="accountForm"
                     :rules="accountRules"
                     label-width="150px"
                     class="demo-ruleForm">
                <el-form-item label="开户行："
                              prop="accountBankName">
                    <div class="list">
                        <el-input style="width: 90%"
                                  v-model="accountForm.accountBankName"
                                  placeholder="请输入开户行"
                                  size="small"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="收款账户："
                              prop="accountName">
                    <div class="list">
                        <el-input style="width: 90%"
                                  v-model="accountForm.accountName"
                                  placeholder="请输入收款账户"
                                  size="small"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="收款账号："
                              prop="accountNo">
                    <div class="list">
                        <el-input style="width: 90%"
                                  v-model="accountForm.accountNo"
                                  placeholder="请输入收款账号"
                                  size="small"></el-input>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer"
                  class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary"
                           @click="addAccount('accountForm')">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="银行订单列表"
                   :visible.sync="dialogVisibleBank"
                   width="80%"
                   style="text-align: left"
                   :before-close="handleCloseBank">
            <div class="wrap">
                <el-table :data="tableDataBank"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                          fit
                          border
                          :render-header="renderHeader"
                          style="width: 100%"
                          show-overflow-tooltip>
                    <af-table-column show-overflow-tooltip
                                     prop="accountName"
                                     fixed
                                     label="收款账户名称">
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="accountNo"
                                     label="收款账户">
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="agentName"
                                     label="渠道商名称">
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="cashActualAmt"
                                     label="实际到账金额(元)">
                        <template slot-scope="scope">
                            {{
                 scope.row.cashActualAmt
              }}
                        </template>
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="cashAmt"
                                     label="提现金额(元)">
                        <template slot-scope="scope"> {{ scope.row.cashAmt }} </template>
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="cashAmt"
                                     label="提现流水">
                        <template slot-scope="scope"> {{ scope.row.cashTransNo }} </template>
                    </af-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="cashStatus"
                                     width="100px"
                                     label="交易状态">
                        <template slot-scope="{ row }">
                            <div>
                                <el-tag :type="(row.cashStatus) | waitFlagTagStatus">
                                    {{ row.cashStatus|cashStatusInfoTwo }}
                                </el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="createdTime"
                                     label="创建时间">
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="invoiceUrl"
                                     label="发票地址"
                                     width="120">
                        <template slot-scope="scope">
                            <a href="javascript:;"
                               @click="seeInvoice(scope.row)">查看发票地址</a>
                        </template>
                        <!-- <template slot-scope="scope">
              <a v-if="scope.row.invoiceUrl"
                 :href="scope.row.invoiceUrl"
                 style="
                  color: #1785ff;
                  cursor: pointer;
                  text-decoration: underline;
                ">发票地址</a><a v-else></a>
            </template> -->
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="payBankName"
                                     label="付款方">
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="payBankN"
                                     label="付款方银行名称"></af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="payBankNo"
                                     label="付款方账号">
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="receiptUrl"
                                     label="回单地址"
                                     width="120px">
                        <template slot-scope="scope">
                            <a href="javascript:;"
                               @click="receipt(scope.row)">查看回单地址</a>
                        </template>
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     fixed="right"
                                     prop="successTime"
                                     label="成功时间">
                    </af-table-column>
                </el-table>
                <!-- <div class="wraped">
          <pagination
            :total="totalBank"
            :currentPage="currentPageBank"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          ></pagination>
        </div> -->
            </div>
        </el-dialog>
        <!-- 查看发票地址 -->
        <el-dialog title="查看发票地址"
                   :visible.sync="dialogVisibleInfo"
                   width="50%"
                   :before-close="handleClose">
            <img :src="invoiceImg"
                 alt=""
                 width="100%"
                 height="100%"
                 style="margin:0 auto">
            <span slot="footer"
                  class="dialog-footer">
                <el-button @click="dialogVisibleInfo = false">取 消</el-button>
                <!-- <el-button type="primary"
                   @click="dialogVisibleInfo = false">确 定</el-button> -->
            </span>
        </el-dialog>
        <!-- 查看回单地址 -->
        <el-dialog title="查看回单地址"
                   :visible.sync="dialogVisiblereceipt"
                   width="50%">
            <img :src="receiptUrlImg"
                 alt=""
                 width="100%"
                 height="100%"
                 style="margin:0 auto">
            <span slot="footer"
                  class="dialog-footer">
                <el-button @click="dialogVisiblereceipt = false">取 消</el-button>
                <!-- <el-button type="primary"
                   @click="dialogVisiblereceipt = false">确 定</el-button> -->
            </span>
        </el-dialog>
    </div>
</template>
<script>
import pagination from '../../components/pagination.vue';
// import tableHeight from "../../mixins/tableHeight";
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
import { f2y, y2f } from '../../util/util.js';
export default {
    filters: {
        waitFlagTag(code) {
            const statusMap = {
                2: 'success',
                3: 'danger'
            };
            return statusMap[code] || 'info';
        },
        waitFlagTagStatus(code) {
            const statusMap = {
                '00': 'success',
                '02': 'danger',
                '01': 'warning'
            };
            return statusMap[code] || 'info';
        }
    },
    // 默认高度
    // mixins: [tableHeight],
    data() {
        return {
            // 渠道商类型
            uploading: '',
            // 查看发票类型
            dialogVisibleInfo: false,
            invoiceImg: '',
            // 查看回单地址
            dialogVisiblereceipt: false,
            receiptUrlImg: '',
            // 上传图片
            // isShow: false,
            titleInfo: '',
            // 待结算金额分页
            totalInfo: null,
            currentPageMath: 1,
            releaseForm: {
                profitName: '',
                serviceId: '',
                day: ''
            },
            tableDataDetail: [],
            // releaseForm: {},
            // 查看银行订单id
            cashTransNo: '',
            // 申请检索条件
            applyForm: {
                cashNo: '',
                createDate: ['', '']
            },
            dialogVisibleBank: false,
            pageSzie: 10,
            // 首页分页
            total: null,
            currentPage: 1,
            // 查看银行账单分页
            totalBank: null,
            currentPageBank: 1,
            // 上传图片
            uploadUrl: api.uploadOss,
            myHeaders: { Authorization: this.$cookies.get('esignmerchantsid') },
            // 渠道商账户信息
            AccountInfo: [],
            dialogVisible: false,
            optionsCopy: [
                {
                    value: '1',
                    label: 'meat'
                },
                {
                    value: '2',
                    label: 'drink'
                },
                {
                    value: '3',
                    label: 'food'
                },
                {
                    value: '4',
                    label: '龙须面'
                },
                {
                    value: '5',
                    label: '北京烤鸭'
                }
            ],
            options: [
                {
                    value: '1',
                    label: 'meat'
                },
                {
                    value: '2',
                    label: 'drink'
                },
                {
                    value: '3',
                    label: 'food'
                },
                {
                    value: '4',
                    label: '龙须面'
                },
                {
                    value: '5',
                    label: '北京烤鸭'
                }
            ],
            // 步骤
            activeIndex: 0,
            ductInfo: 1,
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            accountForm: {
                accountBankName: '',
                accountName: '',
                accountNo: ''
            },
            accountRules: {
                accountBankName: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
                accountName: [{ required: true, message: '请输入收款账户', trigger: 'blur' }],
                accountNo: [{ required: true, message: '请输入收款账号', trigger: 'blur' }]
            },
            ruleForm: {
                agentAccountInfoId: '',
                amt: '',
                appendixUrl: '',
                id: '',
                serviceId: '',
                serviceName: ''
            },
            rules: {
                // amt: [{ required: true, message: "请输入提现金额", trigger: "blur" }],
                // appendixUrl: [{ required: true, message: "请上传附件", trigger: "blur" }],
                amt: [
                    { required: true, trigger: 'change', message: '请输入金额' }
                    // {
                    //   pattern: /(^[1-9]([0.01-9]+)?(\.[0.01-9]{1,2})?$)|(^(0.01){1}$)|(^[0.01-9]\.[0.01-9]([0.01-9])?$)/,
                    //   message: "请输入正确的金额,可保留两位小数",
                    // },
                ],
                agentAccountInfoId: [
                    { required: true, message: '请选择提现绑定卡id', trigger: 'change' }
                ]
            },
            drawStatus: 1,
            tableData: [],
            // 申请列表
            tableDataApply: [],
            tableDataInfo: [],
            binding: [],
            // 查看银行订单
            tableDataBank: []
        };
    },

    mounted() {
        // 渠道商账户信息
        this.channelAccount();
        // 申请列表
        this.channelApply();
        // 渠道商类型
        this.getAgentType();
        // 服务商信息
        this.serviceInfo();
    },
    created() {
        this.defaultDate();
    },
    methods: {
        serviceInfo() {
            post(api.queryServiceList).then(res => {
                if (res.code == 0) {
                    if (res.data != '') {
                        // this.releaseForm.serviceId = res.data[0].serviceId;
                    }
                    this.serviceList = res.data;
                    this.channelAccount();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取渠道商类型
        getAgentType() {
            post(api.getAgentType).then(res => {
                if (res.code == 0) {
                    this.uploading = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 查看发票地址
        seeInvoice(row) {
            this.dialogVisibleInfo = true;
            this.invoiceImg = row.invoiceUrl;
        },

        // 查看回单地址
        receipt(row) {
            this.dialogVisiblereceipt = true;
            this.receiptUrlImg = row.receiptUrl;
        },
        //设置默认日期
        defaultDate() {
            //获取新的时间(2019.4.12）
            let date = new Date();
            //获取当前时间的年份转为字符串
            let year = date.getFullYear().toString(); //'2019'
            //获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接'0'
            let month =
                date.getMonth() + 1 < 10
                    ? '0' + (date.getMonth() + 1).toString()
                    : (date.getMonth() + 1).toString(); //'04'
            //获取天，判断是否小于10，如果是在字符串前面拼接'0'
            let da =
                date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString(); //'12'
            //字符串拼接，开始时间，结束时间
            let end = year + '-' + month + '-' + da; //当天'2019-04-12'
            let beg = year + '-' + month; //当月第一天'2019-04-01'
            this.releaseForm.day = beg; //将值设置给插件绑定的数据
        },
        // 表格
        renderHeader(h, data) {
            return h('span', [
                h('el-tooltip'),
                {
                    attrs: {
                        class: 'item',
                        effect: 'dark',
                        content: data.column.label
                    }
                },
                [h('span', data.column.label)]
            ]);
        },

        // 待结算金额
        widthClose(serviceId) {
            this.releaseForm.serviceId = serviceId;
            this.drawStatus = 6;
            this.agentDetail();
        },
        agentDetail(size) {
            const loading = this.$loading({
                lock: true,
                spinner: 'loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            post(api.profitDetailDay, {
                currentPage: size || 1,
                pageSize: this.pageSzie,
                profitName: this.releaseForm.profitName,
                day: this.releaseForm.day,
                serviceId: this.releaseForm.serviceId
            }).then(d => {
                // console.log(d, "待结算金额");
                if (d.code == 0) {
                    this.totalInfo = d.data.count;
                    this.currentPageMath = d.data.currentPage;
                    loading.close();
                    this.tableDataDetail = d.data.rows;
                    this.tableDataDetail.map(item => {
                        item.profitAmt = f2y(item.profitAmt);
                        item.transAmt = f2y(item.transAmt);
                    });
                } else {
                    this.$message.error(d.message);
                    loading.close();
                }
            });
        },
        // 查询
        search() {
            // 渠道商账户信息
            this.channelAccount();
            // 申请列表
            this.channelApply();
        },
        // 重置
        reset() {
            this.applyForm = {
                cashNo: '',
                createDate: ['', '']
            };
        },
        // 查询
        searchDay() {
            this.agentDetail();
        },
        // 重置
        resetDay() {
            this.releaseForm = {
                profitName: '',
                day: ''
            };
            this.agentDetail();
        },
        // 查看银行订单
        queryBankOrder(row, size) {
            this.cashTransNo = row.cashTransNo;
            const loading = this.$loading({
                lock: true,
                spinner: 'loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            post(api.cashOrderPage, {
                currentPage: size || 1,
                pageSize: this.pageSzie,
                cashTransNo: this.cashTransNo
            }).then(d => {
                this.dialogVisibleBank = true;
                // console.log(d, "查看银行");
                if (d.code == 0) {
                    this.totalBank = d.data.count;
                    this.currentPageBank = d.data.currentPage;
                    this.tableDataBank = d.data.rows;
                    this.tableDataBank.map(item => {
                        item.cashAmt = f2y(item.cashAmt);
                        if (item.cashActualAmt == 'null') {
                            item.cashActualAmt = '';
                        } else {
                            item.cashActualAmt = f2y(item.cashActualAmt);
                        }
                    });
                    loading.close();
                } else {
                    this.$message.error(d.message);
                    loading.close();
                }
            });
        },
        handleCloseBank() {
            this.dialogVisibleBank = false;
        },
        // 申请列表
        channelApply(size) {
            const loading = this.$loading({
                lock: true,
                spinner: 'loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            post(api.applyPage, {
                currentPage: size || 1,
                pageSize: this.pageSzie,
                cashNo: this.applyForm.cashNo,
                createTimeStart: this.applyForm.createDate[0],
                createTimeEnd: this.applyForm.createDate[1]
            }).then(d => {
                // console.log(d, "申请列表");
                if (d.code == 0) {
                    this.tableDataApply = d.data.rows;
                    this.tableDataApply.map(item => {
                        item.cashAmt = f2y(item.cashAmt);
                    });
                    this.total = d.data.count;
                    this.currentPage = d.data.currentPage;
                    // console.log(this.tableDataApply, "55555");
                    loading.close();
                } else {
                    this.$message.error(d.message);
                    loading.close();
                }
            });
        },
        // 渠道商账户信息
        channelAccount() {
            post(api.queryAccount).then(d => {
                // console.log(d, "渠道商账户信息");
                if (d.code == 0) {
                    this.AccountInfo = d.data;
                    // console.log(this.AccountInfo, '待结算金额');
                    for (var i = 0; i < this.AccountInfo.length; i++) {
                        this.AccountInfo[i].cashAmtStr = f2y(this.AccountInfo[i].cashAmtStr);
                        this.AccountInfo[i].cashFreezeStr = f2y(this.AccountInfo[i].cashFreezeStr);
                        this.AccountInfo[i].settleAmtStr = f2y(this.AccountInfo[i].settleAmtStr);
                    }
                    //   this.AccountInfo.cashAmtStr = f2y(this.AccountInfo.cashAmtStr);
                    //   this.AccountInfo.cashFreezeStr = f2y(this.AccountInfo.cashFreezeStr);
                    //   this.AccountInfo.settleAmtStr = f2y(this.AccountInfo.settleAmtStr);
                } else {
                    this.$message.error(d.message);
                }
            });
        },
        // 获取渠道商账户配置列表
        channelConfig() {
            post(api.getAccountInfoList).then(d => {
                // console.log(d, "渠道商配置列表");
                if (d.code == 0) {
                    this.tableDataInfo = d.data;
                    this.binding = d.data;
                } else {
                    this.$message.error(d.message);
                }
            });
        },
        // 添加配置列表
        addAccount(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (this.titleInfo == true) {
                        post(api.addAccountInfo, this.accountForm).then(d => {
                            // console.log(d, "添加配置列表");
                            if (d.code == 0) {
                                this.$message.success(d.message);
                                this.dialogVisible = false;
                                this.channelConfig();
                            } else {
                                this.$message.error(d.message);
                            }
                        });
                    } else {
                        post(api.updateAccountInfo, this.accountForm).then(d => {
                            if (d.code == 0) {
                                // console.log(d, "编辑成功");
                                if (d.code == 0) {
                                    this.$message.success(d.message);
                                    this.dialogVisible = false;
                                    this.channelConfig();
                                }
                            } else {
                                this.$message.error(d.message);
                            }
                        });
                    }
                }
            });
        },

        // 上传附加
        beforeUploadPersonal() {},
        handleAvatarSuccessPersonal(res, file, files, key, val) {
            // console.log(res, "上传附件");
            if (res.code == 0) {
                this.ruleForm.appendixUrl = res.data;
                this.$forceUpdate();
            }
        },
        // 删除图片反面
        handleRemove(file, fileList) {
            // console.log(file, "4444444");
            this.ruleForm.appendixUrl = '';
            this.$forceUpdate();
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        updateRow(type, row) {
            if (type == 1) {
                this.$confirm('此操作将删除配置列表, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    post(api.deleteAccountInfo, { id: row.id }).then(d => {
                        if (d.code == 0) {
                            this.$message.success('删除成功');
                            this.channelConfig();
                        } else {
                            this.$message.error(d.message);
                        }
                    });
                });
            } else {
                this.dialogVisible = true;
                this.titleInfo = false;
                this.accountForm = {
                    accountBankName: row.accountBankName,
                    accountName: row.accountName,
                    accountNo: row.accountNo,
                    id: row.id
                };
            }
        },
        accountSetupAdd() {
            this.dialogVisible = true;
            this.titleInfo = true;
            this.accountForm = {};
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
        // 提现账户设置
        accountSetup() {
            this.drawStatus = 5;
            // 获取渠道商账户配置列表
            this.channelConfig();
        },
        // 查看结果
        lookresult() {
            this.drawStatus = 1;
        },
        // 立即进行实名
        handInfo() {
            this.activeIndex = 3;
            this.ductInfo = 3;
        },
        // 下一步
        nextSetpInfo() {
            this.ductInfo = 2;
            this.activeIndex = 1;
        },
        // 点击计算分润
        countShare() {
            this.drawStatus = 4;
        },
        // 返回
        backdraw() {
            if (this.ductInfo == 2) {
                this.activeIndex = 0;
                this.ductInfo = 1;
            } else if (this.ductInfo == 3) {
                this.activeIndex = 1;
                this.ductInfo = 2;
            } else {
                this.drawStatus = 1;
            }
        },
        // 提现
        withdraw(item) {
            this.ruleForm.id = item.id;
            this.ruleForm.serviceId = item.serviceId;
            this.ruleForm.serviceName = item.serviceName;
            // console.log(item, 333);
            this.drawStatus = 2;
            this.channelConfig();
        },
        // cash(e) {
        //   if (e <= 0) {
        //     this.$message.error("提现金额不能小于0");
        //     this.ruleForm.amt = "";
        //     return;
        //   }
        // },
        // 提现确定
        withSubmit(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    // if (!this.ruleForm.appendixUrl) {
                    //     this.isShow = true;
                    //     return;
                    // } else {
                    //     this.isShow = false;
                    // }
                    const loading = this.$loading({
                        lock: true,
                        spinner: 'loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    const payLoad = JSON.parse(JSON.stringify(this.ruleForm));
                    payLoad.amt = y2f(this.ruleForm.amt);
                    post(api.apply, payLoad).then(d => {
                        // console.log(d, "申请提现");
                        if (d.code == 0) {
                            this.$message.success(d.message);
                            this.drawStatus = 1;
                            this.channelAccount();
                            this.channelApply();
                            this.ruleForm = {};
                            loading.close();
                        } else {
                            this.$message.error(d.message);
                            loading.close();
                        }
                    });
                }
            });
        },
        // 提现取消
        widthCancel() {
            this.drawStatus = 1;
            this.ruleForm = {};
            this.channelAccount();
        },
        // 查看明细
        lookDetail() {
            this.drawStatus = 3;
        },
        // 查看银行订单
        // handleSizeChange(val) {
        //   this.pageSzie = val;
        //   this.queryBankOrder();
        // },
        // handleCurrentChange(size) {
        //   this.queryBankOrder(size);
        // },
        //首页
        handleSizeChangeIndex(val) {
            this.pageSzie = val;
            this.channelApply();
        },
        handleCurrentIndex(size) {
            this.channelApply(size);
        },
        //待结算金额
        handleSizeChangeMath(val) {
            this.pageSzie = val;
            this.agentDetail();
        },
        handleCurrentChangeMath(size) {
            this.agentDetail(size);
        }
    },
    components: {
        pagination: pagination
    }
};
</script>
