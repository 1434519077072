<template>
    <div class="exitPassword">
        <!-- 账户密码 -->
        <el-dialog :title="dialogTitle"
                   width="610px"
                   :visible.sync="dialogInvoice"
                   :before-close="handleClose">
            <div v-if="showTick">
                <div style="width: 500px">
                    <el-form :model="ruleForm"
                             :rules="rules"
                             ref="ruleForm"
                             label-width="140px"
                             class="demo-ruleForm">
                        <!-- <el-form-item label="登录密码:" prop="oldPassword">
            <el-input placeholder="请输入登录密码" v-model="ruleForm.oldPassword" show-password></el-input>
          </el-form-item> -->
                        <el-form-item label="新密码"
                                      prop="newPassword">
                            <el-input type="password"
                                      placeholder="请输入新密码"
                                      v-model="ruleForm.newPassword"
                                      show-password></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码"
                                      prop="confirmPassword">
                            <el-input type="password"
                                      placeholder="请再次输入密码"
                                      v-model="ruleForm.confirmPassword"
                                      show-password></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div slot="footer"
                     class="dialog-footer">
                    <el-button @click="resetForm('ruleForm')">取消</el-button>
                    <el-button type="primary"
                               @click="submitForm('ruleForm')">确定</el-button>
                </div>
            </div>
            <div v-else>
                <div style="width: 500px; margin: 0 auto">
                    <div class="main">
                        <div>
                            <span>
                                短信验证码会发送至您实名认证的手机号:
                                <br />
                                {{ phone }},请注意查收
                            </span>
                        </div>
                        <div class="validation">
                            <div class="left">
                                <el-input prefix-icon="el-icon-set-up"
                                          v-model="graphics"
                                          placeholder="请输入手机验证码"
                                          @keyup.enter.native="homeSkip"></el-input>
                            </div>
                            <div class="obtain"
                                 @click="validationSms(merchantloginPhone)">
                                {{ countdownHtml }}
                            </div>
                        </div>
                    </div>
                    <div slot="footer"
                         class="dialog-footer">
                        <el-button @click="quxiaoBtn">取消</el-button>
                        <el-button type="primary"
                                   @click="homeSkip"
                                   :loading="shenheLoading">确定</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';

export default {
    name: 'exitPassword',
    data() {
        let validatePassword = (rule, value, callback) => {
            var reg1 = /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z]{8,}$/; //密码必须是8位以上、必须含有字母、数字
            // var reg2 = /(123|234|345|456|567|678|789|012)/; //不能有3个连续数字
            if (!reg1.test(value)) {
                callback(new Error('密码必须是8位以上、必须含有字母、数字'));
            } else {
                callback();
            }
        };
        // var validatePass = (rule, value, callback) => {
        //   if (value === "") {
        //     callback(new Error("请输入密码"));
        //   } else {
        //     if (this.ruleForm.newPasswordConfirm !== "") {
        //       this.$refs.ruleForm.validateField("newPasswordConfirm");
        //     }
        //     callback();
        //   }
        // };
        // var validatePass = (rule, value, callback) => {
        //   var type = /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{8,20}$/i;
        //   if (value === "") {
        //     callback(new Error("请输入密码"));
        //   }else if (!type.test(value)) {
        //     callback(new Error("请按规则输入密码"));
        //   } else {
        //     callback();
        //   }
        // };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            showTick: true,
            graphics: '',
            countdownHtml: '获取验证码',
            times: 60,
            timer: null,
            merchantloginPhone: '',
            shenheLoading: false,
            phone: '',
            ruleForm: {
                newPassword: '',
                confirmPassword: ''
            },
            rules: {
                newPassword: [{ validator:validatePassword,message: '请输入新密码,密码必须是8位以上、必须含有字母、数字', trigger: 'blur', required: true }],
                confirmPassword: [{ validator: validatePass2, trigger: 'blur', required: true }]
            }
        };
    },
    props: {
        dialogInvoice: Boolean,
        dialogTitle: String
    },
    created() {
        this.merchantloginPhone = this.$cookies.get('merchantloginPhone') || '';
    },
    mounted() {},
    methods: {
        quxiaoBtn() {
            this.showTick = true;
        },
        //60秒倒计时
        countdown() {
            var that = this;
            if (that.times == 0) {
                clearInterval(this.timer);
                that.countdownHtml = '获取验证码';
                that.times = 60;
                return false;
            } else {
                that.countdownHtml = '' + that.times + 's';
                that.times--;
            }
        },
        validationSms() {
            if (this.times != 60) {
                return;
            }
            if (!this.merchantloginPhone) {
                this.$message.error('请输入手机号');
                return;
            }
            //   短信验证码
            var regMoblie = /^1[345678]\d{9}$/;
            if (!regMoblie.test(this.merchantloginPhone)) {
                this.$message.error('该手机号格式错误');
                return;
            }
            this.countdown();
            this.timer = setInterval(this.countdown, 1000);
            if (this.dialogTitle == '修改账户密码') {
                post(api.pwdSmsCode, {
                    mobile: this.merchantloginPhone
                }).then(
                    d => {
                        if (d.code == 0) {
                            this.$message.success('发送成功');
                        } else {
                            clearInterval(this.timer);
                            this.countdownHtml = '获取验证码';
                            this.times = 60;
                            this.$message.error(d.message);
                        }
                        //success callback
                    },
                    err => {
                        //error callback
                    }
                );
            } else {
                post(api.companyPwdSmsCode, {
                    mobile: this.merchantloginPhone
                }).then(
                    d => {
                        if (d.code == 0) {
                            this.$message.success('发送成功');
                        } else {
                            clearInterval(this.timer);
                            this.countdownHtml = '获取验证码';
                            this.times = 60;
                            this.$message.error(d.message);
                        }
                        //success callback
                    },
                    err => {
                        //error callback
                    }
                );
            }
        },
        handleClose(done) {
            (this.ruleForm = {
                newPassword: '',
                confirmPassword: ''
            }),
                (this.showTick = true);
            this.$emit('handleClose', false);
        },
        homeSkip() {
            this.shenheLoading = true;
            if (this.graphics == '') {
                this.$message.error('请输入验证码');
                this.shenheLoading = false;
                return;
            }
            if (this.dialogTitle == '修改账户密码') {
                post(api.pwdSmsReset, {
                    mobile: this.merchantloginPhone,
                    newPassword: this.ruleForm.newPassword,
                    confirmPassword: this.ruleForm.confirmPassword,
                    smsCode: this.graphics
                }).then(
                    d => {
                        this.shenheLoading = false;
                        if (d.code == 0) {
                            this.$message.success('修改成功');
                            this.$emit('handleClose', false);
                            // console.log(d.code,'小好测试进入退出登录111111111111111')
                            this.$cookies.remove('esignmerchantsid');
                            this.$cookies.remove('merchantloginPhone');
                            this.$cookies.remove('permissions');
                            localStorage.removeItem('store');
                            this.$cookies.remove('mchcompanyId');
                            // console.log(this.$cookies.get("esignmerchantsid"),'小好测试删除token退出登录111111111111111')
                            this.$cookies.remove('token_kick');
                            this.$router.push({ path: '/login' });
                            this.showTick = true;
                            (this.ruleForm = {
                                newPassword: '',
                                confirmPassword: ''
                            }),
                                this.$router.push({ path: '/' });
                        } else {
                            this.$message.error(d.message);
                        }

                        //success callback
                    },
                    err => {
                        this.shenheLoading = false;
                        //error callback
                    }
                );
            } else {
                post(api.companyPwdSmsReset, {
                    mobile: this.merchantloginPhone,
                    newPassword: this.ruleForm.newPassword,
                    confirmPassword: this.ruleForm.confirmPassword,
                    smsCode: this.graphics
                }).then(
                    d => {
                        this.shenheLoading = false;
                        if (d.code == 0) {
                            this.$message.success('修改成功');
                            this.$emit('handleClose', false);
                            this.showTick = true;
                            (this.ruleForm = {
                                newPassword: '',
                                confirmPassword: ''
                            }),
                                this.$router.push({ path: '/' });
                        } else {
                            this.$message.error(d.message);
                        }

                        //success callback
                    },
                    err => {
                        this.shenheLoading = false;
                        //error callback
                    }
                );
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (this.dialogTitle == '修改账户密码') {
                        if (this.ruleForm.newPassword.length < 8) {
                            this.$message.error('新密码最少八个字符');
                            return;
                        }
                    } else {
                        if (this.ruleForm.newPassword.length < 6) {
                            this.$message.error('新密码最少六个字符');
                            return;
                        }
                    }

                    this.showTick = false;
                    this.phone = this.merchantloginPhone.replace(
                        new RegExp('(\\d{3})(\\d{4})(\\d{4})'),
                        '$1****$3'
                    );
                    clearInterval(this.timer);
                    this.countdownHtml = '获取验证码';
                    this.times = 60;
                    this.graphics = '';
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            (this.ruleForm = {
                newPassword: '',
                confirmPassword: ''
            }),
                this.$emit('handleClose', false);
            this.$refs[formName].resetFields();
        }
    }
};
</script>

<style lang="less" scoped>
.exitPassword {
    width: 100%;
    height: 100%;
    .main {
        width: 100%;
        height: auto;
        .validation {
            width: 85%;
            height: 52px;
            margin: 40px auto;
            .left {
                float: left;
                width: 257px;
                border-radius: 6px;
            }
            .obtain {
                float: right;
                width: 114px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background: #409eff;
                border-radius: 6px;
                border: 1px solid #e0e0e0;
                font-size: 13px;
                font-family: PingFangSC;
                font-weight: 400;
                color: white;
                cursor: pointer;
            }
            .right {
                float: right;
                width: 97px;
                height: 52px;
                padding: 8px;
                box-sizing: border-box;
                background: rgba(245, 245, 245, 1);
                div {
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    cursor: pointer;
                }
            }
        }

        .determine {
            width: 85%;
            margin: 0 auto;
            height: 40px;
            background: rgba(121, 176, 250, 1);
            box-shadow: 0px 1px 9px 0px rgba(0, 121, 254, 0.54);
            border-radius: 2px;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #ffffff;
            margin-top: 9%;
            cursor: pointer;
        }
        .determineNo {
            width: 85%;
            margin: 0 auto;
            height: 40px;
            background: rgba(121, 176, 250, 0.5);
            border-radius: 2px;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #ffffff;
            margin-top: 9%;
            cursor: pointer;
        }
    }
}
</style>

<style>
</style>