import Vue from 'vue';
import store from '../store';
import Config from '../util/constant';
import {
  Message
} from 'element-ui';
import VueCookie from 'vue-cookie';
import _ from 'underscore';
import api from "@/api/api.js";
import {
  get,
  post
} from "@/api/index.js";

let permissions = [];

function getPermission(resources) {
  if (!resources && resources.length == 0) {
    return;
  }
  // console.log(resources,'resources')
  for (let item of resources) {
    if (item.hasOwnProperty("resourceCode")) {
      permissions.push(item.resourceCode);
    }
    if (item.hasOwnProperty("childrens") && item.childrens.length > 0) {
      getPermission(item.childrens);
    }
  }
}

export default () => new Promise((resolve, reject) => {
  // let  menuList= 
  // [  
  //    {
  //     icon: 'el-icon-s-platform',
  //     name: '任务中心',
  //     childrens:[{
  //             name: '我发布的任务',
  //             path: '/',
  //             resourceCode: 'publish_task',
  //             childrens:
  //             [{
  //                 name: '我发布的任务-发布任务',
  //                 path: 'url',
  //                 resourceCode: 'publish_taskbtn',
  //                 childrens:[]
  //             }]
  //         }, {
  //             name: '投递人员信息',
  //             path:'/delivery',
  //             resourceCode: 'delivery_personnel',
  //             childrens:[]
  //         }]
  //     },
  //     {
  //                 icon: 'el-icon-s-shop',
  //                 name: '合同中心',
  //                 childrens: 
  //                 [{
  //                     name: '签约管理',
  //                     path:'/signing',
  //                     resourceCode: 'contract_management',
  //                     childrens:[]
  //                 }]
  //     },
  //     {
  //             icon: 'el-icon-s-management',
  //             name: '交易中心',
  //             childrens: 
  //             [{
  //                 name: '工单管理',
  //                 path:'/repairOrder',
  //                 resourceCode: 'order_management',
  //                 childrens:[]
  //             }, {
  //                 name: '交易管理',
  //                 path:'/trading',
  //                 resourceCode: 'transaction_management',
  //                 childrens:[
  //                     {
  //                     name: '交易管理-批量打款',
  //                     path: 'url',
  //                     resourceCode: 'transaction_payment',
  //                     childrens:[]
  //                 }
  //                 ]
  //             }],
  //     },{
  //         icon: 'el-icon-s-ticket',
  //         name: '商户中心',
  //         childrens: 
  //         [{
  //             name: '权限角色',
  //             path:'/permissionrole',
  //             resourceCode: 'authority_management',
  //             childrens:[{
  //                 name: '权限角色-增加角色',
  //                     path: 'url',
  //                     resourceCode: 'add_role',
  //                     childrens:[]
  //             }]
  //         }]
  //     }
  //  ];
  post(api.currentResources).then(
    (d) => {
      if (d.code === 0) {
        getPermission(d.data);
        let permissionsTostring = permissions.join(',') || '!';
        if (permissionsTostring.length) {
          d.data = d.data;
        }
        console.log(permissionsTostring, 236521452141)
        // VueCookie.set('permissions', permissionsTostring);
        store.dispatch('getMenu', d.data);
        resolve();
      } else {
        Vue.prototype.$message.error(d.message);
        // console.log(d.message);
      }
    },
    (err) => {
      //error callback
    }
  );

  return


});