import { render, staticRenderFns } from "./agentInfomat.vue?vue&type=template&id=4fd756ae&scoped=true&"
import script from "./agentInfomat.vue?vue&type=script&lang=js&"
export * from "./agentInfomat.vue?vue&type=script&lang=js&"
import style0 from "./agentInfomat.vue?vue&type=style&index=0&id=4fd756ae&lang=less&scoped=true&"
import style1 from "./agentInfomat.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fd756ae",
  null
  
)

export default component.exports