<style lang="less" scoped>
.permissionrole {
    width: 100%;
    min-height: 100%;
    // padding: 12px;
    box-sizing: border-box;
    background-color: #fff;
    text-align: left;
    .box {
        width: 100%;
        padding-top: 12px;
        .toobar {
            .main_button {
                margin-left: 36px;
            }
        }
        .wrap {
            width: 100%;
            padding: 12px;
            box-sizing: border-box;
            .wraped {
                width: 100%;
            }
        }
    }
}
</style>
<style>
.permissionrole .el-table__fixed-right {
    right: 0 !important;
}
/* 树形结构节点添加连线 */
.permissionrole .tree .el-tree-node {
    position: relative;
}

.permissionrole .tree .el-tree-node__children {
    padding-left: 16px;
}

.permissionrole .tree .el-tree-node :last-child:before {
    height: 38px;
}

.permissionrole .tree .el-tree > .el-tree-node:before {
    border-left: none;
}
.permissionrole .tree-container .el-tree > .el-tree-node:after {
    border-top: none;
}

.permissionrole .tree .el-tree-node__children .el-tree-node:before {
    content: '';
    left: -4px;
    position: absolute;
    right: auto;
    border-width: 1px;
}

.permissionrole .tree .el-tree-node:after {
    content: '';
    left: -4px;
    position: absolute;
    right: auto;
    border-width: 1px;
}
.permissionrole .tree .el-tree-node__expand-icon.is-leaf {
    display: none;
}

.permissionrole .tree .el-tree-node:before {
    border-left: 1px dashed #dddddd;
    bottom: 0px;
    height: 100%;
    top: -26px;
    width: 1px;
}

.permissionrole .tree .el-tree-node:after {
    border-top: 1px dashed #dddddd;
    height: 20px;
    top: 12px;
    width: 24px;
}
.permissionrole .tree .custom-tree-node {
    padding-left: 10px;
}
</style>


<template>
    <div class="permissionrole">
        <div class="box">
            <el-form ref="releaseForm"
                     :model="releaseForm"
                     label-width="120px">
                <el-row>
                    <el-col :lg="18"
                            :md="20">
                        <el-row>
                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="角色名称：">
                                    <el-input v-model="releaseForm.name"
                                              size="small"
                                              placeholder="请选择角色名称"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :md="6"
                            :sm="8">
                        <el-form-item label=" ">
                            <el-button type="primary"
                                       size="small"
                                       icon="el-icon-search"
                                       @click="search()">查询</el-button>
                            <el-button size="small"
                                       icon='el-icon-refresh-left'
                                       class="chongzhi"
                                       @click="reset()">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="toobar">
                <div class="main_button">
                    <el-button type="primary"
                               size="small"
                               @click="onClickRoleAdd()"><i class="el-icon-plus"></i>&nbsp;&nbsp;添加角色</el-button>
                    <!-- v-permission="'permissionrole_add_role'" -->
                </div>
            </div>

            <div class="wrap">
                <el-table :data="tableData"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                          fit
                          border
                          :render-header="renderHeader"
                          style="width: 100%">
                    <el-table-column show-overflow-tooltip
                                     fixed
                                     prop="name"
                                     label="角色名称">
                    </el-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="remark"
                                     label="描述">
                    </af-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="createTime"
                                     label="创建时间"
                                     width="230">
                    </el-table-column>
                    <el-table-column fixed="right"
                                     label="操作"
                                     width="150">
                        <template slot-scope="scope">
                            <el-button type="text"
                                       size="small"
                                       @click="onClickRoleUpdate(scope.row)">
                                编辑
                            </el-button>
                            <el-button type="text"
                                       size="small"
                                       @click="onClickRoleDelete(scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wraped">
                    <pagination :total="total"
                                :currentPage="currentPage"
                                @handleSizeChange="handleSizeChange"
                                @handleCurrentChange="handleCurrentChange"></pagination>
                </div>
            </div>
        </div>

        <el-dialog :title="isCreateItem ? '添加角色' : '编辑角色'"
                   :visible.sync="isShowDialog"
                   :close-on-click-modal="false"
                   top="10vh"
                   width="70%"
                   @close="closeDialog">
            <el-form class="dialog-form dialog-input-form"
                     labelWidth="90px"
                     ref="tempForm"
                     :model="tempForm">
                <el-form-item label="角色名称"
                              prop="roleName"
                              v-if="isCreateItem"
                              :rules="[
            {
              required: true,
              message: '角色名称为必填项',
              trigger: ['blur', 'change'],
            },
          ]">
                    <el-input v-model="tempForm.roleName"
                              placeholder="请输入角色名称"
                              size="small"></el-input>
                </el-form-item>

                <el-form-item v-loading="onLoadingWidgetData"
                              label="角色权限">
                    <div>
                        <el-tree class="tree filter-tree"
                                 show-checkbox
                                 default-expand-all
                                 highlight-current
                                 node-key="id"
                                 :default-checked-keys="checkedList"
                                 ref="widgetTree"
                                 :props="widgetProps"
                                 :data="widgetTreed">
                            <!-- default-expand-all -->
                            <span slot-scope="{ node, data }"
                                  class="custom-tree-node">
                                <span :dataType="data.type">
                                    <!-- <i class="iconfont iconic_services"></i> -->
                                    {{ node.label }}
                                </span>
                            </span>
                        </el-tree>
                    </div>
                </el-form-item>
                <el-form-item prop="remark"
                              label="备注">
                    <el-input v-model="tempForm.remark"
                              type="textarea"
                              :rows="2" />
                </el-form-item>
            </el-form>
            <div slot="footer"
                 class="dialog-footer">
                <el-button size="medium"
                           @click="closeDialog"> 取消 </el-button>
                <el-button type="primary"
                           size="medium"
                           @click="onConfirm"
                           :loading="onSubmitLoading">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pagination from '../../components/pagination.vue';
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
export default {
    data() {
        return {
            dialogType: 'create', // create, view, update
            onLoadingWidgetData: false,
            isCreateItem: true,
            widgetProps: {
                children: 'childrens',
                label: 'name'
            },
            checkedList: [],
            widgetTreed: [
                // {
                //   id: "1",
                //   icon: "el-icon-s-platform",
                //   name: "任务中心",
                //   childrens: [
                //     {
                //       id: "2",
                //       name: "我发布的任务",
                //       path: "/",
                //       resourceCode: "publish_task",
                //       childrens: [
                //         {
                //           id: "3",
                //           name: "我发布的任务-发布任务",
                //           path: "url",
                //           resourceCode: "publish_taskbtn",
                //           childrens: [],
                //         },
                //       ],
                //     },
                //     {
                //       id: "4",
                //       name: "投递人员信息",
                //       path: "/delivery",
                //       resourceCode: "delivery_personnel",
                //       childrens: [],
                //     },
                //   ],
                // },
                // {
                //   icon: "el-icon-s-shop",
                //   name: "合同中心",
                //   childrens: [
                //     {
                //       id: "5",
                //       name: "签约管理",
                //       path: "/signing",
                //       resourceCode: "contract_management",
                //       childrens: [],
                //     },
                //   ],
                // },
                // {
                //   icon: "el-icon-s-management",
                //   name: "交易中心",
                //   childrens: [
                //     {
                //       id: "6",
                //       name: "工单管理",
                //       path: "/repairOrder",
                //       resourceCode: "order_management",
                //       childrens: [],
                //     },
                //     {
                //       id: "7",
                //       name: "交易管理",
                //       path: "/trading",
                //       resourceCode: "transaction_management",
                //       childrens: [
                //         {
                //           id: "8",
                //           name: "交易管理-批量打款",
                //           path: "url",
                //           resourceCode: "transaction_payment",
                //           childrens: [],
                //         },
                //       ],
                //     },
                //   ],
                // },
                // {
                //   icon: "el-icon-s-ticket",
                //   name: "商户中心",
                //   childrens: [
                //     {
                //       id: "9",
                //       name: "权限角色",
                //       path: "/permissionrole",
                //       resourceCode: "authority_management",
                //       childrens: [
                //         {
                //           id: "10",
                //           name: "权限角色-增加角色",
                //           path: "url",
                //           resourceCode: "add_role",
                //           childrens: [],
                //         },
                //       ],
                //     },
                //   ],
                // },
            ],
            tempForm: {
                roleId: '',
                roleName: '',
                type: '',
                resourcesIds: [],
                childrenResourcesIds: [],
                remark: ''
            },
            roleId: '',
            isShowDialog: false,
            onSubmitLoading: false,
            releaseForm: {
                name: ''
            },
            pageSzie: 10,
            total: null,
            currentPage: 1,
            options: [
                {
                    value: '0',
                    label: '待签约'
                },
                {
                    value: '1',
                    label: '签约中'
                },
                {
                    value: '2',
                    label: '已签约'
                },
                {
                    value: '3',
                    label: '未签约'
                },
                {
                    value: '4',
                    label: '已过期'
                }
            ],
            tableData: []
        };
    },
    mounted() {
        this.queryPageData();
        this.baoguo();
    },
    methods: {
        // 表格
        // 表格
        renderHeader(h, data) {
            return h('span', [
                h('el-tooltip'),
                {
                    attrs: {
                        class: 'item',
                        effect: 'dark',
                        content: data.column.label
                    }
                },
                [h('span', data.column.label)]
            ]);
        },
        baoguo(size) {
            post(api.getAllResource).then(
                d => {
                    if (d.code === 0) {
                        this.widgetTreed = d.data;
                        // console.log(this.widgetTreed,66666)
                    } else {
                        // console.log(d.message);
                    }
                },
                err => {
                    //error callback
                }
            );
        },
        onClickRoleDelete(row) {
            this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    post(api.deleteCompanyRole, {
                        roleId: row.id,
                        roleName: row.name,
                        remark: row.remark
                    }).then(
                        d => {
                            if (d.code == 0) {
                                this.$message.success('删除成功');
                                this.queryPageData();
                            } else {
                                this.$message.error(d.message);
                            }
                            //success callback
                        },
                        err => {
                            //error callback
                        }
                    );
                })
                .catch(() => {});
        },
        // 编辑
        onClickRoleUpdate(data) {
            this.$confirm('此操作将进行编辑, 是否继续?', '提示', {
                confirmButtonText: '继续',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.checkedList = [];
                    this.tempForm = {
                        roleId: data.id,
                        roleName: data.name,
                        type: '01',
                        remark: data.remark
                    };
                    this.isCreateItem = false;
                    this.onLoadingWidgetData = true;
                    this.$nextTick(() => {
                        this.$refs.widgetTree.setCheckedKeys([]);
                    });
                    return post(api.getIdsByRoleId, {
                        roleId: this.tempForm.roleId
                    }).then(
                        d => {
                            if (d.code == 0) {
                                this.checkedList = d.data;
                                this.isShowDialog = true;
                                this.onLoadingWidgetData = false;
                            } else {
                                this.isShowDialog = false;
                                // this.onLoadingWidgetData = true
                                this.$message.error(d.message);
                            }
                            //success callback
                        },
                        err => {
                            //error callback
                            this.onLoadingWidgetData = true;
                        }
                    );
                })
                .catch(() => {});
        },

        onConfirm() {
            this.onSubmitLoading = true;
            this.$refs.tempForm.validate((valid, fields) => {
                // Failed.
                if (!valid) {
                    for (let key in fields) {
                        if (fields.hasOwnProperty(key)) {
                            // Show Notice.
                            this.onSubmitLoading = false;
                            return this.$message.error(fields[key][0].message);
                        }
                    }
                }
                // 资源id的集合
                let subKeys = this.$refs.widgetTree.getCheckedKeys();
                // console.log(subKeys,'555555')
                // 资源子id的集合
                let halfKeys = this.$refs.widgetTree.getHalfCheckedKeys();
                // console.log(halfKeys,'666666666666')
                let resources = halfKeys.concat(subKeys);
                // 排序并返回数组
                resources.sort();
                this.tempForm.resourcesIds = resources;
                this.tempForm.childrenResourcesIds = subKeys;
                return post(api.saveRoleResources, this.tempForm).then(
                    d => {
                        this.onSubmitLoading = false;
                        if (d.code == 0) {
                            this.$message.success('提交成功');
                            this.closeDialog();
                            this.queryPageData();
                            this.$refs.tempForm.resetFields();
                        } else {
                            this.onSubmitLoading = false;
                            this.$message.error(d.message);
                        }
                        //success callback
                    },
                    err => {
                        this.onSubmitLoading = false;
                        //error callback
                    }
                );
            });
        },
        onClickRoleAdd() {
            this.tempForm.roleName = '';
            this.tempForm.roleId = '';
            this.tempForm.type = '00';
            this.tempForm.remark = '';
            this.isCreateItem = true;
            this.openDialog();
            this.$nextTick(() => {
                this.$refs.widgetTree.setCheckedKeys([]);
            });
        },
        closeDialog() {
            this.isShowDialog = false;
        },
        openDialog() {
            this.isShowDialog = true;
        },

        queryPageData(size) {
            const loading = this.$loading({
                lock: true,
                spinner: 'loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            post(api.getRolePageList, {
                currentPage: size || 1,
                pageSize: this.pageSzie,
                name: this.releaseForm.name
            }).then(
                d => {
                    loading.close();
                    if (d.code === 0) {
                        // console.log(d, "!!!!!!!!!!!!");
                        if (d.hasOwnProperty('data')) {
                            this.total = d.data.count;
                            this.currentPage = d.data.currentPage;
                            this.tableData = d.data.rows;
                        } else {
                            this.total = 0;
                            this.currentPage = 1;
                            this.pageSzie = 10;
                            this.tableData = [];
                        }
                    } else {
                        this.$message.error(d.message);
                        this.total = 0;
                        this.currentPage = 1;
                        this.pageSzie = 10;
                        this.tableData = [];
                    }
                },
                err => {
                    //error callback
                }
            );
        },
        search() {
            this.queryPageData();
        },
        reset() {
            this.releaseForm = {
                name: ''
            };
            this.queryPageData();
        },
        handleSizeChange(val) {
            this.pageSzie = val;
            // console.log(`每页 ${val} 条aaaaa`);
            this.queryPageData();
        },
        handleCurrentChange(size) {
            // console.log(size);
            this.queryPageData(size);
        }
    },
    components: {
        pagination: pagination
    }
};
</script>
