import Vue from 'vue' //引入vue
import Router from 'vue-router'
Vue.use(Router)


//登录
import Login from '@/pages/login/login.vue'
// 首页
import Index from '@/pages/index/index.vue'
// 渠道商管理
import agentManage from '@/pages/channle/agentManage.vue'
// 渠道商管理产品
import agentManageProduct from '@/pages/channle/agentManageProduct.vue'
// 商户集团信息
import merchantInfo from '@/pages/businessCenter/merchantInfo.vue'
// 商户管理
import merchantManage from '@/pages/businessCenter/merchantManage.vue'
// 商户列表
import merchantRelManage from '@/pages/businessCenter/merchantRelManage.vue'
// 渠道商交易统计
import agentcount from '@/pages/runningTab/agentcount.vue'
// 直拓商户交易查询
import mertrans from '@/pages/runningTab/mertrans.vue'
// 我的账户
import account from '@/pages/myAccount/account.vue'
// 分润明细
import profitDetail from '@/pages/myAccount/profitDetail.vue'
// 组织结构
import depart from '@/pages/departCenter/depart.vue'
// 渠道商用户管理
import agentuser from '@/pages/departCenter/agentuser.vue'
// 渠道商角色管理
import agentrole from '@/pages/departCenter/agentrole.vue'
// 消息通知
import messageManage from '@/pages/messageCenter/messageManage.vue'
// 票据中心
import invoiceManage from '@/pages/invoiceCenter/invoiceManage.vue'


// 中间页
// 基本信息
import agentIndex from '@/pages/agentCenter/agentIndex'
// 渠道商信息
import agentInfomat from '@/pages/agentCenter/agentInfomat'
// 服务商信息
import serviceInfomat from '@/pages/agentCenter/serviceInfomat'
// 交付商信息
import deliveryInfomat from '@/pages/agentCenter/deliveryInfomat'
// 服务商产品列表
import serviceProduct from '@/pages/agentCenter/serviceProduct'
// 绑定服务商
import binding from '@/pages/agentCenter/binding'
// 切换页
// import middleIndex from '@/pages/agentCenter/middleIndex.vue'






export default new Router({
  mode: 'history', //去掉#，
  // base: '/beijing/',//这个配置也很重要，否则会出现页面空白情况
  base: '/', //这个配置也很重要，否则会出现页面空白情况
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    //   切换页
    // {
    //     path: '/middleIndex',
    //     name: 'middleIndex',
    //     component: middleIndex
    // },
    // 中间页
    {
      path: '/agentInfomat',
      name: 'agentInfomat',
      meta: {
        // requireAuth: false, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: '',
        title: '渠道商信息'
      },
      component: agentInfomat
    },
    {
      path: '/serviceInfomat',
      name: 'serviceInfomat',
      meta: {
        // requireAuth: false, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: '',
        title: '服务商信息'
      },
      component: serviceInfomat
    },
    {
      path: '/deliveryInfomat',
      name: 'deliveryInfomat',
      meta: {
        // requireAuth: false, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: '',
        title: '交付商信息'
      },
      component: deliveryInfomat
    },
    {
      path: '/serviceProduct',
      name: 'serviceProduct',
      meta: {
        // requireAuth: false, // 配置此条，进入页面前判断是否需要登陆  是这个路由吗恩恩
        resourceCode: '',
        title: '产品列表'
      },
      component: serviceProduct
    },
    {
      path: '/binding',
      name: 'binding',
      meta: {
        // requireAuth: false, // 配置此条，进入页面前判断是否需要登陆  是这个路由吗恩恩
        resourceCode: '',
        title: '绑定服务商'
      },
      component: binding
    },
    // 登录
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/',
      redirect: '/index',
    },
    // 首页
    {
      path: '/index',
      name: 'index',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: '',
        title: '首页'
      },
      component: Index
    },
    // 渠道商管理
    {
      path: '/agentManage',
      name: 'agentManage',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: '',
        title: '渠道商管理'
      },
      component: agentManage
    },
    // 渠道商管理产品
    {
      path: '/agentManageProduct',
      name: 'agentManageProduct',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: '',
        title: '渠道商管理产品'
      },
      component: agentManageProduct,
      hidden: true,
    },
    // 商户集团信息
    {
      path: '/merchantInfo',
      name: 'merchantInfo',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: '',
        title: '商户集团信息'
      },
      component: merchantInfo
    },
    // 商户管理
    {
      path: '/merchantManage',
      name: 'merchantManage',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: merchantManage
    },
    // 商户列表
    {
      path: '/merchantRelManage',
      name: 'merchantRelManage',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: merchantRelManage
    },
    // 渠道商交易统计
    {
      path: '/agentcount',
      name: 'agentcount',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: agentcount
    },
    // 直拓商户交易查询
    {
      path: '/mertrans',
      name: 'mertrans',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: mertrans
    },
    // 我的账户
    {
      path: '/account',
      name: 'account',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: account
    },
    // 分润明细
    {
      path: '/profitDetail',
      name: 'profitDetail',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: profitDetail
    },
    {
      path: '/agentIndex',
      name: 'agentIndex',
      meta: {
        requireAuth: false, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: agentIndex
    },
    // 消息通知
    {
      path: '/messageManage',
      name: 'messageManage',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: messageManage
    },
    // 发票管理
    {
      path: '/invoiceManage',
      name: 'invoiceManage',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: invoiceManage
    },
    // 组织结构
    {
      path: '/depart',
      name: 'depart',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: depart
    },
    // 渠道商用户管理
    {
      path: '/agentuser',
      name: 'agentuser',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: agentuser
    },
    // 渠道商角色管理
    {
      path: '/agentrole',
      name: 'agentrole',
      meta: {
        requireAuth: true, // 配置此条，进入页面前判断是否需要登陆
        resourceCode: ''
      },
      component: agentrole
    },

  ]
})

// Redirected when going from “/xxx” to “/yyy” via a navigation guard. 处理路由守卫重定向报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}