<template>
  <div class="overall">
    <div class="middleIndex">
      <!-- 我是服务商 -->
      <div class="middle leftInfo"
           @mouseover="serviceInfo()"
           @click="myService()">
        <img src="@/assets/img/service.png"
             alt="">
        <div class="serviceIndex">
          我是服务商
        </div>
        <p>取得了地方政策相应的税收政策，为灵活用工企业、自由职业者在平台获取收入提供财税解决方案</p>
      </div>
      <!-- 我是渠道商 -->
      <div class="middle rightInfo"
           @mouseover="agentInfo()"
           @click="myAgent()">
        <img src="@/assets/img/agent.png"
             alt="">
        <div class="agentIndex">
          我是渠道商
        </div>
        <p>通过给财税服务商输出灵活用工企业信息或拓展下级渠道获得相应分成、奖励等收入</p>
      </div>
      <!-- 我是税源地 -->
      <div class="middle sourceInfo"
           @mouseover="sourceInfo()"
           @click="mySource()">
        <img src="@/assets/img/税源地.png"
             alt="">
        <div class="sourceIndex">
          我是税源地
        </div>
        <p>将园区优惠政策发布至灵活用工平台，供服务商选择使用从而获取税源</p>
      </div>
    </div>
    <!-- 底部 -->
    <div class='bottom'
         v-show="serviceList">
      <div class="one">
        <img src="@/assets/img/品牌定制.png"
             alt="">
        <p class="p1">品牌定制</p>
        <p>为服务商打造自有品牌的线上业务运营平台</p>
      </div>
      <div class="one">
        <img src="@/assets/img/资金担保.png"
             alt="">
        <p class="p1">资金担保</p>
        <p>全面接入支付机构资金托管账户，助力服务商解决企业打款信任机制问题</p>
      </div>
      <div class="one">
        <img src="@/assets/img/分销.png"
             alt="">
        <p class="p1">分销管理</p>
        <p>系统为服务商自身业务拓展提供分销管理功能</p>
      </div>
      <div class="one">
        <img src="@/assets/img/接口.png"
             alt="">
        <p class="p1">接口整合</p>
        <p>一站式整合短信验证、四要素校验、电子协议，解决财税服务商业务运营功能需求</p>
      </div>
    </div>
    <div class='bottom'
         v-show="agentList">
      <div class="one">
        <img src="@/assets/img/分销.png"
             alt="">
        <p class="p1">分销管理</p>
        <p>系统为服务商自身业务拓展提供分销管理功能</p>
      </div>
      <div class="one">
        <img src="@/assets/img/功能.png"
             alt="">
        <p class="p1">功能强大</p>
        <p>系统自动计算每级分润</p>
      </div>
      <div class="one">
        <img src="@/assets/img/方便快捷.png"
             alt="">
        <p class="p1">方便简洁</p>
        <p>发展灵活用工企业、拓展下级渠道</p>
      </div>
      <div class="one">
        <img src="@/assets/img/多税源.png"
             alt="">
        <p class="p1">多税源地</p>
        <p>湖北、湖南、河南、福建、广西等</p>
      </div>
    </div>
    <div class='bottom'
         v-show="sourceList">
      <div class="one">
        <img src="@/assets/img/渠道共享.png"
             alt="">
        <p class="p1">渠道共享</p>
        <p>可以与灵工云内渠道合作，为零工需求方提供服务</p>
      </div>
      <div class="one">
        <img src="@/assets/img/税源分发.png"
             alt="">
        <p class="p1">税源分发</p>
        <p>税源地可为灵工云内所有平台提供服务</p>
      </div>
      <div class="one">
        <img src="@/assets/img/风险分摊.png"
             alt="">
        <p class="p1">风险分摊</p>
        <p>通过灵工云进行风控，减少风险</p>
      </div>
      <div class="one">
        <img src="@/assets/img/及时结算.png"
             alt="">
        <p class="p1">及时结算</p>
        <p>当月交税，次月结算</p>
      </div>
    </div>
    <!-- 弹框 -->
    <el-dialog title="添加"
               style="text-align: left;"
               :visible.sync="dialogVisible"
               width="50%">
      <el-form ref="form"
               :rules="rules"
               :model="form"
               label-width="80px"
               style="width:90%;margin:0 auto">
        <el-form-item prop="agentType"
                      :rules="[{required:true,message:'请选择渠道商类型',trigger:'change'}]">
          <el-radio name="我是服务商"
                    v-model="form.agentType"
                    label="0">我是服务商</el-radio>
          <el-radio name="我是渠道商"
                    v-model="form.agentType"
                    label="1">我是渠道商</el-radio>
          <el-radio name="我是税源地"
                    v-model="form.agentType"
                    label="2">我是税源地</el-radio>
        </el-form-item>
        <!-- 服务商 -->
        <el-form-item label="姓名"
                      v-if="form.agentType==0"
                      prop="userName">
          <el-input v-model="form.userName"
                    placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话"
                      v-if="form.agentType==0"
                      prop="ucMobile">
          <el-input v-model="form.ucMobile"
                    placeholder="请输入联系电话"
                    :disabled='true'></el-input>
        </el-form-item>
        <el-form-item label="企业名称"
                      v-if="form.agentType==0"
                      prop="organizationName">
          <el-input v-model="form.organizationName"
                    placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item label="备注"
                      v-if="form.agentType==0"
                      prop="remark">
          <el-input v-model="form.remark"
                    placeholder="请输入备注"></el-input>
        </el-form-item>
        <!-- 渠道商 -->
        <el-form-item label="姓名"
                      v-if="form.agentType==1"
                      prop="userName">
          <el-input v-model="form.userName"
                    placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话"
                      v-if="form.agentType==1"
                      prop="ucMobile">
          <el-input v-model="form.ucMobile"
                    placeholder="请输入联系电话"
                    :disabled='true'></el-input>
        </el-form-item>
        <el-form-item label="企业名称"
                      v-if="form.agentType==1"
                      prop="organizationName">
          <el-input v-model="form.organizationName"
                    placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item label="备注"
                      v-if="form.agentType==1"
                      prop="remark">
          <el-input v-model="form.remark"
                    placeholder="请输入备注"></el-input>
        </el-form-item>
        <!-- 税源地 -->
        <el-form-item label="姓名"
                      v-if="form.agentType==2"
                      prop="userName">
          <el-input v-model="form.userName"
                    placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话"
                      v-if="form.agentType==2"
                      prop="ucMobile">
          <el-input v-model="form.ucMobile"
                    placeholder="请输入联系电话"
                    :disabled='true'></el-input>
        </el-form-item>
        <el-form-item label="企业名称"
                      v-if="form.agentType==2"
                      prop="organizationName">
          <el-input v-model="form.organizationName"
                    placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item label="备注"
                      v-if="form.agentType==2"
                      prop="remark">
          <el-input v-model="form.remark"
                    placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submitInfo('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api/api.js";
import { get, post } from "@/api/index.js";
export default {
  computed: {
    ...mapState({
      organizationId: state => state.app.organizationId,
      mobile: state => state.app.mobile,
      role: state => state.app.role,
      organizationType: state => state.app.organizationType,
      organizationName: state => state.app.organizationName,
    })
  },
  data() {
    return {
      hbToken: "",
      countdownHtml: "获取验证码",
      times: 60,
      timer: null,
      form: {
        agentType: "0",
        userName: '',
        ucMobile: '',//this.mobile,//localStorage.getItem('mobile'),//localStorage.getItem('mobile'),//'13581868502'
        smsCode: '',
        remark: "",
        organizationName: '',
        organizationType: ''
      },
      rules: {
        userName: [
          { required: true, message: '请选择联系人', trigger: 'blur' }
        ],
        ucMobile: [
          { required: true, message: '请填写手机号', trigger: 'blur' }
        ],
        // smsCode: [
        //   { required: true, message: '请填写验证码', trigger: 'blur' }
        // ],
        remark: [
          { required: true, message: '请填写备注', trigger: 'blur' }
        ],
        organizationName: [
          { required: true, message: '请填写企业名称', trigger: 'blur' }
        ],
      },
      // 弹框状态
      dialogVisible: false,
      // 服务商
      serviceList: true,
      // 渠道商
      agentList: false,
      //   税源地
      sourceList: false,
    }
  },
  mounted() {
    this.getHBToken()
    this.hbuser()
  },
  methods: {
    getHBToken() {
      let key = "HB-TOKEN-TEST";
      if (window.location.hostname.toLowerCase().indexOf("bpo-agent-test") == -1) {
        key = "HB-TOKEN";
      }
      if (localStorage.getItem('routerStatus') == 'true') {
        if (this.$cookies.get(key) == null && key == 'HB-TOKEN-TEST') {
          window.location.href = "https://hbtest.gongsibao.com/login"
          this.$message.error('伙伴不存在')
        } else if (this.$cookies.get(key) == null && key == 'HB-TOKEN') {
          window.location.href = 'https://hb.gongsibao.com/login'
          this.$message.error('伙伴不存在')
        }
      }
      this.hbToken = this.$cookies.get(key);
      // console.log("hbtoken-----", this.hbToken)
      //   let key = "";
      //   if (window.location.hostname.toLowerCase().indexOf("bpo-agent-test") == -1) {
      //     key = "HB-TOKEN";
      //     if (this.$cookies.get(key) == '') {
      //       window.location.href = "https://hb.gongsibao.com/login"
      //     }
      //   } else {
      //     key = "HB-TOKEN-TEST"

      //   }
      //   this.hbToken = this.$cookies.get(key);
      //   // console.log("hbtoken-----", this.hbToken,111111)
    },
    // 获取伙伴端
    hbuser() {

      post(api.hbuser, {
        //  昆哥发我的 
        token: this.hbToken,
        // 王岩伙伴端
        // token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiZmNfcmVzIiwidWNfcmVzIiwiYXV0aF9yZXMiXSwiZXhwIjoxNjM2ODc4NzA0LCJ1c2VyX25hbWUiOiJ7XCJuaWNrTmFtZVwiOlwi546E6aOOXCIsXCJ1c2VySWRcIjpcIjEzNzY4OTg1ODc3MTE2MjMxNzBcIixcInVzZXJuYW1lXCI6XCIxNTEzNjg3NTgwOVwifSIsImp0aSI6ImVhZjRlYzU5LWFkZDMtNGJkOS1hNjYyLWMwZTdkY2Q3M2QzNSIsImNsaWVudF9pZCI6InBhcnRuZXIiLCJzY29wZSI6WyJBTEwiXX0.i6S01hVD8OhNOp5nSA8FL1_b1QgIxV7ljDcUTgLo-V0'
        // 
        // token: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiZmNfcmVzIiwidWNfcmVzIiwiYXV0aF9yZXMiXSwiZXhwIjoxNjM1MDQzNjcyLCJ1c2VyX25hbWUiOiJ7XCJuaWNrTmFtZVwiOlwi546L5L-K5pqWXCIsXCJ1c2VySWRcIjpcIjE0NDEyMzE5NjI0NTQ4NTk3NzhcIixcInVzZXJuYW1lXCI6XCIxMzEzMTk5NjAwOVwifSIsImp0aSI6IjkwMmUwNmRmLTk1YjgtNDNmOC1iZDgwLTliNzhkZmVmNTQwOSIsImNsaWVudF9pZCI6InBhcnRuZXIiLCJzY29wZSI6WyJBTEwiXX0.iS-2jHELfYnbYXX_Ggi7E0--Kae86JDom8E6TwyCE2M',
      }).then((d) => {
        if (d.code == 0) {
          localStorage.setItem('nickName', d.data.nickName)
          localStorage.setItem('mobile', d.data.username)
          localStorage.setItem('userId', d.data.userId)
          this.$store.commit('ROLE', d.data.orgList[0].role)
          this.$store.commit('ORAGANIZATIONID', d.data.orgList[0].organizationId)
          this.$store.commit('ORGANIZATIONAME', d.data.orgList[0].organizationName)
          this.$store.commit('ORGANIZATIONTYPE', d.data.orgList[0].organizationType)
          this.$store.commit('MOBILE', d.data.username)
          this.form.organizationName = this.organizationName
          this.form.organizationType = this.organizationType
          this.form.ucMobile = this.mobile
        } else {
          this.$message.error(d.message)
          let key = "HB-TOKEN-TEST";
          if (window.location.hostname.toLowerCase().indexOf("bpo-agent-test") == -1) {
            key = "HB-TOKEN";
          }
          if (d.message == '伙伴不存在' && key == 'HB-TOKEN-TEST') {
            window.location.href = "https://hbtest.gongsibao.com/login"
          } else if (d.message == '伙伴不存在' && key == 'HB-TOKEN') {
            window.location.href = 'https://hb.gongsibao.com/login'
          }
        }
      },
      );
    },
    // 确定
    submitInfo(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.agentType == '0') {
            this.form.source = 1
          } else if (this.form.agentType == '1') {
            this.form.source = 2
          } else {
            this.form.source = 3
          }
          post(api.createInfo, {
            infoType: this.form.agentType,
            userName: this.form.userName,
            // smsCode: this.form.smsCode,
            source: this.form.source,
            remark: this.form.remark,
            ucMobile: this.form.ucMobile,
            partnerOrganizationId: this.organizationId,
            partnerNickname: localStorage.getItem('nickName'),
            partnerUserId: localStorage.getItem('userId'),
            organizationName: this.form.organizationName,
            organizationType: this.form.organizationType,
            role: this.role,
          }).then((res) => {
            // console.log(res)
            if (res.code == 0) {
              this.$message.success(res.message)
              this.form.userName = ''
              this.form.smsCode = ''
              this.form.remark = ''
              this.dialogVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    serviceInfo() {
      this.serviceList = true
      this.agentList = false
      this.sourceList = false
    },
    agentInfo() {
      this.agentList = true
      this.serviceList = false
      this.sourceList = false
    },
    sourceInfo() {
      this.sourceList = true
      this.serviceList = false
      this.agentList = false
    },
    // 跳转服务页面
    myService() {
      this.dialogVisible = true
      this.form.agentType = '0'
      this.form.userName = ''
      this.form.remark = ''
    },
    // 跳转渠道页面
    myAgent() {
      this.dialogVisible = true
      this.form.agentType = '1'
      this.form.userName = ''
      this.form.remark = ''
    },
    // 跳转税源地
    mySource() {
      this.dialogVisible = true
      this.form.agentType = '2'
      this.form.userName = ''
      this.form.remark = ''
    },
  },
}
</script>

<style lang="less" scoped>
.overall {
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  .middleIndex {
    width: 100%;
    height: 60%;
    background: url("../../assets/img/middle.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftInfo {
      margin-left: 15%;
    }
    .sourceInfo {
      margin-right: 15%;
    }
    .middle {
      width: 15%;
      height: 300px;
      line-height: 60px;
      font-size: 24px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 600;
      color: #fff;
      letter-spacing: 1px;
      cursor: pointer;
      p {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 200;
        color: #ffffff;
        line-height: 24px;
      }
    }
    .serviceIndex {
      width: 100%;
      height: 60px;
      line-height: 60px;
      border-radius: 40px;
      background: linear-gradient(
        180deg,
        #0ca5f5 0%,
        #02e8e2 100%
      ); /*设置按钮为渐变颜色*/
    }
    .agentIndex {
      width: 100%;
      height: 60px;
      line-height: 60px;
      border-radius: 40px;
      background: linear-gradient(180deg, #11e8d4 0%, #02c3a3 100%);
    }
    .sourceIndex {
      width: 100%;
      height: 60px;
      line-height: 60px;
      border-radius: 40px;
      background: linear-gradient(180deg, #ffbf30 0%, #ff945a 100%);
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    .one {
      width: 20%;
      margin-top: 2%;
    }
    .p1 {
      font-size: 24px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #000000;
      line-height: 36px;
      letter-spacing: 1px;
    }
    p {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #9e9e9e;
      line-height: 21px;
    }
  }
  .obtain {
    float: right;
    width: 114px;
    height: 39px;
    line-height: 39px;
    text-align: center;
    background: white;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    font-size: 13px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
  }
}
</style>