export default {
    device: 'desktop',
    sidebar: {
        opened: !+localStorage.getItem('sidebarStatus'),
        withoutAnimation: false,
    },
    organizationId:"",
    organizationName:'',
    role:'',
    routerStatus:'',
    mobile:'',
    organizationType:'',
    organizationName:''
};
