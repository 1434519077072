/* ============
 * Vuex Actions
 * ============
 *
 * All the actions that can be used
 * inside the store
 */
import * as types from './mutation-types';

// Account
export const getAccount = ({ commit }, account) => {
    commit(types.GET_ACCOUNT, account);
};

// Auth
export const login = ({ commit }, account) => {
    commit(types.LOGIN, account);
};

export const logout = ({ commit }) => {
    commit(types.LOGOUT);
};

export const checkAuthentication = ({ commit }) => {
    commit(types.CHECK_AUTHENTICATION);
};

export const getAuthentication = ({ commit }, operations) => {
    commit(types.GET_AUTHENTICATION, operations);
};

export const getMenu = ({ commit }, menus) => {
    commit(types.GET_MENU, menus);
};

export const clearMenu = ({ commit }) => {
    commit(types.CLEAR_MENU);
};

export const side = ({ commit }, opens) => {
    commit(types.SIDE_OPENED, opens);
};

export const loadingShow = ({ commit }, bool) => {
    commit(types.LOADING, bool);
};

export const toggleDevice = ({ commit }, device) => {
    commit(types.TOGGLE_DEVICE, device);
};

export const toggleSideBar = ({ commit }) => {
    commit(types.TOGGLE_SIDEBAR);
};

export const closeSideBar = ({ commit }, { withoutAnimation }) => {
    commit(types.CLOSE_SIDEBAR, withoutAnimation);
};

export const openSideBar = ({ commit }, { withoutAnimation }) => {
    commit(types.OPEN_SIDEBAR, withoutAnimation);
};
