import axios from 'axios'
import Vue from 'vue';
import VueCookies from 'vue-cookies'
import VueCookie from "vue-cookie";
import QS from 'qs'
import router from '../router'
import api from './api.js'
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
      headers: {
        'content-type': 'application/json',
        'Authorization': VueCookies.get('esignmerchantsid'),
      }
    },).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params || {}, {
      headers: {
        'content-type': 'application/json',
        'Authorization': VueCookies.get('esignmerchantsid'),
        // 'companyId':VueCookies.get('mchcompanyId')
        // 'agentId':VueCookies.get('mchcompanyId')
      }
    })
      .then(res => {
        // console.log(res, '重要')
        // res.data.code == 403
        //就是这里
        if (res.data.code == 403) {
          VueCookies.set('token_kick', "1");
          // console.log(VueCookies.get('token_kick'), "---------token_kick-------");
          VueCookies.remove("mchcompanyId");
          VueCookies.remove("esignmerchantsid");
          VueCookies.remove("merchantloginPhone");
          VueCookies.remove("permissions");
          localStorage.removeItem("store");
          // console.log(res.data.code,'33333')
          router.push({ path: '/login' });
          // console.log("失效了")
          res.data.message = "登录失效，请重新登录";
        }
        resolve(res.data)
      })
      .catch(err => {
        // console.log('接口提示', err.data.msg)
        reject(err.data)
      })
  })
}
