<template>
  <div class="apps">
    <div class="app-head">
      <div class="appinput">
        <span>绑定服务商</span>
        <div class="msg">
          <span>*</span>
          <span class="msg-tit">次为必填项</span>
        </div>
      </div>
    </div>
    <div class="app-body">
      <el-form :model="form"
               ref="form"
               label-width="220px"
               class="form">
        <el-form-item prop="organizationType"
                      label="渠道商类型"
                      :rules="[{required:true,message:'请选择渠道商类型',trigger:'change'}]">
          <el-radio name="机构"
                    v-model="form.organizationType"
                    label="0">机构</el-radio>
          <el-radio name="个人"
                    v-model="form.organizationType"
                    label="1">个人</el-radio>
        </el-form-item>
        <el-form-item v-if="form.organizationType==0"
                      prop="businessLincenseImg"
                      label="上传营业执照"
                      :rules="[{required:true,message:'请上传营业执照',trigger:'change'}]">
          <el-upload class="avatar-uploader"
                     :action="uploadUrled"
                     :headers="myHeaders"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccessed"
                     :before-upload="beforeUploaded">
            <div class="uploading-camp">
              <div class="uploading-left">
                <img v-if="form.businessLincenseImg"
                     :src="form.businessLincenseImg"
                     width="100px"
                     height="100px" />

                <i v-else
                   style="font-size: 80px; color: #cccccc; margin-top: -25%"
                   class="iconfont iconyunshangchuan"></i>
              </div>
              <div class="uploading-right">
                <p class="right-one">上传营业执照</p>
                <p class="right-two">文件上传不得大于10M</p>
                <p class="right-three">上传文件</p>
              </div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item prop="agentName"
                      label="渠道商公司名称"
                      :rules="[{required:true,message:'请输入渠道商公司名称',trigger:'blur'}]">
          <el-input :style="{width:width}"
                    v-model="form.agentName"
                    placeholder="请输入渠道商公司名称"></el-input>
        </el-form-item>
        <el-form-item v-if="form.organizationType==1"
                      prop="idcardFront"
                      label="身份证正面"
                      :rules="[{required:true,message:'请选择身份证正面',trigger:'change'}]">
          <el-upload :action="uploadUrl"
                     :headers="myHeaders"
                     :on-success="handleAvatarSuccessPersonal"
                     :before-upload="beforeUploadPersonal"
                     multiple
                     :limit="1"
                     :on-remove="handleRemove"
                     :before-remove="beforeRemove">
            <div class="uploading-per">
              <div class="uploading-left">
                <img v-if="form.idcardFront"
                     class="el-upload-list__item-thumbnail"
                     :src="form.idcardFront"
                     width="100px"
                     height="100px"
                     alt="" />
                <i v-else
                   class="iconfont iconyunshangchuan"
                   style="font-size: 80px; color: #cccccc"></i>
              </div>
              <div class="uploading-right">
                <p class="right-one">上传身份证正面</p>
                <p class="right-two">文件上传不得大于10M</p>
                <p class="right-three">上传文件</p>
              </div>
            </div>
          </el-upload>
          <span style="color: #f56c6c"
                v-show="isShow">请上传身份证正面</span>
        </el-form-item>
        <el-form-item v-if="form.organizationType==1"
                      prop="idcardBlack"
                      label="身份证反面"
                      :rules="[{required:true,message:'请选择身份证反面',trigger:'change'}]">
          <el-upload :action="uploadUrl"
                     :headers="myHeaders"
                     :on-success="handleAvatarSuccessPersonalback"
                     :before-upload="beforeUploadPersonalback"
                     multiple
                     :limit="1"
                     :on-remove="handleRemoveInfo"
                     :before-remove="beforeRemoveInfo">
            <div class="uploading-per backInfo">
              <div class="uploading-left">
                <img v-if="form.idcardBlack"
                     class="el-upload-list__item-thumbnail"
                     :src="form.idcardBlack"
                     width="100px"
                     height="100px"
                     alt="" />
                <i v-else
                   class="iconfont iconyunshangchuan"
                   style="font-size: 80px; color: #cccccc"></i>
              </div>
              <div class="uploading-right">
                <p class="right-one">上传身份证国徽面</p>
                <p class="right-two">文件上传不得大于10M</p>
                <p class="right-three">上传文件</p>
              </div>
            </div>
          </el-upload>
          <span style="color: #f56c6c"
                v-show="isShowInfo">请上传身份证国徽面</span>
        </el-form-item>
        <el-form-item v-if="form.organizationType==0"
                      prop="creditCode"
                      label="组织信用代码"
                      :rules="[{required:true,message:'请输入组织信用代码',trigger:'blur'}]">
          <el-input :style="{width:width}"
                    v-model="form.creditCode"
                    placeholder="请输入组织信用代码"></el-input>
        </el-form-item>
        <el-form-item v-if="form.organizationType==1"
                      prop="idCard"
                      label="身份证号码"
                      :rules="[{required:true,message:'请输入身份证',trigger:'change'}]">
          <el-input :style="{width:width}"
                    v-model="form.idCard"
                    placeholder="请输入身份证号码"></el-input>
        </el-form-item>
        <el-form-item prop="userName"
                      label="联系人"
                      :rules="[{required:true,message:'请输入联系人',trigger:'blur'}]">
          <el-input :style="{width:width}"
                    v-model="form.userName"
                    placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item prop="ucMobile"
                      label="联系电话"
                      :rules="[{validator:validateTel,required:true,message:'请输入联系电话',trigger:'blur'}]">
          <el-input :style="{width:width}"
                    v-model="form.ucMobile"
                    disabled
                    maxlength="11"
                    placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item prop="email"
                      label="电子邮箱">
          <el-input :style="{width:width}"
                    v-model="form.email"
                    placeholder="请输入电子邮箱"></el-input>
        </el-form-item>
        <el-form-item prop="address"
                      label="联系地址">
          <el-input :style="{width:width}"
                    v-model="form.address"
                    placeholder="请输入联系地址"></el-input>
        </el-form-item>
        <el-form-item label="服务商"
                      :rules="[{required:true,message:'请选择服务商',trigger:'blur'}]"
                      prop="serviceId">
          <el-select v-model="form.serviceId"
                     @change="serviceProduct($event)"
                     :style="{width:width}"
                     clearable
                     filterable
                     style="width: 100%"
                     placeholder="请选择服务商">
            <el-option v-for="item in serviceOptions"
                       :key="item.supplierId"
                       :label="item.companyName"
                       :value="item.supplierId" />
          </el-select>
        </el-form-item>
        <div v-for="(item,index) in form.productPartnerVos"
             :key="item.id">
          <div style="display: flex">
            <el-form-item label="服务商产品"
                          :rules="[{required:true,message:'请选择服务商产品',trigger:'blur'}]"
                          :prop="'productPartnerVos.' + index + '.productId'">
              <el-select v-model="form.productPartnerVos[index].productId"
                         ref="select"
                         :style="{width:width}"
                         clearable
                         filterable
                         @change="productChange(index)"
                         placeholder="请选择服务商产品">
                <el-option v-for="item in productList"
                           :key="item.productId"
                           :label="item.productName"
                           :value="item.productId" />
              </el-select>
            </el-form-item>
            <div class="buttonInfo">
              <el-button type="primary"
                         size="small"
                         @click="addProduct()">增加</el-button>
            </div>
            <div class="buttonInfo">
              <el-button type="danger"
                         size="small"
                         v-if="form.productPartnerVos.length>1"
                         @click="deleteProduct(item,index)">删除</el-button>
            </div>
          </div>
          <div class="addInfo">
            <span style="font-weight: bold;padding-left:40px;">个人签约配置</span>
            <el-form-item label="个人签约要求 - 身份证"
                          style="margin-top:20px">
              <!--:rules="[{required:true,message:'请选择个人签约要求',trigger:'blur'}]"
                    :prop="'productPartnerVos.' + index +'.idPhotoFlag'" -->
              <el-select v-model="form.productPartnerVos[index].idPhotoFlag"
                         :disabled="true"
                         :style="{width:width}"
                         clearable
                         filterable
                         placeholder="请选择">
                <el-option v-for="item in idCardOptions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="签约形式">
              <!--:rules="[{required:true,message:'请选择签约形式',trigger:'blur'}]"
                     :prop="'productPartnerVos.' + index + '.idSignType'" -->
              <el-select v-model="form.productPartnerVos[index].idSignType"
                         :disabled="true"
                         :style="{width:width}"
                         clearable
                         filterable
                         placeholder="请选择">
                <el-option v-for="item in signTypeOptions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value" />
              </el-select>
            </el-form-item>
            <span style="font-weight: bold;padding-left:40px;">发票配置</span>
            <el-form-item label="可开具发票类型"
                          style="margin-top:20px">
              <!--
:rules="[{required:true,message:'请选择可开具发票类型',trigger:'blur'}]"                     :prop="'productPartnerVos.' + index + '.invoiceTypes'" -->
              <el-select v-model="form.productPartnerVos[index].invoiceTypes"
                         :disabled="true"
                         clearable
                         filterable
                         multiple
                         :style="{width:width}"
                         placeholder="请选择">
                <el-option v-for="item in invoiceTypeOptions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value" />
              </el-select>
              <!-- <select v-model="item.invoiceTypes"
                      class="proto"
                      clearable
                      filterable
                      multiple
                      :style="{width:width}"
                      placeholder="请选择">
                <option v-for="item in invoiceTypeOptions"
                        :key="item.value"       
                        :label="item.label"
                        :value="item.value" />
              </select> -->
            </el-form-item>
            <el-form-item label="个人签约要求 - 身份证认证要素">
              <!--
:rules="[{required:true,message:'请选择身份证认证要素',trigger:'blur'}]"                     :prop="'productPartnerVos.' + index + '.idValidType'" -->
              <el-select v-model="form.productPartnerVos[index].idValidType"
                         :disabled="true"
                         :style="{width:width}"
                         clearable
                         filterable
                         placeholder="请选择">
                <el-option v-for="item in validOptions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value" />
              </el-select>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="备注"
                      prop="remarkPartner"
                      :rules="[{required:true,message:'请输入备注',trigger:'change'}]">
          <el-input v-model="form.remarkPartner"
                    :style="{width:width}"
                    placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item prop="address"
                      label="">
          <el-button type="primary"
                     @click="submit()">立即保存</el-button>
          <el-button type=""
                     @click="init()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import picUpload from "@/components/picUpload";
import { validatecP, validateTel } from "@/api/reg";
import api from "@/api/api.js";
import { get, post } from "@/api/index.js";
import { mapState } from "vuex";
export default {
  components: { picUpload },
  data() {
    return {
      addInfo: '',
      idCardOptions: [
        { value: '0', label: '需要' },
        { value: '1', label: '不需要' }
      ],
      validOptions: [
        { value: '0', label: '银行卡四要素' },
        { value: '1', label: '银行卡三要素' },
        { value: '2', label: '运营商三要素' },
        { value: '3', label: '两要素' }
      ],
      signTypeOptions: [
        { value: '', label: '不限' },
        { value: '0', label: 'APP签约' },
        { value: '1', label: 'H5签约' },
        { value: '2', label: '静默签' }
      ],
      invoiceTypeOptions: [
        { value: '10', label: '普通发票' },
        { value: '11', label: '普通发票电子发票' },
        { value: '20', label: '增值税普通发票' },
        { value: '21', label: '增值税专用发票' }
      ],
      // 服务商
      serviceOptions: [],
      //   服务商产品
      productList: [],
      isShow: false,
      isShowInfo: false,
      // 上传营业执照
      uploadUrled: api.businessLicense,
      //   上传身份证
      uploadUrl: api.uploadOss,
      myHeaders: { Authorization: this.$cookies.get("esignmerchantsid") },
      width: "400px",
      validatecP: validatecP,
      validateTel: validateTel,
      form: {
        remarkPartner: '',
        role: '',
        organizationType: "0",
        businessLincenseImg: "",
        agentName: "",
        idcardFront: "",
        idcardBlack: "",
        creditCode: "",
        idCard: "",
        userName: "",
        ucMobile: localStorage.getItem('mobile'),
        email: "",
        address: "",
        serviceId: '',
        serviceName: '',
        source: 3,
        productPartnerVos: [
          {
            productId: '',
            idPhotoFlag: '',
            idValidType: '',
            idSignType: '',
            invoiceTypes: '',
          },
        ],
      },
    }
  },
  mounted() {
    this.supplierInfolist()
    this.organizationTypeInfo()
  },
  created() {
  },
  computed: {
    ...mapState({
      organizationId: state => state.app.organizationId,
      organizationName: state => state.app.organizationName,
      role: state => state.app.role
    }),
  },
  methods: {
    //   重置
    init() {
      if (this.form.organizationType == 0) {
        this.form = {
          organizationType: "0",
          businessLincenseImg: "",
          agentName: "",
          ucMobile: localStorage.getItem('mobile'),
          creditCode: "",
          userName: "",
          email: "",
          address: "",
          serviceId: '',
          serviceName: '',
          source: 3,
          productPartnerVos: [
            {
              productId: '',
              idPhotoFlag: '',
              idValidType: '',
              idSignType: '',
              invoiceTypes: '',
              remarkPartner: ''
            },
          ],
        }
      } else {
        this.form = {
          role: '',
          organizationType: "1",
          agentName: "",
          idcardFront: "",
          ucMobile: localStorage.getItem('mobile'),
          idcardBlack: "",
          idCard: "",
          userName: "",
          email: "",
          address: "",
          serviceId: '',
          serviceName: '',
          source: 3,
          productPartnerVos: [
            {
              productId: '',
              idPhotoFlag: '',
              idValidType: '',
              idSignType: '',
              invoiceTypes: '',
              remarkPartner: ''
            },
          ],
        }
      }
    },
    //   取消
    cancel() {
      this.$router.push('/agentInfomat')
    },
    // 有基础信息时展示
    organizationTypeInfo() {
      post(api.selectByIdPartnerInfo, { partnerOrganizationId: this.organizationId }).then((res) => {
        if (res.code == 0) {
          if (res.data.agentType == '0') {
            this.form = {
              remarkPartner: '',
              organizationType: "0",
              businessLincenseImg: res.data.businessLincenseImg,
              agentName: res.data.agentName,
              idcardFront: "",
              idcardBlack: "",
              creditCode: res.data.creditCode,
              idCard: "",
              userName: res.data.userName,
              ucMobile: localStorage.getItem('mobile'),
              email: res.data.email,
              address: res.data.address,
              serviceId: '',
              serviceName: '',
              source: 3,
              productPartnerVos: [
                {
                  productId: '',
                  idPhotoFlag: '',
                  idValidType: '',
                  idSignType: '',
                  invoiceTypes: '',
                },
              ],
            }
          } else {
            this.form = {
              remarkPartner: '',
              organizationType: "1",
              businessLincenseImg: '',
              agentName: res.data.agentName,
              idcardFront: res.data.idcardFront,
              idcardBlack: res.data.idcardBlack,
              creditCode: '',
              idCard: res.data.idCard,
              userName: res.data.userName,
              ucMobile: localStorage.getItem('mobile'),
              email: res.data.email,
              address: res.data.address,
              serviceId: '',
              serviceName: '',
              source: 3,
              productPartnerVos: [
                {
                  productId: '',
                  idPhotoFlag: '',
                  idValidType: '',
                  idSignType: '',
                  invoiceTypes: '',
                },
              ],
            }
          }
        }
      })
    },
    // 增加服务商产品（梯度）
    addProduct() {
      this.form.productPartnerVos.push({
        productId: '',
        idPhotoFlag: '',
        idValidType: '',
        idSignType: '',
        invoiceTypes: '',
      })
    },
    // 删除服务商产品
    deleteProduct(item, index) {
      this.form.productPartnerVos = this.form.productPartnerVos.filter((e, i) => {
        return i != index;
      })
      //   if (this.form.productPartnerVos.length > 1) {
      //     this.form.productPartnerVos.some((item1, i) => {
      //       if (item1 == item) {
      //         this.form.productPartnerVos.splice(i, 1);
      //       }
      //     });
      //   }
    },
    // 上传正面身份证
    beforeUploadPersonal() { },
    handleAvatarSuccessPersonal(res, file, files, key, val) {
      if (res.code == 0) {
        // console.log(res.data, "6363");
        this.form.idcardFront = res.data;
        this.$forceUpdate();
      }
    },
    // 删除图片正面
    handleRemove(file, fileList) {
      this.form.idcardFront = "";
      this.$forceUpdate();
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 删除图片反面
    handleRemoveInfo(file, fileList) {
      this.form.idcardBlack = "";
      this.$forceUpdate();
    },
    beforeRemoveInfo(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 上传反面身份证
    beforeUploadPersonalback() { },
    handleAvatarSuccessPersonalback(res, file, files, key, val) {
      if (res.code == 0) {
        this.form.idcardBlack = res.data;
        this.$forceUpdate();
      }
    },
    // 上传职业营照
    beforeUploaded(file) { },
    handleAvatarSuccessed(res, file, files, key, val) {
      this.$forceUpdate();
      if (res.code == 0) {
        this.$message.warning(res.data.msg);
        this.file = files;
        this.form.businessLincenseImg = res.data.uploadUrl;
        this.form["agentName"] = res.data.companyName;
        this.form["creditCode"] = res.data.socialCreditCode;
        this.form['userName'] = res.data.legalPerson
      } else {
        this.$message.error(res.data.msg);
      }
    },
    // 新增
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 机构新增
          this.form.role = this.role
          this.form.organizationId = this.organizationId,
            this.form.organizationName = this.organizationName,
            this.form.nickName = localStorage.getItem('nickName'),
            this.form.userId = localStorage.getItem('userId'),
            this.form.mobile = localStorage.getItem('mobile')
          if (this.form.organizationType == 0) {
            post(api.createAgentForPartnerInfo, this.form).then((res) => {
              // console.log(res, 123456)
              if (res.code == 0) {
                this.$router.push({ path: '/agentInfomat' })
                this.$message.success(res.message)
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            // 个人新增
            this.form.role = this.role
            post(api.createAgentPartnerForPersonal, this.form).then((res) => {
              if (res.code == 0) {
                this.$message.success(res.message)
                this.$router.push({ path: '/agentInfomat' })
              } else {
                this.$message.error(res.message)
              }
            })
          }
        } else {
          this.$message.info("请填写完整表单")
          return false;
        }
      });
    },
    // 查看服务商下拉
    supplierInfolist() {
      post(api.supplierInfolist).then((res) => {
        if (res.code == 0) {
          this.serviceOptions = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 服务商改变请求服务商产品并且循环展示
    serviceProduct(val) {
      post(api.productlistInfo, { supplierId: val }).then((res) => {
        if (res.code == 0) {
          if (res.data == '') {
            this.form.productPartnerVos = [{
              productId: '',
              idPhotoFlag: '',
              idValidType: '',
              idSignType: '',
              invoiceTypes: '',
              remarkPartner: ''
            }]
            this.productList = ''
          } else {
            this.productList = res.data
            // console.log(res.data, 639)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 当服务商产品改变时带出相应的信息
    productChange(index) {
      post(api.supplierProductInfo, { productId: this.form.productPartnerVos[index].productId }).then((res) => {
        // console.log(res, 666)
        if (res.code == 0) {
          // this.$set(this.form.productPartnerVos,'idPhotoFlag',res.data.idPhotoFlag)
          // this.$set(this.form.productPartnerVos,'remarkPartner',res.data.remarkPartner)
          this.$set(this.form.productPartnerVos, index, {
            productId: res.data.productId,
            productName: res.data.productName,
            idPhotoFlag: res.data.idPhotoFlag,
            idValidType: res.data.idValidType,
            idSignType: res.data.idSignType,
            invoiceTypes: res.data.listInvoiceTypes,
          })
          this.$forceUpdate()
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>

<style>
#app
  > div.main
  > div
  > div.app-body
  > form
  > div:nth-child(4)
  > div
  > div
  > ul {
  width: 50% !important;
}
#app
  > div.main
  > div
  > div.app-body
  > form
  > div:nth-child(3)
  > div
  > div
  > ul {
  width: 50% !important;
}
#app
  > div.main
  > div
  > div.app-body
  > form
  > div:nth-child(2)
  > div
  > div
  > div {
  width: 50% !important;
}
</style>
<style lang="less" scoped>
.form {
  text-align: left;
  padding-left: 25%;
}
// 新增产品
.buttonInfo {
  margin-left: 20px;
  margin-top: 3px;
}
// 上传身份证
.uploading-per {
  //   width: 100%;
  border: 1px solid #f5f5f5;
  border-right: none;
  margin-bottom: 15px;
  display: flex;
  height: 100px;
  .uploading-left {
    width: 100px;
    height: 100px;
    background-color: #f5f5f5;
    position: relative;
    i {
      position: absolute;
      top: 50%;
      margin-top: -25%;
      left: 50%;
      margin-left: -40%;
    }
  }
  .uploading-right {
    padding-left: 15px;
    line-height: 10px;
    .right-one {
      font-weight: 600;
      color: #666666;
    }
    .right-two {
      color: #9999a6;
    }
    .right-three {
      color: #0079fe;
    }
  }
}
// 上传职业营照
.uploading-camp {
  width: 100%;
  border: 1px solid #f5f5f5;
  border-right: none;
  margin-bottom: 35px;
  height: 100px;
  display: flex;
  .uploading-left {
    width: 100px;
    height: 100px;
    background-color: #f5f5f5;
    position: relative;
    i {
      position: absolute;
      top: 50%;
      margin-top: -40%;
      left: 50%;
      margin-left: -40%;
    }
  }
  .uploading-right {
    padding-left: 30px;
    // padding-top: 15px;
    line-height: 10px;
    .right-one {
      font-weight: 600;
      color: #666666;
    }
    .right-two {
      color: #9999a6;
    }
    .right-three {
      color: #0079fe;
    }
  }
}
</style>
