<template>
    <div class="table-bar">
        <div class="right-wrap">
            <el-button-group>
                <!-- <el-button icon="el-icon-refresh-right"
                           size="small"
                           @click="refresh()"
                           v-if="layout.indexOf('refresh') !== -1" /> -->
                <el-popover placement="bottom-end"
                            width="100"
                            trigger="click"
                            @show="showPopover"
                            @hide="handleSelectaite"
                            v-if="layout.indexOf('column') !== -1">
                    <el-checkbox v-for="(item, index) in columns"
                                 v-model="item.show"
                                 :label="item.name"
                                 :key="item.id"
                                 @change="changeColumn($event, index)" />
                    <el-button slot="reference"
                               size="small"
                               icon="el-icon-s-operation" />
                </el-popover>
            </el-button-group>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
export default {
    name: 'TableBar',
    inject: ['reload'],
    props: {
        columns: {
            type: Array,
            default: []
        },
        nameId: {
            type: String,
            default: ''
        },
        layout: {
            type: String,
            default: 'search, refresh, column'
        }
    },
    data() {
        return {
            colOptions: [],
            colSelect: [],
            columnChage: false,
            chooseType: this.columns
        };
    },
    mounted() {},
    methods: {
        // 关闭事件
        handleSelectaite() {
            post(api.editHeader, { fieldVoList: this.columns, name: this.nameId }).then(res => {
                if (res.code == 0) {
                    post(api.getHeader, { name: this.nameId }).then(resNew => {
                        if (resNew.code == 0) {
                            this.chooseType = resNew.data.fieldVoList;
                        } else {
                            this.$message.error(resNew.message);
                        }
                    });
                }
            });
        },
        // 刷新页面
        refresh() {
            this.reload();
        },
        // 列显示隐藏
        showPopover() {
            if (!this.columnChage) {
                let ops = [];
                this.columns.map(item => {
                    ops.push(item.name);
                });
                this.colOptions = ops;
                this.colSelect = ops;
                this.columnChage = true;
            }
        },
        // 选择列
        changeColumn(show, index) {
            let columns = this.columns;
            columns.map((item, i) => {
                if (index === i) {
                    item.show = show;
                }
            });

            this.$emit('changeColumn', columns);
        }
    }
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 500px) {
    /deep/ .el-form {
        padding-bottom: 15px;
    }
}
</style>
