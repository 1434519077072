<style lang="less" scoped>
.qiyeRen {
  width: 100%;
  min-height: 100%;
  padding: 12px;
  box-sizing: border-box;
  background-color: #fff;


  .statusSty {
    width: 80px;
    height: 30px;
    padding: 2px;
    line-height: 28px;
    text-align: center;
    box-sizing: border-box;
    display: inline-block;
  }

  .box {
    width: 100%;

    .main_button {
      text-align: left;
      margin-left: 36px;
    }

    .wrap {
      width: 100%;
      margin-top: 22px;
      // padding: 0px 12px;
      box-sizing: border-box;

      .daishen {
        background-color: #1890ff;
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        vertical-align: middle;
      }

      .wraped {
        width: 100%;
      }
    }
  }

  .block {
    width: 100%;

    // margin-top: -26px;
    .col {
      width: 102%;
      height: 10px;
      background-color: #f2f2f2;
      margin-left: -12px;
    }

    .list {
      width: 100%;
      text-align: left;
      padding: 20px;
      box-sizing: border-box;
      font-size: 14px;
      color: rgba(16, 16, 16, 100);

      .grid-content {
        width: 100%;
        margin-bottom: 20px;
        display: inline-block;
      }

      .baoguo {
        width: 100%;
        overflow: hidden;

        .zongji {
          float: left;
          font-weight: 500;
          font-size: 22px;
          color: #303133;
        }

        .anniu {
          float: right;
        }
      }

      .biankuang {
        width: 336px;
        height: 169px;
        border: 1px solid #6666;

        .dian {
          background-color: #52c41a;
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          vertical-align: middle;
          margin-left: 30px;
        }

        .jinqian {
          font-size: 20px;
          line-height: 89px;
          vertical-align: middle;
          margin-left: 20px;
        }

        .wenzi {
          margin-left: 89px;
          font-size: 22px;
          color: #666;
        }
      }
    }

    .main {
      width: 100%;
      padding: 13px;
      box-sizing: border-box;
      font-size: 14px;
      color: #101010;
      text-align: left;

      .grid-content {
        width: 100%;
        margin-bottom: 20px;
        display: inline-block;
      }

      .grid-left {
        float: left;
        margin-bottom: 20px;
      }

      .grid-img {
        float: left;
        width: 160px;
        height: 97px;
        margin-right: 22px;
      }

      .wrap {
        width: 100%;
        margin-top: 22px;
        box-sizing: border-box;
      }

      .el-row.row-header {
        margin-bottom: 20px;
      }

      .range-separator {
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #4d4f4f;
        font-weight: 300;
      }

      .gradient-name {
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #4d4f4f;
        font-weight: 300;
      }

      .btn-con {
        position: relative;
        width: 100%;
        height: 30px;
        line-height: 30px;

        .btn-clear {
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -8px 0 0 -8px;
          width: 16px;
          height: 16px;
          line-height: 16px;
          padding: 1px 0 0 0;
          text-align: center;
          color: #fff;
          background: #e0e0e0;
          border-radius: 50%;
          cursor: pointer;
        }
      }

      .add-bar {
        margin: 15px 40px;
        padding: 6px 0;
        line-height: 16px;
        font-size: 12px;
        color: #00beff;
        text-align: center;
        background: #f9fafa;
        border-radius: 2px;
        cursor: pointer;

        i {
          margin-right: 8px;
          vertical-align: initial;
        }

        &:hover,
        &:active {
          background: #f4f6f7;
        }
      }

      .header {
        padding: 0 6px;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        font-weight: bold;
        color: #96a0a1;
        text-align: center;
        background-color: #f0f3f4;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .footer {
        margin-top: 60px;
      }
    }
  }
}
</style>
<style>
.qiyeRen .el-table__fixed-right {
  right: 0 !important;
}

/* .qiyeRen .el-input--small .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
} */
.qiyeRen .el-date-editor.el-input {
  width: 100%;
}

.qiyeRen .el-select {
  width: 100%;
}

.qiyeRen .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.qiyeRen .avatar-uploader .baoguo-icon {
  width: 178px;
  height: 178px;
  line-height: 178px !important;
  display: block;
}

.qiyeRen .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.qiyeRen .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<template>
  <div class="qiyeRen">
    <div class="box" v-show="releaseType">
      <el-form ref="releaseForm" :model="releaseForm" label-width="120px">
        <el-row>
          <el-col :lg="18" :md="20">
            <el-row>
              <el-col :lg="8" :md="12">
                <el-form-item label="公司名称：">
                  <el-input v-model="releaseForm.companyName" size="small" placeholder="请输入公司名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12">
                <el-form-item label="服务商名称：">
                  <el-select v-model="releaseForm.serviceId" filterable placeholder="请输入服务商名称" :loading="loading"
                    style="width: 100%" size="small">
                    <el-option v-for="item in serviceList" :key="item.serviceId" :label="item.serviceName"
                      :value="item.serviceId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12">
                <el-form-item label="状态：">
                  <el-select size="small" v-model="releaseForm.status" clearable placeholder="请选择状态" style="width: 100%">
                    <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :lg="8" :md="12">
                <el-form-item label="经办人ID：">
                  <el-input
                    v-model="releaseForm.userId"
                    size="small"
                    placeholder="请输入经办人ID"
                  ></el-input>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :lg="8" :md="12">
                <el-form-item label="经办人姓名：">
                  <el-input
                    v-model="releaseForm.userName"
                    size="small"
                    placeholder="请输入经办人姓名"
                  ></el-input>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :lg="8" :md="12">
                <el-form-item label="经办人手机：">
                  <el-input
                    v-model="releaseForm.userMobile"
                    size="small"
                    placeholder="请输入经办人手机"
                  ></el-input>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :lg="8" :md="12">
                <el-form-item label="结算商户ID：">
                  <el-input
                    v-model="releaseForm.xbgMerchantId"
                    size="small"
                    placeholder="请输入结算商户ID"
                  ></el-input>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :lg="8" :md="12">
                <el-form-item label="发票类型：">
                  <el-select
                    clearable
                    filterable
                    v-model="releaseForm.companyInvoiceType"
                    style="width: 100%"
                    placeholder="请选择发票类型"
                    size="small"
                  >
                    <el-option
                      v-for="item in fapiaoOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :lg="8" :md="12">
                <el-form-item label="审核状态：">
                  <el-select
                    clearable
                    filterable
                    v-model="releaseForm.checkStatus"
                    style="width: 100%"
                    placeholder="请选择审核状态"
                    size="small"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
            </el-row>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label=" ">
              <el-button type="primary" size="small" icon="el-icon-search" @click="search()">查询</el-button>
              <el-button size="small" icon='el-icon-refresh-left' class="chongzhi" @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="toobar">
        <div class="main_button">
          <!-- <el-button
            type="primary"
            size="small"
            v-permission="'userInfo_authentication_btn'"
            @click="clickApply()"
            ><i class="el-icon-plus"></i>&nbsp;&nbsp;企业认证</el-button
          > -->
        </div>
      </div>
      <div class="wrap">
        <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }" fit border :render-header="renderHeader" style="width: 100%">
          <af-table-column show-overflow-tooltip fixed prop="id" label="商户ID">
          </af-table-column>
          <af-table-column show-overflow-tooltip prop="createTime" label="创建时间">
          </af-table-column>
          <af-table-column show-overflow-tooltip prop="agentName" label="所属渠道（渠道商）">
          </af-table-column>
          <af-table-column show-overflow-tooltip prop="companyName" label="商户名称">
          </af-table-column>
          <af-table-column show-overflow-tooltip prop="serviceName" label="服务商名称">
          </af-table-column>
          <el-table-column show-overflow-tooltip prop="signMainName" label="交付商名称" min-width="180">
            <template slot-scope="scope">
              {{ scope.row.signMainName || '--' }}
            </template>
          </el-table-column>
          <el-table-column prop="invoiceType" show-overflow-tooltip label="发票类型" min-width="160px" align="center">
            <template slot-scope="scope">
              {{ scope.row.invoiceType=='0' ? '增值税普通发票' : (scope.row.invoiceType=='1'?'增值税专用发票':'--') }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="signMainRateFee" label="渠道底价(%)" min-width="130">
            <template slot-scope="scope">
              {{ scope.row.signMainRateFee || '--' }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="taxNo" label="组织信用代码" min-width="150">
          </el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="checkStatus" width="100" label="状态">
          </el-table-column> -->
          <af-table-column show-overflow-tooltip prop="legalman" label="商户负责人">
          </af-table-column>
          <el-table-column prop="status" fixed="right" label="状态" align="center" show-overflow-tooltip min-width="130">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" placement="top" :disabled="scope.row.status == 0">
                <div slot="content">
                  <div>备注：{{ scope.row.remark }}</div>
                  <div>终止合作时间：{{ scope.row.cancelTime }}</div>
                </div>
                <span class="statusSty" :style="{
                  color: statusCol[scope.row.status],
                  background: statusBg[scope.row.status],
                }">{{ statusObj[scope.row.status] }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="120">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item v-if="scope.row.auditStatus == 1">
                    <el-button
                      @click="clickRow(3, scope.row)"
                      size="mini"
                      class="my-2 w_fluid"
                      plain
                    >
                      审核
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      @click="clickRow(1, scope.row)"
                      size="mini"
                      class="my-2 w_fluid"
                      plain
                    >
                      详情
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="
                      scope.row.auditStatus == 2 && scope.row.signedStatus == 0
                    "
                  >
                    <el-button
                      @click="clickRow(4, scope.row)"
                      size="mini"
                      class="my-2 w_fluid"
                      plain
                    >
                      签约
                    </el-button>
                  </el-dropdown-item> -->
                  <!-- v-if="scope.row.signedStatus == 1" -->
                  <el-dropdown-item>
                    <el-button @click="clickRow(5, scope.row)" size="mini" class="my-2 w_fluid" plain>
                      查看签约
                    </el-button>
                  </el-dropdown-item>
                  <!-- <el-dropdown-item>
                 <el-button
                @click="clickRow(2, scope.row)"
                type="text"
                size="small"
                v-if="!scope.row.xbgMerchantId"
              >
                关联
              </el-button>
                  </el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="wraped">
          <pagination :total="total" :currentPage="currentPage" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"></pagination>
        </div>
      </div>
    </div>
    <div class="block" v-show="!releaseType">
      <el-page-header @back="goBack" :content="contentDetails">
      </el-page-header>
      <el-form :model="signInfo" labelWidth="180px" ref="merchant_form" :rules="merchantRules">
        <div class="list">
          <el-row>
            <el-col :span="10">
              <el-form-item label="是否已开设结算商户：" prop="signRecord" v-if="contentType == 0">
                <el-select v-model="signInfo.signRecord" @change="xuanzeName" placeholder="请选择是否已开设结算商户"
                  style="width: 300px" :disabled="disabled">
                  <el-option value="1" label="是"></el-option>
                  <el-option value="0" label="否"></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="关联结算商户："
                                          prop="busiName"
                                          v-if="contentType == 1">
                                <el-input v-model="signInfo.busiName"
                                          size="small"
                                          placeholder="请输入关联结算商户"
                                          style="width: 300px"
                                          :disabled="disabled"></el-input>
                            </el-form-item> -->
            </el-col>
            <el-col :span="10">
              <el-form-item label="关联结算商户：" prop="busiId" v-if="contentType == 0">
                <el-select @change="onInputGuanChange" v-model="signInfo.busiId" filterable remote reserve-keyword
                  placeholder="请输入关联结算商户" :remote-method="remoteGuanMethod" :loading="loading" style="width: 300px">
                  <el-option v-for="item in guanlianOptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="关联商户ID："
                                          prop="busiId"
                                          v-if="contentType == 1">
                                <el-input v-model="signInfo.busiId"
                                          :disabled="disabled"
                                          size="small"
                                          placeholder="请输入关联商户ID"
                                          style="width: 300px"></el-input>
                            </el-form-item> -->
            </el-col>
          </el-row>
        </div>
        <div class="col"></div>
        <div class="main">
          <div class="sep-form-item">
            <el-row>
              <el-col :md="8">
                <el-form-item label="企业ID：" prop="companyId">
                  <span>{{ signInfo.companyId }}</span>
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item label="企业名称：" prop="companyName">
                  <span>{{ signInfo.companyName }}</span>
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item label="合作类型" prop="invoiceType">
                  <el-select v-model="signInfo.invoiceType" @change="onInvoiceTypeChange" :disabled="disabled">
                    <el-option value="1" label="专票"></el-option>
                    <el-option value="0" label="普票"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="8">
                <el-form-item label="签约主体：" prop="mainId" :rules="[
                  {
                    required: true,
                    message: '签约主体为必填项',
                    trigger: ['blur'],
                  },
                ]">
                  <el-select v-model="signInfo.mainId" @change="onInputPost" :disabled="disabled">
                    <el-option v-for="item in qianyeOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item label="签约开始时间：" prop="beginTime" :rules="[
                  {
                    required: true,
                    message: '签约开始时间为必填项',
                    trigger: ['blur'],
                  },
                ]">
                  <el-date-picker v-model="signInfo.beginTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                    :disabled="disabled">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item label="签约截止时间：" prop="completeTime" :rules="[
                  {
                    required: true,
                    message: '签约完成时间为必填项',
                    trigger: ['blur'],
                  },
                ]">
                  <el-date-picker v-model="signInfo.completeTime" :disabled="disabled" type="date" placeholder="选择日期"
                    value-format="yyyy-MM-dd" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="8" v-if="merchantType == 1">
                <el-form-item label="个税来源：" prop="incomeCompany" :rules="[
                  {
                    required: true,
                    message: '个税来源为必填项',
                    trigger: 'blur',
                  },
                ]">
                  <el-select v-model="signInfo.incomeCompany" :disabled="disabled">
                    <el-option value="0" label="个税向个人收"></el-option>
                    <el-option value="1" label="个税向企业收"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" v-if="merchantType == 1">
                <el-form-item label="个税计费模式：" prop="taxChargeMode" :rules="[
                  {
                    required: true,
                    message: '个税计费模式为必填项',
                    trigger: ['blur'],
                  },
                ]">
                  <el-select v-model="signInfo.taxChargeMode" :disabled="disabled">
                    <el-option value="0" label="按月"></el-option>
                    <el-option value="1" label="按年"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" v-if="merchantType == 1">
                <el-form-item label="服务费PLUS收取类型：" prop="addedCollectType" :rules="[
                  {
                    required: true,
                    message: '服务费PLUS收取类型',
                    trigger: ['blur'],
                  },
                ]">
                  <el-select v-model="signInfo.addedCollectType" :disabled="disabled">
                    <el-option value="0" label="个人"></el-option>
                    <el-option value="1" label="企业"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" v-if="merchantType == 1">
                <el-form-item label="服务费PLUS费率(%)：" prop="addedValueRate" :rules="[
                  {
                    required: true,
                    message: '服务费PLUS费率为必填项',
                    trigger: 'blur',
                  },
                ]">
                  <el-input-number v-model="signInfo.addedValueRate" controls-position="right" :precision="2" :step="0.1"
                    :min="0" :max="100" :disabled="disabled" />
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item label="服务费来源：" prop="serviceCollectType" :rules="[
                  {
                    required: true,
                    message: '服务费来源',
                    trigger: ['blur'],
                  },
                ]">
                  <el-select v-model="signInfo.serviceCollectType" :disabled="disabled">
                    <el-option value="0" label="个人"></el-option>
                    <el-option value="1" label="企业"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" v-if="merchantType == 0">
                <el-form-item label="服务费费率(%)：" prop="serviceRate" :rules="[
                  {
                    required: true,
                    message: '服务费费率为必填项',
                    trigger: 'blur',
                  },
                ]">
                  <el-input-number v-model="signInfo.serviceRate" controls-position="right" :precision="2" :step="0.1"
                    :min="0" :max="100" />
                </el-form-item>
              </el-col>
              <el-col :md="8" v-if="merchantType == 1">
                <el-form-item prop="serviceTotalType" label="服务费累计：" :rules="[
                  {
                    required: true,
                    message: '请选择服务费是否累计',
                    trigger: 'blur',
                  },
                ]">
                  <el-select v-model="signInfo.serviceTotalType" placeholder="请选择" :disabled="disabled">
                    <el-option label="是" :value="1"></el-option>
                    <el-option label="否" :value="Number('0')"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" v-if="merchantType == 1">
                <el-form-item label="核定成本费用率(%)：" prop="operatingCost" :rules="[
                  {
                    required: true,
                    message: '核定成本费用率为必填项',
                    trigger: 'blur',
                  },
                ]">
                  <el-input-number v-model="signInfo.operatingCost" controls-position="right" :precision="4" :step="0.1"
                    :min="0" :max="100" :disabled="disabled" />
                </el-form-item>
              </el-col>
              <el-col :md="8" v-if="merchantType == 1">
                <el-form-item label="个人月限额(元)：" prop="monthLimit">
                  <el-input-number v-model="signInfo.monthLimit" controls-position="right" :precision="2" :step="0.1"
                    :min="0" :disabled="disabled" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="备注：">
                  <el-input v-model="signInfo.remark" type="textarea" :rows="4" placeholder="请输入备注" resize="none"
                    :disabled="disabled" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div v-if="merchantType == 1">
            <el-row class="row-header">
              <el-col :span="4">
                <div class="header">服务费梯度</div>
              </el-col>
              <el-col :span="9">
                <div class="header">梯度金额(元)</div>
              </el-col>
              <el-col :span="10">
                <div class="header">梯度税率(%)</div>
              </el-col>
              <!-- <el-col :span="5">
                    <div class="header">速扣金额(元)</div>
                </el-col> -->
              <el-col :span="1">
                <div class="header"></div>
              </el-col>
            </el-row>
            <el-row class="input-area" v-for="(gradient, index) in signInfo.serviceRate" :key="index">
              <el-col :span="4" class="gradient-name">
                第{{ numberMap[index] }}梯度
              </el-col>
              <el-col :span="9">
                <el-row class="range-area">
                  <el-col :span="11">
                    <el-form-item :prop="'serviceRate.' + index + '.minValue'" label-width="0">
                      <el-input type="text" v-model="gradient.minValue" :disabled="true" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" class="range-separator">~</el-col>
                  <el-col :span="11">
                    <el-form-item :prop="'serviceRate.' + index + '.maxValue'" :rules="index !== signInfo.serviceRate.length - 1
                      ? {
                        validator: checkNumber,
                        trigger: ['blur', 'change'],
                      }
                      : null
                      " label-width="0">
                      <el-input type="text" v-model="gradient.maxValue" @keyup.native="
                        handleMaxCountChange1(gradient.maxValue, index)
                        " :disabled="disabled" />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="10" align="center">
                <el-form-item :prop="'serviceRate.' + index + '.rate'" :rules="{
                  validator: checkPrice,
                  trigger: ['blur', 'change'],
                }" label-width="0">
                  <el-input-number v-model="gradient.rate" controls-position="right" :precision="4" :step="0.1" :min="0"
                    :max="100" :disabled="disabled" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-header">
              <el-col :span="4">
                <div class="header">个税梯度</div>
              </el-col>
              <el-col :span="9">
                <div class="header">梯度金额(元)</div>
              </el-col>
              <el-col :span="5">
                <div class="header">梯度税率(%)</div>
              </el-col>
              <el-col :span="5">
                <div class="header">速扣金额(元)</div>
              </el-col>
              <el-col :span="1">
                <div class="header"></div>
              </el-col>
            </el-row>
            <div>
              <el-row class="input-area" v-for="(gradient, index) in signInfo.incomeRate" :key="index">
                <el-col :span="4" class="gradient-name">
                  第{{ numberMap[index] }}梯度
                </el-col>
                <el-col :span="9">
                  <el-row class="range-area">
                    <el-col :span="11">
                      <el-form-item :prop="'incomeRate.' + index + '.minValue'" label-width="0">
                        <el-input type="text" v-model="gradient.minValue" :disabled="true" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="2" class="range-separator">~</el-col>
                    <el-col :span="11">
                      <el-form-item :prop="'incomeRate.' + index + '.maxValue'" :rules="index !== signInfo.incomeRate.length - 1
                        ? {
                          validator: checkNumber,
                          trigger: ['blur', 'change'],
                        }
                        : null
                        " label-width="0">
                        <!-- <el-input
                          type="text"
                          v-model="gradient.maxValue"
                          @keyup.native="
                            handleMaxCountChange(gradient.maxValue, index)
                          "
                          :disabled="index === signInfo.incomeRate.length - 1"
                        /> -->
                        <el-input type="text" v-model="gradient.maxValue" @keyup.native="
                          handleMaxCountChange(gradient.maxValue, index)
                          " :disabled="disabled" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="5" align="center">
                  <el-form-item :prop="'incomeRate.' + index + '.rate'" :rules="{
                    validator: checkPrice,
                    trigger: ['blur', 'change'],
                  }" label-width="0">
                    <el-input-number v-model="gradient.rate" controls-position="right" :precision="4" :step="0.1" :min="0"
                      :max="100" :disabled="disabled" />
                  </el-form-item>
                </el-col>
                <el-col :span="5" align="center">
                  <el-form-item :prop="'incomeRate.' + index + '.quickDeductionFactor'" :rules="{
                    validator: checkPrice,
                    trigger: ['blur', 'change'],
                  }" label-width="0">
                    <el-input-number v-model="gradient.quickDeductionFactor" controls-position="right" :precision="2"
                      :step="0.1" :min="0" :disabled="disabled" />
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="1">
                  <div
                    class="btn-con"
                    v-show="signInfo.incomeRate.length > 1"
                    @click="clearRow(index)"
                  >
                    <i class="btn-clear el-icon-close"></i>
                  </div>
                </el-col> -->
              </el-row>
            </div>
            <!-- <div class="add-bar" @click="addRow">
              <i class="icon iconfont icon-tianjia"></i>添加梯度
            </div> -->
          </div>
          <el-row>
            <el-col :md="8">
              <el-form-item label="签约合同：" prop="signedUrl">
                <el-upload :action="uploadUrl" :headers="myHeaders" :on-success="handleAvatarSuccess"
                  :before-upload="beforeUpload" multiple :limit="1" :file-list="file" :on-remove="handleRemove"
                  :before-remove="beforeRemove" v-if="contentType == 0">
                  <el-button>
                    <i class="el-icon-download"></i>上传文件
                  </el-button>
                  <div slot="tip" class="el-upload__tip">
                    支持扩展名：.rar .zip .doc .docx .pdf .jpg...
                  </div>
                </el-upload>
                <img src="https://gsb-zc.oss-cn-beijing.aliyuncs.com//zc_287111608603155855202022101235855zai.png"
                  class="item_img" v-if="contentType == 1" @click="handlePictureCardPreview()" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="打款密码" prop="payPassword" v-if="contentType == 0">
                <el-input v-model="signInfo.payPassword" size="small" placeholder="请输入打款密码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="dlg-footer" style="padding: 15px; text-align: center" v-if="contentType == 0">
            <el-button type="primary" :loading="shenheLoading" size="medium" @click="submit">
              确定
            </el-button>

            <el-button plain size="medium" @click="quxiaoBtn('merchant_form')">
              取消
            </el-button>
          </div>
        </div>
      </el-form>
    </div>
    <!-- 查看详情 -->
    <el-dialog :visible.sync="dialogDetails" width="680px" :before-close="handleClose2">
      <div class="block">
        <div class="main">
          <el-row>
            <el-col :span="24">
              <h4>企业认证信息</h4>
            </el-col>
            <el-col :span="10"><span class="grid-content bg-purple">企业名称：{{ qiyeInfo.companyName }}</span></el-col>
            <el-col :span="10"><span class="grid-content bg-purple">信用代码：{{ qiyeInfo.creditCode }}</span></el-col>
            <el-col :span="24"><span class="grid-content bg-purple">法人姓名：{{ qiyeInfo.legalPersonName }}</span></el-col>
            <el-col :span="24" style="margin-bottom: 20px"><span class="grid-left">营业执照：</span><img class="grid-img"
                :src="qiyeInfo.businessLincenseImg" alt="" @click="detailsImage(qiyeInfo.businessLincenseImg)" /></el-col>
            <el-col :span="10"><span class="grid-content bg-purple">经办人姓名：{{ qiyeInfo.userName }}</span></el-col>
            <el-col :span="10"><span class="grid-content bg-purple">经办人手机号：{{ qiyeInfo.userMobile }}</span></el-col>

            <el-col :span="10"><span class="grid-content bg-purple">经办人卡号：{{ qiyeInfo.userBankNo }}</span></el-col>
            <el-col :span="24"><span class="grid-content bg-purple">经办人身份证号：{{ qiyeInfo.userIdcard }}</span></el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
    <!-- 关联 -->
    <el-dialog :visible.sync="dialogDetailed" width="700px" :before-close="handleClose3" title="关联">
      <div class="box">
        <el-row>
          <el-form ref="leaseForm" :model="leaseForm" label-width="120px">
            <el-col :span="15">
              <el-form-item label="企业名称：">
                <el-select @change="onInputChange" v-model="leaseForm.name" filterable remote reserve-keyword
                  placeholder="请输入企业名称" :remote-method="remoteMethod" :loading="loading">
                  <el-option v-for="item in qiyeOptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label=" " style="text-align: left">
                <el-button type="primary" size="small" @click="searchBtn()">确定</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '../../components/pagination.vue';
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
import { spunFenform } from '../../filters/textTransform';
// import tableHeight from "../../mixins/tableHeight";
export default {
  // mixins: [tableHeight],
  data() {
    return {
      statusObj: {
        0: '可用',
        1: '冻结'
      },
      statusCol: {
        0: '#67c23a',
        1: '#f56c6c'
      },
      statusBg: {
        0: '#f0f9eb',
        1: '#fef0f0'
      },
      statusList: [
        { id: '', name: '全部' },
        { id: '0', name: '可用' },
        { id: '1', name: '冻结' }
      ],
      serviceList: [],
      // 服务商模糊搜索
      qiyeRenOptions: [],
      statesRen: [],
      loading: false,
      uploadUrl: api.uploadOss,
      uploadUrled: api.businessLicense,
      disableTitle: '',
      file: [],
      fileListed: [],
      myHeaders: { Authorization: this.$cookies.get('esignmerchantsid') },
      total: null,
      pageSzie: 10,
      currentPage: 1,
      dialogImg: false,
      shenheLoading: false,
      shenheLoadingfed: false,
      shenheLoadinged: false,
      imageDetails: '',
      releaseType: true,
      qiyeInfo: {},
      dialogVisible: false,
      dialogDetails: false,
      dialogDetailed: false,
      dialogVisible4: false,
      contentDetails: '企业签约',
      contentType: 0, //0签约1查看签约
      signInfo: {
        id: '',
        accType: '',
        settleType: '',
        grantWay: '',
        bdMobile: '',
        bdUserId: '',
        invoiceType: '',
        beginTime: '',
        busiId: '',
        signRecord: '0',
        busiName: '',
        companyId: '',
        companyName: '',
        mainId: '',
        mainName: '',
        incomeCompany: '',
        completeTime: '',
        addedValueRate: '',
        remark: '',
        version: '',
        signedUrl: '',
        operatingCost: '',
        taxChargeMode: '',
        monthLimit: 100000,
        serviceTotalType: 1,
        serviceRate: '',
        payPassword: '',
        incomeRate: [
          { minValue: '0', maxValue: '', rate: '', quickDeductionFactor: '' },
          {
            minValue: '',
            maxValue: '2147483647',
            rate: '',
            quickDeductionFactor: ''
          }
        ],
        addedCollectType: '1',
        serviceCollectType: '1'
      },
      infoBoxStatusColor: {
        1: '#3083FF',
        2: '#52C41A',
        3: '#F5222D'
      },
      auditForm: {
        auditStatus: '',
        auditNotes: '',
        id: ''
      },
      auditRules: {
        auditStatus: [{ required: true, message: '请选择审核状态', trigger: 'change' }],
        auditNotes: [{ required: true, message: '请填写审核备注', trigger: 'blur' }]
      },
      merchantRules: {
        signRecord: [
          {
            required: true,
            message: '请选择是否已开设结算商户',
            trigger: 'change'
          }
        ],
        invoiceType: [{ required: true, message: '请选择合作类型', trigger: 'blur' }],
        payPassword: [{ required: true, message: '请选择打款密码', trigger: 'blur' }],
        signedUrl: [{ required: true, message: '请上传签约合同', trigger: 'blur' }],
        busiName: [{ required: true, message: '请填写关联结算商户', trigger: 'blur' }],
        busiId: [{ required: true, message: '请填写关联商户ID', trigger: 'blur' }]
      },
      qianyeOptions: [],
      guanlianOptions: [],
      auditOptions: [
        {
          value: '2',
          label: '审核通过'
        },
        {
          value: '3',
          label: '审核失败'
        }
      ],
      fapiaoOptions: [
        {
          value: '0',
          label: '普票'
        },
        {
          value: '1',
          label: '专票'
        }
      ],
      leaseForm: {
        name: []
      },
      releaseForm: {
        companyName: '',
        status: '',
        checkStatus: '',
        serviceId: ''
      },
      options: [
        {
          value: '1',
          label: '待审核'
        },
        {
          value: '2',
          label: '审核通过'
        },
        {
          value: '3',
          label: '审核失败'
        }
      ],
      jiesuanData: [],
      tableData: [],
      qiyeOptions: [],
      list: [],
      loading: false,
      states: [],
      qiyeId: '',
      xbgMerchantId: '',
      xbgMerchantInvoiceType: '',
      xbgMerchantName: '',
      isCreate: false, // true 新增 false 修改
      numberMap: {
        0: '一',
        1: '二',
        2: '三',
        3: '四',
        4: '五',
        5: '六',
        6: '七',
        7: '八',
        8: '九',
        9: '十'
      },
      merchantType: 0, //1是0否
      disabled: false,
      disabledFalse: false,
      typeID: false,
      taskForm: {
        businessLincenseImg: '',
        companyName: '',
        businessTerm: '',
        businessType: '',
        creditCode: '',
        legalPersonName: '',
        busiId: '',
        mobile: ''
        // password: "",
        // ucname: "",
      }
    };
  },
  mounted() {
    this.serviceInfo(); //服务商
  },
  computed: {},
  methods: {
    serviceInfo() {
      post(api.queryServiceList).then(res => {
        if (res.code == 0) {
          this.releaseForm.serviceId = res.data[0].serviceId;
          this.serviceList = res.data;
          this.qiyeInfoPost();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 表格
    renderHeader(h, data) {
      return h('span', [
        h('el-tooltip'),
        {
          attrs: {
            class: 'item',
            effect: 'dark',
            content: data.column.label
          }
        },
        [h('span', data.column.label)]
      ]);
    },
    onInputPost(val) {
      //向父组件传递选择地值，调用父组件setMajor把选中值赋给父组件
      // console.log(val, "djdjddjj");
      let obj = {};
      obj = this.qianyeOptions.find(item => {
        //model就是上面的数据源
        return item.value === val; //筛选出匹配数据
      });
      // console.log(obj, "zidiandd");
      this.signInfo.mainName = obj.label;
      this.signInfo.mainId = obj.value;
    },
    //关联结算商户
    remoteGuanMethod(query) {
      // console.log(query, "44444");
      if (query !== '') {
        // this.loading = true;
        this.guanlianOptions = [];
        post(api.queryCompanyByName, {
          companyName: query
        }).then(d => {
          if (d.code === 0) {
            this.states = d.data;
            this.list = [];
            if (this.states.length > 0) {
              this.list = this.states.map(item => {
                // console.log(item.name_id,'33933939399')
                return {
                  value: `${item.id}`,
                  label: `${item.nameId}`,
                  company_name: `${item.companyName}`
                };
              });
              this.guanlianOptions = this.list.filter(item => {
                return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
              });
            } else {
              this.states = [];
              this.list = [];
            }
          }
        });
        // setTimeout(() => {
        //   this.loading = false;
        //   console.log(this.list)

        // }, 100);
      } else {
        this.guanlianOptions = [];
      }
    },
    onInputGuanChange(val) {
      //向父组件传递选择地值，调用父组件setMajor把选中值赋给父组件
      // console.log(val, "djdjddjj");
      let obj = {};
      obj = this.guanlianOptions.find(item => {
        //model就是上面的数据源
        return item.value === val; //筛选出匹配数据
      });
      // console.log(obj, "zidiandd");
      this.signInfo.busiName = obj.company_name;
      this.signInfo.busiId = obj.value;
      this.taskForm.busiId = obj.value;
      post(api.querySignedRecord, {
        busiId: obj.value
      }).then(
        d => {
          if (d.code === 0) {
            // console.log(d, "111111111");
            this.signInfo.id = d.data.id;
            this.signInfo.invoiceType = d.data.invoiceType;
            this.signInfo.beginTime = d.data.beginTime;
            this.signInfo.mainId = d.data.mainId;
            this.signInfo.incomeCompany = d.data.incomeCompany;
            this.signInfo.completeTime = d.data.completeTime;
            this.signInfo.addedValueRate = d.data.addedValueRate;
            this.signInfo.remark = d.data.remark;
            this.signInfo.operatingCost = d.data.operatingCost;
            this.signInfo.taxChargeMode = d.data.taxChargeMode;
            this.signInfo.monthLimit = d.data.monthLimit;
            this.signInfo.serviceTotalType = d.data.serviceTotalType;
            this.signInfo.serviceRate = d.data.serviceRate;
            this.signInfo.serviceRate = JSON.parse(d.data.serviceRate);
            this.signInfo.incomeRate = JSON.parse(d.data.incomeRate);
            this.signInfo.addedCollectType = d.data.addedCollectType;
            this.signInfo.serviceCollectType = d.data.serviceCollectType;
            this.onInvoiceTypeChange(d.data.invoiceType);
            this.signInfo.mainId = d.data.mainId;
            this.signInfo.mainName = d.data.mainName;
            this.signInfo.version = d.data.version;
            this.signInfo.accType = d.data.accType;
            this.signInfo.settleType = d.data.settleType;
            this.signInfo.grantWay = d.data.grantWay;
            this.signInfo.bdMobile = d.data.bdMobile;
            this.signInfo.bdUserId = d.data.bdUserId;
          }
        },
        err => {
          //error callback
        }
      );
    },
    onInvoiceTypeChange(val) {
      this.qianyeOptions = [];
      this.signInfo['mainId'] = '';
      post(api.queryByInvoiceType, {
        invoiceType: val
      }).then(
        d => {
          if (d.code === 0) {
            // console.log(d, "111111111");
            for (let i = 0; i < d.data.length; i++) {
              var obj = {
                value: d.data[i].id,
                label: d.data[i].companyName
              };
              this.qianyeOptions.push(obj);
            }
          }
        },
        err => {
          //error callback
        }
      );
    },
    beforeUpload(file) { },
    handleAvatarSuccess(res, file, files, key, val) {
      // console.log(res.data, "44444");
      this.file = files;
      this.signInfo.signedUrl = res.data;
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //业务合同删除
    handleRemove(file, fileList) {
      // console.log(file, "4444444");
      this.signInfo.signedUrl = '';
      // let arr = [];
      // for (let i = 0; i < fileList.length; i++) {
      //   arr.push(fileList[i].url);
      // }
      // console.log(arr);
    },
    formatIncomeRate(data) {
      let lastCount = 0;
      for (let i = 0, len = data.length; i < len; i++) {
        if (data[i].maxValue - 1 < lastCount) {
          return {
            result: false,
            index: i + 1
          };
        }
        lastCount = +data[i].maxValue;

        // 乘100
        data[i].minValue = Util.multiply(data[i].minValue, 100);
        data[i].maxValue = Util.multiply(data[i].maxValue, 100);
        let quickDeductionFactorValue =
          !data[i].quickDeductionFactor || data[i].quickDeductionFactor === ''
            ? 0
            : data[i].quickDeductionFactor;
        data[i].quickDeductionFactor = Util.multiply(quickDeductionFactorValue, 100);
      }
      return {
        result: true,
        data
      };
    },
    // xhr添加梯度
    submit() {
      // console.log(this.merchantType, "3333");
      this.shenheLoading = true;
      this.$refs.merchant_form.validate(valid => {
        if (valid) {
          const params = _.clone(this.signInfo);
          //1是（旧客户）0否（新签约）
          if (this.merchantType == 1) {
            const incomeRate = JSON.parse(JSON.stringify(this.signInfo.incomeRate));
            const serviceRate = JSON.parse(JSON.stringify(this.signInfo.serviceRate));
            const formatted = this.formatIncomeRate(incomeRate);
            const formatted2 = this.formatIncomeRate(serviceRate);
            if (!formatted.result) {
              this.$message({
                showClose: true,
                message: `第${this.numberMap[formatted.index]}梯度最大数量输入有误`,
                type: 'error'
              });
              this.shenheLoading = false;
              return;
            }
            if (!formatted2.result) {
              this.$message({
                showClose: true,
                message: `第${this.numberMap[formatted2.index]
                  }梯度最大数量输入有误`,
                type: 'error'
              });
              this.shenheLoading = false;
              return;
            }

            params.incomeRate = formatted.data;
            params.serviceRate = formatted2.data;
            params.monthLimit = spunFenform(params.monthLimit);
          }
          // params.addedCollectType = addedCollectType;
          // params.incomeCompany = incomeCompany;
          params.beginTime = params.beginTime ? `${params.beginTime} 00:00:00` : '';
          params.completeTime = params.completeTime
            ? `${params.completeTime} 00:00:00`
            : '';
          // console.log(params, "向后台传输的数据----------------");
          post(api.saveSignedRecord, params).then(
            d => {
              this.shenheLoading = false;
              if (d.code === 0) {
                this.$message.success('签约成功');
                this.releaseType = true;
                this.$refs.merchant_form.resetFields();
                this.file = [];
                this.signInfo.signedUrl = '';
                //  console.log(this.currentPage,'6666666666666')
                this.qiyeInfoPost(this.currentPage);
              } else {
                this.shenheLoading = false;
                this.$message.error(d.message);
              }
            },
            err => {
              //error callback
              this.shenheLoading = false;
            }
          );
        } else {
          this.shenheLoading = false;
        }
      });
    },

    onInvoiceChange() {
      if (this.isOrdinaryInvoice) {
        this.signInfo.incomeCompany = '0'; // 个税：0 个税向个人收 1 个税向企业收
        this.signInfo.addedCollectType = '1'; // 增值服务费收取类型, 0 个人收 1 企业收
        this.signInfo.serviceCollectType = '1'; // 服务费收取类型, 0 个人收 1 企业收
        return;
      }
      this.signInfo.incomeCompany = '';
      this.signInfo.addedCollectType = '';
      this.signInfo.serviceCollectType = '';
    },
    // Triggered when user select mainId.
    // 检查正整数
    checkNumber(rule, value, callback) {
      // if (!value) {
      //     callback(new Error('不能为空'));
      // } else if (!Number.isInteger(+value)) {
      //     callback(new Error('请输入整数'));
      // } else if (String(+value) !== String(value)) {
      //     callback(new Error('请输入整数'));
      // } else if (+value <= 0) {
      //     callback(new Error('请输入>0的数'));
      // } else {
      //     callback();
      // }
      const pattern = /^(?:0|[1-9][0-9]*)(\.[0-9]{1,2})?$/;
      if (!value) {
        callback(new Error('不能为空'));
      } else if (!pattern.exec(value)) {
        callback(new Error('数值必须(非0开头，最多两位小数)'));
      } else if (+value < 0) {
        callback(new Error('请输入>=0的数'));
      } else {
        callback();
      }
    },
    // 检查两位小数的正数
    checkPrice(rule, value, callback) {
      // const pattern = /^[1-9]\.[0-9]{1,2}$|^0\.[0-9][0-9]?$|^[1-9]\d{0,8}(\.\d{1,2}){0,1}$/;
      /*
              Allow zero.
           */
      const pattern = /^(?:0|[1-9][0-9]*)(\.[0-9]{1,4})?$/;
      if (value === null || value === undefined) {
        callback(new Error('不能为空'));
      } else if (!pattern.exec(value)) {
        callback(new Error('数值必须(非0开头，最多四位小数)'));
      } else if (+value < 0) {
        callback(new Error('请输入>=0的数'));
      } else {
        callback();
      }
    },
    // 梯度数量修改
    handleMaxCountChange(value, index) {
      if (index >= this.signInfo.incomeRate.length - 1) {
        return;
      }
      // if (value && Number.isInteger(+value)) {
      this.signInfo.incomeRate[index + 1].minValue = (+value + 0.01).toFixed(2);
      // }
      this.$forceUpdate();
    },
    // 梯度数量修改
    handleMaxCountChange1(value, index) {
      if (index >= this.signInfo.serviceRate.length - 1) {
        return;
      }
      // if (value && Number.isInteger(+value)) {
      this.signInfo.serviceRate[index + 1].minValue = (+value + 0.01).toFixed(2);
      // }
      this.$forceUpdate();
    },
    // 删除当前行
    clearRow(index) {
      if (this.signInfo.incomeRate.length === 1) {
        this.$message({
          showClose: true,
          message: '只剩最后一行了',
          type: 'warning'
        });
        return;
      }
      this.signInfo.incomeRate.splice(index, 1);
      // 处理最大值
      const len = this.signInfo.incomeRate.length;
      this.signInfo.incomeRate[len - 1].maxValue = '2147483647';
      // 处理最小值
      this.signInfo.incomeRate[0].minValue = '0';
      // 处理中间值
      if (len > 1 && index > 0 && index !== len) {
        const val = this.signInfo.incomeRate[index - 1].maxValue;
        if (val && Number.isInteger(+val)) {
          this.signInfo.incomeRate[index].minValue = +val + 1;
        }
      }
      this.$forceUpdate();
      // 移除校验结果
      this.$refs.merchant_form.clearValidate();
    },
    // 删除当前行
    clearRow1(index) {
      if (this.signInfo.serviceRate.length === 1) {
        this.$message({
          showClose: true,
          message: '只剩最后一行了',
          type: 'warning'
        });
        return;
      }
      this.signInfo.serviceRate.splice(index, 1);
      // 处理最大值
      const len = this.signInfo.serviceRate.length;
      this.signInfo.serviceRate[len - 1].maxValue = '2147483647';
      // 处理最小值
      this.signInfo.serviceRate[0].minValue = '0';
      // 处理中间值
      if (len > 1 && index > 0 && index !== len) {
        const val = this.signInfo.serviceRate[index - 1].maxValue;
        if (val && Number.isInteger(+val)) {
          this.signInfo.serviceRate[index].minValue = +val + 1;
        }
      }
      this.$forceUpdate();
      // 移除校验结果
      this.$refs.merchant_form.clearValidate();
    },
    // 增加一行
    addRow() {
      if (this.signInfo.incomeRate.length >= 10) {
        this.$message({
          showClose: true,
          message: '最多添加10行',
          type: 'warning'
        });
        return;
      }
      this.signInfo.incomeRate.push({
        minValue: '',
        maxValue: '2147483647',
        rate: '',
        quickDeductionFactor: ''
      });
      // 清空最大值
      const len = this.signInfo.incomeRate.length - 2;
      this.signInfo.incomeRate[len].maxValue = '';
      this.$forceUpdate();
      // 移除校验结果
      this.$refs.merchant_form.clearValidate();
    },
    // 增加一行
    addRow1() {
      if (this.signInfo.serviceRate.length >= 10) {
        this.$message({
          showClose: true,
          message: '最多添加10行',
          type: 'warning'
        });
        return;
      }
      this.signInfo.serviceRate.push({
        minValue: '',
        maxValue: '2147483647',
        rate: ''
      });
      // 清空最大值
      const len = this.signInfo.serviceRate.length - 2;
      this.signInfo.serviceRate[len].maxValue = '';
      this.$forceUpdate();
      // 移除校验结果
      this.$refs.merchant_form.clearValidate();
    },
    remoteMethod(query) {
      // console.log(query, "44444");
      if (query !== '') {
        // this.loading = true;
        this.qiyeOptions = [];
        post(api.queryByXBG, {
          name: query
        }).then(d => {
          if (d.code === 0) this.states = d.data;
          this.list = [];
          if (this.states.length > 0) {
            this.list = this.states.map(item => {
              // console.log(item.name_id,'33933939399')
              return {
                value: `${item.id}`,
                label: `${item.name_id}`,
                company_name: `${item.company_name}`,
                invoice_type: `${item.invoice_type}`
              };
            });
            this.qiyeOptions = this.list.filter(item => {
              return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });
          } else {
            this.states = [];
            this.list = [];
          }
        });
        // setTimeout(() => {
        //   this.loading = false;
        //   console.log(this.list)

        // }, 100);
      } else {
        this.qiyeOptions = [];
      }
    },
    onInputChange(val) {
      //向父组件传递选择地值，调用父组件setMajor把选中值赋给父组件
      // console.log(val, "djdjddjj");
      let obj = {};
      obj = this.qiyeOptions.find(item => {
        //model就是上面的数据源
        return item.value === val; //筛选出匹配数据
      });
      // console.log(obj.label, "zidiandd");
      this.xbgMerchantName = obj.company_name;
      // console.log(this.xbgMerchantName, "dddddggg");
      this.xbgMerchantInvoiceType = obj.invoice_type;
    },
    handleCloseGuan() {
      this.dialogImg = false;
    },
    detailsImage(src) {
      if (src) {
        this.imageDetails = src;
        this.dialogImg = true;
      }
    },
    qiyeInfoPost(size) {
      if (true) {
        // 数字
        const loading = this.$loading({
          lock: true,
          spinner: 'loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        post(api.agentRealCompanyPage, {
          currentPage: size || 1,
          pageSize: this.pageSzie,
          companyName: this.releaseForm.companyName,
          status: this.releaseForm.status,
          checkStatus: this.releaseForm.checkStatus,
          serviceId: this.releaseForm.serviceId
        }).then(
          d => {
            loading.close();
            if (d.code === 0) {
              // console.log(d, "!!!!!!!!!!!!");
              this.total = d.data.count;
              this.tableData = d.data.rows;
              this.currentPage = d.data.currentPage;
            } else {
              // console.log(d.message);
            }
          },
          err => {
            //error callback
          }
        );
      } else {
        //字符
        this.total = 0;
        this.currentPage = 1;
        this.pageSzie = 10;
        this.tableData = [];
      }
    },
    xuanze(val) {
      // console.log(val, "选择1");
      // this.$refs.auditForm.resetFields();
      if (val == 3) {
        this.auditRules['auditNotes'] = [
          { required: true, message: '请输入审核备注', trigger: 'blur' }
        ];
      } else if (val == 2) {
        this.auditRules['auditNotes'] = [
          { required: false, message: '请输入审核备注', trigger: 'blur' }
        ];
      }
    },
    xuanzeName(val) {
      // console.log(val, "选择1");
      //   merchantType:0,//1是0否
      this.contentType = 0;
      if (val == 1) {
        this.merchantType = 1;

        this.disabled = false;
        this.disabledFalse = true;
        this.merchantRules['busiId'] = [
          { required: true, message: '请选择关联结算商户', trigger: 'blur' }
        ];
        this.signInfo.serviceRate = [{ minValue: '0', maxValue: '2147483647', rate: '' }];
      } else if (val == 0) {
        this.merchantType = 0;
        this.disabled = true;
        this.disabledFalse = false;
        this.merchantRules['busiId'] = [
          { required: false, message: '请选择关联结算商户', trigger: 'blur' }
        ];
        this.signInfo = {
          signRecord: '0',
          companyId: this.signInfo.companyId,
          companyName: this.signInfo.companyName,
          invoiceType: '',
          beginTime: '',
          busiId: '',
          busiName: '',
          mainId: '',
          mainName: '',
          version: '',
          incomeCompany: '',
          completeTime: '',
          addedValueRate: '',
          remark: '',
          signedUrl: '',
          operatingCost: '',
          taxChargeMode: '',
          monthLimit: 100000,
          serviceTotalType: 1,
          serviceRate: '',
          payPassword: '',
          incomeRate: [
            { minValue: '0', maxValue: '', rate: '', quickDeductionFactor: '' },
            {
              minValue: '',
              maxValue: '2147483647',
              rate: '',
              quickDeductionFactor: ''
            }
          ],
          addedCollectType: '1',
          serviceCollectType: '1'
        };
      }
    },
    // 取消
    resetForm(formName) {
      // console.log("0111");
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleClose3(done) {
      done();
      this.leaseForm = {};
      this.qiyeOptions = [];
      this.dialogDetailed = false;
    },
    handleClose2() {
      this.dialogDetails = false;
    },
    submitForm(formName) {
      this.shenheLoadingfed = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          post(api.updateCompany, this.auditForm).then(
            d => {
              this.shenheLoadingfed = false;
              if (d.code === 0) {
                // console.log(d, "??????????????");
                this.dialogVisible = false;
                this.qiyeInfoPost(this.currentPage);
              } else {
                this.$message.error(d.message);
              }
            },
            err => {
              //error callback
              this.shenheLoadingfed = false;
            }
          );
        } else {
          // console.log('error submit!!');
          this.shenheLoadingfed = false;
          return false;
        }
      });
    },
    goBack() {
      this.shenheLoading = false;
      this.releaseType = true;
      this.qiyeInfoPost();
    },
    quxiaoBtn(formName) {
      this.$refs[formName].resetFields();
      this.releaseType = true;
    },

    clickRow(val, row) {
      //  1详情 2关联 3 审核 4 签约 5 查看签约
      if (val == 1) {
        post(api.getById, {
          id: row.id
        }).then(
          d => {
            if (d.code === 0) {
              // console.log(d, "??????????????");
              this.qiyeInfo = d.data;
              this.qiyeInfo.userName = row.userName;
              this.qiyeInfo.userMobile = row.userMobile;
              this.qiyeInfo.userIdcard = row.userIdcard;
              this.qiyeInfo.userBankNo = row.userBankNo;
              this.dialogDetails = true;
            } else {
              this.$message.error(d.message);
            }
          },
          err => {
            //error callback
          }
        );
      } else if (val == 2) {
        this.qiyeId = row.id;
        this.leaseForm = {};
        this.qiyeOptions = [];
        this.jiesuanData = [];
        post(api.getById, {
          id: row.id
        }).then(
          d => {
            if (d.code === 0) {
              // console.log(d, "??????????????");
              if (d.data.xbgMerchantId != '' && d.data.xbgMerchantName != '') {
                this.jiesuanData.push(d.data);
              } else {
                this.jiesuanData = [];
              }

              this.dialogDetailed = true;
            } else {
              this.$message.error(d.message);
            }
          },
          err => {
            //error callback
          }
        );
      } else if (val == 3) {
        this.auditForm = {
          auditStatus: '',
          auditNotes: '',
          id: row.id
        };
        this.dialogVisible = true;
      } else if (val == 4) {
        // contentType:0,//0签约1查看签约
        this.releaseType = false;
        this.contentDetails = '企业签约';
        this.contentType = 0;
        this.disabled = true;
        this.merchantType = 1; //1是（旧客户）0否（新签约）

        this.merchantRules['busiId'] = [
          { required: false, message: '请选择关联结算商户', trigger: 'blur' }
        ];
        this.file = [];
        this.signInfo = {
          invoiceType: '',
          beginTime: '',
          busiId: '',
          signRecord: '1',
          busiName: '',
          companyId: row.id,
          companyName: row.companyName,
          mainId: '',
          mainName: '',
          incomeCompany: '',
          completeTime: '',
          addedValueRate: '',
          version: '',
          remark: '',
          signedUrl: '',
          operatingCost: '',
          taxChargeMode: '',
          monthLimit: 100000,
          serviceTotalType: 1,
          serviceRate: '',
          payPassword: '',
          incomeRate: [
            { minValue: '0', maxValue: '', rate: '', quickDeductionFactor: '' },
            {
              minValue: '',
              maxValue: '2147483647',
              rate: '',
              quickDeductionFactor: ''
            }
          ],
          addedCollectType: '1',
          serviceCollectType: '1'
        };
        this.$forceUpdate();
      } else {
        this.contentDetails = '查看签约';
        this.contentType = 1;
        this.disabled = true;
        this.merchantRules['busiId'] = [
          { required: true, message: '请选择关联结算商户', trigger: 'blur' }
        ];
        this.file = [];
        this.items = [];
        post(api.queryAgentMerchantSign, {
          companyId: row.id
        }).then(
          d => {
            if (d.code === 0) {
              this.merchantType = d.data.signRecord; //1是（旧客户）0否（新签约）
              this.signInfo.companyId = row.id;
              this.signInfo.companyName = row.companyName;
              this.signInfo.id = d.data.id;
              this.signInfo.busiId = d.data.busiId;
              this.signInfo.busiName = d.data.busiName;
              this.signInfo.invoiceType = d.data.invoiceType;
              this.signInfo.beginTime = d.data.beginTime;
              this.signInfo.mainId = d.data.mainId;
              this.signInfo.incomeCompany = d.data.incomeCompany;
              this.signInfo.completeTime = d.data.completeTime;
              this.signInfo.addedValueRate = d.data.addedValueRate;
              this.signInfo.remark = d.data.remark;
              this.signInfo.operatingCost = d.data.operatingCost;
              this.signInfo.taxChargeMode = d.data.taxChargeMode;
              this.signInfo.monthLimit = d.data.monthLimit;
              this.signInfo.serviceTotalType = d.data.serviceTotalType;
              this.signInfo.serviceRate = d.data.serviceRate;
              this.signInfo.accType = d.data.accType;
              this.signInfo.settleType = d.data.settleType;
              this.signInfo.grantWay = d.data.grantWay;
              this.signInfo.bdMobile = d.data.bdMobile;
              this.signInfo.bdUserId = d.data.bdUserId;
              // this.signInfo.version = d.data.version;
              if (d.data.signRecord == 0) {
                this.signInfo.serviceRate = d.data.serviceRate;
              } else {
                this.signInfo.serviceRate = JSON.parse(d.data.serviceRate);
              }
              this.signInfo.incomeRate = JSON.parse(d.data.incomeRate);
              this.signInfo.addedCollectType = d.data.addedCollectType;
              this.signInfo.serviceCollectType = d.data.serviceCollectType;
              this.onInvoiceTypeChange(d.data.invoiceType);
              this.signInfo.mainId = d.data.mainId;
              this.signInfo.mainName = d.data.mainName;
              // if (d.data.signedUrl != "" && d.data.signedUrl != null) {
              //   this.items = d.data.signedUrl.split(",");
              //   console.log(this.items, "cehdhhdhdhd");
              //   let obj = {};
              //   for (let i = 0; i < this.items.length; i++) {
              //     this.file.push(
              //       obj = {
              //         name: this.items[i],
              //         url: this.items[i]
              //       }
              //     );
              //   }
              //   console.log(this.file,'55555')
              // }
              this.signInfo.signedUrl = d.data.signedUrl;
              this.$forceUpdate();
              this.releaseType = false;
            } else {
              this.$message.error(d.message);
            }
          },
          err => {
            //error callback
          }
        );
      }
    },
    //列表
    search() {
      this.qiyeInfoPost();
    },
    //关联
    searchBtn() {
      post(api.relevance, {
        id: this.qiyeId,
        xbgMerchantId: this.leaseForm.name,
        xbgMerchantName: this.xbgMerchantName,
        xbgMerchantInvoiceType: this.xbgMerchantInvoiceType
      }).then(
        d => {
          if (d.code === 0) {
            this.qiyeOptions = [];
            this.dialogDetailed = false;
            this.qiyeInfoPost(this.currentPage);
          }
        },
        err => {
          //error callback
        }
      );
    },
    reset() {
      (this.releaseForm = {
        companyName: '',
        checkStatus: '',
        status: '',
        serviceId: this.releaseForm.serviceId
      }),
        this.qiyeInfoPost();
    },
    handleSizeChange(val) {
      this.pageSzie = val;
      // console.log(`每页 ${val} 条aaaaa`);
      this.qiyeInfoPost();
    },
    handleCurrentChange(size) {
      // console.log(size);
      this.qiyeInfoPost(size);
    }
  },

  components: {
    pagination: pagination
  }
};
</script>
