<template>
  <div class="upload-container">
    <el-upload list-type="picture-card"
               :class="isHide?'isHideSome':''"
               :multiple="false"
               :show-file-list="true"
               :on-success="handleImageSuccess"
               :on-preview="handlePreview"
               :on-remove="handleRemove"
               :file-list="fileList"
               class="image-uploader"
               :limit="1"
               :action="globalUploadUrl">
      <i class="el-icon-plus" />
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%"
           :src="dialogImageUrl"
           alt="">
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/api.js";
export default {
  name: 'PicUpload',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
        globalUploadUrl:api.businessLicense,
        tempUrl: '',
        dialogImageUrl: '',
        dialogVisible: false,
        fileList: []
    };
  },
  computed: {
    isHide() {
      if (this.tempUrl) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    value(newValue, oldValue) {
      // console.log("测试新的值");
      // console.log(newValue);
      if (!newValue) {
        this.tempUrl = '';
        this.fileList = [];
      } else {
        this.tempUrl = newValue;
        this.fileList = [{ url: newValue }];
      }
    }
  },
  mounted() {
    // console.log('--->', this.value);
    if (this.value) {
      this.tempUrl = this.value;
      this.fileList = [{ url: this.value }]; // 用于回显数据
    }
  },
  methods: {
    rmImage() {
      this.emitInput('');
    },
    handleImageSuccess(response, file, fileList) {
      const { code, data } = response;
      // console.log(data);
      if (code === 200) {
        this.tempUrl = data;
        this.$emit('input', data);
      }
    },
    handlePreview(file) {
      // console.log(file, 123);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      this.tempUrl = '';
      this.$emit('input', '');
    }
  }
};
</script>
<style rel="stylesheet/less" lang="less">
.isHideSome {
  .el-upload--picture-card {
    display: none !important;
  }
}
</style>

<style rel="stylesheet/less" lang="less" scoped>
#pane-first > div > form > div:nth-child(2) > div > div > div.image-uploader {
  width: 148px;
}
.upload-container {
  width: 100%;
  position: relative;
  .image-uploader {
    width: 200px;
    float: left;
  }
  .image-preview {
    width: 200px;
    height: 200px;
    position: relative;
    border: 1px dashed #d9d9d9;
    float: left;
    margin-left: 50px;
    .image-preview-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .image-preview-action {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s;
      cursor: pointer;
      text-align: center;
      line-height: 200px;
      .el-icon-delete {
        font-size: 36px;
      }
    }
    &:hover {
      .image-preview-action {
        opacity: 1;
      }
    }
  }
}
</style>
