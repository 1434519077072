<template>
  <div class="invoiceRequests">
    <!-- 发票统计 -->
    <el-card class="box-card" style="width:50%;margin-bottom: 20px;">
      <div slot="header" class="clearfix" style="padding-bottom: 10px;">
        <span>发票统计</span>
        <el-date-picker v-model="invoiceMonth" @change="dateRangeChangeVote" type="month" :clearable="false"
          :picker-options="pickerOptionsMonth" style="float: right;" value-format="yyyy-MM" value="yyyy-MM"
          placeholder="选择月份">
        </el-date-picker>
        <!-- <el-date-picker style="float: right" v-model="dateRangeVote" @change="dateRangeChangeVote" type="daterange"
          align="right" unlink-panels range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions">
        </el-date-picker> -->
      </div>
      <div class="btn-rowNew">
        <div style="text-align: center;margin-right: 60px;margin-left: 20px;" @click="voteClick(1)">
          <div>
            <div class="point" :style="`border: 3px solid ${'#1854FF'};`"></div>
            <span style="font-size: 12px">已申请</span>
          </div>
          <div style="margin-top: 10px;" class="num">{{ invoicedCount }}</div>
        </div>
        <div style="text-align: center" @click="voteClick(0)">
          <div>
            <div class="point" :style="`border: 3px solid ${'red'};`"></div>
            <span style="font-size: 12px">未申请</span>
          </div>
          <div style="margin-top: 10px;" class="num">{{ notInvoicedCount }}</div>
        </div>
      </div>
    </el-card>
    <!-- 已开票/未开票 -->
    <el-dialog :title="listQuery.invoiceFlag == 0 ? '未申请商户信息' : '已申请商户信息'" :visible.sync="dialogVisibleVote"
      width="1100px" :close-on-click-modal="false" :before-close="handleCloseVote">
      <el-table v-loading="tableDataVoteLoading" :data="tableDataVote" fit border style="width: 100%"
        :header-cell-style="{ background: '#eef1f6' }">
        <el-table-column prop="busiId" label="商户ID" align="center" min-width="180" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.busiId ? scope.row.busiId : '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="商户名称" align="center" min-width="180" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.companyName ? scope.row.companyName : '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="driverCompanyName" label="交付商名称" align="center" min-width="180" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.driverCompanyName ? scope.row.driverCompanyName : '--' }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="agentName" label="渠道商名称" min-width="180" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.agentName ? scope.row.agentName : '--' }}
          </template>
        </el-table-column> -->
        <el-table-column prop="deductAmtTotal" label="金额(元)" min-width="120" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.deductAmtTotal ? scope.row.deductAmtTotal : '--' }}
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination-container">
        <el-pagination background="" :current-page="listQuery.current" :page-sizes="[40, 30, 20, 10]"
          :page-size="listQuery.size" layout="total, sizes, prev, pager, next, jumper" :total="tableTotal"
          @size-change="handleSizeChangeTotal" @current-change="handleCurrentChangeTotal" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleVote = false">取 消</el-button>
      </span>
    </el-dialog>
    <div class="query-part">
      <el-form ref="query_form" label-width="110px" :model="queryForm">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :lg="6">
            <el-form-item label="开票状态：" prop="chkSta" class="mini-query">
              <el-select v-model.trim="queryForm.fpFlag" placeholder="请选择开票状态" style="width:100%" size="small" clearable>
                <el-option value="100" label="未开票" />
                <el-option value="101" label="已开票" />
                <el-option value="110" label="开票中" />
                <el-option value="112" label="开票失败" />
                <el-option value="109" label="已作废" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="12" style="height:63px">
            <el-form-item label="申请时间：" v-if="tabPosition == 'left'">
              <el-date-picker style="width:100%" :clearable="false" v-model="dateRange" @change="fetchTime(dateRange)"
                value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" :default-time="['00:00:00', '23:59:59']"
                :picker-options="queryPickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                align="right">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="申请时间：" v-else>
              <el-date-picker style="width:100%" :clearable="false" v-model="dateRange" @change="fetchTime(dateRange)"
                value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" :default-time="['00:00:00', '23:59:59']"
                :picker-options="queryPickerOptions1" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                align="right">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-row :xs="24" :sm="12" :lg="6" style="float: right; margin-right: 10px;">
            <el-form-item>
              <el-button type="primary" size="small" icon="el-icon-search" v-waves @click="onSearch">查询</el-button>
              <el-button size="small" @click="onReset" icon="el-icon-refresh-left" class="chongzhi">重置</el-button>
            </el-form-item>
          </el-row>
        </el-row>
      </el-form>
    </div>
    <div class="main_button">
      <el-radio-group v-model="tabPosition" @change="tabChange">
        <el-radio-button label="left">今年数据</el-radio-button>
        <el-radio-button label="right">历史数据</el-radio-button>
      </el-radio-group>
      <!-- 初始话字段隐藏显示 -->
      <TableBar :columns="columns" :nameId="nameId" @changeColumn="changeColumn">
      </TableBar>
    </div>
    <el-table class="opt-table" v-if="columnsLoading" ref="table" :data="tableData.rows" highlight-current-row
      style="width: 100%; overflow-x: auto">
      <el-table-column label="商户名称" prop="companyName" align="center" min-width="180" />
      <el-table-column label="商户号" prop="busiId" align="center" min-width="180" />
      <el-table-column label="申请编号" prop="id" align="center" min-width="180" />
      <el-table-column label="发票类型" v-if="columns[0].show" align="center" prop="invoiceType" min-width="150"
        show-overflow-tooltip>
        <template slot-scope="scope">
          {{ invoiceTypesMap[scope.row.invoiceType] }}
        </template>
      </el-table-column>
      <el-table-column label="开票类型" v-if="columns[1].show" align="center" prop="invoiceBody" min-width="150"
        show-overflow-tooltip />
      <el-table-column label="发票金额（元）" v-if="columns[2].show" align="center" min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.amt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="价税合计总额（元）" v-if="columns[3].show" align="center" min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.fpAmt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" align="center" min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开票日期" align="center" min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.fpDt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开票状态" v-if="columns[4].show" align="center" prop="chkRemark" min-width="180"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="statusSty"
            :style="{ color: chkStaCol[scope.row.lgyFpFlag], background: chkStaBg[scope.row.lgyFpFlag] }">
            <div v-if="scope.row.lgyFpFlag == 112" class="changeInfo" @click="failChange(scope.row.failRemark)">
              {{ chkStaObj[scope.row.lgyFpFlag] }}
            </div>
            <div v-else>
              {{ chkStaObj[scope.row.lgyFpFlag] }}
            </div>
          </span>
        </template>
      </el-table-column>

      <el-table-column label="发票抬头" v-if="columns[5].show" align="center" prop="invoiceName" min-width="180"
        show-overflow-tooltip />
      <el-table-column label="开票方" v-if="columns[6].show" align="center" prop="mainName" min-width="180"
        show-overflow-tooltip />
      <el-table-column label="纳税人识别号" v-if="columns[7].show" align="center" prop="taxNo" min-width="150"
        show-overflow-tooltip />
      <el-table-column label="服务费发票类型" v-if="columns[8].show" align="center" prop="invoiceTypeService" min-width="150"
        show-overflow-tooltip>
        <template slot-scope="scope">{{ invoiceTypeServiceObj[scope.row.invoiceTypeService] }}</template>
      </el-table-column>
      <el-table-column label="服务费发票类目" v-if="columns[9].show" align="center" prop="invoiceBody" min-width="150"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="地址" v-if="columns[10].show" align="center" prop="invoiceAddress" min-width="180"
        show-overflow-tooltip />
      <el-table-column label="电话" v-if="columns[11].show" align="center" prop="invoiceMobile" min-width="150"
        show-overflow-tooltip />
      <el-table-column label="开户行" v-if="columns[12].show" align="center" prop="openBank" min-width="180"
        show-overflow-tooltip />
      <el-table-column label="银行账号" v-if="columns[13].show" align="center" prop="bankNo" min-width="150"
        show-overflow-tooltip />
      <el-table-column label="收件人" v-if="columns[14].show" align="center" prop="receiver" min-width="150"
        show-overflow-tooltip />
      <el-table-column label="收件人电话" v-if="columns[15].show" align="center" prop="telephone" min-width="150"
        show-overflow-tooltip />
      <el-table-column label="收件人地址" v-if="columns[16].show" align="center" prop="address" min-width="150"
        show-overflow-tooltip />
      <el-table-column label="审核备注" v-if="columns[17].show" align="center" prop="failRemark" min-width="150"
        show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.failRemark ? scope.row.failRemark : '--' }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" min-width="150" class-name="white">
        <template slot-scope="{ row }">
          <div class="align_right px-1">
            <el-button type="info" size="mini" plain @click="handleItemDetails(row)">
              查看
            </el-button>
          </div>
        </template>
      </el-table-column>
      <template slot="empty">
        <img style="width:126px;height:auto;margin-top:40px;" src="../../assets/img/noData.png" alt="">
        <div>暂未查询到数据</div>
      </template>
    </el-table>
    <div class="wraped">
      <el-pagination layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="tableData.currentPage" :page-sizes="[10, 20, 30]"
        :page-size="tableData.pageSize" :total="tableData.total">
      </el-pagination>
    </div>

    <el-dialog width="80%" :visible.sync="dialogFormVisible" :close-on-click-modal="false" class="invoiceDialog">
      <el-tabs v-model="activeTab" slot="title" style="background-color: #fff; text-align: left">
        <el-tab-pane name="first">
          <span slot="label" style="font-size: 16px; font-weight: bold">申请信息</span>

          <h4 class="my-5 pa-1" style="border-left: solid 3px #409EFF">
            发票详情
          </h4>
          <el-row :gutter="20" class="px-5">
            <el-col :lg="6" :md="12">
              <div class="mb-4">申请编号: {{ tempForm.id }}</div>
            </el-col>
            <el-col :lg="6" :md="12">
              <div class="mb-4">发票金额(元): {{ tempForm.amt }}</div>
            </el-col>
            <el-col :lg="6" :md="12">
              <div class="mb-4">
                申请时间: {{ tempForm.createTime }}
              </div>
            </el-col>
            <el-col :lg="6" :md="12">
              <div class="mb-4">
                备注: {{ tempForm.remark }}
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="px-5">
            <el-col :lg="6" :md="12">
              <div class="mb-4">
                发票类型: {{ invoiceTypesMap[tempForm.invoiceType1] }}
              </div>
            </el-col>
            <el-col :lg="6" :md="12">
              <div class="mb-4">开票类目: {{ tempForm.invoiceBody }}</div>
            </el-col>
            <el-col :lg="6" :md="12">
              <div class="mb-4">发票介质: {{ tempForm.invoiceType == '0' ? specialMediumObj[tempForm.actualAmtMedium] :
                specialMediumObj[tempForm.specialMedium] }}</div>
            </el-col>
            <el-col :lg="6" :md="12">
              <div class="mb-4">发票金额: {{ tempForm.invoiceType == '1' ? tempForm.amt : tempForm.actualIncomeAmt }} 元</div>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="px-5" v-if="tempForm.invoiceType == '0'">
            <el-col :lg="6" :md="12">
              <div class="mb-4">
                服务费发票类型: {{ invoiceTypeServiceObj[tempForm.invoiceTypeService] }}
              </div>
            </el-col>
            <el-col :lg="6" :md="12">
              <div class="mb-4">
                服务费发票类目: {{ tempForm.invoiceBodyService }}
              </div>
            </el-col>
            <el-col :lg="6" :md="12">
              <div class="mb-4">
                服务费发票介质: {{ specialMediumObj[tempForm.serviceMedium] }}
              </div>
            </el-col>
            <el-col :lg="6" :md="12">
              <div class="mb-4">
                服务费金额: {{ tempForm.serviceAmt }} 元
              </div>
            </el-col>
          </el-row>

          <h4 class="my-5 pa-1" style="border-left: solid 3px #409EFF">
            发票抬头
          </h4>
          <el-row :gutter="20" class="px-5">
            <el-col :span="8">
              <div class="mb-4">抬头名称: {{ tempForm.invoiceName || "未知" }}</div>
            </el-col>
            <el-col :span="8">
              <div class="mb-4">
                公司税号: {{ tempForm.taxNo || "未知" }}
              </div>
            </el-col>
            <el-col :span="8">
              <div class="mb-4">
                注册地址: {{ tempForm.invoiceAddress || "未知" }}
              </div>
            </el-col>
            <el-col :span="8">
              <div class="mb-4">
                注册电话: {{ tempForm.invoiceMobile || "未知" }}
              </div>
            </el-col>
            <el-col :span="8">
              <div class="mb-4">开户银行: {{ tempForm.openBank || "未知" }}</div>
            </el-col>
            <el-col :span="8">
              <div class="mb-4">银行账号: {{ tempForm.bankNo || "未知" }}</div>
            </el-col>
          </el-row>

          <h4 class="my-5 pa-1" style="border-left: solid 3px #409EFF">
            邮寄信息
          </h4>
          <el-row :gutter="20" class="px-5">
            <el-col :span="8">
              <div class="mb-4">
                收件姓名: {{ tempForm.receiver || "未知" }}
              </div>
            </el-col>
            <el-col :span="8">
              <div class="mb-4">
                联系电话: {{ tempForm.telephone || "未知" }}
              </div>
            </el-col>
            <el-col :span="8">
              <div class="mb-4">
                电子邮箱: {{ tempForm.email || "未知" }}
              </div>
            </el-col>
            <el-col :span="24">
              <div class="mb-4">邮寄地址: {{ tempForm.address || "未知" }}</div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="third">
          <span slot="label" style="font-size: 16px; font-weight: bold">已开票据</span>
          <div style="padding-top:20px">
            <el-button type="primary" @click="onClickBill" size="mini" v-if="tempForm.invoicePhoto">点击下载票据</el-button>
          </div>
          <div v-if="!tempForm.invoicePhoto && !tempForm.invoicePdf" class="image-slot align_center">
            <i class="el-icon-picture-outline"></i>
            <span class="font_14px text-color_mute">未查询到凭证</span>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog title="修改申请" width="80%" :visible.sync="dialogTempFormVisible" :close-on-click-modal="false">
      <el-form ref="invoiceForm" labelWidth="140px" class="invoice-form" :model="tempForm" :hide-required-asterisk="true">
        <h4 class="pb-5">发票抬头信息</h4>
        <el-row :gutter="20" class="px-5">
          <el-col :lg="12" :md="24">
            <el-form-item label="商户名称:" prop="invoiceName" :rules="[
              { required: true, message: '请输入商户名称', trigger: 'blur' },
            ]">
              <el-input v-model="tempForm.invoiceName" placeholder="请输入" disabled />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="统一社会信用代码:" prop="taxNo" :rules="[
              {
                required: true,
                message: '请输入统一社会信用代码',
                trigger: 'blur',
              },
            ]">
              <el-input v-model="tempForm.taxNo" placeholder="请输入" disabled />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="地址:" prop="invoiceAddress" :rules="[
              { required: true, message: '请输入地址', trigger: 'blur' },
            ]">
              <el-input v-model="tempForm.invoiceAddress" placeholder="请输入" disabled />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="电话:" prop="invoiceMobile" :rules="[
              { required: true, message: '请输入电话', trigger: 'blur' },
            ]">
              <el-input v-model="tempForm.invoiceMobile" placeholder="请输入" disabled />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="开户行:" prop="openBank" :rules="[
              { required: true, message: '请输入开户行', trigger: 'blur' },
            ]">
              <el-input v-model="tempForm.openBank" placeholder="请输入" disabled />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="银行账号:" prop="bankNo" :rules="[
              { required: true, message: '请输入银行账号', trigger: 'blur' },
            ]">
              <el-input v-model="tempForm.bankNo" placeholder="请输入" disabled />
            </el-form-item>
          </el-col>
        </el-row>

        <h4 class="py-5">发票信息</h4>
        <el-row :gutter="20" class="px-5">
          <el-col :lg="12" :md="24">
            <el-form-item label="发票类型:" prop="invoiceType" :rules="[
              {
                required: true,
                message: '请选择发票类型',
                trigger: ['blur', 'change'],
              },
            ]">
              <el-select v-model="tempForm.invoiceType" class="w-fluid" style="width: 100%" placeholder="请选择"
                :loading="!invoiceTypes" clearable>
                <el-option v-for="(value, key) in invoiceTypes" :key="key" :label="value" :value="key" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="发票金额:" prop="invoiceAmt" class="form-item-amount" :rules="[
              { required: true, message: '请输入金额', trigger: 'blur' },
            ]">
              <el-input v-model.number="tempForm.invoiceAmt" disabled>
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="开票类目:" prop="adminInvoiceId" :rules="[
              {
                required: true,
                message: '请选择开票类目',
                trigger: ['blur', 'change'],
              },
            ]">
              <el-select v-model="tempForm.adminInvoiceId" class="w-fluid" style="width: 100%" placeholder="请选择"
                :loading="!invoiceCategories" clearable>
                <el-option v-for="(item, index) in invoiceCategories" :key="index" :label="item.invoiceBody"
                  :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <h4 class="py-5">交付信息</h4>
        <el-row :gutter="20" class="px-5">
          <el-col :lg="12" :md="24">
            <el-form-item label="收件人:" prop="receiver" :rules="[
              { required: true, message: '请输入收件人', trigger: 'blur' },
            ]">
              <el-input v-model="tempForm.receiver" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="联系电话:" prop="telephone" :rules="[
              { required: true, message: '请输入联系电话', trigger: 'blur' },
            ]">
              <el-input v-model="tempForm.telephone" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="邮寄地址:" prop="address" :rules="[
              { required: true, message: '请输入邮寄地址', trigger: 'blur' },
            ]">
              <el-input v-model="tempForm.address" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTempFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitForm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 审核原因 -->
    <el-dialog title="审核原因" :visible.sync="dialogVisibleFail" width="600px" :before-close="handleCloseFail">
      <span>{{ contentChange }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleFail = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/api.js';
import moment from 'moment';
import { get, post } from '@/api/index.js';
import { invoiceTypeFilter } from '@/filters/textTransform.js';
import { f2y, y2f } from '@/util/util.js';
import TableBar from '@/components/Table/TableBar'
export default {
  filters: {
    invoiceTypeFilter,

  },
  components: {
    TableBar
  },
  data() {
    return {
      invoicedCount: 0,
      notInvoicedCount: 0,
      tableDataVote: [],
      tableDataVoteLoading: false,
      listQuery: {
        current: 1,
        size: 10
      },
      invoiceMonth: moment().format('YYYY-MM'),
      tableTotal: 0,
      dialogVisibleVote: false,
      // dateRangeVote: [],
      tableData: {
        currentPage: 1,
        pageSize: 10,
        rows: []
      },
      // 审核原因
      dialogVisibleFail: false,
      contentChange: '',
      // 切换数据
      tabPosition: 'left',
      // 字段隐藏展示
      nameId: 'INVOICE',
      columnsOld: {
        fieldVoList: [
          { name: '发票类型', show: true },
          { name: '开票类型', show: true },
          { name: '发票金额', show: true },
          { name: '价税合计总额（元）', show: false },
          { name: '开票审核状态', show: true },
          { name: '发票抬头', show: false },
          { name: '开票方', show: false },
          { name: '纳税人识别号', show: false },
          { name: '服务费发票类型', show: true },
          { name: '服务费发票类目', show: false },
          { name: '地址', show: false },
          { name: '电话', show: false },
          { name: '开户行', show: false },
          { name: '银行账号', show: false },
          { name: '收件人', show: false },
          { name: '收件人电话', show: false },
          { name: '收件人地址', show: false },
          { name: '审核备注', show: false }
        ]
      },
      columnsLoading: false,
      columns: [],
      value7: '',
      activeTab: 'first',
      dialogFormVisible: false,
      dialogTempFormVisible: false,
      invoiceTypes: undefined,
      invoiceCategories: undefined,
      pickerOptionsMonth: {
        disabledDate(time) {
          var year = new Date().getFullYear();
          return (
            time.getTime() - 3700 * 1000 * 24 > Date.now() ||
            time.getTime() < new Date(year - 2 + '-' + 12 + '-' + 31)
          );
        }
      },
      // pickerOptions: {
      //   shortcuts: [
      //     {
      //       text: '最近一周',
      //       onClick(picker) {
      //         const end = new Date(moment().endOf('day'));
      //         const start = new Date(moment().startOf('day'));
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
      //         picker.$emit('pick', [start, end]);
      //       }
      //     },
      //     {
      //       text: '最近一个月',
      //       onClick(picker) {
      //         const end = new Date(moment().endOf('day'));
      //         const start = new Date(moment().startOf('day'));
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      //         picker.$emit('pick', [start, end]);
      //       }
      //     }
      //   ]
      // },
      tempForm: {
        amt: '',
        fpAmt: '',
        invoiceAmt: '',
        invoiceBody: '',
        invoicePdf: '',
        adminInvoiceId: ''
      },
      dateRange: [
        moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      ],
      queryPickerOptions: {
        // disabledDate(time) {
        //   return (
        //     time.getTime() >
        //     new Date(moment().endOf('year').format('YYYY-MM-DD HH:mm:ss')) ||
        //     time.getTime() <
        //     new Date(moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'))
        //   );
        // }
      },
      queryPickerOptions1: {
        // disabledDate(time) {
        //   var year = new Date().getFullYear() - 1;
        //   return time.getTime() > new Date(year + '-12-31 23:59:59');
        // }
      },
      queryForm: {
        isHis: 0,
        chkSta: '',
        status: '',
        fpFlag: '',
        createTimeEnd: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        createTimeStart: moment()
          .subtract(6, 'days')
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
      },
      itemTableData: {
        reserve: '0',
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        multipleSelection: [],
        addIndex: 1,
        onLoadingItemTable: false
      },
      tableDataBill: [],
      onLoadingItemBill: false,
      invoiceTypesMap: {
        0: '增值税普通发票',
        1: '增值税专用发票',
        2: '增值税电子普通发票',
        3: '增值税电子专用发票'
      },
      totalBill: 0,
      invoiceTypeServiceObj: {
        0: '增值税普通发票',
        1: '增值税专用发票',
        2: '增值税电子普通发票',
        3: '增值税电子专用发票'
      },
      chkStaObj: {
        100: '未开票',
        101: '已开票',
        110: '开票中',
        112: '开票失败',
        109: '已作废',
        199: '--'
      },
      chkStaCol: {
        100: '#409EFF',
        101: '#67c23a',
        110: '#E6A23C',
        112: '#F56C6C',
        109: '#E6A23C'
      },
      chkStaBg: {
        100: '#ECF5FF',
        101: '#f0f9eb',
        110: '#FDF6EC',
        112: '#FEF0F0',
        109: '#FDF6EC'
      },
      specialMediumObj: {
        0: '电子',
        1: '纸质'
      },
      statusObj: {
        100: '已申请',
        110: '审核失败',
        101: '开票',
        109: '已作废'
      },
      statusCol: {
        100: '#67c23a',
        110: '#F56C6C',
        101: '#409EFF',
        109: '#E6A23C'
      },
      statusBg: {
        100: '#f0f9eb',
        110: '#FEF0F0',
        101: '#ECF5FF',
        109: '#FDF6EC'
      }
    };
  },
  mounted() {
    this.hideField();
    // this.dateRangeVote = [
    //   moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
    //   moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
    // ];
    this.statistics();
    this.getTable(1);
  },
  methods: {
    handleCloseFail() {
      this.dialogVisibleFail = false;
    },
    // 发票统计
    voteClick(invoiceFlag) {
      this.listQuery.current = 1;
      this.listQuery.size = 10;
      this.listQuery.invoiceFlag = invoiceFlag;
      this.listQuery.invoiceMonth=this.invoiceMonth,
      this.statisticsList();
      this.dialogVisibleVote = true;
    },
    // 未开票/已开票
    statisticsList() {
      this.tableDataVoteLoading = true;
      get(api.statisticsList, this.listQuery).then((res) => {
        if (res.code == 0) {
          this.tableDataVote = res.data.records;
          this.tableDataVote.map((item) => {
            item.deductAmtTotal = f2y(item.deductAmtTotal)
          })
          this.tableTotal = res.data.total;
          this.tableDataVoteLoading = false;
        } else {
          this.$notify.error({
            title: '错误',
            message: res.data.msg
          });
          this.tableDataVoteLoading = false;
        }
      })
    },
    // 发票统计
    dateRangeChangeVote() {
      // if (new Date(this.dateRangeVote[1]) - new Date(this.dateRangeVote[0]) > 31 * 24 * 3600 * 1000) {
      //   return this.$message.error("时间选择范围不能超过1个月");
      // }
      this.statistics();
    },
    statistics() {
      // var par = { startTime: "", endTime: "" };
      // if (this.dateRangeVote && this.dateRangeVote.length === 2) {
      //   par.startTime = this.dateRangeVote[0];
      //   par.endTime = this.dateRangeVote[1];
      // }
      // this.listQuery.startTime = par.startTime;
      // this.listQuery.endTime = par.endTime;
      get(api.statistics, { invoiceMonth: this.invoiceMonth }).then((res) => {
        if (res.code == 0) {
          this.invoicedCount = res.data.invoicedCount;
          this.notInvoicedCount = res.data.notInvoicedCount;
        } else {
          return this.$message.error(res.message);
        }
      })
    },
    handleCloseVote() {
      this.dialogVisibleVote = false;
    },
    // 审核原因
    failChange(content) {
      this.contentChange = content;
      this.dialogVisibleFail = true;
    },
    // 切换数据
    tabChange() {
      //今年数据
      if (this.tabPosition == 'left') {
        this.dateRange = [
          moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
        ];
        if (this.dateRange && this.dateRange.length === 2) {
          this.queryForm.createTimeStart = this.dateRange[0];
          this.queryForm.createTimeEnd = this.dateRange[1];
        }
        this.tableData.currentPage = 1;
        this.queryForm.isHis = 0;
        this.getTable();
      } else if (this.tabPosition == 'right') {
        //历史数据
        var year = new Date().getFullYear() - 1;
        this.dateRange = [
          moment(year + '-12-27 00:00:00').format('YYYY-MM-DD HH:mm:ss'),
          moment(year + '-12-31 23:59:59').format('YYYY-MM-DD HH:mm:ss')
        ];
        if (this.dateRange && this.dateRange.length === 2) {
          this.queryForm.createTimeStart = this.dateRange[0];
          this.queryForm.createTimeEnd = this.dateRange[1];
        }
        this.tableData.currentPage = 1;
        this.queryForm.isHis = 1; //标识是否是历史数据
        this.getTable();
      }
    },
    // 分页--点击某页码
    handleCurrentChange(val) {
      this.tableData.currentPage = val;
      this.getTable();
    },
    // 分页--更改每页显示条数
    handleSizeChange(val) {
      this.tableData.pageSize = val;
      this.getTable();
    },
    // 分页--点击某页码发票统计
    handleCurrentChangeTotal(val) {
      this.listQuery.current = val;
      this.statisticsList();
    },
    // 分页--更改每页显示条数发票统计
    handleSizeChangeTotal(val) {
      this.listQuery.size = val;
      this.statisticsList();
    },
    onClickBill() {
      window.open(this.tempForm.invoicePhoto);
    },
    // 初始化隐藏字段
    forList(name, list) {
      var boolTure = '';
      var listNew = [];
      list.map(value => {
        if (!!value) {
          listNew.push(value);
        }
      });
      for (var i = 0; i < listNew.length; i++) {
        if (name === listNew[i].name) {
          boolTure = listNew[i];
        }
      }
      return boolTure;
    },
    hideField() {
      post(api.getHeader, { name: 'INVOICE' }).then(resNew => {
        if (null != resNew.data.fieldVoList && undefined != resNew.data.fieldVoList) {
          var lisDateOld = this.columnsOld.fieldVoList; //页面
          var resDates = resNew.data.fieldVoList; //后台
          for (var i = 0; i < lisDateOld.length; i++) {
            var data = this.forList(lisDateOld[i].name, resDates);
            if ('' != data) {
              this.columns.push(data);
            } else {
              this.columns.push(lisDateOld[i]);
            }
          }
          this.columnsLoading = true;
        } else {
          post(api.editHeader, {
            fieldVoList: this.columnsOld.fieldVoList,
            name: 'INVOICE'
          }).then(res => {
            if (res.code == 0) {
              post(api.getHeader, { name: 'INVOICE' }).then(resNew => {
                if (resNew.code == 0) {
                  this.columns = resNew.data.fieldVoList;
                  this.columnsLoading = true;
                } else {
                  this.$message.error(resNew.message);
                }
              });
            }
          });
        }
      });
    },
    //  字段隐藏显示
    changeColumn(columns) {
      this.columns = columns;
      this.$refs.table.doLayout();
    },
    fetchTime(dateRange) {
      if (!dateRange) {
        this.dateRange = [];
      }
      this.getTable();
    },
    onSearch() {
      //今年数据
      if (this.tabPosition == 'left') {
        if (this.dateRange && this.dateRange.length === 2) {
          this.queryForm.createTimeStart = this.dateRange[0];
          this.queryForm.createTimeEnd = this.dateRange[1];
        }
        this.tableData.currentPage = 1;
        this.queryForm.isHis = 0;
      } else if (this.tabPosition == 'right') {
        //历史数据
        if (this.dateRange && this.dateRange.length === 2) {
          this.queryForm.createTimeStart = this.dateRange[0];
          this.queryForm.createTimeEnd = this.dateRange[1];
        }
        this.tableData.currentPage = 1;
        this.queryForm.isHis = 1; //标识是否是历史数据
      }
      this.getTable();
    },
    onReset() {
      this.queryForm = {
        chkSta: '',
        status: '',
        createTimeStart: '',
        createTimeEnd: ''
      };
      //今年数据
      if (this.tabPosition == 'left') {
        this.dateRange = [
          moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
        ];
        if (this.dateRange && this.dateRange.length === 2) {
          this.queryForm.createTimeStart = this.dateRange[0];
          this.queryForm.createTimeEnd = this.dateRange[1];
        }
        this.tableData.currentPage = 1;
        this.queryForm.isHis = 0;
      } else if (this.tabPosition == 'right') {
        //历史数据
        var year = new Date().getFullYear() - 1;
        this.dateRange = [
          moment(year + '-12-27 00:00:00').format('YYYY-MM-DD HH:mm:ss'),
          moment(year + '-12-31 23:59:59').format('YYYY-MM-DD HH:mm:ss')
        ];
        if (this.dateRange && this.dateRange.length === 2) {
          this.queryForm.createTimeStart = this.dateRange[0];
          this.queryForm.createTimeEnd = this.dateRange[1];
        }
        this.tableData.currentPage = 1;
        this.queryForm.isHis = 1; //标识是否是历史数据
      }
      this.itemTableData.pageSize = 10;
      this.itemTableData.currentPage = 1;

      this.getTable();
    },
    handleItemTableSizeChange(val) {
      this.itemTableData.pageSize = val;
      this.getItemTable();
    },
    handleItemTableCurrentChange(val) {
      // 未保留选择
      if (this.itemTableData.reserve === '0') {
        this.itemTableData.multipleSelection = [];
      }
      this.itemTableData.currentPage = val;
      this.itemTableData.addIndex =
        (this.itemTableData.currentPage - 1) * this.itemTableData.pageSize + 1;
      this.getItemTable();
    },
    handleItemUpdate(row) {
      this.tempForm = row;
      this.tempForm.amt = f2y(this.tempForm.amt);
      this.tempForm.fpAmt = f2y(this.tempForm.fpAmt);
      this.tempForm.invoiceAmt = Util.parse2Show(row.amt || 0);
      if (this.tempForm.invoiceBody) {
        this.tempForm.adminInvoiceId =
          this.invoiceCategories &&
          this.invoiceCategories.find(
            item => item.invoiceBody === this.tempForm.invoiceBody
          ).invoiceBody;
      }
      this.dialogTempFormVisible = true;
    },
    handleItemDetails(row) {
      this.getInvoiceDetails(row);
      this.activeTab = 'first';
      this.tempForm = JSON.parse(JSON.stringify(row));
      this.tempForm.actualIncomeAmt = f2y(this.tempForm.actualIncomeAmt);
      this.tempForm.serviceAmt = f2y(this.tempForm.serviceAmt);
      this.tempForm.invoiceType1 = this.tempForm.invoiceType;
      if (row.invoicePhoto) {
        if (/\.(jpeg|jpg|gif|png)$/.test(row.invoicePhoto)) {
          this.tempForm.invoicePhoto = `${row.invoicePhoto}?${new Date().getTime()}`;
        } else if (/\.pdf$/.test(row.invoicePhoto)) {
          this.tempForm.invoicePdf = row.invoicePhoto;
        }
      }
    },
    handleSubmitForm() {
      this.$refs.invoiceForm.validate(valid => {
        if (valid) {
          post(api.invoiceApplyUpdate, this.tempForm).then(res => {
            if (res.success) {
              this.$message({
                showClose: true,
                message: '修改成功',
                type: 'success'
              });
              this.getTable();
              this.dialogTempFormVisible = false;
            }
          });
        }
      });
    },
    getInvoiceDetails(row) {
      if (this.tabPosition == 'left') {
        var isHis = 0;
      } else {
        var isHis = 1;
      }
      post(api.invoiceDetail, { isHis: isHis, busiId: row.busiId, id: row.id }).then(res => {
        console.log(res, 88)
        const data = res.data;
        if (!data) {
          return this.$message.error('获取发票抬头信息失败!');
        }
        if (typeof data !== 'object') {
          return this.$message.error('发票抬头数据格式错误,服务器未正确返回');
        }
        for (const key of Object.keys(data)) {
          if (key == 'invoiceType') this.tempForm[key] = data[key];
        }
        if (!data.allowInvoiceTypes) {
          this.invoiceTypes = [];
          this.tempForm.invoiceType = '';
          return this.$message.error('无开票类型可供选择,请联系管理员');
        }
        this.invoiceTypes = data.allowInvoiceTypes.split(',').reduce((acc, cur) => {
          acc[cur] = this.invoiceTypesMap[cur] || '未知类型';
          return acc;
        }, {});
        this.dialogFormVisible = true;
        return 'getInvoiceDetails success';
      });
    },
    getItemTable(val) {
      if (val) {
        this.itemTableData.currentPage = 1;
      }
      this.itemTableData.onLoadingItemTable = true;
      post(api.invoiceApplyDetail, {
        id: this.tempForm.id,
        currentPage: this.itemTableData.currentPage,
        pageSize: this.itemTableData.pageSize
      })
        .then(res => {
          if (res.success) {
            this.itemTableData.rows = res.data.rows;
            this.itemTableData.total = res.data.count;
            this.itemTableData.rows.map(item => {
              item.deductAmt = f2y(item.deductAmt);
            });
          }
        })
        .finally(() => {
          this.itemTableData.onLoadingItemTable = false;
        });
    },
    getTable() {
      const loading = this.$loading({
        lock: true,
        spinner: 'loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (this.tabPosition == 'left') {
        if (this.dateRange && this.dateRange.length === 2) {
          this.queryForm.createTimeStart = this.dateRange[0];
          this.queryForm.createTimeEnd = this.dateRange[1];
        }
        this.queryForm.isHis = 0;
      } else if (this.tabPosition == 'right') {
        //历史数据
        if (this.dateRange && this.dateRange.length === 2) {
          this.queryForm.createTimeStart = this.dateRange[0];
          this.queryForm.createTimeEnd = this.dateRange[1];
        }
        this.queryForm.isHis = 1; //标识是否是历史数据
      }
      get(
        api.invoiceList,
        Object.assign(
          {
            current: this.tableData.currentPage,
            size: this.tableData.pageSize
          },
          this.queryForm
        )
      ).then(res => {
        loading.close();
        if (res.success) {
          this.tableData.rows = res.data.records;
          this.tableData.total = res.data.total;
          this.tableData.rows.map(item => {
            item.amt = f2y(item.amt);
            item.fpAmt = f2y(item.fpAmt);
          });
          console.log(this.tableData.rows, '列表')
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.query-part {
  padding-top: 10px;
  box-sizing: border-box;
}

.main_button {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.invoiceRequests {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  text-align: left;
  padding: 20px 10px;
}

.mb-4 {
  line-height: 35px;
  font-size: 14px;
}

.my-5 {
  padding-left: 5px;
}

.align_center {
  width: 100%;
  text-align: center;
  padding-top: 24px;
  font-size: 14px;
}

.opt-table .el-button {
  width: auto;
  height: auto;
  padding: 7px 15px;
}

.invoiceDialog .el-dialog__header {
  background-color: #fff;
}

.invoiceDialog .el-tabs__item.is-active,
.invoiceDialog .el-tabs__item:hover {
  color: #409eff !important;
}

.invoiceDialog .el-tabs__active-bar {
  height: 3px;
  background-color: #409eff !important;
}

.invoiceDialog .el-table th {
  background-color: #666;
  color: #909399;
}

.el-pagination {
  padding: 20px 0;
}

.wraped {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.statusSty {
  width: 80px;
  height: 30px;
  padding: 2px;
  line-height: 28px;
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
}

.changeInfo {
  cursor: pointer;
  text-decoration: underline;
}

.btn-rowNew {
  cursor: pointer;
  display: flex;

  .point {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: white;
  }
}
</style>
