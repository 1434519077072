import {
    TOGGLE_DEVICE,
    TOGGLE_SIDEBAR,
    CLOSE_SIDEBAR,
    OPEN_SIDEBAR,
    ORAGANIZATIONID,
    ORGANIZATIONAME,
    ORGANIZATIONTYPE,
    ROLE,
    ROUTERSTATUS,
    MOBILE,
} from '../../mutation-types';

export default {
    [TOGGLE_DEVICE](state, device) {
        state.device = device;
    },
    [ORAGANIZATIONID](state, data) {
        state.organizationId = data;
    },
    [ORGANIZATIONAME](state, data) {
        state.organizationName = data;
    },
    [ORGANIZATIONTYPE](state, data) {
        state.organizationType = data;
    },
    [ROLE](state, data) {
        state.role = data;
    },
    [ROUTERSTATUS](state, data) {
        state.routerStatus = data;
    },
    [MOBILE](state, data) {
        state.mobile = data;
    },
    [TOGGLE_SIDEBAR](state) {
        if (state.sidebar.opened) {
            localStorage.setItem('sidebarStatus', 1);
        } else {
            localStorage.setItem('sidebarStatus', 0);
        }
        state.sidebar.opened = !state.sidebar.opened;
        state.sidebar.withoutAnimation = false;
    },
    [CLOSE_SIDEBAR](state, withoutAnimation) {
        localStorage.setItem('sidebarStatus', 1);
        state.sidebar.opened = false;
        state.sidebar.withoutAnimation = withoutAnimation;
    },
    [OPEN_SIDEBAR](state, withoutAnimation) {
        localStorage.setItem('sidebarStatus', 0);
        state.sidebar.opened = true;
        state.sidebar.withoutAnimation = withoutAnimation;
    },
};
