<style lang="less" scoped>
.profitDetail {
    width: 100%;
    min-height: 100%;
    /* // padding: 12px; */
    box-sizing: border-box;
    background-color: #fff;
    .main_button {
        margin-left: 36px;
    }
    .box {
        width: 100%;
        padding-top: 12px;
        .main_button {
            margin-left: 36px;
        }
        .baowei {
            width: 100%;
            .chongzhi {
                margin-left: 40px;
            }
            @media (max-width: 1400px) {
                .chongzhi {
                    margin-left: 26px;
                }
            }
            @media (max-width: 1300px) {
                .chongzhi {
                    margin-left: 26px;
                }
            }
            @media (max-width: 1270px) {
                .chongzhi {
                    margin-left: 17px;
                }
            }
        }

        .wrap {
            width: 100%;
            margin-top: 22px;
            /* // padding: 0px 30px; */
            padding: 12px;
            box-sizing: border-box;
            .wraped {
                width: 100%;
            }
        }
    }
}
</style>
<style>
.repairOrder .el-table__fixed-right {
    right: 0 !important;
}
.repairOrder .el-.el-upload-list__item-name-right {
    width: 400px !important;
}
</style>


<template>
    <div class="profitDetail">
        <div class="box">
            <el-form ref="releaseForm"
                     :model="releaseForm"
                     label-width="120px">
                <el-row>
                    <el-col :lg="18"
                            :md="20">
                        <el-row>
                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="分润方名称：">
                                    <el-input v-model="releaseForm.profitName"
                                              size="small"
                                              placeholder="请输入分润方名称"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="服务商名称：">
                                    <el-select v-model="releaseForm.serviceId"
                                               filterable
                                               placeholder="请输入服务商名称"
                                               :loading="loading"
                                               style="width: 100%"
                                               size="small">
                                        <el-option v-for="item in serviceList"
                                                   :key="item.serviceId"
                                                   :label="item.serviceName"
                                                   :value="item.serviceId">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="日期："
                                              prop="day">
                                    <el-date-picker v-model="releaseForm.dayInfo"
                                                    type="month"
                                                    style="width:100%"
                                                    placeholder="选择日期"
                                                    format="yyyy 年 MM 月"
                                                    value-format="yyyy-MM"
                                                    size="small">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :md="6"
                            :sm="8">
                        <el-form-item label=" ">
                            <el-button type="primary"
                                       size="small"
                                       icon="el-icon-search"
                                       @click="search()">查询</el-button>
                            <el-button type="primary"
                                       size="small"
                                       icon="el-icon-arrow-up"
                                       :loading="exportInfoLoading"
                                       @click="exportInfo()">导出</el-button>
                            <el-button size="small"
                                       icon='el-icon-refresh-left'
                                       class="chongzhi"
                                       @click="reset()">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div class="wrap">
                <el-table :data="tableData"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                          fit
                          border
                          :render-header="renderHeader"
                          style="width: 100%">
                    <af-table-column fixed
                                     prop="serviceName"
                                     show-overflow-tooltip
                                     label="服务商名称"
                                     width="180px">
                    </af-table-column>
                    <af-table-column prop="agentName"
                                     show-overflow-tooltip
                                     label="渠道商名称"
                                     width="180px">
                    </af-table-column>
                    <af-table-column prop="productName"
                                     show-overflow-tooltip
                                     label="产品名称"
                                     width="180px">
                    </af-table-column>
                    <af-table-column prop="agentFee"
                                     label="渠道商费率(%)"
                                     show-overflow-tooltip
                                     width="180px">
                        <template slot-scope="scope">
                            <span>{{ scope.row.agentFee }}</span>
                        </template>
                    </af-table-column>
                    <af-table-column prop="day"
                                     label="分润日期"
                                     show-overflow-tooltip
                                     width="180px"></af-table-column>
                    <af-table-column prop="profitName"
                                     show-overflow-tooltip
                                     label="分润方名称"
                                     width="180px">
                    </af-table-column>
                    <af-table-column prop="profitType"
                                     show-overflow-tooltip
                                     label="分润类型"
                                     width="180px">
                        <template slot-scope="scope">
                            <span>{{
                scope.row.profitType == "agent" ? "渠道商分润" : "商户分润"
              }}</span>
                        </template>
                    </af-table-column>
                    <af-table-column prop="transAmt"
                                     show-overflow-tooltip
                                     label="应发金额（原交易金额）"
                                     width="180px">
                        <template slot-scope="scope">
                            <span>{{ scope.row.transAmt }}元</span>
                        </template>
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="transNum"
                                     label="交易笔数">
                        <template slot-scope="scope">
                            <span>{{ scope.row.transNum }}笔</span>
                        </template>
                    </af-table-column>
                    <af-table-column prop="profitAmt"
                                     label="服务费金额"
                                     show-overflow-tooltip
                                     width="180px">
                        <template slot-scope="scope">
                            <span>{{ scope.row.totalFeeAmt }}元</span>
                        </template>
                    </af-table-column>
                    <af-table-column prop="profitAmt"
                                     label="分润金额"
                                     show-overflow-tooltip
                                     width="180px">
                        <template slot-scope="scope">
                            <span>{{ scope.row.profitAmt }}元</span>
                        </template>
                    </af-table-column>
                    <af-table-column prop="createTime"
                                     fixed="right"
                                     label="创建时间"
                                     width="180px"
                                     show-overflow-tooltip>
                    </af-table-column>
                </el-table>
                <div class="wraped">
                    <pagination :total="total"
                                @handleSizeChange="handleSizeChange"
                                @handleCurrentChange="handleCurrentChange"></pagination>
                </div>
            </div>
        </div>
        <!-- 上传交付物 -->
        <el-dialog title="上传交付物"
                   :visible.sync="dialogVisibleJiao"
                   width="600px"
                   :before-close="handleCloseJiao">
            <el-form :model="jiaoForm"
                     :rules="jiaoRules"
                     ref="jiaoForm"
                     label-width="150px"
                     class="demo-ruleForm">
                <el-form-item label="交付物"
                              prop="certificateUrl">
                    <el-upload :action="uploadUrl"
                               :headers="myHeaders"
                               :on-success="handleAvatarSuccess"
                               :before-upload="beforeUpload"
                               multiple
                               :limit="1"
                               :file-list="file"
                               :on-remove="handleRemove"
                               :before-remove="beforeRemove">
                        <el-button> <i class="el-icon-download"></i>上传文件 </el-button>
                        <div slot="tip"
                             class="el-upload__tip">
                            支持扩展名：.rar .zip .doc .docx .pdf .jpg...
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="内容："
                              prop="workloadContent">
                    <el-input type="textarea"
                              style="width: 300px"
                              v-model="jiaoForm.workloadContent"
                              placeholder="请输入内容"
                              class="baoweied"
                              size="small"></el-input>
                </el-form-item>
                <!--  :picker-options="pickerOptions0" -->
                <!-- <el-form-item label="交付时间：" prop="workloadBatch">
          <el-date-picker
            v-model="jiaoForm.workloadBatch"
             type="month"
            style="width: 318px"
            placeholder="选择日期时间"
            format="yyyy 年 MM 月"
            value-format="yyyyMM"
             
          >
          </el-date-picker>
        </el-form-item> -->
                <el-form-item>
                    <el-button type="primary"
                               :loading="shenheLoadinged"
                               @click="submitFormJiao('jiaoForm')">确定</el-button>
                    <el-button @click="resetFormJiao('jiaoForm')">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import pagination from '../../components/pagination.vue';
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
import { f2y, y2f } from '../../util/util.js';
export default {
    data() {
        return {
            // 导出
            exportInfoLoading: false,
            serviceList: [],
            // 服务商模糊搜索
            qiyeRenOptions: [],
            statesRen: [],
            loading: false,
            jiaoRules: {
                certificateUrl: [{ required: true, message: '请上传文件', trigger: 'blur' }]
                // workloadBatch: [
                //   { required: true, message: "请选择时间", trigger: "blur" },
                // ],
            },
            dialogVisibleJiao: false,
            jiaoForm: {
                platformTaskApplyId: '',
                workloadBatch: '',
                urls: [],
                // certificateUrl: "",
                workloadContent: ''
            },
            total: null,
            pageSzie: 10,
            releaseForm: {
                profitName: '',
                dayInfo: '',
                serviceId: ''
            },
            shenheLoadinged: false,
            file: [],
            uploadUrl: api.uploadOss,
            myHeaders: { Authorization: this.$cookies.get('esignplatformsid') },
            options: [
                {
                    value: '1',
                    label: '未确认'
                },
                {
                    value: '2',
                    label: '拒绝确认'
                },
                {
                    value: '3',
                    label: '待结算'
                },
                {
                    value: '4',
                    label: '已关闭'
                },
                {
                    value: '5',
                    label: '已结算'
                },
                {
                    value: '6',
                    label: '不可结算'
                },
                {
                    value: '7',
                    label: '待打款'
                }
            ],
            tableData: []
        };
    },
    mounted() {
        this.serviceInfo(); //服务商
    },
    created() {
        this.defaultDate();
    },
    methods: {
        // 导出
        exportInfo() {
            this.exportInfoLoading = true;
            const link = document.createElement('a');
            this.axios({
                url: api.agentExportList,
                method: 'POST',
                responseType: 'arraybuffer', //arraybuffer
                headers: { Authorization: this.$cookies.get('esignmerchantsid') },
                params: {
                    currentPage: 1,
                    pageSize: this.pageSzie,
                    profitName: this.releaseForm.profitName,
                    day: this.releaseForm.dayInfo,
                    serviceId: this.releaseForm.serviceId
                }
            }).then(res => {
                if (!res) return;
                try {
                    let enc = new TextDecoder('utf-8');
                    res = JSON.parse(enc.decode(new Uint8Array(res.data))); //转化成json对象
                    // console.log(res, 333);
                    if (res.status == 'true') {
                        this.$message.success(res.message);
                        this.exportInfoLoading = false;
                    } else {
                        this.$message.error(res.message);
                        this.exportInfoLoading = false;
                    }
                } catch (err) {
                    // 创建Blob对象，设置文件类型
                    let blob = new Blob([res.data], {
                        type: 'application/vnd.ms-excel'
                    });
                    let objectUrl = URL.createObjectURL(blob); // 创建URL
                    link.href = objectUrl;
                    link.download = '渠道分润明细'; // 自定义文件名
                    link.click(); // 下载文件
                    URL.revokeObjectURL(objectUrl); // 释放内存
                    this.exportInfoLoading = false;
                }
            });
        },
        serviceInfo() {
            post(api.queryServiceList).then(res => {
                if (res.code == 0) {
                    this.releaseForm.serviceId = res.data[0].serviceId;
                    this.serviceList = res.data;
                    this.queryPageData();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //设置默认日期
        defaultDate() {
            //获取新的时间(2019.4.12）
            let date = new Date();
            //获取当前时间的年份转为字符串
            let year = date.getFullYear().toString(); //'2019'
            //获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接'0'
            let month =
                date.getMonth() + 1 < 10
                    ? '0' + (date.getMonth() + 1).toString()
                    : (date.getMonth() + 1).toString(); //'04'
            //获取天，判断是否小于10，如果是在字符串前面拼接'0'
            let da =
                date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString(); //'12'
            //字符串拼接，开始时间，结束时间
            let end = year + '-' + month + '-' + da; //当天'2019-04-12'
            let beg = year + '-' + month; //当月第一天'2019-04-01'
            this.releaseForm.dayInfo = beg; //将值设置给插件绑定的数据
        },
        // 表格
        renderHeader(h, data) {
            return h('span', [
                h('el-tooltip'),
                {
                    attrs: {
                        class: 'item',
                        effect: 'dark',
                        content: data.column.label
                    }
                },
                [h('span', data.column.label)]
            ]);
        },
        //上传文件
        submitFormed(formName) {
            this.shenheLoadinged = true;
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (this.typeShow) {
                        post(api.certificateUpload, this.taskFormed).then(
                            d => {
                                this.shenheLoadinged = false;
                                if (d.code === 0) {
                                    this.$message.success('上传成功');
                                    this.$refs[formName].resetFields();
                                    this.dialogVisibleed = false;
                                    this.queryPageData(this.currentPage);
                                } else {
                                    this.$message.error(d.message);
                                }
                            },
                            err => {
                                //error callback
                                this.shenheLoadinged = false;
                            }
                        );
                    } else {
                        this.taskFormed.urls.push(this.taskFormed.certificateUrl);
                        post(api.uploadBookingForm, this.taskFormed).then(
                            d => {
                                this.shenheLoadinged = false;
                                if (d.code === 0) {
                                    this.$message.success(d.message);
                                    this.$refs[formName].resetFields();
                                    this.dialogVisibleed = false;
                                    this.queryPageData(this.currentPage);
                                } else {
                                    this.$message.error(d.message);
                                }
                            },
                            err => {
                                //error callback
                                this.shenheLoadinged = false;
                            }
                        );
                    }
                } else {
                    // console.log('error submit!!');
                    this.shenheLoadinged = false;
                    return false;
                }
            });
        },
        submitFormJiao(formName) {
            this.shenheLoadingJiao = true;
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.jiaoForm.urls.push(this.jiaoForm.certificateUrl);
                    post(api.deliveryNote, this.jiaoForm).then(
                        d => {
                            this.shenheLoadingJiao = false;
                            if (d.code === 0) {
                                this.$message.success(d.message);
                                this.$refs[formName].resetFields();
                                this.dialogVisibleJiao = false;
                                this.queryPageData(this.currentPage);
                            } else {
                                this.$message.error(d.message);
                            }
                        },
                        err => {
                            //error callback
                            this.shenheLoadingJiao = false;
                        }
                    );
                } else {
                    // console.log('error submit!!');
                    this.shenheLoadingJiao = false;
                    return false;
                }
            });
        },
        resetFormJiao(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisibleJiao = false;
        },
        handleCloseJiao() {
            this.dialogVisibleJiao = false;
        },
        // 上传交付物
        updateRowed(row) {
            this.jiaoForm.platformTaskApplyId = row.id;
            this.jiaoForm.certificateUrl = '';
            this.jiaoForm.workloadBatch = '';
            this.jiaoForm.workloadContent = '';
            this.jiaoForm.urls = [];
            this.file = [];
            this.dialogVisibleJiao = true;
        },
        resetFormed(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisibleed = false;
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        //删除
        handleRemove(file, fileList) {
            // console.log(file, "4444444");
            this.taskFormed.certificateUrl = '';
            this.jiaoForm.certificateUrl = '';
        },
        beforeUpload(file) {},
        handleAvatarSuccess(res, file, files, key, val) {
            // console.log(res.data, "44444");
            if (res.code == 1) {
                this.$message.error(res.message);
                return;
            }
            this.file = files;
            this.jiaoForm.certificateUrl = res.data;
            this.taskFormed.certificateUrl = res.data;
        },
        handleCloseed() {
            this.dialogVisibleed = false;
        },
        queryPageData(size) {
            if (true) {
                // 数字
                const loading = this.$loading({
                    lock: true,
                    spinner: 'loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                post(api.profitDetailMonth, {
                    currentPage: size || 1,
                    pageSize: this.pageSzie,
                    profitName: this.releaseForm.profitName,
                    day: this.releaseForm.dayInfo,
                    serviceId: this.releaseForm.serviceId
                }).then(
                    d => {
                        loading.close();
                        if (d.code === 0) {
                            // console.log(d, "!!!!!!!!!!!!");
                            this.total = d.data.count;
                            this.currentPage = d.data.currentPage;
                            this.tableData = d.data.rows;
                            this.tableData.map(item => {
                                item.profitAmt = f2y(item.profitAmt);
                                item.transAmt = f2y(item.transAmt);
                                item.totalFeeAmt = f2y(item.totalFeeAmt);
                            });
                        }
                    },
                    err => {
                        //error callback
                    }
                );
            } else {
                //字符
                this.total = 0;
                this.currentPage = 1;
                this.pageSzie = 10;
                this.tableData = [];
            }
        },
        search() {
            this.queryPageData();
        },
        reset() {
            this.releaseForm = {
                profitName: '',
                dayInfo: this.releaseForm.dayInfo,
                serviceId: this.releaseForm.serviceId
            };
            this.queryPageData();
        },
        handleSizeChange(val) {
            this.pageSzie = val;
            // console.log(`每页 ${val} 条aaaaa`);
            this.queryPageData();
        },
        handleCurrentChange(size) {
            // console.log(size);
            this.queryPageData(size);
        }
    },
    components: {
        pagination: pagination
    }
};
</script>
