<!---echarts图表封装
  config 配置说明
  id echarts的id 页面多个的时候必传
  list  组件series 数据 
  xAxis  组件X抽数据
---->
<template>
  <div class="echarts">
    <div class="echarts"
         :id="config.id"
         v-if="config.list"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { y2f, f2y } from "../util/util.js";
export default {
  name: "lines",
  props: ["config"],
  data() {
    return {
      myChartOne: null,
      options: {
        title: {
          text: "总代发统计",
          textStyle: {
            padding: 5,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["金额", "笔数"],
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            // 调整柱状图的间距
            //boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // scale: true,
            max: 100,
          },
        ],
        series: [],
      },
    };
  },
  methods: {
    init() {
      this.options.title.text = this.config.title;
      this.options.series = this.config.list;
      var legend = [];
      this.config.list.map((e) => {
        legend.push(e.name);
      });
      this.options.legend.data = legend;
      this.options.xAxis[0].data = this.config.xAxis;

      this.options.yAxis[0].max =
        this.config.list[0].data && this.config.list[1].data.length > 0
          ? null
          : 100;
      var arr = [];
      for (var i = 0; i < this.config.list[0].data.length; i++) {
        arr.push(f2y(this.config.list[0].data[i]));
      }
      this.config.list[0].data = arr
      this.myChartOne = echarts.init(document.getElementById(this.config.id));
      this.myChartOne.setOption(this.options);
      var than = this;
      // window.onresize =  than.myChartOne.resize()
      window.addEventListener("resize", () => {
        than.myChartOne.resize();
      });
    },
  },
  watch: {
    config: function () {
      setTimeout(() => {
        this.init();
      }, 300);
    },
  },
  mounted() {
    if (this.config.list) {
      setTimeout(() => {
        this.init();
      }, 300);
    }
  },
  destroyed() {
    if (this.myChartOne) {
      this.myChartOne.dispose();
    }
  },
};
</script>
<style lang="less" scoped>
.echarts {
  width: 100%;
  height: 100%;
}
</style>