export default {
    data() {
        return {
            tableHeight: 'auto',
            bottomHeight: 0,
        };
    },
    mounted() {
        window.addEventListener('resize', this.resizeListener, false);
        this.resizeListener();
    },
    methods: {
        resizeListener() {
            setTimeout(() => {
                if (document.body.offsetWidth < 1024) {
                    this.tableHeight = 'auto';
                    return;
                }
                const table = this.$el.querySelector('.el-table');
                if (table) {
                    const isVisible = table.offsetWidth > 0 || table.offsetHeight > 0;
                    if (isVisible) {
                        let Y = table.getBoundingClientRect().top + document.documentElement.scrollTop;
                        if (this.bottomHeight > 0) {
                            Y += this.bottomHeight;
                        }
                        let height = 0;
                        if (this.withoutPager) {
                            height = document.body.offsetHeight - Y - 15;
                        } else {
                            height = document.body.offsetHeight - Y - 90;
                        }
                        this.tableHeight = height < 100 ? 100 : height;
                    }
                }
            }, 0);
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeListener, false);
    },
};
