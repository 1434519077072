import {
    SIDE_OPENED
} from '../../mutation-types';

export default {
    [SIDE_OPENED](state, side) {
        localStorage.setItem('side_menu_opened', side);
        state.side = side;
    },
};
