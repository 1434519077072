import { render, staticRenderFns } from "./merchantManage.vue?vue&type=template&id=a2c9c138&scoped=true&"
import script from "./merchantManage.vue?vue&type=script&lang=js&"
export * from "./merchantManage.vue?vue&type=script&lang=js&"
import style0 from "./merchantManage.vue?vue&type=style&index=0&id=a2c9c138&lang=less&scoped=true&"
import style1 from "./merchantManage.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2c9c138",
  null
  
)

export default component.exports