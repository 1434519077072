//正则表达式-----集合

//验证用户名
export function validateUserName(rule, value, callback) {
    var reg = /^[a-zA-Z0-9_-]{4,16}$/;
    if (!reg.test(value)) {
        callback(new Error('用户名格式不正确,请输入4到16位（字母，数字，下划线，减号）'));
    } else {
        callback();
    }
};

//验证密码
export const validatePass = (rule, value, callback) => {
    var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
    if (!reg.test(value)) {
        callback(new Error('请输入6-16位的数字或字母的密码'));
    } else {
        callback();
    }
};
//验证三级联动
export const validateCity = (rule, value, callback) => {
    // console.log(value);
    if (!(value && value.length > 0)) {
        callback(new Error('请选择'));
    } else {
        callback();
    }
};
//验证邮箱
export function validateEmail(rule, value, callback) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!reg.test(value) && !value) {
        callback(new Error('请输入正确的邮箱格式'));
    } else {
        callback();
    }
};

//身份证号
export function validatecP(rule, value, callback) {
    var reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (!reg.test(value)) {
        callback(new Error('请输入正确的身份证号'));
    } else {
        callback();
    }
};

//银行卡卡号
export function bankCardOrder(rule, value, callback) {
    if (/^[0-9]+[0-9]$/.test(value)) {
        value = (value + "").replace(/\s+/g, "");
        if (value.length < 16) {
            callback(new Error('请输入大于16位的纯数字的银行卡卡号'));
        } else {
            callback();
        }
    } else {
        callback(new Error('请输入大于16位的纯数字的银行卡卡号'));
    }
};
//银行卡密码
export function bankCardPass(rule, value, callback) {
    var reg = /^[0-9]{6}$/;
    if (!reg.test(value)) {
        callback(new Error('请输入6位纯数字的银行卡密码'));
    } else {
        callback();
    }
};
//手机号
export function validateTel(rule, value, callback) {
    var reg = /^1[3456789]\d{9}$/;
    if (!reg.test(value)) {
        callback(new Error('请输入正确的11位手机号'));
    } else {
        callback();
    }
};
//手机号模糊验证
export function validateModel(rule, value, callback) {
    var reg = /^1[3456789]\d{9}$/;
    if (value) {
        if (!reg.test(value)) {
            callback(new Error('请输入正确的11位手机号'));
        } else {
            callback();
        }
    } else {
        callback();
    }
};
//服务电话
export function validateTel2(rule, value, callback) {
    var reg = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
    if (!reg.test(value)) {
        callback(new Error('请输入固定电话'));
    } else {
        callback();
    }
};
//正整数正则
export function posPattern(rule, value, callback) {
    var reg = /^\d+$/;
    if (!reg.test(value)) {
        callback(new Error('请输入正整数'));
    } else {
        callback();
    }
};
//价格2位小数
export function validatePrice(rule, value, callback) {
    var reg = /^\d{0,8}\.{0,1}(\d{1,2})?$/;
    if (!reg.test(value) || value == "") {
        callback(new Error('请输入纯数字，最多2为小数'));
    } else {
        callback();
    }
};
//ip地址
export function validateIp(rule, value, callback) {
    var reg = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
    if (value) {
        if (!reg.test(value)) {
            callback(new Error('请输入正确ip地址'));
        } else {
            callback();
        }
    } else {
        callback();
    }
};
export default {
    validateUserName,
    validatePass,
    validateEmail,
    validatecP,
    posPattern,
    validateTel,
    validateTel2,
    bankCardPass,
    bankCardOrder,
    validateIp,
    validatePrice,
    validateModel
}