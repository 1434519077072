const Util = {
  dateFormat(date, fmt) {
    let o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    for (let k in o)
      if (new RegExp(`(${k})`).test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1)
        ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
    return fmt;
  },
  getScrollbarWidth() {
    let oP = document.createElement('p');
    let styles = {
      width: '100px',
      height: '100px',
      overflowY: 'scroll',
    };
    let i;
    let scrollbarWidth;
    for (i in styles) oP.style[i] = styles[i];
    document.body.appendChild(oP);
    scrollbarWidth = oP.offsetWidth - oP.clientWidth;
    oP.remove();
    return scrollbarWidth;
  },
  multiply(num1, num2) {
    let m = 0;
    let s1 = num1.toString();
    let s2 = num2.toString();
    try {
      m += s1.split('.')[1].length;
    } catch (e) {
      //
    }
    try {
      m += s2.split('.')[1].length;
    } catch (e) {
      //
    }
    return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
  },
  accSub(arg1, arg2) {
    let r1;
    let r2;
    let m;
    let n;
    try {
      r1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = (r1 >= r2) ? r1 : r2;
    return (((arg1 * m) - (arg2 * m)) / m).toFixed(n);
  },
  add(num1, num2) {
    let r1;
    let r2;
    let m;
    let c;
    try {
      r1 = num1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = num2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    // console.log('test1', 'num1:', num1, 'num2:', num2, 'r1:', r1, 'r2:', r2, 'c:', c, 'm:', m)
    if (c > 0) {
      let cm = Math.pow(10, c);
      if (r1 > r2) {
        num1 = Number(num1.toString().replace('.', ''));
        num2 = Number(num2.toString().replace('.', '')) * cm;
      } else {
        num1 = Number(num1.toString().replace('.', '')) * cm;
        num2 = Number(num2.toString().replace('.', ''));
      }
      // console.log('test2', 'num1:', num1, 'num2:', num2, 'cm:', cm)
    } else {
      num1 = Number(num1.toString().replace('.', ''));
      num2 = Number(num2.toString().replace('.', ''));
    }
    // return ((num1 * m) + (num2 * m)) / m;
    return (num1 + num2) / m;
  },
  divide(num1, num2) {
    let t1 = 0;
    let t2 = 0;
    let r1;
    let r2;
    try {
      t1 = num1.toString().split('.')[1].length;
    } catch (e) {
      //
    }
    try {
      t2 = num2.toString().split('.')[1].length;
    } catch (e) {
      //
    }
    r1 = Number(num1.toString().replace('.', ''));
    r2 = Number(num2.toString().replace('.', ''));
    return (r1 / r2) * Math.pow(10, t2 - t1);
  },
  parse2Show(num) {
    if (!num) return num;
    return parseFloat(Util.divide(num, 100).toFixed(2));
  },
  rand(m) {
    m = m > 16 ? 16 : m;
    let num = Math.random().toString();
    if (num.substr(num.length - m, 1) === '0') {
      return Util.rand(m);
    }
    return num.substring(num.length - m);
  },
  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
  },
  isObjDiff(objA, objB) {
    for (const key of Object.keys(objA)) {
      if (objA[key] !== objB[key]) {
        return true
      }
    }
    return false
  }
};

export default Util;


// 渠道金额处理
export function y2f(y) {
  if (!y) {
    return 0
  }
  // return Number(y) * 100
  return (Number(y) * 100).toFixed(0);
}

export function f2y(f) {
  if (!f) {
    return 0
  }
  // const res = (Number(f) / 100).toFixed(2)
  const res = parseFloat((Number(f) / 100).toFixed(2));
  return res
}
