<template>
  <div>
    <!-- 点击上传按钮 -->
    <el-upload class="upload-demo" action="#" :show-file-list="true" :file-list="fileList" list-type="picture"
      :on-preview="handlePreviewPaperAgrement" :on-remove="handleRemovePaperAgrement"
      :before-remove="beforeRemovePaperAgrement" :auto-upload="true" :on-change="hangeChange"
      :on-exceed="handleExceedPaperAgrement" :http-request="paperAgrementUpload" :accept="accept">
      <el-button size="small" type="primary">
        <i class="el-icon-upload"></i>
        {{ name ? name : '点击上传' }}
      </el-button>
      <el-button v-if="downTemplate" style="float:left;margin-top:3px;margin-right:10px;margin-left:0" size="small"
        class="download-sty" @click="donwWhiteTemp">
        <i class="el-icon-upload"></i>
        下载模板
      </el-button>
      <div v-if="accept.length > 0" class="el-upload__tip" slot="tip">支持上传{{ accept }}文件</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body :close-on-click-modal="false" width="1000">
      <img width="100%" :src="dialogImageUrl" alt="" v-if="imgFlag === 'pic'">
    </el-dialog>
  </div>
</template>
<script>
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
import moment from 'moment';

export default {
  data() {
    return {
      dialogVisible: false, //弹框
      imgFlag: '', //图片状态
      dialogImageUrl: '', //图片
      paperAgreementList: [], //上传的文件列表
      availableAgreements: [], // 可上传文件列表
      ossConfig: '', //上传参数
      preViewUel: '', //上传文件打开内容,
      leaseForm: {
        exportUrl: ''
      }, //导入form
      fileList: [],
      dialogVisibleAccredit: false,
    };
  },
  props: {
    // 上传的值（v-model）
    value: {
      type: String,
      default: ''
    },
    // 下载模版的值
    downTemplate: {
      type: String,
      default: ''
    },
    // 按钮名称
    name: {
      type: String,
      default: ''
    },
    // 上传文件格式限制,为空时不限制 eg: ".jpg,.jpeg,.png,.pdf"
    accept: {
      type: String,
      default: ''
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (!newValue) {
        this.tempUrl = '';
        this.fileList = [];
      } else {
        this.tempUrl = newValue;
        var fileNameInfo = newValue;
        if (newValue.includes('?Expires=')) {
          fileNameInfo = newValue.split("?Expires=")[0];
        }
        const lastIndex = fileNameInfo.lastIndexOf('/');
        fileNameInfo = fileNameInfo.substring(lastIndex + 1);
        var imgShow = ''
        const extensions = ['.png', '.jpeg', '.jpg'];
        const isMatch = extensions.some(ext => fileNameInfo.includes(ext));
        if (fileNameInfo.includes('.pdf')) {
          imgShow = 'https://bpo-dev.oss-cn-beijing.aliyuncs.com/paidProof/20230922101907/PDF%20(1).png'
        } else if (fileNameInfo.includes('.xlsx') || fileNameInfo.includes('.xls')) {
          imgShow = "https://bpo-dev.oss-cn-beijing.aliyuncs.com/paper_agreement/1695352570446/xlsx.png"
        } else if (isMatch) {
          imgShow = newValue
        } else if (fileNameInfo.includes('.zip') || fileNameInfo.includes('.rar')) {
          imgShow = "https://bpo-dev.oss-cn-beijing.aliyuncs.com/paidProof/20230922113258/zip.png"
        } else {
          imgShow = "https://bpo-dev.oss-cn-beijing.aliyuncs.com/paidProof/20230922113553/下载.png"
        }
        this.fileList = [{ name: fileNameInfo, url: imgShow, urlAddress: newValue }];
      }
    }
  },
  mounted() {
    if (this.value) {
      this.tempUrl = this.value;
      var fileNameInfo = this.value;
      if (this.value.includes('?Expires=')) {
        fileNameInfo = this.value.split("?Expires=")[0];
      }
      const lastIndex = fileNameInfo.lastIndexOf('/');
      fileNameInfo = fileNameInfo.substring(lastIndex + 1);
      var imgShow = ''
      const extensions = ['.png', '.jpeg', '.jpg'];
      const isMatch = extensions.some(ext => fileNameInfo.includes(ext));
      if (fileNameInfo.includes('.pdf')) {
        imgShow = 'https://bpo-dev.oss-cn-beijing.aliyuncs.com/paidProof/20230922101907/PDF%20(1).png'
      } else if (fileNameInfo.includes('.xlsx') || fileNameInfo.includes('.xls')) {
        imgShow = "https://bpo-dev.oss-cn-beijing.aliyuncs.com/paper_agreement/1695352570446/xlsx.png"
      } else if (isMatch) {
        imgShow = this.value
      } else if (fileNameInfo.includes('.zip') || fileNameInfo.includes('.rar')) {
        imgShow = "https://bpo-dev.oss-cn-beijing.aliyuncs.com/paidProof/20230922113258/zip.png"
      } else {
        imgShow = "https://bpo-dev.oss-cn-beijing.aliyuncs.com/paidProof/20230922113553/下载.png"
      }
      this.fileList = [{ name: fileNameInfo, url: imgShow, urlAddress: this.value }];// 用于回显数据
    }
  },
  methods: {
    // 下载模版
    donwWhiteTemp() {
      window.open(this.downTemplate)
    },
    // 改变替换第一次上传的
    hangeChange(file, fileList) {
      if (this.fileList.length > 1) {
        fileList.split(0, 1);
      }
    },
    // 封装判断是否是pdf
    urlContent(url) {
      let str = url
      if (url.includes("?Expires=")) {
        str = url.split("?Expires=")[0];
      }
      const fileSuffix = str.substring(str.lastIndexOf('.') + 1);
      if (['jpg', 'png', 'jpeg'].includes(fileSuffix)) {
        this.dialogImageUrl = url;
        this.imgFlag = 'pic';
        this.dialogVisible = true;
      } else if (['xls', 'doc', 'xlsx'].includes(fileSuffix)) {
        this.dialogImageUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`;
        window.open(this.dialogImageUrl)
      } else if (['pdf'].includes(fileSuffix)) {
        this.dialogImageUrl = url;
        window.open(this.dialogImageUrl)
      } else {
        window.open(url);
        this.dialogVisible = false;
      }
    },
    // 点击文件预览
    handlePreviewPaperAgrement(file) {
      this.urlContent(file.urlAddress)
    },
    // 文件列表移除文件时的钩子
    handleRemovePaperAgrement(file, paperAgreementList) {
      this.paperAgreementList = [];
      this.leaseForm.exportUrl = '';
      this.$emit('input', '');
    },
    // 删除文件之前的钩子
    beforeRemovePaperAgrement(file, paperAgreementList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 文件超出个数限制时的钩子
    handleExceedPaperAgrement(files, paperAgreementList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + paperAgreementList.length
        } 个文件`
      );
    },
    // 覆盖默认的上传行为，可以自定义上传的实现
    paperAgrementUpload(param) {
      this.getOssConfig(param);
    },
    // 前端上传
    getFileNameUUID() {
      function rx() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      return `${(+new Date()).toString().split('').reverse().join('')}_${rx()}${rx()}`;
    },
    post2Oss(file) {
      const isLt = file.size / 1024 / 5 >= 1 && file.size / 1024 / 1024 / 20 <= 1;
      if (!isLt) {
        this.paperAgreementList = [];
        this.leaseForm.exportUrl = '';
        this.fileList = [];
        this.$message.error('上传文件大小不得小于5KB,不得大于20MB!');
        return false;
      }
      var fileName = file.name.replace(/[#?&=+%^!*'"：:]/g, '_');
      const fileSuffix = fileName.substring(fileName.lastIndexOf('.') + 1);
      const whiteList = ['xlsx', 'pdf', 'png', 'jpeg', 'jpg', 'zip', 'xls'];
      const isSuffix = whiteList.indexOf(fileSuffix.toLowerCase()) === -1;
      if (isSuffix) {
        this.$message.error('上传文件格式必须为xlsx,pdf, png, jpeg, jpg, zip, xls');
        this.paperAgreementList = [];
        this.leaseForm.exportUrl = '';
        this.fileList = []
        return;
      }
      let formData = new FormData();
      const lastDotIndex = fileName.lastIndexOf('.');
      const nameInfo = fileName.substring(0, lastDotIndex);
      formData.append(
        'key',
        `paidProof/${moment().format('YYYYMMDDHHmmss')}/${nameInfo}.${fileName.split('.').slice(-1)[0]
        }`
      );
      formData.append('name', fileName);
      formData.append('Bucket', this.ossConfig.bucket);
      formData.append('policy', this.ossConfig.policy);
      formData.append('OSSAccessKeyId', this.ossConfig.ossAccessKeyId);
      formData.append('success_action_status', this.ossConfig.successActionStatus);
      formData.append('Signature', this.ossConfig.signature);
      formData.append('file', file);

      this.leaseForm.exportName = fileName.split('.')[0];

      this.axios({
        url: this.ossConfig.url,
        method: 'POST',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(res => {
          try {
            let xml = this.loadXML(res.data);
            let url =
              xml.documentElement.getElementsByTagName('Location')[0].textContent;
            var fileNameInfo = url;
            if (url.includes('?Expires=')) {
              fileNameInfo = url.split("?Expires=")[0];
            }
            const lastIndex = fileNameInfo.lastIndexOf('/');
            fileNameInfo = fileNameInfo.substring(lastIndex + 1);
            var imgShow = ''
            const extensions = ['.png', '.jpeg', '.jpg'];
            const isMatch = extensions.some(ext => fileNameInfo.includes(ext));
            if (fileNameInfo.includes('.pdf')) {
              imgShow = 'https://bpo-dev.oss-cn-beijing.aliyuncs.com/paidProof/20230922101907/PDF%20(1).png'
            } else if (fileNameInfo.includes('.xlsx') || fileNameInfo.includes('.xls')) {
              imgShow = "https://bpo-dev.oss-cn-beijing.aliyuncs.com/paper_agreement/1695352570446/xlsx.png"
            } else if (isMatch) {
              imgShow = url
            } else if (fileNameInfo.includes('.zip') || fileNameInfo.includes('.rar')) {
              imgShow = "https://bpo-dev.oss-cn-beijing.aliyuncs.com/paidProof/20230922113258/zip.png"
            } else {
              imgShow = "https://bpo-dev.oss-cn-beijing.aliyuncs.com/paidProof/20230922113553/下载.png"
            }
            this.paperAgreementList = [{ name: fileNameInfo, url: imgShow, urlAddress: url, }];
            this.leaseForm.exportUrl = url;
            if (this.leaseForm.exportUrl) {
              this.tempUrl = url;
              this.$emit('input', url);
              this.$emit('dialogVisibleAccredit', this.dialogVisibleAccredit);
            }
            this.preViewUel = url;
          } catch (e) { }
        })
        .catch(err => { });
    },
    loadXML(xmlString) {
      var xmlDoc = null;
      if (!window.DOMParser && window.ActiveXObject) {
        var xmlDomVersions = [
          'MSXML.2.DOMDocument.6.0',
          'MSXML.2.DOMDocument.3.0',
          'Microsoft.XMLDOM'
        ];
        for (var i = 0; i < xmlDomVersions.length; i++) {
          try {
            xmlDoc = new ActiveXObject(xmlDomVersions[i]);
            xmlDoc.async = false;
            xmlDoc.loadXML(xmlString);
            break;
          } catch (e) { }
        }
      } else if (
        window.DOMParser &&
        document.implementation &&
        document.implementation.createDocument
      ) {
        try {
          var domParser = new DOMParser();
          xmlDoc = domParser.parseFromString(xmlString, 'text/xml');
        } catch (e) { }
      } else {
        return null;
      }
      return xmlDoc;
    },
    paperAgreementValidator(file) {
      let fileName = file.name || '';
      let fileValue = { status: 1, fileName: fileName };
      // 验证成功
      this.availableAgreements.push(fileValue);
      return fileValue;
    },
    getOssConfig(param) {
      post(api.ossUploadConfig).then((res) => {
        if (res.code == 0) {
          this.ossConfig = res.data;
          this.post2Oss(param.file);
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
};
</script>