<style lang="less" scoped>
.serviceProduct {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  text-align: left;
  // 产品配置
  .productConfig {
    .basicsConfig {
      font-family: PingFangSC-Semibold, "PingFang SC Semibold", "PingFang SC";
      font-weight: 650;
      font-style: normal;
      font-size: 16px;
      color: rgb(102, 102, 102);
      line-height: 24px;
      padding-top: 30px;
    }
    .el-submit {
      display: flex;
      margin-left: 45%;
      margin-top: 30px;
      margin-bottom: 30px;
      .Control-submit {
        width: 100px;
        height: 36px;
        background-color: #0079fe;
        text-align: center;
        color: #fff;
        font-size: 12px;
        line-height: 36px;
        border-radius: 3px;
        margin-right: 20px;
        cursor: pointer;
      }
      .Control-channel {
        width: 100px;
        height: 36px;
        border: 1px solid #0079fe;
        border-radius: 3px;
        text-align: center;
        color: #0079fe;
        font-size: 12px;
        line-height: 36px;
        cursor: pointer;
      }
    }
  }
  .box {
    width: 100%;
    padding-top: 12px;
    .main_button {
      margin-left: 36px;
    }
    .wrap {
      width: 100%;
      margin-top: 22px;
      padding: 12px;
      box-sizing: border-box;
      .wraped {
        width: 100%;
      }
    }
  }
  .block {
    width: 100%;
    .main {
      width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      .grid-content {
        width: 100%;
        margin-bottom: 20px;
        display: inline-block;
      }
      .grid-left {
        float: left;
        margin-bottom: 20px;
      }
      .grid-img {
        float: left;
        width: 160px;
        height: 97px;
        margin-right: 22px;
      }
      .wrap {
        width: 100%;
        margin-top: 22px;
        padding: 30px;
        box-sizing: border-box;
      }
      .footer {
        padding: 30px;
      }
      .col {
        width: 102%;
        height: 10px;
        background-color: #f2f2f2;
        margin-left: -12px;
      }
    }
  }
  .addConflict {
    float: right;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
</style>
<style>
.el-form-item {
  /* DOTO */
  /* padding-left: 15px !important; */
}
.el-table th.is-leaf,
.el-table th {
  background-color: #f5f5f5;
  border-bottom: none;
}
/* .main .el-tabs__nav-scroll {
  padding-left: 34px !important;
} */
.main .el-tabs__header {
  margin: 0px !important;
}
.serviceProduct .el-table__fixed-right {
  right: 0 !important;
  border: none;
}
.serviceProduct .el-.el-upload-list__item-name-right {
  width: 400px !important;
}
</style>
<template>
  <div class="serviceProduct">
    <div class="box">
      <el-form label-width="120px">
        <el-row>
          <el-col :lg="18"
                  :md="20">
            <el-col :lg="8"
                    :md="12">
              <el-form-item label="集团信息名称：">
                <el-input size="small"
                          placeholder="请输入需求ID"></el-input>
              </el-form-item>
            </el-col>

            <el-col :lg="8"
                    :md="12">
              <el-form-item label="项目需求名称：">
                <el-input size="small"
                          placeholder="请输入项目需求名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="8"
                    :md="12">
              <el-form-item label="任务名称：">
                <el-input size="small"
                          placeholder="请输入任务名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="8"
                    :md="12">
              <el-form-item label="任务ID：">
                <el-input size="small"
                          placeholder="请输入任务ID"></el-input>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :lg="6"
                  :md="8">
            <el-form-item label=" ">
              <el-button type="primary"
                         size="small">查询</el-button>
              <el-button size="small"
                         class="chongzhi">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- <div class="addConflict">
        <el-button type="primary"
                   size="small">新增渠道商</el-button>
      </div> -->
      <div class="wrap">
        <el-table :data="tableData"
                  border
                  :render-header="renderHeader"
                  fit
                  style="width: 100%">
          <el-table-column fixed
                           prop="productName"
                           label="产品名称"
                           min-width="130">
          </el-table-column>
          <el-table-column prop="productId"
                           label="ID"
                           width="150px">
          </el-table-column>
          <el-table-column prop="companyName"
                           label="服务商名称"
                           min-width="160">
          </el-table-column>
          <el-table-column prop="supplierId"
                           label="服务商ID"
                           min-width="150">
          </el-table-column>
          <el-table-column label="操作"
                           fixed="right"
                           min-width="120">
            <template slot-scope="scope">
              <div @click="productDetail(scope.row)"
                   style="color:#19A9FC;font-size:12px;cursor: pointer;">
                产品详情
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="wraped">
          <pagination :total="total"
                      :currentPage="current"
                      @handleSizeChange="handleSizeChange"
                      @handleCurrentChange="handleCurrentChange"></pagination>
        </div>
      </div>
    </div>
    <!-- 产品详情弹框 -->
    <el-dialog title="查看服务商产品详情"
               :visible.sync="dialogVisible"
               width="70%"
               :close-on-click-modal="false"
               :before-close="handleClose">
      <div class="productConfig">
        <el-form ref="addproConfigForm"
                 :model="addproConfigForm"
                 :rules="addproConfigrules"
                 style="
                 width:100%
                 text-align:center;
          margin-left:10%;
          line-height: 50px;
        ">
          <el-row>
            <el-col :lg="18"
                    :md="20">
              <el-col :lg="8"
                      :md="12">
                <el-form-item class="basicsConfig"
                              label="基础规则"
                              style="margin-left: -20%" />
              </el-col>
              <el-col />
              <el-col />
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="产品名称："
                              prop="productName">
                  <el-input v-model="addproConfigForm.productName"
                            size="small"
                            placeholder="请输入产品名称" />
                </el-form-item>
              </el-col>
              <el-col />
              <el-col />
              <!--产品方案，先注释，以后会启用这种方式的-->
              <!-- <el-col :lg="8"
                      :md="12">
                <el-form-item label="产品方案："
                              prop="productType">
                  <el-select v-model="addproConfigForm.productType"
                             clearable
                             filterable
                             style="width: 100%"
                             placeholder="请选择方案"
                             size="small">
                    <el-option v-for="item in productTypeOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="产品名称："
                              prop="productName">
                  <el-input v-model="addproConfigForm.productName"
                            size="small"
                            placeholder="请输入产品名称" />
                </el-form-item>
              </el-col> -->
              <el-col />
              <el-col />
              <el-col :lg="8"
                      :md="12">
                <el-form-item class="basicsConfig"
                              label="基础风控规则"
                              style="margin-left: -20%" />
              </el-col>
              <el-col />
              <el-col />
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="单笔最大交易额（元）："
                              prop="signleAmt">
                  <el-input v-model="addproConfigForm.signleAmt"
                            size="small"
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                            placeholder="请输入单笔最大交易额" />
                </el-form-item>
              </el-col>
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="单日最大交易额（元）："
                              prop="dayAmt">
                  <el-input v-model="addproConfigForm.dayAmt"
                            size="small"
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                            placeholder="请输入单日最大交易额" />
                </el-form-item>
              </el-col>
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="单月最大交易额（元）："
                              prop="monthAmt">
                  <el-input v-model="addproConfigForm.monthAmt"
                            size="small"
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                            placeholder="请输入单月最大交易额" />
                </el-form-item>
              </el-col>
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="自然年最大交易额（元）："
                              prop="yearAmt">
                  <el-input v-model="addproConfigForm.yearAmt"
                            size="small"
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                            placeholder="请输入自然年最大交易额" />
                </el-form-item>
              </el-col>
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="单人日最大交易额（元）："
                              prop="userDayAmt">
                  <el-input v-model="addproConfigForm.userDayAmt"
                            size="small"
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                            placeholder="请输入单人日最大交易额" />
                </el-form-item>
              </el-col>
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="单人月最大交易额（元）："
                              prop="userMonthAmt">
                  <el-input v-model="addproConfigForm.userMonthAmt"
                            size="small"
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                            placeholder="请输入单人月最大交易额" />
                </el-form-item>
              </el-col>
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="单人年最大交易额（元）："
                              prop="userYearAmt">
                  <el-input v-model="addproConfigForm.userYearAmt"
                            size="small"
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                            placeholder="请输入单人年最大交易额" />
                </el-form-item>
              </el-col>
              <el-col />
              <el-col />
              <el-col :lg="8"
                      :md="12">
                <el-form-item class="basicsConfig"
                              label="个人签约配置"
                              style="margin-left: -20%" />
              </el-col>
              <el-col />
              <el-col />
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="个人签约要求 - 身份证"
                              prop="idPhotoFlag">
                  <el-select v-model="addproConfigForm.idPhotoFlag"
                             clearable
                             filterable
                             style="width: 100%"
                             placeholder="请选择"
                             size="small">
                    <el-option v-for="item in idCardOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="个人签约要求 - 身份证认证要素"
                              prop="idValidType">
                  <el-select v-model="addproConfigForm.idValidType"
                             clearable
                             filterable
                             style="width: 100%"
                             placeholder="请选择"
                             size="small">
                    <el-option v-for="item in validOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8"
                      :md="12">
                <el-form-item label="签约形式："
                              prop="idSignType">
                  <el-select v-model="addproConfigForm.idSignType"
                             clearable
                             filterable
                             style="width: 100%"
                             placeholder="请选择"
                             size="small">
                    <el-option v-for="item in signTypeOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8"
                      :md="12">
                <el-form-item class="basicsConfig"
                              label="发票配置"
                              style="margin-left: -12%" />
              </el-col>
              <el-col />
              <el-col />
              <el-col :lg="8"
                      :md="12">
                <!-- 普票方案(去掉21) -->
                <!-- v-if="addproConfigForm.productType==0" -->
                <!-- <el-form-item
                              label="可开具发票类型："
                              prop="invoiceTypes">
                  <el-select v-model="addproConfigForm.invoiceTypes"
                             clearable
                             filterable
                             multiple
                             style="width: 100%"
                             placeholder="请选择"
                             size="small">
                    <el-option v-for="item in invoiceTypeOptions0"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value" />
                  </el-select>
                </el-form-item> -->

                <!-- 专用发票方案(选所有) -->
                <!-- v-if="addproConfigForm.productType==1" -->
                <el-form-item label="可开具发票类型："
                              prop="invoiceTypes">
                  <el-select v-model="addproConfigForm.invoiceTypes"
                             clearable
                             filterable
                             multiple
                             style="width: 100%"
                             placeholder="请选择"
                             size="small">
                    <el-option v-for="item in invoiceTypeOptions1"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col />
              <el-col />
            </el-col>
          </el-row>
        </el-form>
        <div class="el-submit">
          <!-- <div class="Control-submit"
               @click="ControlSubmit('addproConfigForm')">
            立即提交
          </div> -->
          <div class="Control-channel"
               @click="ConfigInfochannel()">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/components/pagination.vue'
import api from "@/api/api.js";
import { get, post } from "@/api/index.js";
import { f2y, y2f } from "../../util/util.js";
export default {
  // 默认高度
  data() {
    return {
      // 产品详情
      idCardOptions: [
        { value: "0", label: "需要" },
        { value: "1", label: "不需要" }
      ],
      validOptions: [
        { value: "0", label: "银行卡四要素" },
        { value: "1", label: "银行卡三要素" },
        { value: "2", label: "运营商三要素" },
        { value: "3", label: "两要素" }
      ],
      signTypeOptions: [
        { value: "", label: "不限" },
        { value: "0", label: "APP签约" },
        { value: "1", label: "H5签约" },
        { value: "2", label: "静默签" }
      ],
      invoiceTypeOptions0: [
        { value: "10", label: "增值税电子专用发票" },
        { value: "11", label: "增值税电子普通发票" },
        { value: "20", label: "增值税普通发票" }
      ],

      invoiceTypeOptions1: [
        { value: "10", label: " 增值税电子专用发票" },
        { value: "11", label: "增值税电子普通发票" },
        { value: "20", label: "增值税普通发票" },
        { value: "21", label: "增值税专用发票" }
      ],
      addproConfigForm: {
        productType: "",
        signMainName: "",
        productName: "",
        signleAmt: "",
        dayAmt: "",
        monthAmt: "",
        yearAmt: "",
        userDayAmt: "",
        userMonthAmt: "",
        userYearAmt: "",
        idPhotoFlag: "",
        idValidType: "",
        idSignType: "",
        invoiceTypes: "",
        addedRate: "",
        incomeRate: ""
      },
      addproConfigrules: {
        signMainName: [
          { required: true, message: "请输入交付商名称", trigger: "blur" }
        ],
        productType: [{ required: true, message: '请选择产品方案', trigger: 'blur' }],
        productName: [
          { required: true, message: "请输入产品名称", trigger: "blur" }
        ],
        signleAmt: [
          { required: true, message: "请输入单笔最大交易额", trigger: "blur" },
        ],
        dayAmt: [
          { required: true, message: "请输入单日最大交易额", trigger: "blur" },
        ],
        monthAmt: [
          { required: true, message: "请输入单月最大交易额", trigger: "blur" },
        ],
        yearAmt: [
          {
            required: true,
            message: "请输入自然年最大交易额",
            trigger: "blur"
          },
        ],
        userDayAmt: [
          {
            required: true,
            message: "请输入单人日最大交易额",
            trigger: "blur"
          },
        ],
        userMonthAmt: [
          {
            required: true,
            message: "请输入单人月最大交易额",
            trigger: "blur"
          },
        ],
        userYearAmt: [
          {
            required: true,
            message: "请输入单人年最大交易额",
            trigger: "blur"
          },
        ],
        idPhotoFlag: [
          {
            required: true,
            message: "请选择个人签约要求 - 身份证",
            trigger: "blur"
          }
        ],
        idValidType: [
          {
            required: true,
            message: "请选择个人签约要求 - 身份证认证要素",
            trigger: "blur"
          }
        ],
        idSignType: [
          { required: true, message: "请选择签约形式", trigger: "blur" }
        ],
        invoiceTypes: [
          { required: true, message: "请选择可开具发票类型", trigger: "blur" }
        ],
        addedRate: [
          {
            required: true,
            message: "请输入增值税PLUS配置(%)",
            trigger: "blur"
          },
          { required: true, pattern: /^(([0-9][0-9]|[0-9])(\.\d{1,2})?|0\.\d{1,2})$/, message: "须小于100%" },
        ],
        incomeRate: [
          {
            required: true,
            message: "请输入个人所得税配置(%)",
            trigger: "blur"
          },
          { required: true, pattern: /^(([0-9][0-9]|[0-9])(\.\d{1,2})?|0\.\d{1,2})$/, message: "须小于100%" },
        ]
      },
      dialogVisible: false,
      supplierId: '',
      current: 1,
      total: 0,
      pageSzie: 10,
      tableData: []
    };
  },

  mounted() {
    this.supplierId = this.$route.query.supplierId
    this.serviceProductList()
  },

  methods: {
    // 表格
    renderHeader(h, data) {
      return h('span', [
        h('el-tooltip'), {
          attrs: {
            class: 'item',
            effect: 'dark',
            content: data.column.label
          }
        }, [
          h('span', data.column.label)
        ]
      ])
    },
    // 产品列表
    serviceProductList(size) {
      const loading = this.$loading({
        lock: true,
        spinner: "loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      post(api.supplierProductlist, {
        current: size || 1,
        pageSzie: this.pageSzie,
        supplierId: this.supplierId
      }).then((d) => {
        // console.log(d, 666)
        if (d.code == 0) {
          this.tableData = d.data.rows
          this.total = d.data.total
          this.current = d.data.current
          loading.close();
        } else {
          this.$message.error(d.message)
          loading.close();
        }
      })
    },
    // 查看服务商产品详情
    productDetail(row) {
      // console.log(row, 969696)
      this.dialogVisible = true
      const loading = this.$loading({
        lock: true,
        spinner: "loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      post(api.supplierProductDetail, { productId: row.productId }).then((d) => {
        if (d.code == 0) {
          // console.log(d.data, 120)
          this.addproConfigForm = {
            // productType: '',
            // signMainName: row.productName,
            productName: d.data.productName,
            signleAmt: f2y(d.data.singleAmt),
            dayAmt: f2y(d.data.dayAmt),
            monthAmt: f2y(d.data.monthAmt),
            yearAmt: f2y(d.data.yearAmt),
            userDayAmt: f2y(d.data.userDayAmt),
            userMonthAmt: f2y(d.data.userMonthAmt),
            userYearAmt: f2y(d.data.userYearAmt),
            idPhotoFlag: d.data.idPhotoFlag,
            idValidType: d.data.idValidType,
            idSignType: d.data.idSignType,
            invoiceTypes: d.data.listInvoiceTypes,
            addedRate: d.data.addedRate,
            incomeRate: d.data.incomeRate
          }
          loading.close();
        } else {
          this.$message.error(d.message)
          loading.close()
        }
      })
    },
    ConfigInfochannel() {
      this.dialogVisible = false
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 搜索
    search() { },
    // 重置
    reset() { },
    // 产品详情分页
    handleSizeChange(val) {
      this.pageSzie = val;
      this.serviceProductList();
    },
    handleCurrentChange(size) {
      this.serviceProductList(size);
    },
  },
  components: {
    pagination: pagination,
  }
};
</script>
