import Vue from 'vue';
import ElementUI from 'element-ui';

Vue.use(ElementUI);

import _ from 'underscore';

window._ = window._ || _;

import Util from './util/util';

window.Util = window.Util || Util;
