<style lang="less" scoped>
.agentManage {
    ::-webkit-scrollbar {
        display: none;
    }
    overflow-x: hidden;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    text-align: left;
    .proto {
        outline: none;
        border: 1px solid #d8dce5;
        border-radius: 4px;
        height: 29px;
        line-height: 29px;
        width: 97%;
        padding-left: 15px;
        color: #606266;
    }
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
        color: #d8dce5;
    }
    .box {
        width: 100%;
        padding-top: 12px;
        .main_button {
            margin-left: 36px;
        }
        .wrap {
            width: 100%;
            margin-top: 22px;
            padding: 12px;
            box-sizing: border-box;
            .wraped {
                width: 100%;
            }
        }
    }
    .block {
        width: 100%;
        .main {
            width: 100%;
            box-sizing: border-box;
            font-size: 14px;
            .grid-content {
                width: 100%;
                margin-bottom: 20px;
                display: inline-block;
            }
            .grid-left {
                float: left;
                margin-bottom: 20px;
            }
            .grid-img {
                float: left;
                width: 160px;
                height: 97px;
                margin-right: 22px;
            }
            .wrap {
                width: 100%;
                margin-top: 22px;
                padding: 30px;
                box-sizing: border-box;
            }
            .footer {
                padding: 30px;
            }
            .col {
                width: 102%;
                height: 10px;
                background-color: #f2f2f2;
                margin-left: -12px;
            }
        }
    }
    // 新增渠道商
    .add-agent {
        float: right;
        margin-right: 30px;
        margin-bottom: 30px;
        cursor: pointer;
    }
    .back {
        cursor: pointer;
        display: inline-block;
        padding: 30px 20px 0px 30px;
    }
    .title span {
        padding-right: 20px;
        color: #d8dce5;
    }
    .addgrads {
        font-size: 14px;
        line-height: 32px;
    }
    // 表单
    .add-form {
        padding-left: 20%;
        padding-right: 20%;
        margin: 50px auto;
        // 上传职业营照
        .uploading-camp {
            width: 100%;
            border: 1px solid #f5f5f5;
            border-right: none;
            margin-bottom: 35px;
            height: 100px;
            display: flex;
            .uploading-left {
                width: 100px;
                height: 100px;
                background-color: #f5f5f5;
                position: relative;
                i {
                    position: absolute;
                    top: 50%;
                    margin-top: -40%;
                    left: 50%;
                    margin-left: -40%;
                }
            }
            .uploading-right {
                padding-left: 30px;
                line-height: 10px;
                .right-one {
                    font-weight: 600;
                    color: #666666;
                }
                .right-two {
                    color: #9999a6;
                }
                .right-three {
                    color: #0079fe;
                }
            }
        }
        .card {
            display: flex;
        }
        // 上传身份证
        .uploading-per {
            width: 100%;
            border: 1px solid #f5f5f5;
            border-right: none;
            margin-bottom: 15px;
            display: flex;
            height: 100px;
            .uploading-left {
                width: 100px;
                height: 100px;
                background-color: #f5f5f5;
                position: relative;
                i {
                    position: absolute;
                    top: 50%;
                    margin-top: -25%;
                    left: 50%;
                    margin-left: -40%;
                }
            }
            .uploading-right {
                padding-left: 15px;
                line-height: 10px;
                .right-one {
                    font-weight: 600;
                    color: #666666;
                }
                .right-two {
                    color: #9999a6;
                }
                .right-three {
                    color: #0079fe;
                }
            }
        }
        span {
            font-size: 12px;
            color: #999999;
            line-height: 25px;
        }
        .personal {
            cursor: pointer;
            display: inline-block;
            margin-left: 35px;
            text-align: center;
            width: 60px;
            height: 30px;
        }
        .personal.c {
            width: 60px;
            height: 30px;
            background-color: #1890ff;
            color: #fff;
            text-align: center;
            border-radius: 5px;
            cursor: pointer;
            margin-left: 35px;
            padding-bottom: 10px;
        }
        .personalInfo {
            width: 60px;
            height: 30px;
            background-color: #1890ff;
            color: #fff;
            text-align: center;
            border-radius: 5px;
            cursor: pointer;
            margin-left: 123px;
            padding-bottom: 10px;
        }
    }
    .product-all {
        margin-top: 30px;
        .el-form {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .sign-input {
            padding-left: 1%;

            .sign-el-input {
                width: 220px;
            }
        }
    }
    // 底部
    .add-bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        margin-top: 60px;
    }
    .backstyle {
        margin-bottom: 30px;
    }
    .add-product {
        margin-top: 80px;
    }
    // 上传职业营照
    .uploading-camp {
        margin-left: 15px;
        margin-right: 60px;
        width: 80%;
        border: 1px solid #f5f5f5;
        border-right: none;
        margin-bottom: 15px;
        height: 100px;
        margin: 0 auto;
        display: flex;
        .uploading-left {
            width: 100px;
            height: 100px;
            background-color: #f5f5f5;
            position: relative;
            i {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -40%;
            }
        }
        .uploading-right {
            padding-left: 30px;
            line-height: 10px;
            .right-one {
                font-weight: 600;
                color: #666666;
            }
            .right-two {
                color: #9999a6;
                font-size: 12px;
            }
        }
    }
    // 上传身份证
    .uploading-per1 {
        width: 1200px;
        border: 1px solid #f5f5f5;
        border-right: none;
        margin-bottom: 15px;
        display: flex;
        height: 100px;
        margin-left: -160px;
        .uploading-left {
            width: 100px;
            height: 100px;
            background-color: #f5f5f5;
            position: relative;
            i {
                position: absolute;
                top: 50%;
                margin-top: -25%;
                left: 50%;
                margin-left: -40%;
            }
        }
        .uploading-right {
            padding-left: 15px;
            line-height: 10px;
            .right-one {
                font-weight: 600;
                color: #666666;
            }
            .right-two {
                color: #9999a6;
            }
            .right-three {
                color: #0079fe;
            }
        }
    }
}
</style>
<style>
body .el-table th.gutter {
    display: table-cell !important;
}

body .el-table colgroup.gutter {
    display: table-cell !important;
}
::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #c0c4cc;
}
:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #c0c4cc;
}
.avatar-uploader .el-upload {
    width: 100%;
    height: 100px;
    margin-right: -100px;
}
.el-table th.is-leaf,
.el-table th {
    background-color: #f5f5f5;
    border-bottom: none;
}
/* .main .el-tabs__nav-scroll {
    padding-left: 34px !important;
} */
.main .el-tabs__header {
    margin: 0px !important;
}
.agentManage .el-table__fixed-right {
    right: 0 !important;
    border: none;
}
.agentManage .el-.el-upload-list__item-name-right {
    width: 400px !important;
}
</style>

<template>
    <div class="agentManage">
        <!-- 表单数据展示 -->
        <div class="box"
             v-if="addAgentStatus == 1">
            <el-form label-width="120px">
                <el-row>
                    <el-col :lg="18"
                            :md="20">
                        <el-col :lg="8"
                                :md="12">
                            <el-form-item label="渠道商名称：">
                                <el-input size="small"
                                          v-model="reFormInfo.agentName"
                                          placeholder="渠道商名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <!-- <el-col :lg="8"
                                :md="12">
                            <el-form-item label="组织信用代码：">
                                <el-input size="small"
                                          placeholder="请输入组织信用代码"
                                          v-model="reFormInfo.creditCode"></el-input>
                            </el-form-item>
                        </el-col> -->
                        <el-col :lg="8"
                                :md="12">
                            <el-form-item label="手机号：">
                                <el-input size="small"
                                          v-model="reFormInfo.ucMobile"
                                          placeholder="请输入手机号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :lg="8"
                                :md="12">
                            <el-form-item label="服务商名称：">
                                <el-select v-model="reFormInfo.serviceId"
                                           filterable
                                           placeholder="请输入服务商名称"
                                           :loading="loading"
                                           style="width: 100%"
                                           size="small">
                                    <el-option v-for="item in serviceList"
                                               :key="item.serviceId"
                                               :label="item.serviceName"
                                               :value="item.serviceId">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :lg="6"
                            :md="8">
                        <el-form-item label=" ">
                            <el-button type="primary"
                                       size="small"
                                       icon="el-icon-search"
                                       @click="search()">查询</el-button>
                            <el-button size="small"
                                       icon='el-icon-refresh-left'
                                       class="chongzhi"
                                       @click="reset()">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="add-agent"
                 @click="addAgent(0)">
                <el-button type="primary"
                           icon='el-icon-plus'
                           size="small">新增渠道商</el-button>
            </div>
            <div class="wrap">
                <el-table :data="tableData"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                          border
                          :render-header="renderHeader"
                          fit
                          style="width: 100%">
                    <el-table-column prop="createdTime"
                                     show-overflow-tooltip
                                     label="创建时间"
                                     width="170px">
                    </el-table-column>
                    <el-table-column prop="agentName"
                                     show-overflow-tooltip
                                     label="渠道商名称"
                                     min-width="180">
                    </el-table-column>
                    <el-table-column prop="serviceName"
                                     show-overflow-tooltip
                                     label="服务商名称"
                                     min-width="180">
                    </el-table-column>
                    <el-table-column prop="ucMobile"
                                     show-overflow-tooltip
                                     label="手机号"
                                     min-width="150">
                    </el-table-column>
                    <el-table-column prop="email"
                                     show-overflow-tooltip
                                     label="邮箱"
                                     min-width="200">
                    </el-table-column>
                    <el-table-column prop="isEnabled"
                                     label="认证状态"
                                     width="100px"
                                     :show-overflow-tooltip="true">
                        <template slot-scope="{ row }">
                            <div>
                                <el-tag :type="(row.isEnabled) | waitFlagTag">
                                    {{ row.isEnabled|isEnabledInfo }}
                                </el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作"
                                     fixed="right">
                        <template slot-scope="scope">
                            <el-dropdown>
                                <span class="el-dropdown-link"
                                      style="cursor: pointer">
                                    更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <!-- <el-dropdown-item>
                                        <el-button @click="addAgent(1, scope.row)"
                                                   size="mini"
                                                   class="my-2 w_fluid"
                                                   plain
                                                   v-if="scope.row.isshow == 1">
                                            编辑
                                        </el-button>
                                    </el-dropdown-item> -->
                                    <el-dropdown-item>
                                        <el-button @click="addAgent(2, scope.row)"
                                                   size="mini"
                                                   class="my-2 w_fluid"
                                                   plain
                                                   v-if="scope.row.isshow == 1">
                                            查看
                                        </el-button>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <el-button @click="product(scope.row)"
                                                   size="mini"
                                                   class="my-2 w_fluid"
                                                   plain>
                                            产品
                                        </el-button>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wraped">
                    <pagination :total="total"
                                :currentPage="currentPage"
                                @handleSizeChange="handleSizeChange"
                                @handleCurrentChange="handleCurrentChange"></pagination>
                </div>
            </div>
        </div>
        <!-- 企业和个人列表 -->
        <div class="addAgent"
             v-if="addAgentStatus == 2">
            <div class="back"
                 @click="back()">
                <i class="iconfont iconfanhui"></i>
                返回
            </div>
            <span class="title"> <span>|</span> {{ titleInfo }}</span>
            <!-- 表单 -->
            <div class="add-form">
                <el-form style="margin-left: 10px">
                    <el-form-item label="渠道商类型：">
                        <div v-if="showInfo == true"
                             class="personal"
                             @click="show(1)"
                             :class="{ c: totalInfo == 1 }">
                            企业
                        </div>
                        <div v-if="shows == true"
                             class="personal"
                             @click="show(2)"
                             :class="{ c: totalInfo == 2 }">
                            个人
                        </div>
                        <div v-if="hide == true"
                             class="personalInfo">企业</div>
                        <div v-if="hideInfo == true"
                             class="personalInfo">个人</div>
                    </el-form-item>
                </el-form>
                <el-form ref="form"
                         :model="form"
                         :rules="rules"
                         label-width="135px">
                    <!-- 企业 -->
                    <div v-if="totalInfo == 1">
                        <el-form-item prop="businessLincenseImg">
                            <el-upload class="avatar-uploader"
                                       :action="uploadUrled"
                                       :headers="myHeaders"
                                       :show-file-list="false"
                                       :on-success="handleAvatarSuccessed"
                                       :before-upload="beforeUploaded">
                                <div class="uploading-camp">
                                    <div class="uploading-left">
                                        <img v-if="form.businessLincenseImg"
                                             :src="form.businessLincenseImg"
                                             width="100px"
                                             height="100px" />

                                        <i v-else
                                           style="font-size: 80px; color: #cccccc; margin-top: -25%"
                                           class="iconfont iconyunshangchuan"></i>
                                    </div>
                                    <div class="uploading-right">
                                        <p class="right-one">上传营业执照</p>
                                        <p class="right-two">文件上传不得大于10M</p>
                                        <p class="right-three">上传文件</p>
                                    </div>
                                </div>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="渠道商公司名称："
                                      prop="agentName">
                            <input type="text"
                                   name=""
                                   id=""
                                   v-model="form.agentName"
                                   placeholder="请填写渠道商公司名称"
                                   class="proto" />
                        </el-form-item>
                        <el-form-item label="组织信用代码："
                                      prop="creditCode">
                            <input type="text"
                                   name=""
                                   id=""
                                   v-model="form.creditCode"
                                   placeholder="请填写渠道商组织信用代码"
                                   class="proto" />
                        </el-form-item>
                        <el-form-item label="联系人："
                                      prop="userName">
                            <el-input :disabled="diasabledInput"
                                      size="small"
                                      v-model="form.userName"
                                      placeholder="请输入联系人"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话："
                                      prop="ucMobile">
                            <el-input :disabled="diasabledInput"
                                      size="small"
                                      v-model="form.ucMobile"
                                      placeholder="请输入联系电话"></el-input>
                        </el-form-item>
                        <el-form-item label="电子邮箱："
                                      prop="email">
                            <el-input size="small"
                                      v-model="form.email"
                                      placeholder="请输入渠道商电子邮箱"></el-input>
                        </el-form-item>
                        <el-form-item label="联系地址："
                                      prop="address">
                            <el-input size="small"
                                      v-model="form.address"
                                      placeholder="请输入联系地址"></el-input>
                        </el-form-item>
                        <el-form-item label="服务商"
                                      prop="serviceId">
                            <el-select v-model="form.serviceId"
                                       @change="onSelectedDrug($event)"
                                       placeholder="请选择服务商"
                                       size="small"
                                       :disabled="diasabledInput">
                                <el-option v-for="(item,index) in serviceList"
                                           :key="index"
                                           :label="item.serviceName"
                                           :value="item.serviceId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="开户行："
                                      prop="accountBankName">
                            <el-input :disabled="diasabledInput"
                                      size="small"
                                      v-model="form.accountBankName"
                                      placeholder="请输入开户行"></el-input>
                        </el-form-item>
                        <el-form-item label="收款账户："
                                      prop="accountName">
                            <el-input :disabled="diasabledInput"
                                      size="small"
                                      v-model="form.accountName"
                                      placeholder="请输入收款账户"></el-input>
                        </el-form-item>
                        <el-form-item label="收款账号："
                                      prop="accountNo">
                            <el-input :disabled="diasabledInput"
                                      size="small"
                                      v-model="form.accountNo"
                                      placeholder="请输入收款账号"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="checkval == '1'"
                                       type="primary"
                                       @click="onSubmit('form')">确认提交</el-button>
                            <el-button @click="back()">取消</el-button>
                        </el-form-item>
                    </div>
                </el-form>
                <el-form ref="formPersonal"
                         :model="formPersonal"
                         :rules="rulesPersonal"
                         label-width="135px"
                         style="width: 115%">
                    <!-- 个人 -->
                    <div v-if="totalInfo == 2">
                        <el-form-item label="渠道商名称："
                                      prop="agentName">
                            <el-input v-model="formPersonal.agentName"
                                      placeholder="请填写渠道商名称"></el-input>
                        </el-form-item>
                        <div class="card">
                            <el-form-item prop="idcardFront">
                                <el-upload :disabled="uploadInfo"
                                           :action="uploadUrlCard"
                                           :headers="myHeaders"
                                           :on-success="handleAvatarSuccessPersonal"
                                           :before-upload="beforeUploadPersonal"
                                           multiple
                                           :limit="1"
                                           :on-remove="handleRemove"
                                           :before-remove="beforeRemove"
                                           style="margin-right: -100px">
                                    <div class="uploading-per">
                                        <div class="uploading-left">
                                            <img v-if="formPersonal.idcardFront"
                                                 class="el-upload-list__item-thumbnail"
                                                 :src="formPersonal.idcardFront"
                                                 width="100px"
                                                 height="100px"
                                                 alt="" />
                                            <i v-else
                                               class="iconfont iconyunshangchuan"
                                               style="font-size: 80px; color: #cccccc"></i>
                                        </div>
                                        <div class="uploading-right">
                                            <p class="right-one">上传身份证正面</p>
                                            <p class="right-two">文件不得大于10M</p>
                                            <p class="right-three">上传文件</p>
                                        </div>
                                    </div>
                                </el-upload>
                                <span style="color: #f56c6c"
                                      v-show="isShow">请上传身份证正面</span>
                            </el-form-item>
                            <el-form-item prop="idcardBlack">
                                <el-upload :disabled="uploadInfo"
                                           :action="uploadUrl"
                                           :headers="myHeaders"
                                           :on-success="handleAvatarSuccessPersonalback"
                                           :before-upload="beforeUploadPersonalback"
                                           multiple
                                           :limit="1"
                                           :on-remove="handleRemoveInfo"
                                           :before-remove="beforeRemoveInfo">
                                    <div class="uploading-per backInfo">
                                        <div class="uploading-left">
                                            <img v-if="formPersonal.idcardBlack"
                                                 class="el-upload-list__item-thumbnail"
                                                 :src="formPersonal.idcardBlack"
                                                 width="100px"
                                                 height="100px"
                                                 alt="" />
                                            <i v-else
                                               class="iconfont iconyunshangchuan"
                                               style="font-size: 80px; color: #cccccc"></i>
                                        </div>
                                        <div class="uploading-right">
                                            <p class="right-one">上传身份证国徽面</p>
                                            <p class="right-two">文件不得大于10M</p>
                                            <p class="right-three">上传文件</p>
                                        </div>
                                    </div>
                                </el-upload>
                                <span style="color: #f56c6c"
                                      v-show="isShowInfo">请上传身份证国徽面</span>
                            </el-form-item>
                        </div>
                        <el-form-item label="身份证号码："
                                      prop="idCard">
                            <el-input v-model="formPersonal.idCard"
                                      :disabled="diasabledInput"
                                      placeholder="请填写渠道商身份证号码"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人："
                                      prop="userName">
                            <el-input v-model="formPersonal.userName"
                                      :disabled="diasabledInput"
                                      placeholder="请输入联系人"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话："
                                      prop="ucMobile">
                            <el-input v-model="formPersonal.ucMobile"
                                      :disabled="diasabledInput"
                                      placeholder="请输入联系电话"></el-input>
                        </el-form-item>
                        <el-form-item label="电子邮箱：" prop="email">
                            <el-input v-model="formPersonal.email"
                                      placeholder="请输入渠道商电子邮箱"></el-input>
                        </el-form-item>
                        <el-form-item label="联系地址：">
                            <el-input v-model="formPersonal.address"
                                      placeholder="请输入联系地址"></el-input>
                        </el-form-item>
                        <el-form-item label="服务商"
                                      prop="serviceId">
                            <el-select v-model="formPersonal.serviceId"
                                       @change="onSelectedDrug($event)"
                                       placeholder="请选择服务商"
                                       size="small"
                                       :disabled="uploadInfo">
                                <el-option v-for="(item,index) in serviceList"
                                           :key="index"
                                           :label="item.serviceName"
                                           :value="item.serviceId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="开户行："
                                      prop="accountBankName">
                            <el-input :disabled="uploadInfo"
                                      size="small"
                                      v-model="formPersonal.accountBankName"
                                      placeholder="请输入开户行"></el-input>
                        </el-form-item>
                        <el-form-item label="收款账户："
                                      prop="accountName">
                            <el-input :disabled="uploadInfo"
                                      size="small"
                                      v-model="formPersonal.accountName"
                                      placeholder="请输入收款账户"></el-input>
                        </el-form-item>
                        <el-form-item label="收款账号："
                                      prop="accountNo">
                            <el-input :disabled="uploadInfo"
                                      size="small"
                                      v-model="formPersonal.accountNo"
                                      placeholder="请输入收款账号"></el-input>
                        </el-form-item>
                        <el-form-item label="联行号："
                                      prop="accountBankNo">
                            <el-input :disabled="uploadInfo"
                                      size="small"
                                      v-model="formPersonal.accountBankNo"
                                      placeholder="请输入联行号"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="checkval == '1'"
                                       type="primary"
                                       @click="onSubmit('formPersonal')">确认提交</el-button>
                            <el-button @click="back()">取消</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
// 引入组件
import pagination from '../../components/pagination.vue';
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
// 分转元  元转分组件
import { f2y, y2f } from '../../util/util.js';

export default {
    filters: {
        waitFlagTag(code) {
            const statusMap = {
                1: 'success',
                0: 'danger'
            };
            return statusMap[code] || 'info';
        }
    },
    data() {
        return {
            serviceIdNew: '',
            serviceList: [],
            // 服务商模糊搜索
            qiyeRenOptions: [],
            statesRen: [],
            loading: false,
            isShow: false, //判断身份证有没有上传的提示状态
            isShowInfo: false,
            disableedInfo: '', // 产品无法编辑（禁用）
            agentId: '', // 渠道商id
            uploadInfo: false, // 渠道无法编辑（禁用）
            diasabledInput: false, // 可读可写
            hide: true, //个人企业切换
            hideInfo: true,
            shows: true,
            showInfo: true,
            data: '', // 根据id获取渠道商
            productList: [], // 产品列表
            uploadUrlCard: api.idCardOcr,
            uploadUrl: api.uploadOss, // 上传图片
            uploadUrled: api.businessLicense, // 上传营业执照
            myHeaders: { Authorization: this.$cookies.get('esignmerchantsid') },
            // 企业
            form: {
                agentName: '',
                creditCode: '',
                userName: '',
                ucMobile: '',
                email: '',
                address: '',
                businessLincenseImg: '',
                accountBankName: '',
                accountName: '',
                accountNo: '',
                serviceId: '',
                serviceName: ''
                // accountBankNo: "",
            },
            rules: {
                // businessLincenseImg: [
                //     { required: true, message: '请上传企业营业执照图', trigger: 'blur' }
                // ],
                agentName: [{ required: true, message: '请输入渠道商名称', trigger: 'blur' }],
                creditCode: [{ required: true, message: '请输入组织信用代码', trigger: 'blur' }],
                userName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                ucMobile: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
                accountBankName: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
                accountName: [{ required: true, message: '请输入收款账户', trigger: 'blur' }],
                accountNo: [{ required: true, message: '请输入收款账号', trigger: 'blur' }],
                serviceId: [{ required: true, message: '请选择服务商', trigger: 'blur' }],
                email: [{ required: true, message: '请输入电子邮箱', trigger: 'blur' }],
            },
            // 个人
            formPersonal: {
                agentName: '',
                idcardFront: '',
                idcardBlack: '',
                idCard: '',
                userName: '',
                ucMobile: '',
                address: '',
                accountBankName: '',
                accountName: '',
                accountNo: '',
                accountBankNo: '',
                serviceId: '',
                serviceName: ''
            },
            rulesPersonal: {
                agentName: [{ required: true, message: '请输入渠道商名称', trigger: 'blur' }],
                idCard: [{ required: true, message: '请输入渠道商身份证', trigger: 'blur' }],
                userName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                ucMobile: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
                accountBankName: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
                accountName: [{ required: true, message: '请输入收款账户', trigger: 'blur' }],
                accountNo: [{ required: true, message: '请输入收款账号', trigger: 'blur' }],
                email: [{ required: true, message: '请输入电子邮箱', trigger: 'blur' }],
                serviceId: [{ required: true, message: '请选择服务商', trigger: 'blur' }],
            },
            checkval: '', //提交按钮的显示隐藏
            // 标题
            titleInfo: '',
            // 渠道列表检索条件
            reFormInfo: {
                agentName: '',
                // creditCode: '',
                id: '',
                ucMobile: '',
                serviceId: ''
            },
            pageSzie: 10,
            // 首页分页
            total: 0,
            currentPage: 1,
            // 产品列表分页
            totalProduct: 0,
            currentPageProduct: 1,
            options: [],
            tableData: [],
            // 企业个人
            totalInfo: 1,
            // 新增渠道商状态
            addAgentStatus: 1,
            // 判断几级（显示隐藏按钮）
            agentGradeInfo: ''
        };
    },

    mounted() {
        this.serviceInfo(); //服务商
    },
    created() {},
    methods: {
        onSelectedDrug(e) {
            let obj = {};
            obj = this.serviceList.find(item => {
                //这里的userList就是上面遍历的数据源
                return item.serviceId === e; //筛选出匹配数据
            });
            this.form.serviceName = obj.serviceName;
            this.formPersonal.serviceName = obj.serviceName;
        },
        serviceInfo() {
            post(api.queryServiceList).then(res => {
                if (res.code == 0) {
                    if (res.data != '') {
                        this.reFormInfo.serviceId = res.data[0].serviceId;
                    }
                    this.serviceList = res.data;
                    this.queryPageData(); //查看渠道商列表
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 表格
        renderHeader(h, data) {
            return h('span', [
                h('el-tooltip'),
                {
                    attrs: {
                        class: 'item',
                        effect: 'dark',
                        content: data.column.label
                    }
                },
                [h('span', data.column.label)]
            ]);
        },
        // 上传职业营照
        beforeUploaded(file) {},
        handleAvatarSuccessed(res, file, files, key, val) {
            this.$forceUpdate();
            if (res.code == 0) {
                this.$message.warning(res.data.msg);
                this.file = files;
                this.form.businessLincenseImg = res.data.uploadUrl;
                this.form['agentName'] = res.data.companyName;
                this.form['creditCode'] = res.data.socialCreditCode;
                this.form['legalPersonName'] = res.data.legalPerson;
            } else {
                this.$message.error(res.data.msg);
            }
        },
        // 上传正面身份证
        beforeUploadPersonal() {},
        handleAvatarSuccessPersonal(res, file, files, key, val) {
            if (res.code == 0) {
                this.formPersonal.idcardFront = res.data.uploadUrl;
                this.formPersonal['idCard'] = res.data.front.idNo;
                this.formPersonal['userName'] = res.data.front.name;
                this.$forceUpdate();
            }
        },
        // 删除图片正面
        handleRemove(file, fileList) {
            this.formPersonal.idcardFront = '';
            this.$forceUpdate();
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        // 删除图片反面
        handleRemoveInfo(file, fileList) {
            this.formPersonal.idcardBlack = '';
            this.$forceUpdate();
        },
        beforeRemoveInfo(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        // 上传反面身份证
        beforeUploadPersonalback() {},
        handleAvatarSuccessPersonalback(res, file, files, key, val) {
            if (res.code == 0) {
                this.formPersonal.idcardBlack = res.data;
                this.$forceUpdate();
            }
        },
        // 上传合同
        beforeUploadContract() {},
        handleAvatarSuccessContract(res, file, files, key, val) {
            if (res.code == 0) {
                this.releaseForm.businessLincenseImg = res.data;
                this.$forceUpdate();
            }
        },
        // 查询渠道商列表
        queryPageData(size) {
            if (true) {
                const loading = this.$loading({
                    lock: true,
                    spinner: 'loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                post(api.queryAgentPage, {
                    currentPage: size || 1,
                    pageSize: this.pageSzie,
                    agentName: this.reFormInfo.agentName,
                    // creditCode: this.reFormInfo.creditCode,
                    ucMobile: this.reFormInfo.ucMobile,
                    id: this.reFormInfo.id,
                    serviceId: this.reFormInfo.serviceId
                }).then(
                    d => {
                        loading.close();
                        if (d.code === 0) {
                            this.total = d.data.count;
                            this.tableData = d.data.rows;
                            this.currentPage = d.data.currentPage;
                        } else {
                        }
                    },
                    err => {}
                );
            } else {
                //字符
                this.total = 0;
                this.currentPage = 1;
                this.pageSzie = 10;
                this.tableData = [];
            }
        },
        // 列表搜索
        search() {
            this.queryPageData();
        },
        // 列表重置
        reset() {
            this.reFormInfo = {
                agentName: '',
                // creditCode: '',
                id: '',
                ucMobile: '',
                serviceId: this.reFormInfo.serviceId
            };
            this.queryPageData();
        },
        // 添加数据编辑数据和查看
        onSubmit(formName) {
            if (this.titleInfo == '新增渠道商') {
                // 企业
                if (this.totalInfo == 1) {
                    this.$refs[formName].validate(valid => {
                        if (valid) {
                            post(api.createAgentForOrganization, {
                                businessLincenseImg: this.form.businessLincenseImg || '',
                                agentName: this.form.agentName,
                                creditCode: this.form.creditCode,
                                email: this.form.email,
                                ucMobile: this.form.ucMobile,
                                userName: this.form.userName,
                                address: this.form.address,
                                accountBankName: this.form.accountBankName,
                                accountName: this.form.accountName,
                                accountNo: this.form.accountNo,
                                serviceId: this.form.serviceId,
                                serviceName: this.form.serviceName,
                                source: 2
                            }).then(d => {
                                if (d.code == 0) {
                                    this.$message.success(d.message);
                                    this.queryPageData();
                                    this.addAgentStatus = 1;
                                    this.form = [];
                                } else {
                                    this.$message.error(d.message);
                                }
                            });
                        }
                    });
                } else {
                    // 个人
                    this.$refs[formName].validate(valid => {
                        if (valid) {
                            // if (!this.formPersonal.idcardFront) {
                            //     this.isShow = true;
                            //     return;
                            // } else {
                            //     this.isShow = false;
                            // }
                            // if (!this.formPersonal.idcardBlack) {
                            //     this.isShowInfo = true;
                            //     return;
                            // } else {
                            //     this.isShowInfo = false;
                            // }
                            post(api.createAgentForPersonal, {
                                agentName: this.formPersonal.agentName,
                                email: this.formPersonal.email,
                                idCard: this.formPersonal.idCard,
                                idcardBlack: this.formPersonal.idcardBlack,
                                idcardFront: this.formPersonal.idcardFront,
                                accountBankName: this.formPersonal.accountBankName,
                                accountName: this.formPersonal.accountName,
                                accountNo: this.formPersonal.accountNo,
                                accountBankNo: this.formPersonal.accountBankNo,
                                source: 2,
                                ucMobile: this.formPersonal.ucMobile,
                                userName: this.formPersonal.userName,
                                address: this.formPersonal.address,
                                serviceId: this.formPersonal.serviceId,
                                serviceName: this.formPersonal.serviceName
                            }).then(d => {
                                if (d.code == 0) {
                                    this.$message.success(d.message);
                                    this.queryPageData();
                                    this.addAgentStatus = 1;
                                    this.formPersonal = {
                                        agentName: '',
                                        idcardFront: '',
                                        idcardBlack: '',
                                        idCard: '',
                                        userName: '',
                                        ucMobile: '',
                                        address: '',
                                        accountBankName: '',
                                        accountName: '',
                                        accountNo: '',
                                        accountBankNo: '',
                                        serviceId: '',
                                        serviceName: ''
                                    };
                                } else {
                                    this.$message.error(d.message);
                                }
                            });
                        }
                    });
                }
            } else if (this.titleInfo == '编辑渠道商') {
                if (this.totalInfo == 1) {
                    post(api.updateAgent, this.form).then(d => {
                        if (d.code == 0) {
                            this.$message.success(d.message);
                            this.addAgentStatus = 1;
                            this.queryPageData();
                        } else {
                            this.$message.error(d.message);
                        }
                    });
                } else {
                    post(api.updateAgent, this.formPersonal).then(d => {
                        if (d.code == 0) {
                            this.$message.success(d.message);
                            this.addAgentStatus = 1;
                            this.queryPageData();
                        } else {
                            this.$message.error(d.message);
                        }
                    });
                }
            } else {
            }
        },
        // 产品列表
        product(row, size) {
            this.$router.push({
                path: '/agentManageProduct',
                query: { agentId: row.id, agentGradeInfo: row.isshow, serviceIdNew: row.serviceId }
            });
        },
        productPage(size) {
            if (true) {
                const loading = this.$loading({
                    lock: true,
                    spinner: 'loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                post(api.queryAgentProduct, {
                    currentPage: size || 1,
                    pageSize: this.pageSzie,
                    agentId: this.agentId,
                    serviceId: this.serviceIdNew
                }).then(
                    d => {
                        loading.close();
                        if (d.code === 0) {
                            this.totalProduct = d.data.count;
                            this.currentPageProduct = d.data.currentPage;
                            this.productList = d.data.rows;
                        } else {
                        }
                    },
                    err => {}
                );
            } else {
                //字符
                this.totalProduct = 0;
                this.currentPageProduct = 1;
                this.pageSzie = 10;
                this.productList = [];
            }
        },
        // 增加梯度
        addInput() {
            var that = this;
            that.releaseForm.profitFeeObjList.push({
                max: '',
                min: '',
                profitFee: ''
            });
        },
        // 删除梯度
        delInput(item) {
            if (this.releaseForm.profitFeeObjList.length > 1) {
                this.releaseForm.profitFeeObjList.some((item1, i) => {
                    if (item1 == item) {
                        this.releaseForm.profitFeeObjList.splice(i, 1);
                    }
                });
            }
        },
        // 增加分润梯度
        addInput1() {
            var that = this;
            that.releaseForm.rateFeeObjList.push({
                max: '',
                min: '',
                rateFee: ''
            });
        },
        // 删除分润梯度
        delInput1(item) {
            if (this.releaseForm.rateFeeObjList.length > 1) {
                this.releaseForm.rateFeeObjList.some((item1, i) => {
                    if (item1 == item) {
                        this.releaseForm.rateFeeObjList.splice(i, 1);
                    }
                });
            }
        },
        // 产品取消
        productcancel() {
            this.addAgentStatus = 4;
        },
        // 新增产品提交
        ductSubmit(formName) {
            if (this.titleInfo == '新增产品') {
                this.disableedInfo = false;
                this.addAgentStatus = 3;
                this.releaseForm.agentId = this.agentId;
                this.releaseForm.serviceId = this.serviceIdNew;
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        // 增加产品处理金额
                        const payLoad = JSON.parse(JSON.stringify(this.releaseForm));
                        var arrAdd = [];
                        payLoad.profitFeeObjList.map(item => {
                            arrAdd.push({
                                max: y2f(item.max),
                                min: y2f(item.min),
                                profitFee: Number(item.profitFee)
                            });
                        });
                        var arrAdd1 = [];
                        payLoad.rateFeeObjList.map(item => {
                            arrAdd1.push({
                                max: y2f(item.max),
                                min: y2f(item.min),
                                rateFee: Number(item.rateFee)
                            });
                        });
                        payLoad.rateFeeObjList = arrAdd1;
                        payLoad.profitFeeObjList = arrAdd;
                        payLoad.rewardAmt = y2f(this.releaseForm.rewardAmt);
                        post(api.createProduct, payLoad).then(d => {
                            if (d.code == 0) {
                                this.$message.success(d.message);
                                this.addAgentStatus = 4;
                                this.productPage();
                                this.releaseForm = [];
                            } else {
                                this.$message.error(d.message);
                            }
                        });
                    }
                });
            } else if (this.titleInfo == '编辑产品') {
                this.disableedInfo = true;
                this.releaseForm.agentId = this.agentId;
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        // 编辑产品处理金额
                        const payLoad = JSON.parse(JSON.stringify(this.releaseForm));
                        var arrEdit = [];
                        payLoad.profitFeeObjList.map(item => {
                            arrEdit.push({
                                max: y2f(item.max),
                                min: y2f(item.min),
                                profitFee: Number(item.profitFee)
                            });
                        });
                        var arrEdit1 = [];
                        payLoad.rateFeeObjList.map(item => {
                            arrEdit1.push({
                                max: y2f(item.max),
                                min: y2f(item.min),
                                rateFee: Number(item.rateFee)
                            });
                        });
                        payLoad.rateFeeObjList = arrEdit1;
                        payLoad.profitFeeObjList = arrEdit;
                        payLoad.rewardAmt = y2f(this.releaseForm.rewardAmt);
                        post(api.updateProduct, payLoad).then(d => {
                            if (d.code == 0) {
                                this.$message.success(d.message);
                                this.addAgentStatus = 4;
                                this.productPage();
                            } else {
                                this.$message.error(d.message);
                            }
                        });
                    }
                });
            } else {
            }
        },
        // 产品添加数据查看编辑数据
        addproductInfo(type, row) {
            // 产品名称数据下拉
            this.producted();
            if (type == 0) {
                this.addAgentStatus = 3;
                this.titleInfo = '新增产品';
                this.disableedInfo = false;
                this.releaseForm = {
                    businessLincenseImg: '',
                    agentId: '', // 渠道商id
                    productId: '', // 发票类型
                    profitFeeObjList: [
                        // 分润梯度费率
                        {
                            max: '', // 最大值
                            min: '', // 最小值
                            profitFee: '' // 梯度费率
                        }
                    ],
                    rateFeeObjList: [
                        // 分润梯度费率
                        {
                            max: '', // 最大值
                            min: '', // 最小值
                            rateFee: '' // 梯度费率
                        }
                    ],
                    rateFee: '', // 服务费税率
                    rewardAmt: '', // 奖励限制金额
                    signTime: '', // 签约完成时间
                    openTime: '', // 签约生效时间
                    rewardAmtPer: '', // 奖励限制金额百分比
                    remark: '' // 备注
                };
            } else if (type == 1) {
                this.addAgentStatus = 3;
                this.titleInfo = '编辑产品';
                this.disableedInfo = true;
                const loading = this.$loading({
                    lock: true,
                    spinner: 'loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                post(api.queryAgentProductInfo, { id: row.id }).then(d => {
                    if (d.code == 0) {
                        var arr = [];
                        d.data.profitFeeObjList.map(item => {
                            arr.push({
                                max: f2y(item.max),
                                min: f2y(item.min),
                                profitFee: Number(item.profitFee)
                            });
                        });
                        var arr1 = [];
                        d.data.rateFeeObjList.map(item => {
                            arr1.push({
                                max: f2y(item.max),
                                min: f2y(item.min),
                                rateFee: Number(item.rateFee)
                            });
                        });
                        this.releaseForm = {
                            businessLincenseImg: d.data.businessLincenseImg, // 合同地址
                            id: d.data.id,
                            agentId: d.data.agentId, // 渠道商id
                            productName: d.data.productName, // 发票类型
                            profitFeeObjList: arr, // 分润梯度费率
                            rateFeeObjList: arr1,
                            rateFee: d.data.rateFee, // 服务费税率
                            rewardAmt: f2y(d.data.rewardAmt), // 奖励限制金额
                            signTime: d.data.signTime, // 签约完成时间
                            openTime: d.data.openTime, // 签约生效时间
                            rewardAmtPer: d.data.rewardAmtPer, // 奖励限制金额百分比
                            remark: d.data.remark // 备注
                        };
                        loading.close();
                    } else {
                        this.$message.error(d.messgae);
                    }
                });
            } else {
                this.addAgentStatus = 3;
                this.titleInfo = '查看产品';
                this.disableedInfo = true;
                const loading = this.$loading({
                    lock: true,
                    spinner: 'loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                post(api.queryAgentProductInfo, { id: row.id }).then(d => {
                    if (d.code == 0) {
                        var arrInfo = [];
                        d.data.profitFeeObjList.map(item => {
                            arrInfo.push({
                                max: f2y(item.max),
                                min: f2y(item.min),
                                profitFee: Number(item.profitFee)
                            });
                        });
                        var arrInfo1 = [];
                        d.data.rateFeeObjList.map(item => {
                            arrInfo1.push({
                                max: f2y(item.max),
                                min: f2y(item.min),
                                rateFee: Number(item.rateFee)
                            });
                        });
                        this.releaseForm = {
                            businessLincenseImg: d.data.businessLincenseImg, // 合同地址
                            agentId: d.data.agentId, // 渠道商id
                            productName: d.data.productName, // 发票类型
                            profitFeeObjList: arrInfo, // 分润梯度费率
                            rateFeeObjList: arrInfo1, // 分润梯度费率
                            rateFee: d.data.rateFee, // 服务费税率
                            rewardAmt: f2y(d.data.rewardAmt), // 奖励限制金额
                            signTime: d.data.signTime, // 签约完成时间
                            openTime: d.data.openTime, // 签约生效时间
                            rewardAmtPer: d.data.rewardAmtPer, // 奖励限制金额百分比
                            remark: d.data.remark // 备注
                        };
                        loading.close();
                    } else {
                        this.$message.error(d.messgae);
                    }
                });
            }
        },
        // 新增产品返回
        ductBack() {
            this.addAgentStatus = 4;
            this.titleInfo = '产品列表';
        },
        // 企业和个人切换
        show(val) {
            this.totalInfo = val;
        },
        // 新增渠道商
        addAgent(type, row) {
            if (type == 0) {
                this.titleInfo = '新增渠道商';
                this.addAgentStatus = 2;
                this.checkval = 1;
                this.form = {};
                this.formPersonal = {};
                this.shows = true;
                this.showInfo = true;
                this.hide = false;
                this.hideInfo = false;
                this.diasabledInput = false;
                this.uploadInfo = false;
            } else if (type == 1) {
                if (row.agentType == 0) {
                    this.totalInfo == 1;
                    this.titleInfo = '编辑渠道商';
                    this.addAgentStatus = 2;
                    this.checkval = 1;
                    this.shows = false;
                    this.showInfo = false;
                    this.hide = true;
                    this.hideInfo = false;
                    this.diasabledInput = true;
                    this.uploadInfo = false;
                    const loading = this.$loading({
                        lock: true,
                        spinner: 'loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    post(api.queryAgentInfo, {
                        id: row.id,
                        agentServiceRelId: row.agentServiceRelId
                    }).then(d => {
                        if (d.code == 0) {
                            this.totalInfo = 1;
                            this.data = d.data;
                            this.form = {
                                businessLincenseImg: d.data.businessLincenseImg,
                                agentName: d.data.agentName,
                                creditCode: d.data.creditCode,
                                email: d.data.email,
                                ucMobile: d.data.ucMobile,
                                userName: d.data.userName,
                                address: d.data.address,
                                accountName: d.data.accountName,
                                accountNo: d.data.accountNo,
                                accountBankName: d.data.accountBankName,
                                serviceId: d.data.serviceId,
                                serviceName: d.data.serviceName,
                                source: 2,
                                id: d.data.id
                            };
                            loading.close();
                        } else {
                            this.$message.error(d.message);
                        }
                    });
                } else {
                    this.totalInfo = 2;
                    this.titleInfo = '编辑渠道商';
                    this.addAgentStatus = 2;
                    this.checkval = 1;
                    this.uploadInfo = true;
                    // 想办法吧企业隐藏
                    this.shows = false;
                    this.showInfo = false;
                    this.hide = false;
                    this.hideInfo = true;
                    this.diasabledInput = true;
                    const loading = this.$loading({
                        lock: true,
                        spinner: 'loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    post(api.queryAgentInfo, {
                        id: row.id,
                        agentServiceRelId: row.agentServiceRelId
                    }).then(d => {
                        if (d.code == 0) {
                            this.totalInfo = 2;
                            this.data = d.data;
                            this.formPersonal = {
                                agentName: d.data.agentName,
                                idcardFront: d.data.idcardFront,
                                idcardBlack: d.data.idcardBlack,
                                idCard: d.data.idCard,
                                userName: d.data.userName,
                                ucMobile: d.data.ucMobile,
                                address: d.data.address,
                                id: d.data.id,
                                email: d.data.email,
                                accountBankName: d.data.accountBankName,
                                accountName: d.data.accountName,
                                accountNo: d.data.accountNo,
                                accountBankNo: d.data.accountBankNo,
                                serviceId: d.data.serviceId,
                                serviceName: d.data.serviceName
                            };
                            loading.close();
                        } else {
                            this.$message.error(d.message);
                        }
                    });
                }
            } else {
                if (row.agentType == 0) {
                    this.totalInfo = 1;
                    this.titleInfo = '查看渠道商';
                    this.addAgentStatus = 2;
                    this.checkval = 2;
                    this.shows = false;
                    this.showInfo = false;
                    this.hide = true;
                    this.hideInfo = false;
                    this.diasabledInput = true;
                    this.uploadInfo = false;
                    post(api.queryAgentInfo, {
                        id: row.id,
                        agentServiceRelId: row.agentServiceRelId
                    }).then(d => {
                        if (d.code == 0) {
                            this.data = d.data;
                            this.form = {
                                businessLincenseImg: d.data.businessLincenseImg,
                                agentName: d.data.agentName,
                                creditCode: d.data.creditCode,
                                email: d.data.email,
                                ucMobile: d.data.ucMobile,
                                userName: d.data.userName,
                                address: d.data.address,
                                accountName: d.data.accountName,
                                accountNo: d.data.accountNo,
                                accountBankName: d.data.accountBankName,
                                serviceId: d.data.serviceId,
                                serviceName: d.data.serviceName,
                                source: 2,
                                id: d.data.id
                            };
                        } else {
                            this.$message.error(d.message);
                        }
                    });
                } else {
                    this.uploadInfo = true;
                    this.totalInfo = 2;
                    this.titleInfo = '查看渠道商';
                    this.addAgentStatus = 2;
                    this.checkval = 2;
                    // 想办法吧企业隐藏
                    this.hide = false;
                    this.hideInfo = true;
                    this.shows = false;
                    this.showInfo = false;
                    post(api.queryAgentInfo, {
                        id: row.id,
                        agentServiceRelId: row.agentServiceRelId
                    }).then(d => {
                        if (d.code == 0) {
                            this.data = d.data;
                            this.formPersonal = {
                                agentName: d.data.agentName,
                                idcardFront: d.data.idcardFront,
                                idcardBlack: d.data.idcardBlack,
                                idCard: d.data.idCard,
                                userName: d.data.userName,
                                ucMobile: d.data.ucMobile,
                                address: d.data.address,
                                id: d.data.id,
                                accountBankName: d.data.accountBankName,
                                accountName: d.data.accountName,
                                accountNo: d.data.accountNo,
                                accountBankNo: d.data.accountBankNo,
                                serviceId: d.data.serviceId,
                                serviceName: d.data.serviceName
                            };
                        } else {
                            this.$message.error(d.message);
                        }
                    });
                }
            }
        },
        // 返回上一页
        back() {
            this.addAgentStatus = 1;
            this.queryPageData();
        },
        // 列表分页
        handleSizeChange(val) {
            this.pageSzie = val;
            this.queryPageData();
        },
        handleCurrentChange(size) {
            this.queryPageData(size);
        }
    },
    // 引用分页组件
    components: {
        pagination: pagination
    }
};
</script>
