<style lang="less" scoped>
.agentuser {
    width: 100%;
    min-height: 100%;
    // padding: 12px;
    box-sizing: border-box;
    background-color: #fff;
    text-align: left;
    .box {
        width: 100%;
        padding-top: 12px;
        .toobar {
            .main_button {
                margin-left: 36px;
            }
        }
        .wrap {
            width: 100%;
            padding: 12px;
            box-sizing: border-box;
            .wraped {
                width: 100%;
            }
        }
    }
}
</style>
<style>
.subaccount .el-table__fixed-right {
    right: 0 !important;
}
</style>


<template>
    <div class="agentuser">
        <div class="box">
            <el-form ref="releaseForm"
                     :model="releaseForm"
                     label-width="120px">
                <el-row>
                    <el-col :lg="18"
                            :md="20">
                        <el-row>
                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="姓名：">
                                    <el-input v-model="releaseForm.name"
                                              size="small"
                                              placeholder="请选择姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="手机号：">
                                    <el-input v-model="releaseForm.mobile"
                                              size="small"
                                              placeholder="请选择手机号"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="状态：">
                                    <el-select clearable
                                               filterable
                                               v-model="releaseForm.isEnabled"
                                               style="width: 100%"
                                               placeholder="请选择状态"
                                               size="small">
                                        <el-option v-for="item in Statusoptions"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :md="6"
                            :sm="8">
                        <el-form-item label=" ">
                            <el-button type="primary"
                                       size="small"
                                       icon="el-icon-search"
                                       @click="search()">查询</el-button>
                            <el-button size="small"
                                       icon='el-icon-refresh-left'
                                       class="chongzhi"
                                       @click="reset()">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="toobar">
                <div class="main_button">
                    <el-button type="primary"
                               size="small"
                               @click="onClickRoleAdd()"><i class="el-icon-plus"></i>&nbsp;&nbsp;添加用户</el-button>
                    <!-- v-permission="'subaccount_account'" -->
                </div>
            </div>
            <div class="wrap">
                <el-table :data="tableData"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                          border
                          :render-header="renderHeader"
                          show-overflow-tooltip
                          fit
                          style="width: 100%">
                    <el-table-column show-overflow-tooltip
                                     fixed
                                     prop="userName"
                                     label="用户姓名"
                                     min-width="120">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="mobile"
                                     label="手机号"
                                     min-width="120">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="roleName"
                                     label="角色名称"
                                     min-width="120">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="isEnabled"
                                     width="100px"
                                     label="状态">
                        <template slot-scope="{ row }">
                            <div>
                                <el-tag :type="(row.isEnabled) | waitFlagTag">
                                    {{ row.isEnabled|workStatusForm }}
                                </el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="createTime"
                                     label="创建时间">
                    </af-table-column>
                    <el-table-column fixed="right"
                                     label="操作"
                                     min-width="150">
                        <template slot-scope="scope">
                            <el-button @click="chakanRow(scope.row)"
                                       type="text"
                                       size="small">
                                查看
                            </el-button>
                            <el-button @click="dakaiRow(scope.row)"
                                       type="text"
                                       size="small">
                                编辑
                            </el-button>
                            <!-- <el-button
                @click="dakaiRowed(scope.row)"
                type="text"
                size="small"
              >
                角色修改
              </el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wraped">
                    <pagination :total="total"
                                :currentPage="currentPage"
                                @handleSizeChange="handleSizeChange"
                                @handleCurrentChange="handleCurrentChange"></pagination>
                </div>
            </div>
        </div>
        <el-dialog :title="
        isCreateItem ? '添加用户' : checkval == '1' ? '编辑用户' : '查看用户'
      "
                   :visible.sync="dialogVisibled"
                   width="800px"
                   style="text-align: left"
                   :before-close="handleCloseed">
            <el-form ref="auditForm"
                     :model="auditForm"
                     :rules="auditRules"
                     label-width="150px"
                     class="demo-ruleForm">
                <!-- <el-form-item v-loading="onLoadingWidgetData"
                              label="所属部门：">
                    <div>
                        <el-tree class="tree filter-tree"
                                 show-checkbox
                                 highlight-current
                                 node-key="deptId"
                                 :default-checked-keys="checkedList"
                                 ref="widgetTree"
                                 :check-on-click-node="true"
                                 check-strictly
                                 :props="widgetProps"
                                 :data="widgetTreed"
                                 @check="changetree"
                                 @check-change="deptTreeCurrentChangeHandle">
                            <span slot-scope="{ node, data }"
                                  class="custom-tree-node">
                                <span :dataType="data.type">
                                    {{ node.label }}
                                </span>
                            </span>
                        </el-tree>
                    </div>
                </el-form-item> -->

                <el-form-item label="手机号："
                              prop="mobile">
                    <div class="list">
                        <el-input v-model="auditForm.mobile"
                                  :disabled="mobileDisabled"
                                  placeholder="请输入手机号"
                                  size="small"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="昵称："
                              prop="userName">
                    <div class="list">
                        <el-input v-model="auditForm.userName"
                                  placeholder="请输入昵称"
                                  size="small"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="密码："
                              prop="loginPassword"
                              v-if="isCreateItem">
                    <div class="list">
                        <el-input v-model="auditForm.loginPassword"
                                  show-password 
                                  placeholder="请输入密码"
                                  size="small"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="状态："
                              prop="status">
                    <div class="list">
                        <el-select v-model="auditForm.status"
                                   style="width: 100%"
                                   placeholder="请选择状态"
                                   size="small">
                            <el-option v-for="item in Statusoptions"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="角色："
                              prop="roleName">
                    <div class="list">
                        <el-select v-model="auditForm.roleName"
                                   style="width: 100%"
                                   placeholder="请选择角色"
                                   @change="onInputPost">
                            <el-option v-for="item in roleArrayInfo"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <!-- <el-form-item label="是否是主管："
                              prop="isManager">
                    <div class="list">
                        <el-select v-model="auditForm.isManager"
                                   style="width: 100%"
                                   placeholder="请选择是否是主管"
                                   size="small">
                            <el-option v-for="item in whether"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item> -->
                <el-form-item>
                    <el-button type="primary"
                               v-if="checkval == '1'"
                               @click="submitForm('auditForm')"
                               :loading="onSubmitLoading">确定</el-button>
                    <el-button @click="resetForm('auditForm')">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="角色修改"
                   :visible.sync="dialogVisibld"
                   width="600px"
                   style="text-align: left"
                   :before-close="handleClosed">
            <el-form ref="auditFormed"
                     :model="auditFormed"
                     :rules="auditRulesed"
                     label-width="140px"
                     class="demo-ruleForm">
                <el-form-item label="角色："
                              prop="resourcesIds">
                    <div class="list">
                        <el-select multiple
                                   filterable
                                   allow-create
                                   default-first-option
                                   v-model="auditFormed.resourcesIds"
                                   style="width: 100%"
                                   placeholder="请选择角色">
                            <el-option v-for="item in roleArrayInfo"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               v-if="checkval == '1'"
                               @click="submitFormed('auditFormed')"
                               :loading="onSubmitLoadinged">确定</el-button>
                    <el-button @click="resetFormed('auditFormed')">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import pagination from '../../components/pagination.vue';
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';

export default {
    filters: {
        waitFlagTag(code) {
            const statusMap = {
                0: 'success',
                1: 'danger'
            };
            return statusMap[code] || 'info';
        }
    },
    data() {
        var phone = (rule, value, callback) => {
            var type = /^1\d{10}$/;
            // var type = value.replace(/^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{8,20}$/i);
            if (!type.test(value)) {
                callback(new Error('请输入正确手机号'));
            } else {
                callback();
            }
        };
        return {
            mobileDisabled: false, //手机号不可编辑
            // 角色列表
            roleArrayInfo: [],
            releaseForm: {
                mobile: '',
                name: '',
                isEnabled: ''
            },
            pageSzie: 10,
            total: null,
            dialogVisibld: false,
            onSubmitLoadinged: false,
            currentPage: 1,
            dialogVisibled: false,
            onLoadingWidgetData: false,
            onLoadingBumenData: false,
            onSubmitLoading: false,
            isCreateItemed: false,
            checkedList: [],
            bumenList: [],
            widgetTreed: [],
            widgetTreben: [],
            widgetProps: {
                children: 'childrens',
                label: 'deptName',
                id: 'deptId'
            },
            widgetPropsben: {
                children: 'childrens',
                label: 'deptName',
                disabled: this.disabledFun //方法
            },
            isCreateItem: true,
            auditForm: {
                // 部门id
                deptId: '',
                // 密码
                loginPassword: '',
                // 手机号
                mobile: '',
                // 角色id
                roleId: '',
                // 角色名称
                roleName: '',
                // 状态
                status: '',
                // 姓名
                userName: '',
                // 是否是主管
                isManager: ''
            },
            auditFormed: {
                userId: '',
                resourcesIds: []
            },
            auditRulesed: {
                resourcesIds: [{ required: true, message: '请选择角色', trigger: 'blur' }]
            },
            auditRules: {
                // deptId: [{ required: true, message: '请选择所属部门id', trigger: 'blur' }],
                mobile: [{ required: true, message: '请填写手机号', trigger: 'blur' }],
                userName: [{ required: true, message: '请填写昵称', trigger: 'blur' }],
                loginPassword: [{ required: true, message: '请填写密码', trigger: 'blur' }],
                roleName: [{ required: true, message: '请选择角色', trigger: 'blur' }],
                status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
                // isManager: [{ required: true, message: '请选择是否是主管', trigger: 'blur' }]
            },

            auditOptions: [],
            checkval: '',
            options: [
                {
                    value: '0',
                    label: '普通'
                },
                {
                    value: '1',
                    label: '微信'
                },
                {
                    value: '2',
                    label: '钉钉'
                }
            ],
            Statusoptions: [
                {
                    value: '0',
                    label: '在职'
                },
                {
                    value: '1',
                    label: '离职'
                }
            ],
            whether: [
                {
                    value: '0',
                    label: '是'
                },
                {
                    value: '1',
                    label: '否'
                }
            ],
            tableData: [],
            qiyeOptions: [],
            expandedKeys: []
        };
    },
    mounted() {
        this.queryPageData();
        // this.weiPost();
        // this.baoguo();
        // this.rolePost();
        // this.bumenPost();
        this.roleInfo();
    },
    created() {},
    methods: {
        // 表格
        renderHeader(h, data) {
            return h('span', [
                h('el-tooltip'),
                {
                    attrs: {
                        class: 'item',
                        effect: 'dark',
                        content: data.column.label
                    }
                },
                [h('span', data.column.label)]
            ]);
        },
        // 角色传id和姓名
        onInputPost(val) {
            //向父组件传递选择地值，调用父组件setMajor把选中值赋给父组件
            // console.log(val, "djdjddjj");
            let obj = {};
            obj = this.roleArrayInfo.find(item => {
                //model就是上面的数据源
                // console.log(item,'33333333')
                return item.id === val; //筛选出匹配数据
            });
            // console.log(obj, "zidiandd");
            this.auditForm.roleName = obj.name;
            this.auditForm.roleId = obj.id;
        },
        //获取角色信息
        roleInfo() {
            post(api.getRoleList).then(d => {
                if (d.code == 0) {
                    this.roleArrayInfo = d.data;
                    // console.log(666, this.roleArrayInfo);
                } else {
                    this.$message.error(d.message);
                }
            });
        },
        //树节点 disabled 设置已加入清单的为禁用状态  addSyncData为已经加入清单的数据
        disabledFun(data, node) {
            const that = this;
            let disabled = false;
            that.widgetTreben.filter(item => {
                // console.log(item,'111111')
                if (item.isDepartment === data.isDepartment) {
                    disabled = true;
                }
            });
            return disabled;
        },
        bumenPost() {
            this.onLoadingBumenData = true;
            return post(api.queryDepartment, {
                id: ''
            }).then(
                d => {
                    // console.log(d,'1111111111')
                    this.onLoadingBumenData = false;
                    if (d.code === 0) {
                        this.widgetTreben = d.data;
                    } else {
                        this.widgetTreben = [];
                        // this.$message.error(d.message);
                    }
                },
                err => {
                    //error callback
                    this.onLoadingBumenData = false;
                }
            );
        },
        dakaiRowed(row) {
            this.dialogVisibld = true;
            this.auditFormed = {
                userId: row.id,
                resourcesIds: []
            };
            post(api.platUserQueryRole, {
                id: row.id
            }).then(
                d => {
                    if (d.code === 0) {
                        this.auditFormed.resourcesIds = d.data;
                        // this.isCreateItemed = true;
                        this.dialogVisibld = true;
                    } else {
                        // this.$message.error(d.message);
                    }
                },
                err => {
                    //error?callback
                }
            );
        },
        //组织结构
        deptTreeCurrentChangebenmen(data, checked, indeterminate) {
            // console.log(data, checked, indeterminate, "111111111111");
        },
        changetree(data, lst) {            // if (lst.checkedKeys.length > 0) {
            //     //这里的treeForm是你el-tree命名的ref的值
            //     this.$refs.widgetTree.setCheckedKeys([data.deptId]);
            // }

        },
        // 部门菜单树选中
        deptTreeCurrentChangeHandle(data, checked, indeterminate) {
            // console.log(data.deptId, "111111111111");
            // if (checked) {
            //     this.$refs.widgetTree.setCheckedKeys([data.deptId]);
            // }
        },
        baoguo(size) {
            post(api.queryYetAgentDepartment).then(
                d => {
                    if (d.code === 0) {
                        this.widgetTreed = d.data;
                        // console.log(this.widgetTreed,'所属部门')
                    } else {
                        // console.log(d.message);
                    }
                },
                err => {
                    //error callback
                }
            );
        },
        rolePost() {
            post(api.userQueryRole).then(
                d => {
                    if (d.code === 0) {
                        // console.log(d, "4444");
                        for (let i = 0; i < d.data.length; i++) {
                            var obj = {
                                label: d.data[i].name,
                                value: d.data[i].id
                            };
                            this.auditOptions.push(obj);
                        }
                    } else {
                        // this.$message.error(d.message);
                    }
                },
                err => {
                    //error?callback
                }
            );
        },
        weiPost() {
            post(api.tenantIdList).then(
                d => {
                    if (d.code === 0) {
                        // console.log(d, "4444");
                        for (let i = 0; i < d.data.length; i++) {
                            var obj = {
                                label: d.data[i].tenantName,
                                value: d.data[i].tenantId
                            };
                            this.qiyeOptions.push(obj);
                        }
                    } else {
                        // this.$message.error(d.message);
                    }
                },
                err => {
                    //error?callback
                }
            );
        },
        handleClosed() {
            this.dialogVisibld = false;
        },

        handleCloseed() {
            this.dialogVisibled = false;
        },
        openDialog() {
            this.dialogVisibled = true;
        },
        onClickRoleAdd() {
            this.mobileDisabled = false;
            this.checkval = '1';
            this.checkedList = [];
            (this.auditForm = {
                // 部门id
                deptId: '',
                // 密码
                loginPassword: '',
                // 手机号
                mobile: '',
                // 角色id
                roleId: '',
                // 角色名称
                roleName: '',
                // 状态
                status: '',
                // 姓名
                userName: '',
                // 是否是主管
                isManager: ''
            }),
                (this.isCreateItem = true);
            this.openDialog();
            // this.$nextTick(() => {
            //     this.$refs.widgetTree.setCheckedKeys([]);
            // });
        },
        submitFormed(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (this.isCreateItem) {
                        this.onSubmitLoadinged = true;
                        post(api.saveUserRole, this.auditFormed).then(
                            d => {
                                this.onSubmitLoadinged = false;
                                if (d.code == 0) {
                                    this.$message.success('修改成功');
                                    this.dialogVisibld = false;
                                    this.queryPageData(this.currentPage);
                                } else {
                                    this.$message.error(d.message);
                                }
                                //success callback
                            },
                            err => {
                                this.onSubmitLoadinged = false;
                                //error callback
                            }
                        );
                    }
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (this.isCreateItem) {
                        this.onSubmitLoading = true;
                        //所属部门
                        // var arr = this.$refs.widgetTree.getCheckedNodes();
                        // console.log(arr,'测试')
                        // var arrayInfo = [];
                        // for (var i = 0; i < arr.length; i++) {
                        //     arrayInfo.push(arr[i].deptId);
                        // }
                        // console.log(arrayInfo, 5555);

                        // this.auditForm.deptId = arrayInfo.join(',');
                        // console.log(this.auditForm.deptId, 996969);

                        // 添加
                        // if (this.auditForm.deptId == '') {
                        //     this.onSubmitLoading = false;
                        //     return this.$message.error('所属部门不能为空');
                        // }

                        post(api.addMerUser, this.auditForm).then(
                            d => {
                                // console.log(d, "添加");
                                this.onSubmitLoading = false;
                                if (d.code == 0) {
                                    this.$message.success(d.message);
                                    this.dialogVisibled = false;
                                    this.queryPageData();
                                } else {
                                    this.$message.error(d.message);
                                }
                                //success callback
                            },
                            err => {
                                this.onSubmitLoading = false;
                                //error callback
                            }
                        );
                    } else {
                        this.onSubmitLoading = true;
                        //所属部门
                        // let subKeys = this.$refs.widgetTree.getCheckedKeys();
                        // console.log(subKeys, "555555");
                        // let halfKeys = this.$refs.widgetTree.getHalfCheckedKeys();
                        // console.log(halfKeys, "666666666666");
                        // let resources = halfKeys.concat(subKeys);
                        // resources.sort();
                        // this.auditForm.deptId = resources.join();
                        // console.log(this.auditForm.deptId, 55555);
                        // var obj = [];
                        // let checkAreaData = this.$refs.widgetTree.getCheckedNodes();
                        // if (this.auditForm.deptId == '') {
                        //     this.onSubmitLoading = false;
                        //     return this.$message.error('所属部门不能为空');
                        // }
                        // this.$nextTick(() => {
                        //     this.$refs.widgetTree.setCheckedKeys([]);
                        // });
                        // 编辑确定
                        post(api.updateMerUserRole, this.auditForm).then(
                            d => {
                                this.onSubmitLoading = false;
                                if (d.code == 0) {
                                    // console.log(d, 333);
                                    this.$message.success('编辑成功');
                                    this.dialogVisibled = false;
                                    this.queryPageData(this.currentPage);
                                } else {
                                    this.$message.error(d.message);
                                }
                                //success callback
                            },
                            err => {
                                this.onSubmitLoading = false;
                                //error callback
                            }
                        );
                    }
                } else {
                    // console.log('error submit!!');
                    this.onSubmitLoading = false;
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisibled = false;
        },
        resetFormed(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisibld = false;
        },
        // 查看
        chakanRow(row) {
            this.mobileDisabled = false;
            this.dialogVisibled = true;
            this.checkval = '2';
            this.checkedList = row.deptId.split(',');
            this.bumenList = [];
            this.auditForm = {
                id: row.id,
                roleId: row.roleId,
                mobile: row.mobile,
                deptId: row.deptId,
                userName: row.userName,
                roleName: row.roleName,
                status: row.isEnabled,
                isManager: row.isManager
            };
            this.isCreateItem = false;

            // this.$nextTick(() => {
            //   this.$refs.widgetTree.setCheckedKeys([]);
            //   this.$refs.bumenTree.setCheckedKeys([]);
            // });
            // this.shuTreePost();
            // this.bumenTreePost();
        },
        // 编辑
        dakaiRow(row) {
            // console.log(row, 111);
            this.mobileDisabled = true;
            this.dialogVisibled = true;
            this.checkval = '1';
            this.checkedList = row.deptId.split(',');
            // this.bumenList = [];
            this.auditForm = {
                id: row.id,
                roleId: row.roleId,
                deptId: row.deptId,
                mobile: row.mobile,
                userName: row.userName,
                roleName: row.roleName,
                status: row.isEnabled,
                isManager: row.isManager
            };
            this.isCreateItem = false;
            // this.checkedList =row.deptId
            // console.log(row.deptId,520520)
            // return post(api.getIdsByRoleId, {
            //   roleId: this.auditForm.roleId,
            // }).then((d) => {
            //   this.checkedList = d.data;
            //   console.log(d, 666);
            // });
        },
        // 列表
        queryPageData(size) {
            const loading = this.$loading({
                lock: true,
                spinner: 'loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            post(api.getUserPageList, {
                currentPage: size || 1,
                pageSize: this.pageSzie,
                mobile: this.releaseForm.mobile,
                name: this.releaseForm.name,
                isEnabled: this.releaseForm.isEnabled
            }).then(
                d => {
                    loading.close();
                    if (d.code === 0) {
                        // console.log(d, "!!!!!!!!!!!!");
                        if (d.hasOwnProperty('data')) {
                            this.total = d.data.count;
                            this.currentPage = d.data.currentPage;
                            this.tableData = d.data.rows;
                            // console.log(this.tableData, 999);
                        } else {
                            this.total = 0;
                            this.currentPage = 1;
                            this.pageSzie = 10;
                            this.tableData = [];
                        }
                    } else {
                        this.$message.error(d.message);
                        this.total = 0;
                        this.currentPage = 1;
                        this.pageSzie = 10;
                        this.tableData = [];
                    }
                },
                err => {
                    //error callback
                }
            );
        },
        search() {
            this.queryPageData();
        },
        reset() {
            (this.releaseForm = {
                mobile: '',
                name: '',
                isEnabled: ''
            }),
                this.queryPageData();
        },
        handleSizeChange(val) {
            this.pageSzie = val;
            // console.log(`每页 ${val} 条aaaaa`);
            this.queryPageData();
        },
        handleCurrentChange(size) {
            // console.log(size);
            this.queryPageData(size);
        }
    },
    components: {
        pagination: pagination
    }
};
</script>
