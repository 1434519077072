/* ============
 * Vuex Getters
 * ============
 *
 * All the getters that can be used
 * inside the store
 */

const account = state => state.account;

const menuList = state => state.menu.menus;

const getSide = state => state.side.side;

const authentications = state => state.auth.authentications;

const sidebar = state => state.app.sidebar;

const device = state => state.app.device;

export default {
    account,
    menuList,
    getSide,
    authentications,
    sidebar,
    device
};
