// Account
export const GET_ACCOUNT = 'GET_ACCOUNT';

// Auth
export const CHECK_AUTHENTICATION = 'CHECK_AUTHENTICATION';
export const GET_AUTHENTICATION = 'GET_AUTHENTICATION';
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const GET_MENU = 'GET_MENU';
export const CLEAR_MENU = 'CLEAR_MENU';
export const SIDE_OPENED = 'SIDE_OPENED';

// loading
export const LOADING = 'LOADING';

// App
export const TOGGLE_DEVICE = 'TOGGLE_DEVICE';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const ORAGANIZATIONID = 'ORAGANIZATIONID';
export const ORGANIZATIONAME = 'ORGANIZATIONAME';
export const ORGANIZATIONTYPE = 'ORGANIZATIONTYPE';
export const ROLE = 'ROLE';
export const ROUTERSTATUS = 'ROUTERSTATUS';
export const MOBILE = 'MOBILE';

// todoCount
// export const UPDATE_TODO_COUNT = 'UPDATE_TODO_COUNT';
// export const UPDATE_MESSAGE_COUNT = 'UPDATE_MESSAGE_COUNT';
