<template>
    <div class="Login">
        <!-- <div class="denglu">
      <span class="yemian">欢迎登录</span>
      <br />
      <span class="yemian"> 渠道商服务平台</span>
      <br /><br />
      <div class="henggang"></div>
    </div> -->
        <div class="dialog"
             v-if="loginType">
            <div class="name">
                <!-- <img src="https://gsb-zc.oss-cn-beijing.aliyuncs.com//zc_367101606117738511202023154858511logo.png"
               alt="" /> -->
                <div class="leftLine"></div>
                <h3 class="title">
                    薪必果综合服务平台<br />
                    渠道商
                </h3>
                <div class="rightLine"></div>
            </div>
            <div class="main_right">
                <el-tabs v-model="activeName"
                         @tab-click="handleClick">
                    <el-tab-pane label="短信登录"
                                 name="first">
                        <div class="main">
                            <div class="input">
                                <el-input v-model="phone"
                                          prefix-icon="el-icon-user"
                                          placeholder="请输入手机号"
                                          clearable></el-input>
                            </div>
                            <div class="validation">
                                <div class="left">
                                    <el-input prefix-icon="el-icon-set-up"
                                              v-model="graphics"
                                              placeholder="请输入手机号验证码"
                                              @keyup.enter.native="homeSkip"></el-input>
                                </div>
                                <div class="obtain"
                                     @click="validationSms(phone)">
                                    {{ countdownHtml }}
                                </div>
                            </div>
                            <div :class="
                  phone != '' && graphics != '' ? 'determine' : 'determineNo'
                "
                                 @click="homeSkip"
                                 id="btn">
                                登&nbsp;&nbsp;录
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="密码登录"
                                 name="second">
                        <div class="main">
                            <div class="input">
                                <el-input v-model="phone"
                                          prefix-icon="el-icon-user"
                                          placeholder="请输入账号"
                                          clearable></el-input>
                            </div>
                            <div class="input">
                                <el-input placeholder="请输入登录密码"
                                          prefix-icon="el-icon-lock"
                                          v-model="password"
                                          show-password
                                          @keyup.enter.native="homeSkip"></el-input>
                            </div>
                            <div :class="
                  phone != '' && password != '' ? 'determine' : 'determineNo'
                "
                                 @click="homeSkip"
                                 id="btn"
                                 :loading="loadingIng">
                                登&nbsp;&nbsp;录
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <!-- 图片滑动验证码 -->
        <!-- <el-dialog
      title="请拖动滑块到指定位置"
      :visible.sync="dialogVisible"
      width="380px"
      :center="true"
    >
      <slide-verify
        :l="42"
        :r="10"
        :w="310"
        :h="155"
        @success="onSuccess"
        @refresh="onRefresh"
        :slider-text="text"
        style="margin: auto"
        ref="slideblock"
      ></slide-verify>
    </el-dialog> -->
    </div>
</template>

<script>
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
import menuService from '../../menu/index';
import { getUrlKey } from '../../utils';
import loading from '../../store/modules/loading';

export default {
    name: 'Login',
    data() {
        var phone = (rule, value, callback) => {
            // var type = /^1\d{10}$/;
            var type = /^(1[3-9])\d{9}$/;
            // var type = value.replace(/^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{8,20}$/i);
            if (!type.test(value)) {
                callback(new Error('请输入正确手机号'));
            } else {
                callback();
            }
        };
        return {
            // 加载
            loadingIng: false,
            // 滑块验证码
            text: '向右滑', // 图片滑动验证码底部文字，
            dialogVisible: false,
            loginType: true,
            phone: '',
            password: '',
            rules: {
                phone: [{ validator: phone, trigger: 'blur' }],
                graphics: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
                password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }]
            },
            countdownHtml: '获取验证码',
            times: 60,
            timer: null,
            graphics: '',
            activeName: 'first',
            changIndex: 'first'
        };
    },
    created() {},
    mounted() {
        // 判断是否是IE
        // console.log(navigator);
        var ua = navigator.userAgent.toLocaleLowerCase();
        // console.log(ua);
        var browserType = '',
            browserVersion = '';
        if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
            alert(
                '为了更好的用户体验，推荐使用谷歌、360、QQ等Chrome内核的浏览器，使用其他浏览器可能会导致显示异常，详情请联系客服，感谢您的支持！'
            );
            // browserType = "IE";
            // 获取ie浏览器版本
            // browserVersion = ua.match(/msie ([\d.]+)/) != null ? ua.match(/msie ([\d.]+)/)[1] : ua.match(/rv:([\d.]+)/)[1];
            // console.log(browserVersion)
            // if ((1 * browserVersion) < 9) {
            //     alert("请在ie11版本浏览器上使用系统")
            // }
        }
        if (this.$cookies.get('token_kick')) {
            // console.log(this.$cookies.get("token_kick"), "ppp");
            this.$cookies.remove('token_kick');
            this.$message.error('登录已失效，请重新登录');
        }
        // console.log(111);
    },
    methods: {
        // 滑块验证码
        // onSuccess() {
        //   this.$refs.slideblock.reset(); //重置组件
        //   this.dialogVisible = false; //隐藏组件
        //   if (this.islogin) {
        //     this.passwordLogin1();
        //   } else {
        //     // this.$message({
        //     //   message: "验证成功！",
        //     //   type: "success",
        //     // });
        //     // this.getVerificationCode1();
        //     this.loginPwd();
        //   } //图片校验成功
        // },
        // 手机密码登录页
        loginPwd() {
            const loading = this.$loading({
                lock: true,
                spinner: 'loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.loadingIng = true;
            post(api.pwdLogin, {
                mobile: this.phone,
                password: this.password
            }).then(
                d => {
                    // console.log(d, 1);
                    if (d.code == 0) {
                        // console.log(d, 452);
                        this.$cookies.set('esignmerchantsid', d.data.loginKey);
                        this.$cookies.set('merchantloginPhone', d.data.mobile);
                        this.$cookies.set('merchantloginNickname', d.data.userName);
                        this.$cookies.set('mchcompanyId', d.data.agentId);
                        this.$cookies.set('agentName', d.data.agentName);
                        this.$cookies.set('agentUserId', d.data.id);
                        this.menu();
                        loading.close();
                        this.loadingIng = false;
                    } else {
                        this.$message.error(d.message);
                        loading.close();
                    }
                },
                err => {
                    loading.close();
                    //console.log(err.data.msg);
                    //error callback
                }
            );
        },
        // onFail() {
        //   this.$message.error("验证未通过！");
        //   this.$refs.slideblock.reset(); //重置组件
        //   this.dialogVisible = true; //隐藏组件
        // },
        onRefresh() {
            this.$message({
                message: '刷新成功！',
                type: 'success'
            });
            this.$refs.slideblock.reset(); //重置组件
        },
        handleClick(tab, event) {
            // console.log(tab.name, "555555555555");
            this.changIndex = tab.name;
        },
        // 短信验证
        validationSms() {
            // console.log(this.phone, "33333");
            if (this.times != 60) {
                return;
            }
            if (!this.phone) {
                this.$message.error('请输入手机号');
                return;
            }
            //   短信验证码
            // var regMoblie = /^1\d{10}$/;
            var regMoblie = /^(1[3-9])\d{9}$/;
            if (!regMoblie.test(this.phone)) {
                this.$message.error('该手机号格式错误');
                return;
            }
            this.countdown();
            this.timer = setInterval(this.countdown, 1000);
            post(api.sendLoginSMS, {
                mobile: this.phone,
                imgCode: '',
                imgKey: '',
                useImgCode: true
            }).then(
                d => {
                    if (d.code == 0) {
                        this.$message.success('发送成功');
                    } else {
                        clearInterval(this.timer);
                        this.countdownHtml = '获取验证码';
                        this.times = 60;
                        this.$message.error(d.message);
                    }
                    //success callback
                },
                err => {
                    //error callback
                }
            );
        },
        //60秒倒计时
        countdown() {
            var that = this;
            if (that.times == 0) {
                clearInterval(this.timer);
                that.countdownHtml = '获取验证码';
                that.times = 60;
                return false;
            } else {
                that.countdownHtml = '' + that.times + 's';
                that.times--;
            }
        },
        menu() {
            menuService.menu().then(() => {
                this.$message.success('登录成功');
                localStorage.setItem('store', JSON.stringify(this.$store.state));
                this.$router.push({
                    // path: this.$store.state.menu.menus[0].path,
                    path: '/'
                });
                // console.log(this.$store.state.menu.menus.length,'111111')
                // this.$router.push({
                //   path: this.$store.state.menu.menus[0].childrens[0].path,
                // });
                // console.log(this.$store.state.menu.menus,'路径')

                // if (this.$store.state.menu.menus.length > 0) {
                // console.log(this.$store.state.menu.menus[0], 666);
                //   this.$router.push({
                //     // path: this.$store.state.menu.menus[0].path,
                //     path:'/'
                //   });
                // console.log(this.$store.state.menu.menus[0].childrens[0].path);
                // }
            });
        },
        homeSkip() {
            if (this.phone == '') {
                this.$message.error('请输入手机号');
                return;
            }
            var regMoblie = /^(1[3-9])\d{9}$/;
            if (!regMoblie.test(this.phone)) {
                this.$message.error('该手机号格式错误');
                return;
            }
            // 短信验证
            if (this.changIndex == 'first') {
                post(api.login, {
                    // 手机号
                    mobile: this.phone,
                    // 验证码
                    smsCode: this.graphics
                }).then(
                    d => {
                        if (d.code == 0) {
                            // this.$message.success("登录成功");
                            // this.$cookies.set("token", d.data.loginKey);
                            // this.$cookies.set("merchantloginPhone", d.data.ucname);
                            // this.$router.push({ path: "/" });

                            var a = this.$cookies.set('esignmerchantsid', d.data.loginKey);
                            this.$cookies.set('merchantloginPhone', d.data.mobile);
                            this.$cookies.set('merchantloginNickname', d.data.userName);
                            this.$cookies.set('mchcompanyId', d.data.agentId);
                            this.$cookies.set('agentName', d.data.agentName);
                            this.$cookies.set('agentUserId', d.data.id);
                            this.menu();
                        } else {
                            this.$message.error(d.message);
                        }
                        //success callback
                    },
                    err => {
                        //console.log(err.data.msg);
                        //error callback
                    }
                );
            } else {
                // 手机密码验证
                if (this.password == '') {
                    this.$message.error('请输入登录密码');
                    return;
                }
                if (this.phone == '') {
                    this.$message.error('请输入手机号');
                    return;
                }
                if (this.phone != '' && this.password != '') {
                    this.dialogVisible = true;
                }
                this.loginPwd();
            }
        }
    },
    components: {},
    updated() {}
};
</script>

<style lang="less" scoped>
.Login::-webkit-scrollbar {
    width: 0;
}

.Login {
    width: 100%;
    height: 100%;
    // background: url("https://gsb-zc.oss-cn-beijing.aliyuncs.com//zc_469101606114648912202023145728912pigntai.jpg")
    //   no-repeat;
    background: url('../../assets/img/loginBackground.jpg') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
    // .denglu {
    //   width: 48%;
    //   position: absolute;
    //   text-align: left;
    //   padding-left: 5%;
    //   padding-top: 3%;
    //   .yemian {
    //     font-size: 44px;
    //     font-family: PingFangSC-Semibold, PingFang SC;
    //     font-weight: 600;
    //     color: #ffffff;
    //   }
    //   .henggang {
    //     width: 76px;
    //     height: 6px;
    //     background: #ffffff;
    //   }
    // }
    .dialog {
        width: 1200px;
        box-sizing: border-box;
        margin: 0 auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .name {
            color: #fff;
            font-size: 24px;
            text-align: center;
            display: flex;
            .leftLine {
                width: 134px;
                height: 1px;
                background-color: #fff;
                opacity: 0.3;
                border-radius: 1px;
                margin-top: 15px;
                margin-right: 15px;
            }
            .rightLine {
                width: 134px;
                height: 1px;
                background-color: #fff;
                opacity: 0.3;
                border-radius: 1px;
                margin-top: 15px;
                margin-left: 15px;
            }
            .title {
                margin: 0 auto 30px auto;
                text-align: center;
                font-size: 24px;
                line-height: 40px;
                color: #ffffff;
            }
            // img {
            //   width: 40px;
            //   height: 40px;
            //   vertical-align: middle;
            // }
        }
        .main_right {
            box-sizing: border-box;
            width: 38%;
            height: 38%;
            background: #ffffff;
            box-shadow: 0px 5px 22px 0px rgba(0, 97, 184, 0.65);
            border-radius: 4px;
            min-width: 421px;
            min-height: 380px;
            .main {
                width: 100%;
                height: auto;
                .laber {
                    width: 100%;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC;
                    font-weight: 500;
                    color: rgba(51, 51, 51, 1);
                    line-height: 20px;
                    text-align: left;
                    margin-top: 25px;
                }
                .input {
                    width: 85%;
                    margin: 0 auto;
                    height: 42px;
                    background: rgb(232, 240, 254);
                    border-radius: 6px;
                    border: 1px solid rgba(224, 224, 224, 1);
                    margin-top: 8%;
                    line-height: 42px;
                    overflow: hidden;
                }
                .validation {
                    width: 85%;
                    height: 42px;
                    margin: 8% auto;
                    .left {
                        float: left;
                        width: 257px;
                        height: 43px;
                        line-height: 43px;
                        border-radius: 6px;
                        border: 1px solid #e0e0e0;
                    }
                    .obtain {
                        float: right;
                        width: 114px;
                        height: 39px;
                        line-height: 39px;
                        text-align: center;
                        background: white;
                        border-radius: 6px;
                        border: 1px solid #e0e0e0;
                        font-size: 13px;
                        font-family: PingFangSC;
                        font-weight: 400;
                        color: #3f86f8;
                        cursor: pointer;
                    }
                    .right {
                        float: right;
                        width: 97px;
                        height: 52px;
                        padding: 8px;
                        box-sizing: border-box;
                        background: rgba(245, 245, 245, 1);
                        div {
                            width: 100%;
                            height: 100%;
                            background-color: #fff;
                            cursor: pointer;
                        }
                    }
                }
                .forget {
                    width: 100%;
                    font-size: 13px;
                    font-family: PingFangSC;
                    font-weight: 400;
                    color: rgba(77, 194, 248, 1);
                    line-height: 18px;
                    text-align: right;
                    padding: 11px 13px;
                    box-sizing: border-box;
                    cursor: pointer;
                }
                .determine {
                    width: 85%;
                    margin: 0 auto;
                    height: 40px;
                    background: rgba(121, 176, 250, 1);
                    box-shadow: 0px 1px 9px 0px rgba(0, 121, 254, 0.54);
                    border-radius: 2px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFangSC;
                    font-weight: 400;
                    color: #ffffff;
                    margin-top: 9%;
                    cursor: pointer;
                }
                .determineNo {
                    width: 85%;
                    margin: 0 auto;
                    height: 40px;
                    background: rgba(121, 176, 250, 0.5);
                    border-radius: 2px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFangSC;
                    font-weight: 400;
                    color: #ffffff;
                    margin-top: 9%;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>

<style>
.Login .dialog .el-input > .el-input__inner {
    height: 42px !important;
    line-height: 42px !important;
    border: none !important;
    padding-left: 70px;
}
.Login .dialog2 .el-input > .el-input__inner {
    height: 42px !important;
    line-height: 42px !important;
    border: none !important;
}
.Login .dialog .el-input__icon {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s !important;
    color: #33aeff !important;
    font-size: 24px !important;
    width: 65px !important;
}
.Login .el-step__head.is-success {
    color: rgba(48, 131, 255, 1);
    border-color: rgba(48, 131, 255, 1);
}
.Login .el-step__title.is-success {
    color: rgba(0, 0, 0, 0.65);
}
.Login .is-process .el-step__icon.is-text {
    background: rgba(48, 131, 255, 1);
    border: none;
    color: #fff;
}
.el-tooltip__popper[x-placement^='right'].is-dark {
    width: 250px !important;
    height: 80px !important;
    background: rgba(255, 255, 255, 1) !important;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
    border: 1px solid rgba(235, 238, 245, 1) !important;
    font-size: 14px !important;
    font-family: PingFangSC-Regular, PingFang SC !important;
    font-weight: 400 !important;
    color: rgba(100, 102, 106, 1) !important;
    line-height: 20px !important;
}
.el-tooltip__popper[x-placement^='right'] .popper__arrow {
    border-right-color: rgba(0, 0, 0, 0.15) !important;
}
.el-tooltip__popper[x-placement^='right'] .popper__arrow:after {
    border-right-color: rgba(0, 0, 0, 0.15) !important;
}
/* .el-tabs__nav-wrap::after {
      position: static !important;
  } */
.Login .el-tabs__header {
    padding: 0;
    position: relative;
    width: 85%;
    margin: 0 auto !important;
    padding-top: 30px !important;
}
.Login .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 49px;
    width: 200px;
}
.Login .el-tabs--top .el-tabs__item.is-top:last-child {
    padding-right: 0;
    width: 150px;
}
</style>
