<template>
    <div class="main">
        <el-form ref="releaseForm"
                 :model="releaseForm"
                 label-width="120px">
            <el-row>
                <el-col :lg="18"
                        :md="20">
                    <el-row>
                        <el-col :lg="8"
                                :md="12">
                            <el-form-item label="是否强制提醒："
                                          style="text-align:left">
                                <el-select v-model="releaseForm.forceRemind"
                                           placeholder="请选择是否强制提醒"
                                           size="small">
                                    <el-option v-for="item in forceRemindList"
                                               :key="item.id"
                                               :label="item.name"
                                               :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :md="6"
                        :sm="8">
                    <el-form-item label=" ">
                        <el-button type="primary"
                                   size="small"
                                   @click="search">搜索</el-button>
                        <el-button size="small"
                                   @click="reset"
                                   class="chongzhi">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <!-- <div class="toobar">
      <div class="main_button" style="float:left;margin-bottom:12px;margin-left:36px">
        <el-button
          type="primary"
          size="small"
          v-permission="'release_requirements_btn'"
        >
        </el-button>
        <el-button type="primary" size="small" @click="addMessSet()"
          ><i class="el-icon-plus"></i>&nbsp;&nbsp;添加消息设置</el-button
        >
      </div>
    </div> -->
        <div class="wrap">
            <el-table :data="tableData"
                      fit
                      style="width: 100%"
                      :height="tableHeight">
                <el-table-column prop="messageType"
                                 label="消息种类"
                                 align="center">
                    <template slot-scope="scope">
                        {{scope.row.messageType==1?'系统消息':''}}
                    </template>
                </el-table-column>
                <el-table-column prop="title"
                                 align="center"
                                 show-overflow-tooltip
                                 label="消息标题"></el-table-column>
                <el-table-column prop="content"
                                 show-overflow-tooltip
                                 align="center"
                                 label="消息内容"></el-table-column>
                <el-table-column prop="forceRemind"
                                 align="center"
                                 label="是否强制提醒">
                    <template slot-scope="scope">
                        {{scope.row.forceRemind=='0'?'是':'否'}}
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 prop="createTime"
                                 label="创建时间"></el-table-column>
            </el-table>
            <div class="wraped">
                <pagination :total="tableTotal"
                            :currentPage="releaseForm.currentPage"
                            @handleSizeChange="handleSizeChange"
                            @handleCurrentChange="handleCurrentChange"></pagination>
            </div>
            <!-- 弹框 -->
            <el-dialog :title="dialogTitle"
                       :visible.sync="dialogVisible"
                       width="780px"
                       :before-close="handleClose">
                <el-form :model="tempData"
                         :rules="rules"
                         style="text-align:left"
                         ref="tempForm"
                         label-width="165px"
                         class="demo-ruleForm">
                    <el-form-item label="账户余额提醒："
                                  prop="balanceRemind">
                        <el-radio v-model="tempData.balanceRemind"
                                  label="0">开启</el-radio>
                        <el-radio v-model="tempData.balanceRemind"
                                  label="1">关闭</el-radio>
                    </el-form-item>
                    <el-form-item label="银行卡余额提醒阈值："
                                  prop="bankThreshold"
                                  :rules="tempData.balanceRemind=='0'?rules.bankThreshold:[{ required: false}]">
                        <el-input v-model="tempData.bankThreshold"
                                  placeholder="请输入银行卡余额提醒阈值"></el-input>
                    </el-form-item>
                    <el-form-item label="支付宝余额提醒阈值："
                                  prop="alipayThreshold"
                                  :rules="tempData.balanceRemind=='0'?rules.alipayThreshold:[{ required: false}]">
                        <el-input v-model="tempData.alipayThreshold"
                                  placeholder="请输入支付宝余额提醒阈值"></el-input>
                    </el-form-item>
                    <el-form-item label="微信余额提醒阈值："
                                  prop="wechatThreshold"
                                  :rules="tempData.balanceRemind=='0'?rules.wechatThreshold:[{ required: false}]">
                        <el-input v-model="tempData.wechatThreshold"
                                  placeholder="请输入微信余额提醒阈值"></el-input>
                    </el-form-item>
                    <el-form-item label="充值到账提醒："
                                  prop="rechargeRemind">
                        <el-radio v-model="tempData.rechargeRemind"
                                  label="0">开启</el-radio>
                        <el-radio v-model="tempData.rechargeRemind"
                                  label="1">关闭</el-radio>
                    </el-form-item>
                    <el-form-item label="系统消息提醒："
                                  prop="systemRemind">
                        <el-radio disabled
                                  v-model="tempData.systemRemind"
                                  label="0">开启</el-radio>
                        <el-radio disabled
                                  v-model="tempData.systemRemind"
                                  label="1">关闭</el-radio>
                    </el-form-item>
                </el-form>
                <span slot="footer"
                      class="dialog-footer">
                    <el-button @click="handleClose">取 消</el-button>
                    <el-button type="primary"
                               @click="onSubmit"
                               v-if="dialogTitle=='添加消息设置'">提 交</el-button>
                    <el-button type="primary"
                               @click="updateData"
                               v-if="dialogTitle=='编辑消息设置'">更 新</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import tableHeight from '../../mixins/tableHeight';
import api from '@/api/api.js';
import moment from 'moment';
import { get, post } from '@/api/index.js';
import pagination from '../../components/pagination.vue';
export default {
    mixins: [tableHeight],
    components: {
        pagination: pagination,
        CountTo: () => import('vue-count-to')
    },
    data() {
        return {
            tableData: [],
            tableTotal: 0,
            dialogVisible: false,
            releaseForm: {
                currentPage: 1,
                pageSize: 10,
                forceRemind: '',
                messageStatus: '',
                readStatus: '',
                receiverId: '',
                receiverName: '',
                receiverType: ''
            },
            forceRemindList: [
                { id: '0', name: '是' },
                { id: '1', name: '否' }
            ],
            messageStatusList: [
                { id: '0', name: '已发送' },
                { id: '1', name: '已撤回' }
            ],
            dialogTitle: '添加消息设置',
            rules: {
                balanceRemind: [{ required: true, message: '请选择账户余额提醒', trigger: 'blur' }],
                rechargeRemind: [
                    { required: true, message: '请选择充值到账提醒', trigger: 'blur' }
                ],
                bankThreshold: [
                    { required: true, message: '请填写银行卡余额提醒阈值', trigger: 'blur' }
                ],
                alipayThreshold: [
                    { required: true, message: '请填写支付宝余额提醒阈值', trigger: 'blur' }
                ],
                wechatThreshold: [
                    { required: true, message: '请填写微信余额提醒阈值', trigger: 'blur' }
                ]
            },
            tempData: {
                alipayThreshold: '',
                balanceRemind: '',
                bankThreshold: '',
                platformType: '3',
                platformUserName: '',
                rechargeRemind: '',
                systemRemind: '1',
                wechatThreshold: '',
                id: ''
            }
        };
    },
    mounted() {
        this.messageListMessage();
    },
    methods: {
        messageListMessage() {
            post(api.messageListMessage, this.releaseForm).then(res => {
                // console.log(res, '消息列表');
                if (res.code === 0) {
                    this.tableData = res.data.rows;
                    this.tableTotal = res.data.count;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        search() {
            this.messageListMessage();
        },
        reset() {
            this.releaseForm = {
                currentPage: 1,
                pageSize: 10,
                forceRemind: '',
                messageStatus: '',
                readStatus: '',
                receiverId: '',
                receiverName: '',
                receiverType: ''
            };
            this.messageListMessage();
        },
        onSubmit() {
            this.$refs['tempForm'].validate(valid => {
                if (valid) {
                    post(api.messageAddMessageInstall, this.tempData).then(res => {
                        if (res.code == 0) {
                            this.$notify({
                                title: '添加成功',
                                message: res.message,
                                type: 'success',
                                duration: '2000'
                            });
                            this.messageListMessage();
                            this.dialogVisible = false;
                        } else {
                            this.$notify({
                                title: '添加失败',
                                message: res.message,
                                type: 'fail',
                                duration: '2000'
                            });
                        }
                    });
                }
            });
        },
        addMessSet() {
            this.dialogVisible = true;
        },
        detailData() {},
        // 提交编辑后的数据
        updateData() {
            const payLoad = JSON.parse(JSON.stringify(this.tempData));
            post(api.messageeEditMessageInstall, payLoad).then(res => {
                if (res.data.code === 200) {
                    this.$notify({
                        title: '更新成功',
                        message: res.data.msg,
                        type: 'success',
                        duration: '2000'
                    });
                    this.messageListMessage();
                } else {
                    this.$notify({
                        title: '更新失败',
                        message: res.data.msg,
                        type: 'fail',
                        duration: '2000'
                    });
                }
            });
        },
        handleClose() {
            this.dialogVisible = false;
        },
        // 分页--点击某页码
        handleCurrentChange(val) {
            this.releaseForm.currentPage = val;
            this.messageListMessage();
        },
        // 分页--更改每页显示条数
        handleSizeChange(val) {
            this.releaseForm.pageSize = val;
            this.messageListMessage();
        }
    }
};
</script>
<style lang="less" scoped>
.main {
    background: #ffffff !important;
    height: 100% !important;
    width: 100% !important;
}
.trading {
    width: 100%;
    min-height: 100%;
    padding: 12px;
    box-sizing: border-box;
    background-color: #fff;
    .box {
        width: 100%;
        // padding-top: 12px;
        .main_button {
            text-align: left !important;
            margin-left: 36px;
        }
        .wrap {
            width: 100%;
            margin-top: 22px;
            text-align: right !important;
            // padding: 0px 12px;
            box-sizing: border-box;
            .wraped {
                width: 100%;
            }
        }
    }
    .block {
        width: 100%;
        .list {
            width: 100%;
            text-align: left;
            padding: 20px;
            box-sizing: border-box;
            font-size: 14px;
            color: rgba(16, 16, 16, 100);
            .grid-content {
                width: 100%;
                margin-bottom: 20px;
                display: inline-block;
            }
            .baoguo {
                width: 100%;
                overflow: hidden;
                .zongji {
                    float: left;
                    font-weight: 500;
                    font-size: 22px;
                    color: #303133;
                }
                .anniu {
                    float: right;
                }
            }
            .biankuang {
                width: 336px;
                height: 169px;
                border: 1px solid #6666;
                .dian {
                    background-color: #52c41a;
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-left: 30px;
                }
                .jinqian {
                    font-size: 20px;
                    line-height: 89px;
                    vertical-align: middle;
                    margin-left: 20px;
                }
                .wenzi {
                    margin-left: 89px;
                    font-size: 22px;
                    color: #666;
                }
            }
        }
        .list2 {
            text-align: left;
            padding: 100px;
            box-sizing: border-box;
            font-size: 14px;
            color: #101010;
            display: inline-block;
            .grid-content {
                width: 100%;
                margin-bottom: 20px;
                display: inline-block;
            }
            .button {
                margin: 0 auto;
                margin-top: 24px;
                height: 40px;
                line-height: 40px;
                background: #3083ff;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                letter-spacing: 1px;
                cursor: pointer;
                text-align: center;
            }
            .text {
                margin-top: 56px;
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                text-align: center;
            }
        }
        .col {
            width: 102%;
            height: 10px;
            background-color: #f2f2f2;
            margin-left: -12px;
        }
    }
}
</style>