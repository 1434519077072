<template>
  <!--查看详情页面格式区分 -->
  <div>
    <div>
      <el-image v-if="srcPhoto && fileType == 'image' && isList != '1'" style="width: 150px; height: 150px"
        :src="srcPhoto" :preview-src-list="[srcPhoto]">
      </el-image>
      <div v-if="srcPhoto && fileType == 'pdf'" @click="pdfOpen()" class="seeStyle">{{ name ? name : '查看' }}</div>
      <div v-if="srcPhoto && fileType == 'zip'" @click="zipOpen()" class="seeStyle">{{ name ? name : '查看' }}</div>
      <div v-if="srcPhoto && fileType == 'xlsx'" @click="xlsxOpen()" class="seeStyle">{{ name ? name : '查看' }}</div>
      <div v-if="srcPhoto && fileType == 'unknown'" @click="unknownOpen()" class="seeStyle">{{ name ? name : '查看' }}</div>
      <div v-if="srcPhoto && fileType == 'image' && isList == '1'" @click="imgOpen()" class="seeStyle">{{ name ? name :
        '查看'
      }}</div>
      <div v-if="!srcPhoto">--</div>
    </div>
    <!-- 列表图片弹框展示 -->
    <el-dialog title="查看" :visible.sync="dialogVisibleImg" append-to-body :close-on-click-modal="false" width="1000px"
      :before-close="handleCloseImg">
      <img width="100%" :src="srcPhoto" alt="">
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleImg = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    // 绑定的值（v-model）
    srcPhoto: {
      type: String,
      default: ''
    },
    // 是否是列表上的图片
    isList: {
      type: String,
      default: ''
    },
    // 按钮名称
    name: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      // 图片弹框
      dialogVisibleImg: false,
      // 文件格式
      fileType: ''
    }
  },
  watch: {
    srcPhoto: {
      immediate: true,
      handler() {
        this.getFileType();
      }
    }
  },
  mounted() {
    this.getFileType();
  },
  methods: {
    handleCloseImg() {
      this.dialogVisibleImg = false
    },
    pdfOpen() {
      window.open(this.srcPhoto)
    },
    zipOpen() {
      window.open(this.srcPhoto)
    },
    xlsxOpen() {
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(this.srcPhoto)}`);
    },
    unknownOpen() {
      window.open(this.srcPhoto)
    },
    imgOpen() {
      this.dialogVisibleImg = true;
    },
    getFileType() {
      // const lastDotIndex = this.srcPhoto.lastIndexOf('.');
      // const fileExtension = this.srcPhoto.substring(lastDotIndex + 1).toLowerCase();
      const fileExtension = this.srcPhoto.split('.').pop().toLowerCase();
      if (fileExtension.includes('jpg') || fileExtension.includes('jpeg') || fileExtension.includes('png')) {
        this.fileType = 'image';
      } else if (fileExtension.includes('pdf')) {
        this.fileType = 'pdf';
      } else if (fileExtension.includes('zip')) {
        this.fileType = 'zip';
      } else if (fileExtension.includes('xlsx')) {
        this.fileType = 'xlsx';
      } else {
        this.fileType = 'unknown';
      }
    }
  }
}
</script>

<style lang="less" scoped>
.seeStyle {
  color: #409EFF;
  // text-decoration: underline;
  text-decoration: none;
  cursor: pointer;
}
</style>