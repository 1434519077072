<style>
#app > div.main {
  overflow-x: hidden !important;
}
</style>
<style lang="less" scoped>
.index::-webkit-scrollbar {
  width: 0;
}
.index {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  .block-all {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    .block {
      width: 24%;
      height: 120px;
      border-radius: 15px;
      text-align: left;
      margin: 5px;
      position: relative;
      .blockInfo {
        color: #9199a2;
        font-size: 14px;
        padding-top: 30px;
        padding-left: 40%;
      }
      .iconImg {
        position: absolute;
        left: 15%;
        top: 50%;
        transform: translateY(-50%);
      }
      .money {
        font-size: 22px;
        padding-top: 10px;
        font-family: "PingFangSC-Semibold", "PingFang SC Semibold",
          "PingFang SC";
        font-weight: 650;
        padding-left: 40%;
      }
    }
  }
}
</style>
<style>
html {
  overflow-y: scroll;
}

/* :root {
  overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  position: absolute;
}

body {
  width: 100vw;
  overflow: hidden;
} */
</style>
<template>
  <div class="index">
    <!-- 数据展示 -->
    <!-- 月份 -->
    <el-form style="width: 100%; margin-left: 35%; padding-top: 30px">
      <el-form-item prop="month">
        <el-date-picker v-model="releaseFormInfoIndex.createTime"
                        @change="indexTime"
                        type="month"
                        style="width: 250px"
                        placeholder="请选择月份"
                        format="yyyy 年 MM 月"
                        value-format="yyyy-MM">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="block-all">
      <div class="block"
           :style="'background:'+(iconAarr[index]?iconAarr[index].color:'')"
           v-for="(item, index) in numArray"
           :key="index">
        <img :src="(iconAarr[index]?iconAarr[index].icon:'')"
             class="iconImg"
             alt="">
        <div class="blockInfo">{{ item.name }}</div>
        <div class="money">
          <count-to :start-val="0"
                    :end-val="item.num"
                    :duration="2000"
                    :decimals="item.unit=='元'?2:0" /><span style="font-size: 14px">{{ item.unit }}</span>
        </div>
      </div>
    </div>
    <!-- 月份 -->
    <el-form style="width: 100%; margin-left: 35%; margin-top: 30px">
      <el-form-item prop="month">
        <el-date-picker v-model="releaseFormInfo.createTime"
                        @change="gainTime"
                        type="month"
                        style="width: 250px"
                        placeholder="请选择月份"
                        format="yyyy 年 MM 月"
                        value-format="yyyy-MM">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div :style="{ width: '98%', height: '600px', margin: '0 auto' }">
      <lines :config="config"></lines>
    </div>
    <!-- 月份 -->
    <el-form style="width: 100%; margin-left: 30%; margin-top: 60px">
      <el-form-item prop="month">
        <el-date-picker v-model="releaseFormInfo.createTime"
                        type="month"
                        @change="columnTime"
                        style="width: 250px"
                        placeholder="请选择月份"
                        format="yyyy 年 MM 月"
                        value-format="yyyy-MM">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div :style="{ width: '98%', height: '600px', margin: '0 auto' }">
      <lines :config="config1"></lines>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import countTo from "vue-count-to"; //这个是什么组件
import api from "@/api/api.js";
import { get, post } from "@/api/index.js";
import lines from "@/components/lines.vue";
import { f2y, y2f } from "../../util/util.js";
import icon1 from "@/assets/img/1.png";
import icon2 from '@/assets/img/2.png'
import icon3 from '@/assets/img/3.png'
import icon4 from '@/assets/img/4.png'
import icon5 from '@/assets/img/01.png'
import icon6 from '@/assets/img/02.png'
import icon7 from '@/assets/img/03.png'
import icon8 from '@/assets/img/04.png'
import icon9 from '@/assets/img/001.png'
import icon10 from '@/assets/img/002.png'
import icon11 from '@/assets/img/003.png'
import icon12 from '@/assets/img/004.png'
export default {
  components: { countTo, lines },
  data() {
    return {
      iconAarr: [{ icon: icon1, color: "#eef5ff" }, { icon: icon2, color: "#FFF9F2" }, { icon: icon3, color: "#F2FBF8" }, { icon: icon4, color: "#FFEAED" }, { icon: icon5, color: "#eef5ff" }, { icon: icon6, color: "#FFF9F2" }, { icon: icon7, color: "#F2FBF8" }, { icon: icon8, color: "#FFEAED" }, { icon: icon9, color: "#eef5ff" }, { icon: icon10, color: "#FFF9F2" }, { icon: icon11, color: "#F2FBF8" }, { icon: icon12, color: "#FFEAED" }],
      config: {},
      config1: {},
      time: "",
      timeInfo: "",
      timeInfoIndex: "",
      indexLineArray: [],
      releaseForm: {
        createTime: "",
      },
      releaseFormInfo: {
        createTime: "",
      },
      releaseFormInfoIndex: {
        createTime: "",
      },
      numArray: [],
    };
  },
  mounted() {
    // 监听浏览器回退事件,返回上一级页面刷新
    (function () {
      var counter = 0;
      if (window.history && window.history.pushState) {
        window.onpopstate = function () {
          window.history.pushState('forward', null, '#');
          window.history.forward(1);
          //alert("不可回退");
          location.replace(document.referrer); //刷新
        };
      }
      window.history.pushState('forward', null, '#'); //在IE中必须得有这两行
      window.history.forward(1);
    })();
    // 首页统计
    this.homeList();
    // 首页折线图（接口）
    this.indexLine();
    // 首页柱形图（接口）
    this.indexColumnList();
    // 折线图展示
    //this.lineInfo();
    // 柱形图展示
    // this.ColumnInfo();
  },
  created() {
    this.defaultDate();
  },
  methods: {
    // 折线图时间改变获取
    gainTime(t) {
      // console.log(t, "时间改变");
      this.time = t;
      this.indexLine();
    },
    // 柱形时间
    columnTime(t) {
      this.timeInfo = t;
      this.indexColumnList();
    },
    // 首页数据时间
    indexTime(t) {
      this.timeInfoIndex = t;
      this.homeList();
    },
    //设置默认日期
    defaultDate() {
      //获取新的时间(2019.4.12）
      let date = new Date();
      //获取当前时间的年份转为字符串
      let year = date.getFullYear().toString(); //'2019'
      //获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接'0'
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1).toString()
          : (date.getMonth() + 1).toString(); //'04'
      //获取天，判断是否小于10，如果是在字符串前面拼接'0'
      let da =
        date.getDate() < 10
          ? "0" + date.getDate().toString()
          : date.getDate().toString(); //'12'
      //字符串拼接，开始时间，结束时间
      let end = year + "-" + month + "-" + da; //当天'2019-04-12'
      let beg = year + "-" + month + "-01"; //当月第一天'2019-04-01'
      this.releaseForm.createTime = beg; //将值设置给插件绑定的数据
      this.releaseFormInfo.createTime = beg; //将值设置给插件绑定的数据
      this.releaseFormInfoIndex.createTime = beg; //将值设置给插件绑定的数据
    },
    // 数据统计（第一部分）
    homeList() {
      const loading = this.$loading({
        lock: true,
        spinner: "loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      post(api.homeList, {
        agentId: this.$cookies.get("mchcompanyId"),
        month: this.timeInfoIndex,
      }).then((d) => {
        if (d.code == 0) {
          this.numArray = d.data;
          this.numArray.map((item => {
            if (item.unit == '元') {
              item.num = f2y(item.num)
            }
          }))
          loading.close();
        } else {
          this.$message.error(d.message);
          loading.close();
        }
      });
    },
    // 首页折线图（接口）
    indexLine() {
      const loading = this.$loading({
        lock: true,
        spinner: "loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      post(api.homeLineList, {
        agentId: this.$cookies.get("mchcompanyId"),
        month: this.time,
      }).then((d) => {
        // console.log(d, 69696)
        loading.close();
        this.config = {
          id: "myChart",
          title: "总代发统计",
          list: [
            { name: "金额", type: "line", data: [], smooth: true, },
            { name: "笔数", type: "line", data: [], smooth: true, },
          ],
          xAxis: [],
        };
        if (d.message == "暂无数据") {
          // this.config.list[0].data = [];
          // this.config.list[1].data = [];
          // this.config.xAxis = [];
        } else {
          for (var i = 0; i < d.data[0].homeLineLists.length; i++) {
            this.config.list[0].data.push(d.data[0].homeLineLists[i].num);
            this.config.list[1].data.push(d.data[1].homeLineLists[i].num);
            this.config.xAxis.push(d.data[0].homeLineLists[i].month);
          }
        }
      });
    },
    // 首页柱形图（接口）
    indexColumnList() {
      const loading = this.$loading({
        lock: true,
        spinner: "loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      post(api.homeColumnList, {
        agentId: this.$cookies.get("mchcompanyId"),
        month: this.timeInfo,
      }).then((d) => {
        loading.close();
        this.config1 = {
          id: "main",
          title: "商户交易Top10",
          list: [
            { name: "交易额", type: "bar", data: [], barWidth: 30 },
            { name: "交易笔数", type: "bar", data: [], barWidth: 30 },
          ],
          xAxis: [],
        };
        if (d.message == "暂无数据") {
        } else {
          for (var i = 0; i < d.data[0].homeLineLists.length; i++) {
            this.config1.list[0].data.push(d.data[0].homeLineLists[i].num);
            this.config1.list[1].data.push(d.data[1].homeLineLists[i].num);
            this.config1.xAxis.push(d.data[0].homeLineLists[i].month);
          }
        }
      });
    },
  },
};
</script>
