<style lang="less" scoped>
.mertrans {
  width: 100%;
  min-height: 100%;
  padding: 12px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: left;

  .box {
    width: 100%;

    /* // padding-top: 12px; */
    .main_button {
      text-align: left;
      margin-left: 36px;
    }

    .wrap {
      width: 100%;
      margin-top: 22px;
      /* // padding: 0px 12px; */
      box-sizing: border-box;

      .wraped {
        width: 100%;
      }
    }
  }

  .block {
    width: 100%;

    .list {
      width: 100%;
      text-align: left;
      padding: 20px;
      box-sizing: border-box;
      font-size: 14px;
      color: rgba(16, 16, 16, 100);

      .grid-content {
        width: 100%;
        margin-bottom: 20px;
        display: inline-block;
      }

      .baoguo {
        width: 100%;
        overflow: hidden;

        .zongji {
          float: left;
          font-weight: 500;
          font-size: 22px;
          color: #303133;
        }

        .anniu {
          float: right;
        }
      }

      .biankuang {
        width: 336px;
        height: 169px;
        border: 1px solid #6666;

        .dian {
          background-color: #52c41a;
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          vertical-align: middle;
          margin-left: 30px;
        }

        .jinqian {
          font-size: 20px;
          line-height: 89px;
          vertical-align: middle;
          margin-left: 20px;
        }

        .wenzi {
          margin-left: 89px;
          font-size: 22px;
          color: #666;
        }
      }
    }

    .list2 {
      text-align: left;
      padding: 100px;
      box-sizing: border-box;
      font-size: 14px;
      color: #101010;
      display: inline-block;

      .grid-content {
        width: 100%;
        margin-bottom: 20px;
        display: inline-block;
      }

      .button {
        margin: 0 auto;
        margin-top: 24px;
        height: 40px;
        line-height: 40px;
        background: #3083ff;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 1px;
        cursor: pointer;
        text-align: center;
      }

      .text {
        margin-top: 56px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        text-align: center;
      }
    }

    .col {
      width: 102%;
      height: 10px;
      background-color: #f2f2f2;
      margin-left: -12px;
    }
  }
}
</style>
<style>
.tradeManage .el-step__head.is-success {
  color: rgba(48, 131, 255, 1);
  border-color: rgba(48, 131, 255, 1);
}

.tradeManage .el-step__title.is-success {
  color: rgba(0, 0, 0, 0.65);
}

.tradeManage .is-process .el-step__icon.is-text {
  background: rgba(48, 131, 255, 1);
  border: none;
  color: #fff;
}

.tradeManage .el-table__fixed-right {
  right: 0 !important;
}
</style>


<template>
  <div class="mertrans">
    <div class="box" v-show="releaseType">
      <el-form ref="releaseForm" :model="releaseForm" label-width="120px">
        <el-row>
          <el-col :lg="18" :md="20">
            <el-row>
              <!-- <el-col :lg="8"
                      :md="12">
                <el-form-item label="企业ID：">
                  <el-input v-model="releaseForm.busiId"
                            placeholder="请输入企业ID"
                            size="small"></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :lg="8" :md="12">
                <el-form-item label="企业名称：">
                  <el-select @change="onInputChangeRen" v-model="releaseForm.busiId" filterable remote reserve-keyword
                    placeholder="请输入企业名称" :remote-method="remoteMethodRen" :loading="loading" style="width: 100%"
                    size="small">
                    <el-option v-for="item in qiyeRenOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12">
                <el-form-item label="服务商名称：">
                  <el-select v-model="releaseForm.serviceId" filterable placeholder="请输入服务商名称" :loading="loading"
                    style="width: 100%" size="small">
                    <el-option v-for="item in serviceList" :key="item.serviceId" :label="item.serviceName"
                      :value="item.serviceId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12">
                <el-form-item label="身份证：">
                  <el-input v-model="releaseForm.idCard" size="small" placeholder="请输入身份证"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12">
                <el-form-item label="创建时间：" v-if="tabPosition == 'left'">
                  <el-date-picker style="width:100%" :clearable="false" v-model="dateRange" value-format="yyyy-MM-dd"
                    type="daterange" :picker-options="queryPickerOptions" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" align="right">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="创建时间：" v-else>
                  <el-date-picker style="width:100%" :clearable="false" v-model="dateRange" value-format="yyyy-MM-dd"
                    type="daterange" :picker-options="queryPickerOptions1" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" align="right">
                  </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="创建时间：">
                                    <el-date-picker v-model="releaseForm.createDate"
                                                    type="daterange"
                                                    style="width: 100%"
                                                    range-separator="至"
                                                    start-placeholder="开始日期"
                                                    end-placeholder="结束日期"
                                                    value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                </el-form-item> -->
              </el-col>
            </el-row>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label=" ">
              <el-button type="primary" size="small" icon="el-icon-search" @click="search()">查询</el-button>
              <el-button type="primary" v-if="tabPosition == 'left'" size="small" icon="el-icon-arrow-up"
                @click="exportInfo()">导出</el-button>
              <el-button size="small" icon="el-icon-refresh-left" @click="reset()" class="chongzhi">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-radio-group v-model="tabPosition" @change="tabChange">
        <el-radio-button label="left">今年数据</el-radio-button>
        <el-radio-button label="right">历史数据</el-radio-button>
      </el-radio-group>
      <div class="wrap">
        <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" fit
          border :render-header="renderHeader" style="width: 100%">
          <el-table-column show-overflow-tooltip prop="companyName" fixed="left" label="企业名称" min-width="180">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="busiId" label="结算企业ID" width="200">
          </el-table-column>

          <el-table-column show-overflow-tooltip prop="agentName" label="渠道商名称" width="200">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="serviceName" label="服务商名称" width="200">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="userName" label="用户姓名">
          </el-table-column>
          <af-table-column show-overflow-tooltip prop="idCard" label="身份证">
          </af-table-column>
          <el-table-column show-overflow-tooltip prop="accNo" label="收款账户" width="200">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="signMainRateFee" label="渠道底价(%)" width="180">
            <template slot-scope="scope">
              {{ scope.row.signMainRateFee || '--' }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="amt" label="应发金额(原交易金额)" width="180">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.amt }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="amt" label="实发金额(元)" width="120">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.actualAmt }}</span>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column show-overflow-tooltip
                                     prop="addedValueTax"
                                     label="增值税(元)"
                                     width="100px">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.addedValueTax }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="incomeTax"
                                     label="个税(元)">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.incomeTax }}</span>
                            </div>
                        </template>
                    </af-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="serviceTax"
                                     label="服务费(元)"
                                     width="150">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.serviceTax }}</span>
                            </div>
                        </template>
                    </el-table-column> -->
          <el-table-column show-overflow-tooltip prop="deductAmt" label="扣除金额(元)" width="120px">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.deductAmt }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="createdAt" label="创建时间" fixed="right" width="200">
          </el-table-column>
        </el-table>
        <div class="wraped">
          <pagination :total="total" :currentPage="currentPage" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"></pagination>
        </div>
      </div>
    </div>
    <el-dialog title="银行订单列表" :visible.sync="dialogVisibleBank" width="80%" style="text-align: left"
      :before-close="handleCloseBank">
      <div class="wrap">
        <el-table border :data="tableDataBank" :render-header="renderHeader" show-overflow-tooltip fit
          style="width: 100%">
          <af-table-column show-overflow-tooltip prop="tradeOrderId" fixed label="订单ID">
          </af-table-column>
          <af-table-column show-overflow-tooltip prop="userName" label="收款人姓名">
          </af-table-column>
          <af-table-column show-overflow-tooltip prop="type" label="证件类型">
          </af-table-column>
          <el-table-column show-overflow-tooltip prop="accType" label="账户类型">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.accType | isaccType }}</span>
              </div>
            </template>
          </el-table-column>
          <af-table-column show-overflow-tooltip prop="accNo" label="收款账户">
          </af-table-column>
          <af-table-column show-overflow-tooltip prop="createTime" label="创建时间">
          </af-table-column>
          <af-table-column show-overflow-tooltip prop="successTime" label="成功时间">
          </af-table-column>
          <el-table-column show-overflow-tooltip prop="amt" label="任务结算(元)" width="150">
                <template slot-scope="scope">
              <div>
                                <span>{{ scope.row.amt }}元</span>
                            
              </div>
                        
            </template>
          </el-table-column>
          <af-table-column show-overflow-tooltip prop="notes" label="交易描述" fixed="right">
          </af-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 导出 -->
    <el-dialog title="导出" :visible.sync="dialogVisibleExport" width="30%">
      <el-form :model="ruleFormExport" :rules="rulesExport" ref="ruleFormExport" label-width="80px" class="demo-ruleForm">
        <el-form-item prop="month" label="月份">
          <el-date-picker v-model="ruleFormExport.month" type="month" style="width: 100%" size="small" placeholder="请选择月份"
            format="yyyy 年 MM 月" value-format="yyyy-MM">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="服务商" prop="serviceId">
          <el-select v-model="ruleFormExport.serviceId" @change="onSelectedDrug($event)" placeholder="请选择服务商" size="small"
            style="width:100%">
            <el-option v-for="(item, index) in serviceList" :key="index" :label="item.serviceName"
              :value="item.serviceId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleExport = false">取 消</el-button>
        <el-button type="primary" :loading="exportInfoLoading" @click="submitExport('ruleFormExport')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '../../components/pagination.vue';
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
import { SoftKey6W } from '../../assets/js/Syunew6.js';
import { f2y, y2f } from '../../util/util.js';
import moment from 'moment';
export default {
  data() {
    return {
      // 切换数据
      tabPosition: 'left',
      exportInfoLoading: false,
      // 服务商模糊搜索
      qiyeRenOptions1: [],
      statesRen1: [],
      loading: false,
      // 导出
      dialogVisibleExport: false,
      ruleFormExport: {
        month: '',
        serviceId: '',
        serviceName: ''
      },
      rulesExport: {
        month: [{ required: true, message: '请选择月份', trigger: 'change' }],
        serviceId: [{ required: true, message: '请选择服务商', trigger: 'change' }]
      },
      releaseType: true,
      shenheLoading: false,
      outTradeNo: '',
      payBankTradeOrder: '',
      companyId: '',
      count: 0,
      money: 1.22,
      zongCount: 0,
      fukuanMoney: 0.0,
      totalRen: null,
      pageSzieRen: 10,
      currentPageRen: 1,
      totalJie: null,
      pageSzieJie: 10,
      currentPageJie: 1,
      total: null,
      pageSzie: 10,
      currentPage: 1,
      totaled: null,
      pageSzied: 10,
      currentPageed: 1,
      merTradeOrder: {},
      valueRemit: false,
      tradeOrderId: '',
      fileList: [
        {
          name: 'food.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }
      ],
      dialogVisible: false,
      dialogVisibleBank: false,
      type: 1, //1详情2批量打款
      typename: '查看详情',
      activeIndex: 0,
      leaseForm: {
        taskName: '',
        accType: '',
        companyTaskId: ''
      },
      leaserules: {
        // accType: [
        //     { required: true, message: "请选择下发通道", trigger: "blur" },
        // ],
        taskName: [{ required: true, message: '请填写任务名称', trigger: 'blur' }],
        companyTaskId: [{ required: true, message: '请填写需求ID', trigger: 'blur' }]
      },
      ruleForm: {
        passwprd: '',
        payType: '0',
        userName: '',
        valuepin: ''
      },
      qiyeOptions: [],
      qiyeRenOptions: [],
      loading: false,
      timer: null,

      releaseFormed: {
        name: '',
        tradeStatus: '',
        payTradeOrder: ''
      },
      releaseForm: {
        // 商户ID
        busiId: '',
        // 企业名称
        // companyName: "",
        // 创建时间
        // createDate: ['', ''],
        isHis: 0,
        createTimeStart: moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD'),
        createTimeEnd: moment().endOf('day').format('YYYY-MM-DD'),
        // 身份证号
        idCard: '',
        serviceId: ''
      },
      dateRange: [
        moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD'),
        moment().endOf('day').format('YYYY-MM-DD ')
      ],
      queryPickerOptions: {
        // disabledDate(time) {
        //   return (
        //     time.getTime() > new Date(moment().endOf('year').format('YYYY-MM-DD')) ||
        //     time.getTime() < new Date(moment().startOf('year').format('YYYY-MM-DD'))
        //   );
        // }
      },
      queryPickerOptions1: {
        // disabledDate(time) {
        //   var year = new Date().getFullYear() - 1;
        //   return time.getTime() > new Date(year + '-12-31 23:59:59');
        // }
      },
      xiaOptions: [
        {
          value: '00',
          label: '银行'
        },
        {
          value: '01',
          label: '支付宝'
        },
        {
          value: '02',
          label: '微信'
        }
      ],
      jiaoyioptions: [
        {
          value: '00',
          label: '处理完成'
        },
        {
          value: '01',
          label: '待处理'
        },
        {
          value: '02',
          label: '处理中'
        },
        {
          value: '03',
          label: '已关闭'
        }
      ],
      jinduoptions: [
        {
          value: '00',
          label: '成功'
        },
        {
          value: '01',
          label: '待处理'
        },
        {
          value: '02',
          label: '失败'
        },
        {
          value: '03',
          label: '失败退回'
        },
        {
          value: '04',
          label: '处理中'
        },
        {
          value: '05',
          label: '已关闭'
        },
        {
          value: '10',
          label: '部分成功'
        }
      ],
      tableData: [],
      tableDataBank: [],
      qiyeRenOptions: [],
      statesRen: [],
      serviceList: []
    };
  },

  mounted() {
    this.serviceInfo(); //服务商
    this.ruleForm.userName = this.$cookies.get('merchantloginPhone');
  },
  created() {
    // this.defaultDate();
  },
  methods: {
    // 切换数据
    tabChange() {
      //今年数据
      if (this.tabPosition == 'left') {
        this.dateRange = [
          moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD '),
          moment().endOf('day').format('YYYY-MM-DD ')
        ];
        if (this.dateRange && this.dateRange.length === 2) {
          this.releaseForm.createTimeStart = this.dateRange[0];
          this.releaseForm.createTimeEnd = this.dateRange[1];
        }
        this.releaseForm.currentPage = 1;
        this.releaseForm.isHis = 0;
        this.queryPageData();
      } else if (this.tabPosition == 'right') {
        //历史数据
        var year = new Date().getFullYear() - 1;
        this.dateRange = [
          moment(year + '-12-27').format('YYYY-MM-DD '),
          moment(year + '-12-31').format('YYYY-MM-DD ')
        ];
        if (this.dateRange && this.dateRange.length === 2) {
          this.releaseForm.createTimeStart = this.dateRange[0];
          this.releaseForm.createTimeEnd = this.dateRange[1];
        }
        this.releaseForm.currentPage = 1;
        this.releaseForm.isHis = 1; //标识是否是历史数据
        this.queryPageData();
      }
    },
    onSelectedDrug(e) {
      let obj = {};
      obj = this.serviceList.find(item => {
        //这里的userList就是上面遍历的数据源
        return item.serviceId === e; //筛选出匹配数据
      });
      this.ruleFormExport.serviceName = obj.serviceName;
    },
    serviceInfo() {
      post(api.queryServiceList).then(res => {
        if (res.code == 0) {
          this.serviceList = res.data;
          this.releaseForm.serviceId = this.serviceList[0].serviceId;
          this.queryPageData();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 导出
    exportInfo() {
      this.dialogVisibleExport = true;
      this.ruleFormExport.month = '';
      this.ruleFormExport.serviceId = '';
      this.ruleFormExport.serviceName = '';
    },
    submitExport(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.exportInfoLoading = true;
          const link = document.createElement('a');
          this.axios({
            url: api.exportList,
            method: 'POST',
            responseType: 'arraybuffer', //arraybuffer
            headers: { Authorization: this.$cookies.get('esignmerchantsid') },
            params: {
              busiId: this.releaseForm.busiId,
              idCard: this.releaseForm.idCard,
              month: this.ruleFormExport.month,
              serviceId: this.ruleFormExport.serviceId,
              serviceName: this.ruleFormExport.serviceName,
              agentId: this.$cookies.get('mchcompanyId'),
              agentName: this.$cookies.get('agentName'),
              agentUserId: this.$cookies.get('agentUserId')
            }
          }).then(res => {
            if (!res) return;
            try {
              let enc = new TextDecoder('utf-8');
              res = JSON.parse(enc.decode(new Uint8Array(res.data))); //转化成json对象
              // console.log(res, 333);
              if (res.status == 'true') {
                this.$message.success(res.message);
                this.exportInfoLoading = false;
              } else {
                this.$message.error(res.message);
                this.exportInfoLoading = false;
              }
            } catch (err) {
              // 创建Blob对象，设置文件类型
              let blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel'
              });
              let objectUrl = URL.createObjectURL(blob); // 创建URL
              link.href = objectUrl;
              link.download = '商户交易数据'; // 自定义文件名
              link.click(); // 下载文件
              URL.revokeObjectURL(objectUrl); // 释放内存
              this.dialogVisibleExport = false;
              this.exportInfoLoading = false;
            }

            // console.log(res, "导出");
            // // 创建Blob对象，设置文件类型
            // let blob = new Blob([res.data], {
            //   type: "application/vnd.ms-excel",
            // });
            // let objectUrl = URL.createObjectURL(blob); // 创建URL
            // link.href = objectUrl;
            // link.download = "商户交易导出"; // 自定义文件名
            // link.click(); // 下载文件
            // URL.revokeObjectURL(objectUrl); // 释放内存
            // this.dialogVisibleExport = false;
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    // 企业名称列表
    remoteMethodRen(query) {
      // console.log(query, "44444");
      if (query !== '') {
        // this.loading = true;
        this.qiyeRenOptions = [];
        post(api.companyList, {
          companyName: query
        }).then(d => {
          // console.log(d, 9999);
          if (d.code == 0) {
            if (d.hasOwnProperty('data')) {
              this.statesRen = d.data;
              this.listRen = [];
              if (this.statesRen.length > 0) {
                this.listRen = this.statesRen.map(item => {
                  // console.log(item,'任务名称')
                  return {
                    value: `${item.busiId}`,
                    label: `${item.companyName}（商户ID:${item.busiId}）`
                  };
                });
                this.qiyeRenOptions = this.listRen.filter(item => {
                  return (
                    item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
                  );
                });
              } else {
                this.statesRen = [];
                this.listRen = [];
              }
            }
          } else {
            // console.log(d.message,'555555555555')
            this.$message.error(d.message);
          }
        });
        // setTimeout(() => {
        //   this.loading = false;
        //   console.log(this.list)

        // }, 100);
      } else {
        this.qiyeRenOptions = [];
      }
    },
    //任务名称
    onInputChangeRen(val) {
      //向父组件传递选择地值，调用父组件setMajor把选中值赋给父组件
      // console.log(val, "djdjddjj");
      let obj = {};
      obj = this.qiyeRenOptions.find(item => {
        // console.log(item, '3333');
        //model就是上面的数据源
        return item.value === val; //筛选出匹配数据
      });
      // console.log(obj, 'zidiandd');
      this.releaseForm.busiId = obj.value;
    },
    // // 设置默认时间
    // defaultDate() {
    //     // 获取最新时间
    //     let date = new Date();
    //     // 获取当前时间的年份转为字符串
    //     let year = date.getFullYear().toString(); //2021
    //     // 获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接0
    //     let month =
    //         date.getMonth() + 1 < 10
    //             ? '0' + (date.getMonth() + 1).toString()
    //             : (date.getMonth() + 1).toString();
    //     // 获取天，判断是否小于10，如果是在字符串前面拼接0
    //     let day =
    //         date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString();
    //     //字符串拼接，开始时间和结束时间
    //     let beg = year + '-' + month + '-' + '01';
    //     let end = year + '-' + month + '-' + day;
    //     // console.log(beg, end, 888);
    //     // 赋值
    //     this.releaseForm['createDate'] = [beg, end];
    // },
    // 表格
    renderHeader(h, data) {
      return h('span', [
        h('el-tooltip'),
        {
          attrs: {
            class: 'item',
            effect: 'dark',
            content: data.column.label
          }
        },
        [h('span', data.column.label)]
      ]);
    },
    baoguoBtn(val) {
      // console.log(val, "3333");
      if (val == '1') {
        this.submitForm();
      }
    },
    selectInit(row) {
      if (this.valueRemit) {
        return false;
      }
      return true;
    },
    isAllRemit(val) {
      this.itemIds = [];
      if (val) {
        this.shenheLoading = true;
        // console.log(this.idsData.length,'33333')
        if (this.idsData.length > 0) {
          this.shenheLoading = false;
          this.itemIds = this.idsData;
        }
      }
    },
    search() {
      if (this.dateRange && this.dateRange.length === 2) {
        this.releaseForm.createTimeStart = this.dateRange[0];
        this.releaseForm.createTimeEnd = this.dateRange[1];
      } else {
        this.releaseForm.createTimeStart = '';
        this.releaseForm.createTimeEnd = '';
      }
      if (this.tabPosition == 'left') {
        this.releaseForm.isHis = 0;
      } else {
        this.releaseForm.isHis = 1;
      }
      this.queryPageData();
    },

    queryBankOrder(row, size) {
      (this.tradeOrderId = row.tradeOrderId),
        post(api.getBankOrder, {
          tradeOrderId: row.tradeOrderId,
          currentPage: size || 1,
          pageSize: this.pageSzie
        }).then(
          d => {
            if (d.code === 0) {
              this.dialogVisibleBank = true;
              this.tableDataBank = d.data.rows;
            } else {
              this.$message.error(d.message);
            }
          },
          err => {
            //error callback
            this.shenheLoading = false;
          }
        );
    },
    handleCloseBank() {
      this.dialogVisibleBank = false;
    },

    queryPageData(size) {
      const loading = this.$loading({
        lock: true,
        spinner: 'loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      post(api.transDetailByAgent, {
        currentPage: size || 1,
        pageSize: this.pageSzie,
        // 商户ID
        busiId: this.releaseForm.busiId,
        // 企业名称 companyName: this.releaseForm.companyName,
        // 创建时间
        createTimeStart: this.releaseForm.createTimeStart,
        createTimeEnd: this.releaseForm.createTimeEnd,
        isHis: this.releaseForm.isHis,
        // 身份证号
        idCard: this.releaseForm.idCard,
        serviceId: this.releaseForm.serviceId
      }).then(
        d => {
          loading.close();
          if (d.code === 0) {
            // console.log(d, "!!!!!!!!!!!!");
            if (d.hasOwnProperty('data')) {
              this.total = d.data.count;
              this.currentPage = d.data.currentPage;
              this.tableData = d.data.rows;
              if (this.tableData) {
                this.tableData.map(item => {
                  item.addedValueTax = f2y(item.addedValueTax);
                  item.amt = f2y(item.amt);
                  item.deductAmt = f2y(item.deductAmt);
                  item.incomeTax = f2y(item.incomeTax);
                  item.serviceTax = f2y(item.serviceTax);
                  item.actualAmt = f2y(item.actualAmt);
                });
              }
            } else {
              this.total = 0;
              this.currentPage = 1;
              this.pageSzie = 10;
              this.tableData = [];
            }
          } else {
            this.$message.error(d.message);
            this.total = 0;
            this.currentPage = 1;
            this.pageSzie = 10;
            this.tableData = [];
          }
        },
        err => {
          //error callback
        }
      );
    },
    goBack() {
      this.releaseType = true;
      this.activeIndex = 0;
      this.leaseForm = {
        taskName: '',
        accType: '',
        companyTaskId: ''
      };
    },
    reset() {
      this.releaseForm = {
        // 商户ID
        busiIds: '',
        // 企业名称
        // companyName: "",
        // 企业id
        busiId: '',
        // 创建时间
        // createDate: ["", ""],
        // 身份证号
        idCard: '',
        serviceId: this.releaseForm.serviceId
      };
      if (this.tabPosition == 'left') {
        //重置今年数据
        this.dateRange = [
          moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
        ];
        if (this.dateRange && this.dateRange.length === 2) {
          this.releaseForm.createTimeStart = this.dateRange[0];
          this.releaseForm.createTimeEnd = this.dateRange[1];
        }
        this.releaseForm.isHis = 0;
      } else if (this.tabPosition == 'right') {
        //重置历史数据
        var year = new Date().getFullYear() - 1;
        this.dateRange = [
          moment(year + '-12-27').format('YYYY-MM-DD HH:mm:ss'),
          moment(year + '-12-31').format('YYYY-MM-DD HH:mm:ss')
        ];
        if (this.dateRange && this.dateRange.length === 2) {
          this.releaseForm.createTimeStart = this.dateRange[0];
          this.releaseForm.createTimeEnd = this.dateRange[1];
        }
        this.releaseForm.isHis = 1;
      }
      this.queryPageData(1);
    },
    handleSizeChange(val) {
      this.pageSzie = val;
      // console.log(`每页 ${val} 条aaaaa`);
      this.queryPageData();
    },
    handleCurrentChange(size) {
      // console.log(size);
      this.queryPageData(size);
    },
    handleSizeChangeed(val) {
      this.pageSzied = val;
      // console.log(`每页 ${val} 条aaaaa`);
      this.baoguoPost();
    },
    handleCurrentChangeed(size) {
      // console.log(size);
      this.baoguoPost(size);
    },

    handleSizeChangeRen(val) {
      this.pageSzieRen = val;
      // console.log(`每页 ${val} 条aaaaa`);
      this.fenyeRenPost();
    },
    handleCurrentChangeRen(size) {
      // console.log(size);
      this.fenyeRenPost(size);
    },

    handleSizeChangeJie(val) {
      this.pageSzieJie = val;
      // console.log(`每页 ${val} 条aaaaa`);
      this.queRenPost();
    },
    handleCurrentChangeJie(size) {
      // console.log(size);
      this.queRenPost(size);
    }
  },
  components: {
    pagination: pagination,
    CountTo: () => import('vue-count-to')
  }
};
</script>
