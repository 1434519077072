/* ============
 * Vuex Store
 * ============
 *
 * The store of the application
 *
 * http://vuex.vuejs.org/en/index.html
 */

import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import * as actions from './actions';
import getters from './getters';

// Modules
import account from './modules/account';
import auth from './modules/auth';
import menu from './modules/menu';
import side from './modules/side';
import loading from './modules/loading';
import app from './modules/app';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    /**
     * Assign the actions to the store
     */
    actions,

    /**
     * Assign the getters to the store
     */
    getters,

    /**
     * Assign the modules to the store
     */
    modules: {
        account,
        auth,
        menu,
        side,
        loading,
        app,
    },

    /**
     * If strict mode should be enabled
     */
    strict: debug,

    /**
     * Plugins used in the store
     */
    plugins: debug ? [createLogger()] : [],
});
