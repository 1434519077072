<template>
    <div class="navigationBar">
        <div class="title"
             @click="IndexPhoto()">
            <!-- <img
        src="https://gsb-zc.oss-cn-beijing.aliyuncs.com//zc_367101606117738511202023154858511logo.png"
        alt=""
        style="vertical-align: middle;padding-right:15px"
        width="32px"
        height="32px"
      /> -->
            <img src="../assets/img/logo.png"
                 alt=""
                 style="vertical-align: middle;padding-right:15px"
                 width="30px"
                 height="30px" />
            <span style="
          display: inline-block;
          margin: 0;
          color: #fff;
          font-weight: 600;
          line-height: 50px;
          font-size: 15px;
          font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
          vertical-align: middle;
        ">
                {{nameInfo}}
            </span>
        </div>
        <!-- 渠道商头部 -->
        <div class="my"
             v-if="topStatus==2">
            <el-select v-model="optionsKey"
                       filterable
                       placeholder="请选择"
                       @change="onInputPost">
                <el-option v-for="(item, key) in auOptions"
                           :key="key"
                           :label="item.label"
                           :value="item.value"></el-option>
            </el-select>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <el-dropdown @command="menuTrigger"
                         trigger="click">
                <span class="el-dropdown-link">
                    <i class="el-icon-user el-icon--right"></i>&nbsp;&nbsp;{{
            merchantloginPhone
          }}
                </span>
                <el-dropdown-menu slot="dropdown"
                                  style="width: 130px">
                    <!-- <el-dropdown-item command="a">个人中心</el-dropdown-item> -->
                    <el-dropdown-item icon="el-icon-unlock"
                                      command="/password">
                        账户密码</el-dropdown-item>
                    <!-- <el-dropdown-item icon="el-icon-lock" command="/Passworded">
              打款密码</el-dropdown-item
            > -->
                    <el-dropdown-item icon="el-icon-back"
                                      command="/login">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- 中间页头部 -->
        <div class="my"
             v-if="topStatus==1">
            <el-select v-model="optionsKey"
                       filterable
                       placeholder="请选择"
                       @change="onInputPostOne">
                <el-option v-for="(item, key) in auOptions"
                           :key="key"
                           :label="item.label"
                           :value="item.value"></el-option>
            </el-select>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span class="el-dropdown-link">
                <i class="el-icon-user el-icon--right"></i>&nbsp;&nbsp;{{
            merchantloginPhoneInfo
          }}
            </span>
        </div>
        <exitPassword :dialogInvoice="dialogInvoice"
                      @handleClose="handleClose"
                      :dialogTitle="dialogTitle"></exitPassword>
        <!-- 未读消息弹框 -->
        <div class="dialog">
            <el-dialog title="未读消息列表"
                       :visible.sync="dialogVisibleNo"
                       width="780px"
                       :showClose="false">
                <div class="message">
                    <div class="message-insert"
                         v-for="(item,index) in tableData"
                         :key="index">
                        <div class="message-block">
                            <div style="max-width:500px;position: relative">
                                <span class="list-circle"></span>
                                <!-- <span>{{'['+messageTypeObj[item.messageType]+']'}}</span> -->
                                <span style="padding-left:11px;display:inline-block;font-size:15px;font-weight:500">{{'['+messageTypeObj[item.messageType]+']'}} {{item.title}}</span>
                            </div>
                            <div>{{item.createTime}}</div>
                        </div>
                        <div class="message-content"
                             v-html="item.content">
                            {{item.content}}
                        </div>
                    </div>
                </div>

                <span slot="footer"
                      class="dialog-footer">
                    <el-button type="primary"
                               @click="handleReaded">我已知晓</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import exitPassword from '@/components/exitPassword';
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
import store from '../store';
import { mapState } from 'vuex';

export default {
    name: 'navigationBar',
    inject: ['reload'], //注入reload方法
    data() {
        return {
            // 弹框
            dialogVisibleNo: false,
            tableData: [],
            listQueryReade: {
                ids: []
            },
            messageTypeObj: {
                1: '系统消息'
            },
            merchantloginPhoneInfo: '',
            // 头部判断状态
            topStatus: '1',
            hbToken: '',
            nameInfo: '',
            activeIndex: '/',
            merchantloginPhone: '',
            dialogInvoice: false,
            dialogTitle: '修改账户密码',
            optionsKey: '',
            auOptions: [],
            menuArr: [
                {
                    name: '首页',
                    path: '/',
                    submenu: []
                },
                {
                    name: '商户中心',
                    path: '/merchants',
                    submenu: [
                        {
                            name: '客户管理',
                            team: [
                                { name: '商户信息', path: '/merchants/businessInformation' },
                                { name: '签约信息', path: '/merchants/contractInformation' },
                                { name: '地址信息', path: '/merchants/addressInformation' },
                                { name: '抬头信息', path: '/merchants/lookUpInformation' }
                            ]
                        }
                    ]
                },
                {
                    name: '业务中心',
                    path: '/trading',
                    submenu: [
                        {
                            name: '订单管理',
                            team: [{ name: '订单信息', path: '/trading/orderInformation' }]
                        },
                        {
                            name: '个体户管理',
                            team: [
                                { name: '用户信息', path: '/trading/userInformation' },
                                { name: '用户签约', path: '/trading/usersSignUp' }
                            ]
                        },
                        {
                            name: '发票管理',
                            team: [
                                { name: '发票申请', path: '/trading/invoiceApplyFor' },
                                { name: '发票管理', path: '/trading/invoiceManagement' }
                            ]
                        }
                    ]
                },
                {
                    name: '财务中心',
                    path: '/financial',
                    submenu: [
                        {
                            name: '资金管理',
                            team: [
                                { name: '资金账户', path: '/financial/capitalAccount' },
                                { name: '充值申请', path: '/financial/topUpApplication' },
                                { name: '资金交易', path: '/financial/cashTransactions' },
                                { name: '资金流水', path: '/financial/capitalFlows' }
                            ]
                        }
                    ]
                },
                {
                    name: '数据中心',
                    path: '/information',
                    submenu: [{ name: '暂无', team: [{ name: '暂无', path: '' }] }]
                },
                {
                    name: '系统中心',
                    path: '/system',
                    submenu: [
                        {
                            name: '信息维护',
                            team: [
                                { name: '注册地信息', path: '/system/registered' },
                                { name: '经营范围信息', path: '/system/businessScope' },
                                { name: '交付商信息', path: '/system/delivery' },
                                { name: '发票内容信息', path: '/system/invoiceContent' }
                            ]
                        }
                    ]
                }
            ]
        };
    },
    components: {
        exitPassword: exitPassword
    },
    mounted() {
        // 判断顶部的下拉渠道
        this.getHBToken();
        this.nameList();
        // 未读消息弹框
        this.listQueryReade.ids = [];
        this.messageForceRemindList();
        // 存入的伙伴组织id
        // this.onInputPostOne()
    },
    created() {
        // this.merchantloginPhone = this.$cookies.get("merchantloginPhone") || "";
        this.merchantloginPhone = this.$cookies.get('merchantloginNickname')
            ? this.$cookies.get('merchantloginNickname')
            : this.$cookies.get('merchantloginPhone');
        this.activeIndex = '/' + this.$router.history.current.path.split('/')[1];

        for (let i = 0; i < this.menuArr.length; i++) {
            if (this.activeIndex == this.menuArr[i].path) {
                this.$emit('handleSelect', '', this.menuArr[i].submenu);
            }
        }
    },
    computed: {
        ...mapState({
            organizationId: state => state.app.organizationId
        }),
        // 我们使用计算属性来获取到当前点击的菜单的路由路径，然后设置default-active中的值
        // 使得菜单在载入时就能对应高亮
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            // if set path, the sidebar will highlight the path you set
            // 可以在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        }
    },
    props: {
        // menuArr:Array
    },
    methods: {
        // 弹框
        messageForceRemindList() {
            post(api.messageForceRemindList, {}).then(res => {
                // console.log(res, '未读列表');
                var menuList = JSON.parse(localStorage.getItem('store')).menu.menus;
                for (var i = 0; i < menuList.length; i++) {
                    if (menuList[i].name == '消息通知') {
                        this.dialogVisibleNo = true;
                        this.one = '1';
                    } else {
                        this.two = '2';
                        this.dialogVisibleNo = false;
                    }
                }
                if (res.code === 0) {
                    this.tableData = res.data;
                    if (this.tableData.length > 0 && this.one == '1') {
                        this.dialogVisibleNo = true;
                    } else {
                        this.dialogVisibleNo = false;
                    }
                }
            });
        },
        // 已读按钮
        handleReaded() {
            // console.log(this.tableData);
            this.tableData.map(item => {
                this.listQueryReade.ids.push(item.id);
            });
            post(api.messageReadMessage, this.listQueryReade).then(res => {
                // console.log(res, '已读按钮');
                if (res.code === 0) {
                    this.$notify({
                        title: '成功',
                        message: res.message,
                        type: 'success',
                        duration: '2000'
                    });
                    this.dialogVisibleNo = false;
                    this.messageForceRemindList();
                } else {
                    this.$notify({
                        title: '失败',
                        message: res.message,
                        type: 'fail',
                        duration: '2000'
                    });
                }
            });
        },
        handleClose() {
            this.dialogVisibleNo = false;
        },

        getHBToken() {
            let key = 'HB-TOKEN-TEST';
            if (window.location.hostname.toLowerCase().indexOf('bpo-agent-test') == -1) {
                key = 'HB-TOKEN';
            }
            if (localStorage.getItem('routerStatus') == 'true') {
                if (this.$cookies.get(key) == null && key == 'HB-TOKEN-TEST') {
                    window.location.href = 'https://hbtest.gongsibao.com/login';
                    this.$message.error('伙伴不存在');
                } else if (this.$cookies.get(key) == null && key == 'HB-TOKEN') {
                    window.location.href = 'https://hb.gongsibao.com/login';
                    this.$message.error('伙伴不存在');
                }
            }
            this.hbToken = this.$cookies.get(key);
            // console.log('hbtoken-----', this.hbToken);
            //   let key = "";
            //   if (window.location.hostname.toLowerCase().indexOf("bpo-agent-test") == -1) {
            //     key = "HB-TOKEN";
            //     if (this.$cookies.get(key) == '') {
            //       window.location.href = "https://hb.gongsibao.com/login"
            //     }
            //   } else {
            //     key = "HB-TOKEN-TEST"

            //   }
            //   this.hbToken = this.$cookies.get(key);
            //   // console.log("hbtoken-----", this.hbToken,111111)
        },
        // 头部图标和顶部渠道
        nameList() {
            if (localStorage.getItem('routerStatus') == 'true') {
                this.nameInfo = '渠道商';
                this.hbuser();
                this.topStatus = 1;
            } else {
                this.nameInfo = '渠道商';
                this.qiyePost();
                this.topStatus = 2;
            }
        },
        // 点击logo跳到首页
        IndexPhoto() {
            this.$router.push({ path: '/' });
        },
        //企业名称下拉
        qiyePost() {
            post(api.getAgentList).then(
                d => {
                    if (d.code === 0) {
                        for (let i = 0; i < d.data.length; i++) {
                            var obj = {
                                label: d.data[i].agentName,
                                value: String(d.data[i].id),
                                // loginKey: d.data[i].loginKey,
                                resourceDTOList: JSON.stringify(d.data[i].resourceDTOList),
                                userId: d.data[i].userId
                            };
                            this.auOptions.push(obj);
                            // // console.log(this.auOptions, "下拉");
                            this.optionsKey = this.$cookies.get('mchcompanyId');
                        }
                    }
                },
                err => {
                    //error?callback
                }
            );
        },
        // 中间页头部
        onInputPostOne(val) {
            const loading = this.$loading({
                lock: true,
                spinner: 'loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            //   localStorage.setItem('organizationId', val)
            // this.$cookies.set('organizationId',val)
            let obj = {};
            obj = this.auOptions.find(item => {
                return (item = obj);
            });
            // console.log(obj, 962256223);
            this.$store.commit('ORGANIZATIONNAME', obj.label);
            this.$store.commit('ROLE', obj.role);
            this.changeOrg();
            loading.close();
        },
        // 切换组织机构
        changeOrg() {
            //   // console.log(document.cookie,741)
            var p = {
                organizationId: this.optionsKey,
                token: this.hbToken
                // 王岩伙伴端
                //  token: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiZmNfcmVzIiwidWNfcmVzIiwiYXV0aF9yZXMiXSwiZXhwIjoxNjM0NDQxOTUzLCJ1c2VyX25hbWUiOiJ7XCJuaWNrTmFtZVwiOlwi54Sm57OW55m-5LmQ5YawXCIsXCJ1c2VySWRcIjpcIjE0MzQ3NjI3ODM1MTA1NjA3NzBcIixcInVzZXJuYW1lXCI6XCIxNjYzMjIzMDIyMVwifSIsImp0aSI6IjQwYjkzMGMxLTg5NGEtNDBmZi05YTVmLTM2NjQ4ODQ0YTQ3OCIsImNsaWVudF9pZCI6InBhcnRuZXIiLCJzY29wZSI6WyJBTEwiXX0.Nt4MLAdi0VXV1tTHXA81vCzuHWZ4CYnUQ2SXwJRRX4s"
                //
                // token: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiZmNfcmVzIiwidWNfcmVzIiwiYXV0aF9yZXMiXSwiZXhwIjoxNjM1MDQzNjcyLCJ1c2VyX25hbWUiOiJ7XCJuaWNrTmFtZVwiOlwi546L5L-K5pqWXCIsXCJ1c2VySWRcIjpcIjE0NDEyMzE5NjI0NTQ4NTk3NzhcIixcInVzZXJuYW1lXCI6XCIxMzEzMTk5NjAwOVwifSIsImp0aSI6IjkwMmUwNmRmLTk1YjgtNDNmOC1iZDgwLTliNzhkZmVmNTQwOSIsImNsaWVudF9pZCI6InBhcnRuZXIiLCJzY29wZSI6WyJBTEwiXX0.iS-2jHELfYnbYXX_Ggi7E0--Kae86JDom8E6TwyCE2M',
            };
            post(api.changeOrg, p).then(res => {
                // console.log(res);
                if (res.code == 0) {
                    this.$store.commit('ORAGANIZATIONID', this.optionsKey);
                    location.reload(); //你这里为什么又刷新页面 我切换之后刷新了，现在就是vuex存得另一个人进来害是上次得
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取伙伴端
        hbuser() {
            post(api.hbuser, {
                //  昆哥发我的
                token: this.hbToken
                // 王岩伙伴端
                // token: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiZmNfcmVzIiwidWNfcmVzIiwiYXV0aF9yZXMiXSwiZXhwIjoxNjM0NDQxOTUzLCJ1c2VyX25hbWUiOiJ7XCJuaWNrTmFtZVwiOlwi54Sm57OW55m-5LmQ5YawXCIsXCJ1c2VySWRcIjpcIjE0MzQ3NjI3ODM1MTA1NjA3NzBcIixcInVzZXJuYW1lXCI6XCIxNjYzMjIzMDIyMVwifSIsImp0aSI6IjQwYjkzMGMxLTg5NGEtNDBmZi05YTVmLTM2NjQ4ODQ0YTQ3OCIsImNsaWVudF9pZCI6InBhcnRuZXIiLCJzY29wZSI6WyJBTEwiXX0.Nt4MLAdi0VXV1tTHXA81vCzuHWZ4CYnUQ2SXwJRRX4s'
                //
                // token: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiZmNfcmVzIiwidWNfcmVzIiwiYXV0aF9yZXMiXSwiZXhwIjoxNjM1MDQzNjcyLCJ1c2VyX25hbWUiOiJ7XCJuaWNrTmFtZVwiOlwi546L5L-K5pqWXCIsXCJ1c2VySWRcIjpcIjE0NDEyMzE5NjI0NTQ4NTk3NzhcIixcInVzZXJuYW1lXCI6XCIxMzEzMTk5NjAwOVwifSIsImp0aSI6IjkwMmUwNmRmLTk1YjgtNDNmOC1iZDgwLTliNzhkZmVmNTQwOSIsImNsaWVudF9pZCI6InBhcnRuZXIiLCJzY29wZSI6WyJBTEwiXX0.iS-2jHELfYnbYXX_Ggi7E0--Kae86JDom8E6TwyCE2M',
            }).then(
                d => {
                    if (d.code == 0) {
                        localStorage.setItem('nickName', d.data.nickName);
                        localStorage.setItem('mobile', d.data.username);
                        localStorage.setItem('userId', d.data.userId);
                        this.$store.commit('ROLE', d.data.orgList[0].role);
                        this.$store.commit('ORAGANIZATIONID', d.data.orgList[0].organizationId);
                        this.$store.commit('MOBILE', d.data.username);
                        for (let i = 0; i < d.data.orgList.length; i++) {
                            // 把当前组织的id存入session中
                            var obj = {
                                label: d.data.orgList[i].organizationName,
                                value: String(d.data.orgList[i].organizationId),
                                role: d.data.orgList[i].role,
                                mobile: d.data.username
                            };
                            this.auOptions.push(obj);
                            this.optionsKey = d.data.orgList[0].organizationId;
                            // 头部名字
                            this.merchantloginPhoneInfo = d.data.username;
                        }
                    } else {
                        this.$message.error(d.message);
                    }
                },
                err => {
                    //error?callback
                }
            );
        },
        // 渠道商头部
        onInputPost(val) {
            //向父组件传递选择地值，调用父组件setMajor把选中值赋给父组件
            let obj = {};
            obj = this.auOptions.find(item => {
                //model就是上面的数据源
                return item.value === val; //筛选出匹配数据
            });
            post(api.reToken, {
                companyId: val,
                userId: obj.userId
            }).then(
                d => {
                    // console.log(d, '小好测试111111111111111');
                    if (d.code == 0) {
                        this.$cookies.set('mchcompanyId', val);
                        // this.$forceUpdate();
                        store.dispatch('getMenu', JSON.parse(obj.resourceDTOList));
                        if (this.$store.state.menu.menus.length > 0) {
                            this.$router.push({
                                path: this.$store.state.menu.menus[0].path
                            });
                            // // console.log(this.$store.state.menu.menus[0].childrens[0].path)
                        }
                        location.reload();
                        // this.reload();
                        // // console.log(val, "小号测试djdjddjj");
                    } else {
                        this.$message.error(d.message);
                    }
                    //success callback
                },
                err => {
                    //error callback
                }
            );
        },
        handleClose() {
            this.dialogInvoice = false;
        },
        handleSelect(key, keyPath) {
            // // console.log(key,keyPath, '菜单点击事件')
            for (let i = 0; i < this.menuArr.length; i++) {
                if (key == this.menuArr[i].path) {
                    this.$emit('handleSelect', key, this.menuArr[i].submenu);
                }
            }
        },
        menuTrigger(command) {
            // // console.log('?????????',command)
            if (command == '/login') {
                // localStorage.removeItem('token');
                this.$confirm('确定注销并退出系统吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // // console.log(d.code,'小好测试进入退出登录111111111111111')
                    this.$cookies.remove('esignmerchantsid');
                    this.$cookies.remove('merchantloginPhone');
                    this.$cookies.remove('permissions');
                    localStorage.removeItem('store');
                    this.$cookies.remove('mchcompanyId');
                    // // console.log(this.$cookies.get("esignmerchantsid"),'小好测试删除token退出登录111111111111111')
                    this.$cookies.remove('token_kick');
                    this.$router.push({ path: '/login' });
                });
            }
            if (command == '/Passworded') {
                this.dialogTitle = '修改打款密码';
                this.dialogInvoice = true;
                // // console.log(typeof this.dialogInvoice, "4444");
            }
            if (command == '/password') {
                this.dialogInvoice = true;
                this.dialogTitle = '修改账户密码';
                // // console.log(typeof this.dialogInvoice, "4444");
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.navigationBar {
    width: 100%;
    height: 58px;
    background-color: #fff;
    overflow: hidden;
    .title {
        float: left;
        width: 182px;
        height: 100%;
        line-height: 58px;
        text-align: center;
        background: #002140;
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
    }
    .menu {
        float: left;
        height: 100%;
        padding-left: 50px;
    }
    .my {
        float: right;
        height: 100%;
        line-height: 58px;
        margin-right: 50px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        cursor: pointer;
    }
    .message {
        text-align: left;
        padding: 15px;
        background: #fafbfc;
        padding-bottom: 0;
    }

    .message-insert {
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 10px;
        padding-bottom: 20px;
    }
    .message-content {
        padding-left: 11px;
        margin-top: 10px;
    }
    .list-circle {
        position: absolute;
        left: 0px;
        top: 7px;
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #c6c6c6;
        border-radius: 50%;
        margin-right: 5px;
    }
    .message-block {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}
</style>

<style>
.navigationBar .el-menu--horizontal > .el-menu-item {
    width: 130px;
    height: 58px;
    line-height: 58px;
}
.navigationBar .el-menu.el-menu--horizontal {
    border-bottom: none;
}
.el-dropdown-menu__item {
    padding: 0 16px !important;
}
.el-dropdown-menu__item i {
    margin-right: 16px !important;
}
</style>
