import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import './assets/iconfont/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css';
import VueCookies from 'vue-cookies'
import moment from "vue-moment"
import AFTableColumn from 'af-table-column'
import store from './store';
import '@/style/body.less'
import directives from './directives';
import axios from 'axios'
// import _ from 'underscore';
import './common';

// axios
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios)

// 滑块验证码
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

// 数字动态滚动
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 性能优化
import 'default-passive-events'



// window._ = window._ || _;

// import Util from './util/util';
// window.Util = window.Util || Util;
Vue.use(AFTableColumn)
Vue.use(VueCookies)
Vue.use(moment); //实例
Vue.directive('waves', directives.waves);
Vue.directive('permission', directives.permission);
Vue.prototype.$cookies = VueCookies;
store.dispatch('checkAuthentication');
// if (process.env.NODE_ENV === "development") {
//   // console.log("开发环境");
//   url = null
// }else {
//   // console.log("生产环境")
//   url = 'rongketech.com'
// }
import "@/style/style.less";
let url = '';
let env = process.env.NODE_ENV
let flag = process.env.VUE_APP_FLAG
    // console.log("--------current hostname---------", window.location.hostname);
if (env == 'production') {
    if (flag == 'dist') { //测试环境
        // url = '.bpo-merchant.bpo'
        // url = '.agent.bpo'
        url = null
    } else { //生产环境
        // url = 'agent.htangcloud.com'
        url = null
    }
    // 渠道对接
    // let host = window.location.hostname || "";
    // let hnArr = host.split(".");
    // // console.log(hnArr)
    // let domainArr = [];
    // let idx = 0;
    // for (let item of hnArr) {
    //     if (idx == 0) {
    //         idx++;
    //         continue;
    //     }
    //     domainArr.push(item);
    // }
    // url = "." + domainArr.join(".");
} else { //开发环境
    url = null
}
// 配置失效时间，路径，域名
VueCookies.config('1d', '/', url)

// import {
//   auditTransform,
//   invoiceTransform,
//   personAuthResult,
//   sexAuthResult,
//   staskStatusResult,
//   taskStatustForm,
//   signStatusForm,
//   applyStatusForm,
//   xueliStatusForm,
//   confirmStatusForm,
//   xiaStatusResult

// } from './filters/textTransform.js';
import * as filters from './filters/textTransform.js' // global filters

Object.keys(filters).forEach(key => {
    // // console.log(filters[key],'555555')
    Vue.filter(key, filters[key])
})

// Vue.filter('audit', auditTransform);
// Vue.filter('invoice', invoiceTransform);
// Vue.filter('authresult', personAuthResult);
// Vue.filter('sexresut', sexAuthResult);
// Vue.filter('staskresult', staskStatusResult);
// Vue.filter('xiajiastatus', xiaStatusResult);
// Vue.filter('taskstatus', taskStatustForm);
// Vue.filter('signstatus', signStatusForm);
// Vue.filter('applystatus', applyStatusForm);
// Vue.filter('xuelistatus', xueliStatusForm);
// Vue.filter('confirmStatus', confirmStatusForm);
//全局修改默认配置
ElementUI.Dialog.props.closeOnClickModal.default = false;
console.info(ElementUI.Dialog);
import {
    message
} from './util/resetMessage';
import loading from './store/modules/loading'
Vue.use(ElementUI)
Vue.prototype.$message = message;
Vue.config.productionTip = false
    // 引导页
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
Vue.prototype.$driver = new Driver({
    opacity: 0.1, //透明度
    allowClose: false, //单击覆盖是否应关闭
    doneBtnText: '完成', // Text on the final button
    closeBtnText: '关闭', // Text on the close button for this step
    stageBackground: '#fff', // Background color for the staged behind highlighted element
    nextBtnText: '下一步', // Next button text for this step
    prevBtnText: '上一步', // Previous button text for this step
})

// const whiteList = ['/login'] // 免登录白名单

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(res => res.meta.requireAuth)) { // 验证是否需要登陆
//     // console.log(VueCookies.isKey('token'),'检查登录状态是否存在')
//     if (VueCookies.isKey('token')) { // 查询本地存储信息是否已经登陆
//       next()
//     } else {
//       if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
//         next()
//       } else {
//         return next({
//           path: '/login' // 未登录则跳转至login页面
//           // query: {redirect: to.fullPath} // 登陆成功后回到当前页面，这里传值给login页面，to.fullPath为当前点击的页面
//         }) // 否则全部重定向到登录页
//       }
//     }
//   } else {
//     next()
//   }
// })

let routerName = null;

router.beforeEach((to, from, next) => {
    // console.log(to.path, '路由首位', from.path)
    // 判断中间页和渠道之间的状态
    if (to.path == '/agentIndex' || to.matched[0].path == '/agentInfomat' || to.matched[0].path == '/serviceInfomat' || to.matched[0].path == '/deliveryInfomat' || to.matched[0].path == '/basicInfomat' || to.matched[0].path == '/binding') {
        localStorage.setItem('routerStatus', true)
        next();
    } else {
        localStorage.setItem('routerStatus', false);
        if (to.matched.some(res => res.meta.requireAuth)) { // 验证是否需要登陆
            // // console.log(VueCookies.isKey('esignmerchantsid'), '检查登录状态是否存在')
            if (VueCookies.isKey('esignmerchantsid')) { // 查询本地存储信息是否已经登陆
                routerName = '登录已失效，请重新登录'
                next()
            } else {
                // // console.log(from, '跳转登录1');
                if (routerName) {
                    message.warning(routerName)
                }
                return next({
                        path: '/login' // 未登录则跳转至login页面
                            // query: {redirect: to.fullPath} // 登陆成功后回到当前页面，这里传值给login页面，to.fullPath为当前点击的页面
                    }) // 否则全部重定向到登录页
            }
        } else {
            let type = router.options.routes.some(item => {
                    // // console.log(item, 22222)
                    return item.path === to.path
                })
                //这个是判断路由是否，你页面跳转的路由不存在，所以退出登录了
                // // console.log(router.options.routes, to.path, "basicInfomat");
            if (type) {
                next()
            } else {
                //走的这里
                // // console.log(from, '跳转登录2');
                next({
                    path: '/login' // 未登录则跳转至login页面
                })
            }
        }
    }

})

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
