<style lang="less" scoped>
.subaccount {
  width: 100%;
  min-height: 100%;
  // padding: 12px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: left;
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
  .box {
    width: 100%;
    padding-top: 12px;
    .back {
      cursor: pointer;
      display: inline-block;
      padding: 30px 20px 0px 30px;
    }
    span {
      padding-right: 20px;
      color: black;
      font-weight: bold;
    }
  }
}
</style>
<style>
.subaccount .el-table__fixed-right {
  right: 0 !important;
}
</style>


<template>
  <div class="subaccount">
    <div class="box">
      <div class="back"
           @click="back()">
        <span> 组织结构 </span>
      </div>
    </div>
    <el-form ref="auditForm"
             :model="auditForm"
             :rules="auditRules"
             label-width="150px"
             class="demo-ruleForm"
             style="width: 50%; margin: 30px auto 0px; padding-bottom: 30px">
      <el-form-item v-loading="onLoadingWidgetData">
        <div>
          <el-tree :data="widgetTreed"
                   check-strictly
                   class="tree filter-tree"
                   show-checkbox
                   highlight-current
                   node-key="deptId"
                   ref="widgetTree"
                   :props="widgetProps"
                   :default-expand-all="false"
                   :expand-on-click-node="false"
                   :default-expanded-keys="checkedList">
            <!-- check-strictly -->
            <span class="custom-tree-node"
                  slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span>{{ node.number }}</span>
              <span>
                <el-button type="text"
                           size="mini"
                           @click="() => append(data, node)">
                  添加
                </el-button>
                <el-button type="text"
                           size="mini"
                           @click="() => remove(node, data)">
                  删除
                </el-button>
              </span>
            </span>
          </el-tree>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog title="名称"
               :visible.sync="dialogVisible"
               width="30%"
               :before-close="handleClose">
      <el-form :model="ruleForm"
               :rules="rules"
               ref="ruleForm"
               label-width="80px">
        <el-form-item label="名称"
                      prop="deptName">
          <el-input size="small"
                    v-model="ruleForm.deptName"
                    placeholder="请输入名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submitInfo('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "../../components/pagination.vue";
import api from "@/api/api.js";
import { get, post } from "@/api/index.js";
// 树形
let id = 1000;
export default {
  inject: ["reload"],
  data() {
    // 树形
    const data = [
      {
        id: 1,
        label: "一级 1",
        children: [
          {
            id: 4,
            label: "二级 1-1",
            children: [
              {
                id: 9,
                label: "三级 1-1-1",
              },
              {
                id: 10,
                label: "三级 1-1-2",
              },
            ],
          },
        ],
      },
      {
        id: 2,
        label: "一级 2",
        children: [
          {
            id: 5,
            label: "二级 2-1",
          },
          {
            id: 6,
            label: "二级 2-2",
          },
        ],
      },
      {
        id: 3,
        label: "一级 3",
        children: [
          {
            id: 7,
            label: "二级 3-1",
          },
          {
            id: 8,
            label: "二级 3-2",
          },
        ],
      },
    ];

    return {
      // 选中的树的数组
      checkAddData: "",
      // 二级id
      parentInfo: "",
      rules: {
        deptName: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      dialogVisible: false,
      ruleForm: {
        deptName: "",
      },
      // 树形
      data: JSON.parse(JSON.stringify(data)),
      data: JSON.parse(JSON.stringify(data)),

      releaseForm: {
        nickName: "",
        ucName: "",
        userSource: "",
        workStatus: "",
      },
      pageSzie: 10,
      total: null,
      dialogVisibld: false,
      onSubmitLoadinged: false,
      currentPage: 1,
      dialogVisibled: false,
      onLoadingWidgetData: false,
      onLoadingBumenData: false,
      onSubmitLoading: false,
      isCreateItemed: false,
      checkedList: [],
      bumenList: [],
      widgetTreed: [],
      widgetTreben: [],
      widgetProps: {
        children: "childrens",
        label: "deptName",
      },
      widgetPropsben: {
        children: "childrens",
        label: "deptName",
        disabled: this.disabledFun, //方法
      },
      isCreateItem: true,
      auditForm: {
        deptId: "",
        authUserList: "",
        isAdmin: "0",
        isAuthority: "0",
        nickName: "",
        password: "",
        tenantId: "",
        tenantName: "",
        ucName: "",
        userSource: "0",
        workStatus: "1",
        dingTalk: "",
        email: "",
        remark: "",
        wechat: "",
      },
      auditFormed: {
        userId: "",
        resourcesIds: [],
      },
      auditRulesed: {},
      auditRules: {
        deptId: [
          { required: true, message: "请选择所属部门id", trigger: "blur" },
        ],
      },
      dataInfo: "",
      auditOptions: [],
      checkval: "",
      options: [
        {
          value: "0",
          label: "普通",
        },
        {
          value: "1",
          label: "微信",
        },
        {
          value: "2",
          label: "钉钉",
        },
      ],
      Statusoptions: [
        {
          value: "0",
          label: "离职",
        },
        {
          value: "1",
          label: "在职",
        },
      ],
      tableData: [],
      qiyeOptions: [],
      expandedKeys: [],
    };
  },
  watch: {
    data: "baoguo",
  },
  mounted() {
    // this.queryPageData();
    // this.weiPost();
    // this.rolePost();
    // this.bumenPost();
    // this.addDepartment();
    this.baoguo();
  },
  created() { },
  methods: {
    // 确定
    submitInfo(ruleForm) {
      var data = this.dataInfo;
      this.dialogVisible = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          post(api.addAgentDepartment, {
            deptName: this.ruleForm.deptName,
            parentId: data.id,
          }).then((d) => {
            // console.log(d.data, 999);
            const newChild = {
              // deptId: d.data.id++,
              deptName: d.data.deptName,
              childrens: [],
            };
            if (!data.childrens) {
              this.$set(data, "childrens", []);
            }
            this.$message.success(d.message);
            this.dialogVisible = false;
            this.baoguo();
            // this.reload()
          });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    // 树形
    append(data, node) {
      // console.log(data, 777);
      this.dataInfo = data;
      this.dialogVisible = true;
      return;
    },
    remove(node, data) {
      // const parent = node.parent;
      // const children = parent.data.children || parent.data;
      // const index = children.findIndex((d) => d.id === data.id);
      // console.log(index,'删除')
      // return;
      if (this.$refs.widgetTree.getCheckedNodes() == "") {
        this.$message.error("请选择要删除的节点");
        return;
      }
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var arr = this.$refs.widgetTree.getCheckedNodes();
          var arrayInfo = [];
          for (var i = 0; i < arr.length; i++) {
            arrayInfo.push(arr[i].deptId);
          }
          post(api.deleteAgentDepartment, {
            agentId: 1,
            depts: arrayInfo,
          }).then((d) => {
            if (d.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }else{
              this.$message.error(d.message)
            }
            this.baoguo();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.append(data)}
            >
              添加
            </el-button>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.remove(node, data)}
            >
              删除
            </el-button>
          </span>
        </span>
      );
    },

    //树节点 disabled 设置已加入清单的为禁用状态  addSyncData为已经加入清单的数据
    disabledFun(data, node) {
      const that = this;
      let disabled = false;
      that.widgetTreben.filter((item) => {
        // console.log(item,'111111')
        if (item.isDepartment === data.isDepartment) {
          disabled = true;
        }
      });
      return disabled;
    },
    bumenPost() {
      this.onLoadingBumenData = true;
      return post(api.queryDepartment, {
        id: "",
      }).then(
        (d) => {
          // console.log(d,'1111111111')
          this.onLoadingBumenData = false;
          if (d.code === 0) {
            this.widgetTreben = d.data;
          } else {
            this.widgetTreben = [];
            // this.$message.error(d.message);
          }
        },
        (err) => {
          //error callback
          this.onLoadingBumenData = false;
        }
      );
    },
    dakaiRowed(row) {
      this.auditFormed = {
        userId: row.id,
        resourcesIds: [],
      };
      post(api.platUserQueryRole, {
        id: row.id,
      }).then(
        (d) => {
          if (d.code === 0) {
            this.auditFormed.resourcesIds = d.data;
            // this.isCreateItemed = true;
            this.dialogVisibld = true;
          } else {
            // this.$message.error(d.message);
          }
        },
        (err) => {
          //error?callback
        }
      );
    },
    //组织结构
    deptTreeCurrentChangebenmen(data, checked, indeterminate) {
      // console.log(data, checked, indeterminate, "111111111111");
    },
    // 部门菜单树选中
    deptTreeCurrentChangeHandle(data, checked, indeterminate) {
      // console.log(data.deptId, "111111111111");
    },
    // 获取所属部门数据
    baoguo(size) {
      const loading = this.$loading({
        lock: true,
        spinner: "loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      post(api.queryYetAgentDepartment).then(
        (d) => {
          loading.close();
          if (d.code === 0) {
            this.widgetTreed = Array(d.data[0]);
            // console.log(this.widgetTreed, 666);
          } else {
            // console.log(d.message);
          }
        },
        (err) => {
          //error callback
        }
      );
    },
    rolePost() {
      post(api.userQueryRole).then(
        (d) => {
          if (d.code === 0) {
            // console.log(d, "4444");
            for (let i = 0; i < d.data.length; i++) {
              var obj = {
                label: d.data[i].name,
                value: d.data[i].id,
              };
              this.auditOptions.push(obj);
            }
          } else {
            // this.$message.error(d.message);
          }
        },
        (err) => {
          //error?callback
        }
      );
    },
    weiPost() {
      post(api.tenantIdList).then(
        (d) => {
          if (d.code === 0) {
            // console.log(d, "4444");
            for (let i = 0; i < d.data.length; i++) {
              var obj = {
                label: d.data[i].tenantName,
                value: d.data[i].tenantId,
              };
              this.qiyeOptions.push(obj);
            }
          } else {
            // this.$message.error(d.message);
          }
        },
        (err) => {
          //error?callback
        }
      );
    },

    onInputPost(val) {
      //向父组件传递选择地值，调用父组件setMajor把选中值赋给父组件
      // console.log(val, "djdjddjj");
      let obj = {};
      obj = this.qiyeOptions.find((item) => {
        //model就是上面的数据源
        // console.log(item,'33333333')
        return item.value === val; //筛选出匹配数据
      });
      // console.log(obj, "zidiandd");
      this.auditForm.tenantId = obj.value;
      this.auditForm.tenantName = obj.label;
    },
    handleClosed() {
      this.dialogVisibld = false;
    },

    handleCloseed() {
      this.dialogVisibled = false;
    },
    openDialog() {
      this.dialogVisibled = true;
    },
    onClickRoleAdd() {
      this.checkval = "1";
      this.checkedList = [];
      this.bumenList = [];
      this.auditForm = {
        deptId: "",
        authUserList: "",
        isAdmin: "0",
        isAuthority: "0",
        nickName: "",
        password: "",
        tenantId: "",
        tenantName: "",
        ucName: "",
        userSource: "0",
        workStatus: "1",
        dingTalk: "",
        email: "",
        remark: "",
        wechat: "",
      };
      this.isCreateItem = true;
      this.openDialog();
      this.$nextTick(() => {
        this.$refs.widgetTree.setCheckedKeys([]);
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isCreateItem) {
            this.onSubmitLoading = true;
            //所属部门
            // let subKeys = this.$refs.widgetTree.getCheckedKeys();
            // // console.log(subKeys,'555555')
            // let halfKeys = this.$refs.widgetTree.getHalfCheckedKeys();
            // // console.log(halfKeys,'666666666666')
            // let resources = halfKeys.concat(subKeys);

            // resources.sort();

            this.auditForm.deptId = resources.join();
            //授权用户不能为空
            if (this.auditForm.isAuthority == "1") {
              var obj = [];
              let checkAreaData = this.$refs.bumenTree.getCheckedNodes();
              //过滤未加入清单的节点
              checkAreaData.forEach((item) => {
                let result = this.widgetTreben.some(
                  (syncItem) => syncItem.isDepartment === item.isDepartment
                );
                if (!result) {
                  obj.push(item);
                }
              });
              var authUserList = [];
              if (checkAreaData.length > 0) {
                for (let index = 0; index < checkAreaData.length; index++) {
                  const element = checkAreaData[index];
                  authUserList.push(element.deptId);
                }
              }
              // console.log(authUserList,'2222')
              this.auditForm.authUserList = authUserList.join();
              //  console.log(this.auditForm.authUserList,'111111')
              if (this.auditForm.authUserList == "") {
                this.onSubmitLoading = false;
                return this.$message.error("授权用户不能为空");
              }
            }

            // 添加
            if (this.auditForm.deptId == "") {
              this.onSubmitLoading = false;
              return this.$message.error("所属部门不能为空");
            }

            post(api.platUserAdd, this.auditForm).then(
              (d) => {
                this.onSubmitLoading = false;
                if (d.code == 0) {
                  this.$message.success("添加成功");
                  this.dialogVisibled = false;
                  this.queryPageData();
                } else {
                  this.$message.error(d.message);
                }
                //success callback
              },
              (err) => {
                this.onSubmitLoading = false;
                //error callback
              }
            );
          } else {
            this.onSubmitLoading = true;
            //所属部门
            let subKeys = this.$refs.widgetTree.getCheckedKeys();
            // console.log(subKeys,'555555')
            let halfKeys = this.$refs.widgetTree.getHalfCheckedKeys();
            // console.log(halfKeys,'666666666666')
            let resources = halfKeys.concat(subKeys);

            resources.sort();

            this.auditForm.deptId = resources.join();
            //授权用户不能为空
            if (this.auditForm.isAuthority == "1") {
              var obj = [];
              let checkAreaData = this.$refs.bumenTree.getCheckedNodes();
              //过滤未加入清单的节点
              checkAreaData.forEach((item) => {
                let result = this.widgetTreben.some(
                  (syncItem) => syncItem.isDepartment === item.isDepartment
                );
                if (!result) {
                  obj.push(item);
                }
              });
              var authUserList = [];
              if (checkAreaData.length > 0) {
                for (let index = 0; index < checkAreaData.length; index++) {
                  const element = checkAreaData[index];
                  authUserList.push(element.deptId);
                }
              }
              // console.log(authUserList,'2222')
              this.auditForm.authUserList = authUserList.join();
              //  console.log(this.auditForm.authUserList,'111111')
              if (this.auditForm.authUserList == "") {
                this.onSubmitLoading = false;
                return this.$message.error("授权用户不能为空");
              }
            }

            if (this.auditForm.deptId == "") {
              this.onSubmitLoading = false;
              return this.$message.error("所属部门不能为空");
            }

            // 编辑
            post(api.platUserUpdate, this.auditForm).then(
              (d) => {
                this.onSubmitLoading = false;
                if (d.code == 0) {
                  this.$message.success("编辑成功");
                  this.dialogVisibled = false;
                  this.queryPageData(this.currentPage);
                } else {
                  this.$message.error(d.message);
                }
                //success callback
              },
              (err) => {
                this.onSubmitLoading = false;
                //error callback
              }
            );
          }
        } else {
          // console.log("error submit!!");
          this.onSubmitLoading = false;
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisibled = false;
    },
    resetFormed(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisibld = false;
    },
    shuTreePost() {
      this.onLoadingWidgetData = true;
      post(api.platUserQueryDepartment, {
        id: this.auditForm.id,
      }).then(
        (d) => {
          this.onLoadingWidgetData = false;
          if (d.code == 0) {
            this.openDialog();
            this.checkedList = d.data;
          } else {
            this.$message.error(d.message);
          }
          //success callback
        },
        (err) => {
          //error callback
          this.onLoadingWidgetData = false;
        }
      );
    },
    bumenTreePost() {
      this.onLoadingWidgetData = true;
      post(api.queryUserAuthority, {
        id: this.auditForm.id,
      }).then(
        (d) => {
          this.onLoadingWidgetData = false;
          if (d.code == 0) {
            this.openDialog();
            this.bumenList = d.data;
          } else {
            this.$message.error(d.message);
          }
          //success callback
        },
        (err) => {
          //error callback
          this.onLoadingWidgetData = false;
        }
      );
    },
  },
  components: {
    pagination: pagination,
  },
};
</script>
