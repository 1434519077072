<style lang="less" scoped>
.merchantManage {
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    text-align: left;
    // 增加商户
    .add-agent {
        float: right;
        margin-right: 30px;
        margin-bottom: 30px;
        cursor: pointer;
    }
    // 上传职业营照
    .uploading-camp {
        width: 100%;
        border: 1px solid #f5f5f5;
        border-right: none;
        margin-bottom: 15px;
        height: 100px;
        display: flex;
        text-align: left;
        .uploading-left {
            width: 100px;
            height: 100px;
            background-color: #f5f5f5;
            position: relative;
            i {
                position: absolute;
                top: 50%;
                margin-top: -40%;
                left: 50%;
                margin-left: -40%;
            }
        }
        .uploading-right {
            padding-left: 30px;
            // padding-top: 15px;
            line-height: 10px;
            .right-one {
                font-weight: 600;
                color: #666666;
            }
            .right-two {
                color: #9999a6;
            }
            .right-three {
                color: #0079fe;
            }
        }
    }
    .add-all {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        h4 {
            padding-left: 30px;
        }
        .add-left {
            width: 50%;
            margin-left: 30px;
            .basics {
                width: 75%;
                margin: 0 auto;
            }
        }
        .add-right {
            width: 50%;
            margin-right: 30px;
            .legal {
                width: 75%;
                margin: 0 auto;
            }
        }
    }
    .back {
        cursor: pointer;
        display: inline-block;
        padding: 30px 20px 0px 30px;
    }
    .title span {
        padding-right: 20px;
        color: #d8dce5;
    }
    // 底部
    .bottom-el {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
    }
    // 产品
    .signAgent {
        width: 100%;
        margin-top: 30px;
        .sign-all {
            margin-left: 2%;
            margin-right: 5%;
            .el-form {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }
    }
    .sign-input {
        padding-left: 1%;

        .sign-el-input {
            width: 220px;
        }
    }
    // 上传职业营照
    .uploading-camp {
        margin-left: 15px;
        margin-right: 60px;
        width: 100%;
        border: 1px solid #f5f5f5;
        border-right: none;
        margin-bottom: 15px;
        height: 100px;
        display: flex;
        .uploading-left {
            width: 100px;
            height: 100px;
            background-color: #f5f5f5;
            position: relative;
            margin-left: -15px;
            i {
                position: absolute;
                top: 50%;
                margin-top: -25%;
                left: 50%;
                margin-left: -40%;
            }
        }
        .uploading-right {
            padding-left: 30px;
            // padding-top: 15px;
            line-height: 10px;
            .right-one {
                font-weight: 600;
                color: #666666;
            }
            .right-two {
                color: #9999a6;
            }
            .right-three {
                color: #0079fe;
            }
        }
    }
    .box {
        width: 100%;
        padding-top: 12px;
        .main_button {
            margin-left: 36px;
        }
        .wrap {
            width: 100%;
            margin-top: 22px;
            padding: 12px;
            box-sizing: border-box;
            .wraped {
                width: 100%;
            }
        }
    }
    .block {
        width: 100%;
        .main {
            width: 100%;
            box-sizing: border-box;
            font-size: 14px;
            .grid-content {
                width: 100%;
                margin-bottom: 20px;
                display: inline-block;
            }
            .grid-left {
                float: left;
                margin-bottom: 20px;
            }
            .grid-img {
                float: left;
                width: 160px;
                height: 97px;
                margin-right: 22px;
            }
            .wrap {
                width: 100%;
                margin-top: 22px;
                padding: 30px;
                box-sizing: border-box;
            }
            .footer {
                padding: 30px;
            }
            .col {
                width: 102%;
                height: 10px;
                background-color: #f2f2f2;
                margin-left: -12px;
            }
        }
    }
}
</style>
<style>
.el-dialog__header {
    padding: 20px 20px 10px;
    background-color: #e3f2fd;
}
.avatar-uploader .el-upload {
    width: 100%;
    height: 100px;
    margin-right: 0px;
}
#app .main[data-v-7ba5bd90] {
    float: left;
    width: calc(100% - 182px);
    height: calc(100% - 58px);
    background-color: #f2f2f2;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}
.el-table th.is-leaf,
.el-table th {
    background-color: #f5f5f5;
    border-bottom: none;
}
/* .main .el-tabs__nav-scroll {
    padding-left: 34px !important;
} */
.main .el-tabs__header {
    margin: 0px !important;
}
.delivery .el-table__fixed-right {
    right: 0 !important;
    border: none;
}
.delivery .el-.el-upload-list__item-name-right {
    width: 400px !important;
}
</style>

<template>
    <div class="merchantManage">
        <!-- 表单数据展示 -->
        <div class="box"
             v-if="addAgentStatus == 1">
            <el-form label-width="120px">
                <el-row>
                    <el-col :lg="18"
                            :md="20">
                        <el-col :lg="8"
                                :md="12">
                            <el-form-item label="商户名称：">
                                <el-input size="small"
                                          placeholder="请输入商户名称"
                                          v-model="form.companyName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :lg="8"
                                :md="12">
                            <el-form-item label="服务商名称：">
                                <el-select v-model="form.serviceId"
                                           filterable
                                           placeholder="请输入服务商名称"
                                           :loading="loading"
                                           style="width: 100%"
                                           size="small">
                                    <el-option v-for="item in serviceList"
                                               :key="item.serviceId"
                                               :label="item.serviceName"
                                               :value="item.serviceId">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :lg="8"
                                :md="12">
                            <el-form-item label="审核状态：">
                                <el-select clearable
                                           filterable
                                           v-model="form.checkStatus"
                                           style="width: 100%"
                                           placeholder="请选择审核状态"
                                           size="small">
                                    <el-option v-for="item in optionsInfo"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :lg="6"
                            :md="8">
                        <el-form-item label=" ">
                            <el-button type="primary"
                                       size="small"
                                       icon="el-icon-search"
                                       @click="search()">查询</el-button>
                            <el-button size="small"
                                       icon='el-icon-refresh-left'
                                       class="chongzhi"
                                       @click="reset()">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="add-agent"
                 @click="addAgent(0)">
                <el-button type="primary"
                           icon='el-icon-plus'
                           size="small">增加商户</el-button>
            </div>
            <div class="wrap">
                <el-table :data="tableData"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                          fit
                          border
                          :render-header="renderHeader"
                          style="width: 100%">
                    <el-table-column show-overflow-tooltip
                                     fixed
                                     prop="id"
                                     label="临时商户号"
                                     min-width="130">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="createTime"
                                     label="创建时间"
                                     width="150px">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="agentName"
                                     label="所属渠道（渠道商）"
                                     min-width="150">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="companyName"
                                     label="商户名称"
                                     min-width="170">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="serviceName"
                                     label="服务商名称"
                                     min-width="170">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="taxNo"
                                     label="组织信用代码"
                                     min-width="150">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="checkStatus"
                                     width="100px"
                                     label="状态">
                        <template slot-scope="{ row }">
                            <div>
                                <el-tag :type="(row.checkStatus) | waitFlagTag">
                                    {{ row.checkStatus|checkStatusInfo }}
                                </el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="userName"
                                     label="商户负责人"
                                     min-width="150">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="signStatus"
                                     fixed="right"
                                     width="100px"
                                     label="签约状态">
                        <template slot-scope="{ row }">
                            <div>
                                <el-tag :type="(row.signStatus) | waitFlagTagStatus">
                                    {{ row.signStatus|signStatusOption }}
                                </el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作"
                                     fixed="right"
                                     min-width="120">
                        <template slot-scope="scope">
                            <el-dropdown>
                                <span class="el-dropdown-link"
                                      style="cursor: pointer">
                                    更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <el-button @click="addAgent(2, scope.row)"
                                                   size="mini"
                                                   class="my-2 w_fluid"
                                                   plain>
                                            查看商户
                                        </el-button>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="scope.row.checkStatus==1">
                                        <el-button @click="addAgent(1, scope.row)"
                                                   size="mini"
                                                   class="my-2 w_fluid"
                                                   plain>
                                            编辑商户
                                        </el-button>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="scope.row.signStatus == 0">
                                        <el-button @click="signInfo(1, scope.row)"
                                                   size="mini"
                                                   class="my-2 w_fluid"
                                                   plain>
                                            产品
                                        </el-button>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="scope.row.signStatus == 1">
                                        <el-button @click="signInfo(2, scope.row)"
                                                   size="mini"
                                                   class="my-2 w_fluid"
                                                   plain>
                                            查看产品
                                        </el-button>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wraped">
                    <pagination :total="total"
                                :currentPage="currentPage"
                                @handleSizeChange="handleSizeChange"
                                @handleCurrentChange="handleCurrentChange"></pagination>
                </div>
            </div>
        </div>
        <!-- 增加商户 -->
        <div class="add-angent"
             v-if="addAgentStatus == 2">
            <div class="back"
                 @click="back()">
                <i class="iconfont iconfanhui"></i>
                返回
            </div>
            <span class="title"> <span>|</span> {{ titleInfo }} </span>
            <div class="add-all">
                <div class="add-left">
                    <h4>基础信息</h4>
                    <div class="basics">
                        <el-form :model="ruleFormInfo"
                                 :rules="rulesInfo"
                                 ref="ruleFormInfo"
                                 label-width="130px"
                                 class="demo-ruleForm">
                            <el-form-item prop="businessLincenseImg">
                                <el-upload class="avatar-uploader"
                                           :action="uploadUrled"
                                           :headers="myHeaders"
                                           :show-file-list="false"
                                           :on-success="handleAvatarSuccessed"
                                           :before-upload="beforeUploaded">
                                    <div class="uploading-camp">
                                        <div class="uploading-left">
                                            <img v-if="ruleFormInfo.businessLincenseImg"
                                                 :src="ruleFormInfo.businessLincenseImg"
                                                 width="100px"
                                                 height="100px" />

                                            <i v-else
                                               style="
                          font-size: 80px;
                          color: #cccccc;
                          margin-top: -25%;
                        "
                                               class="iconfont iconyunshangchuan"></i>
                                        </div>
                                        <div class="uploading-right">
                                            <p class="right-one">上传营业执照</p>
                                            <p class="right-two">文件上传不得大于10M</p>
                                            <p class="right-three">上传文件</p>
                                        </div>
                                    </div>
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="企业名称："
                                          prop="companyName">
                                <el-input size="small"
                                          v-model="ruleFormInfo.companyName"
                                          placeholder="请输入企业名称"></el-input>
                            </el-form-item>
                            <el-form-item label="企业简称："
                                          prop="companyShotName">
                                <el-input v-model="ruleFormInfo.companyShotName"
                                          size="small"
                                          placeholder="请输入企业简称"></el-input>
                            </el-form-item>
                            <el-form-item label="信用代码："
                                          prop="taxNo">
                                <el-input v-model="ruleFormInfo.taxNo"
                                          size="small"
                                          placeholder="请输入信用代码"></el-input>
                            </el-form-item>
                            <el-form-item label="注册资金："
                                          prop="registerCapital">
                                <el-input v-model="ruleFormInfo.registerCapital"
                                          size="small"
                                          placeholder="请输入注册资金"></el-input>
                            </el-form-item>
                            <el-form-item label="业务范围："
                                          prop="businessScope">
                                <el-input v-model="ruleFormInfo.businessScope"
                                          size="small"
                                          placeholder="请输入业务范围"></el-input>
                            </el-form-item>
                            <el-form-item label="服务商："
                                          prop="serviceId">
                                <el-select v-model="ruleFormInfo.serviceId"
                                           style="width:100%"
                                           :disabled='disableTrue'
                                           @change="onSelectedDrug($event)"
                                           placeholder="请选择服务商"
                                           size="small">
                                    <el-option v-for="(item,index) in serviceList"
                                               :key="index"
                                               :label="item.serviceName"
                                               :value="item.serviceId"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="公司注册地址："
                                          prop="registerAddress">
                                <el-input v-model="ruleFormInfo.registerAddress"
                                          size="small"
                                          placeholder="请输入公司注册地址"></el-input>
                            </el-form-item>
                            <el-form-item label="业务员ID："
                                          prop="agentUserId">
                                <div class="baoguo"
                                     @click="salesman()">
                                    <div v-if="diquData.length > 0">
                                        <el-tag v-model="ruleFormInfo.agentUserId"
                                                :key="key"
                                                v-for="(item, key) in diquData"
                                                closable
                                                :disable-transitions="false"
                                                @close="handleCloseRen(key)">
                                            {{ item.deptName }}
                                        </el-tag>
                                    </div>
                                    <el-form-item v-else
                                                  prop="name">
                                        <el-input size="small"
                                                  v-model="ruleFormInfo.agentUserId"
                                                  placeholder="请选择业务员ID"></el-input>
                                    </el-form-item>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="add-right">
                    <el-form :model="ruleForm"
                             :rules="rules"
                             ref="ruleForm"
                             label-width="125px"
                             class="demo-ruleForm">
                        <h4>法人信息</h4>
                        <div class="legal">
                            <el-form-item label="法人姓名："
                                          prop="legalman">
                                <el-input v-model="ruleForm.legalman"
                                          size="small"
                                          placeholder="请输入法人姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="法人手机号："
                                          prop="legalNo">
                                <el-input v-model="ruleForm.legalNo"
                                          size="small"
                                          placeholder="请输入法人手机号"></el-input>
                            </el-form-item>
                            <el-form-item label="法人身份证："
                                          prop="legalIdCard">
                                <el-input v-model="ruleForm.legalIdCard"
                                          size="small"
                                          placeholder="请输入法人身份证"></el-input>
                            </el-form-item>
                        </div>
                        <h4>联系信息</h4>
                        <div class="legal">
                            <el-form-item label="联系人姓名："
                                          prop="linkman">
                                <el-input v-model="ruleForm.linkman"
                                          size="small"
                                          placeholder="请输入联系人姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="联系人手机："
                                          prop="linkno">
                                <el-input v-model="ruleForm.linkno"
                                          size="small"
                                          placeholder="请输入联系人手机"></el-input>
                            </el-form-item>
                            <el-form-item label="联系人邮箱："
                                          prop="linkEmail">
                                <el-input v-model="ruleForm.linkEmail"
                                          size="small"
                                          placeholder="请输入联系人邮箱"></el-input>
                            </el-form-item>
                            <el-form-item label="联系人身份证："
                                          prop="linkIdNo">
                                <el-input v-model="ruleForm.linkIdNo"
                                          size="small"
                                          placeholder="请输入联系人身份证"></el-input>
                            </el-form-item>
                            <el-form-item label="联系人地址："
                                          prop="linkAddress">
                                <el-input v-model="ruleForm.linkAddress"
                                          size="small"
                                          placeholder="请输入联系人地址"></el-input>
                            </el-form-item>
                        </div>
                        <h4>结算信息</h4>
                        <div class="legal">
                            <el-form-item label="开户名称："
                                          prop="bankAccountName">
                                <el-input v-model="ruleForm.bankAccountName"
                                          size="small"
                                          placeholder="请输入开户名称"></el-input>
                            </el-form-item>
                            <el-form-item label="对公账户："
                                          prop="bankNo">
                                <el-input v-model="ruleForm.bankNo"
                                          size="small"
                                          placeholder="请输入对公账户"></el-input>
                            </el-form-item>
                            <el-form-item label="开户银行："
                                          prop="openBank">
                                <el-input v-model="ruleForm.openBank"
                                          size="small"
                                          placeholder="请输入开户银行"></el-input>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
            </div>
            <div class="bottom-el">
                <el-button style="margin-right: 10px"
                           v-if="submitAdd == true"
                           type="primary"
                           @click="addSubmit('ruleFormInfo', 'ruleForm')">确认提交</el-button>
                <el-button @click="cancelInfo()">取消</el-button>
            </div>
        </div>
        <!-- 产品 -->
        <div class="sign-angent"
             v-if="addAgentStatus == 3">
            <div class="back"
                 @click="backAngent()">
                <i class="iconfont iconfanhui"></i>
                返回
            </div>
            <span class="title"> <span>|</span> {{ titleSign }} </span>
            <div class="signAgent">
                <div class="sign-all">
                    <el-form :model="releaseForm"
                             :rules="rulesTenant"
                             ref="releaseForm"
                             label-width="150px"
                             style="
              width: 100%;
              text-align: center;
              margin-left: 14%;
              margin-right: 5%;
            ">
                        <el-row>
                            <el-col :lg="18"
                                    :md="20">
                                <el-col :lg="8"
                                        :md="12">
                                    <el-form-item label="产品名称："
                                                  prop="productId">
                                        <el-select v-model="releaseForm.productId"
                                                   clearable
                                                   filterable
                                                   style="width: 100%"
                                                   placeholder="请选择代理商产品"
                                                   size="small">
                                            <el-option v-for="item in channelInfo"
                                                       :key="item.productId"
                                                       :label="item.productName"
                                                       :value="item.productId">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8"
                                        :md="12">
                                    <el-form-item label="服务费费率（%）："
                                                  prop="addedValueRate">
                                        <el-input v-model="releaseForm.addedValueRate"
                                                  size="small"
                                                  placeholder="请输入服务费费率"
                                                  @change="maxInfo(releaseForm.addedValueRate)"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="8"
                                        :md="12">
                                    <el-form-item label="备注："
                                                  prop="remark">
                                        <el-input size="small"
                                                  v-model="releaseForm.remark"
                                                  placeholder="请输入备注"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col>
                                    <el-form-item label="上传签约合同："
                                                  prop="consultUrl">
                                        <el-upload class="avatar-uploader"
                                                   :action="uploadUrledSign"
                                                   :headers="myHeaders"
                                                   :show-file-list="false"
                                                   :on-success="handleAvatarSuccessedSign"
                                                   :before-upload="beforeUploadedSign"
                                                   multiple
                                                   :limit="1"
                                                   :on-remove="handleRemove"
                                                   :before-remove="beforeRemove">
                                            <div class="uploading-camp">
                                                <div class="uploading-left">
                                                    <img v-if="releaseForm.consultUrl"
                                                         class="el-upload-list__item-thumbnail"
                                                         :src="releaseForm.consultUrl"
                                                         width="100px"
                                                         height="100px"
                                                         alt="" />
                                                    <i v-else
                                                       class="iconfont iconyunshangchuan"
                                                       style="font-size: 80px; color: #cccccc"></i>
                                                </div>
                                                <div class="uploading-right">
                                                    <p class="right-one">上传签约合同</p>
                                                    <p class="right-two">文件上传不得大于10M</p>
                                                    <p class="right-three">上传文件</p>
                                                </div>
                                            </div>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                        </el-row>
                        <el-form-item>
                            <el-button type="primary"
                                       v-if="titleSign == '产品'"
                                       @click="addSubmitSign('releaseForm')">确认提交</el-button>
                            <el-button @click="cancelSign()">取消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <el-dialog :title="'业务员用户'"
                   :visible.sync="dialogVisibled"
                   width="800px"
                   style="text-align: left"
                   :before-close="handleCloseed">
            <el-form ref="auditForm"
                     :model="auditForm"
                     :rules="auditRules"
                     label-width="150px"
                     class="demo-ruleForm">
                <el-form-item v-loading="onLoadingWidgetData"
                              label="所属部门id：">
                    <div>
                        <el-tree class="tree filter-tree"
                                 show-checkbox
                                 highlight-current
                                 node-key="deptId"
                                 :default-checked-keys="checkedList"
                                 ref="widgetTree"
                                 :props="widgetProps"
                                 :data="widgetTreed"
                                 @check="changetree"
                                 @check-change="deptTreeCurrentChangeHandle"
                                 check-strictly>
                            <span slot-scope="{ node, data }"
                                  class="custom-tree-node">
                                <span :dataType="data.type">
                                    {{ node.label }}
                                </span>
                            </span>
                        </el-tree>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               style="margin-left: 70%"
                               @click="onConfirmed('auditForm')"
                               :loading="onSubmitLoading">确定</el-button>
                    <el-button @click="resetForm('auditForm')">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import pagination from '../../components/pagination.vue';
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
export default {
    filters: {
        waitFlagTag(code) {
            const statusMap = {
                2: 'success',
                3: 'danger'
            };
            return statusMap[code] || 'info';
        },
        waitFlagTagStatus(code) {
            const statusMap = {
                1: 'success'
            };
            return statusMap[code] || 'info';
        }
    },
    // 默认高度
    // mixins: [tableHeight],
    data() {
        return {
            disableTrue: false, //服务商编辑置灰
            serviceId: '',
            serviceList: [],
            // 服务商模糊搜索
            qiyeRenOptions: [],
            statesRen: [],
            loading: false,
            submitAdd: '',
            // 个税收取类型
            inconmeInfo: [
                {
                    value: '0',
                    label: '个人收'
                },
                {
                    value: '1',
                    label: '企业收'
                }
            ],
            diquData: [],
            titleInfo: '',
            onSubmitLoading: false,
            auditForm: {
                // 部门id
                agentUserDeptId: ''
            },
            checkedList: [],
            onLoadingWidgetData: false,
            widgetProps: {
                children: 'childrens',
                label: 'deptName',
                id: 'deptId',
                disabled: this.disabledFun //方法
            },
            widgetTreed: [],
            dialogVisibled: false,
            // 签约
            releaseForm: {
                // 产品id
                productId: '',
                consultUrl: '',
                addedValueRate: '',
                // 产品名称
                productName: '',
                // 列表id
                companyTempId: '',
                remark: ''
            },
            // 签约验证
            rulesTenant: {
                productId: [{ required: true, message: '请选择产品名称', trigger: 'change' }],
                consultUrl: [{ required: true, message: '请上传签约合同', trigger: 'change' }],
                addedValueRate: [
                    {
                        required: true,
                        pattern: /^(\d{0,2}(\.\d{1,9})?|100(\.00?)?)$/,
                        message: '须小于100%'
                    }
                ],
                remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
            },
            // 上传营业执照
            uploadUrled: api.businessLicense,
            // 上传签约合同
            uploadUrledSign: api.uploadOss,
            myHeaders: { Authorization: this.$cookies.get('esignmerchantsid') },
            pageSzie: 10,
            total: null,
            currentPage: 1,
            optionsInfo: [
                {
                    value: '1',
                    label: '待审核'
                },
                {
                    value: '2',
                    label: '审核通过'
                },
                {
                    value: '3',
                    label: '审核失败'
                }
            ],
            options: [
                {
                    value: '0',
                    label: '个人收'
                },
                {
                    value: '1',
                    label: '企业收'
                }
            ],
            value: '',
            // 增加商户状态
            addAgentStatus: 1,
            form: {
                companyName: '',
                checkStatus: '',
                serviceId: ''
            },
            titleSign: '',
            tableData: [],
            // 基本
            ruleFormInfo: {
                // 营业执照地址
                businessLincenseImg: '',
                // 企业名称
                companyName: '',
                // 企业简称
                companyShotName: '',
                // 信用代码
                taxNo: '',
                // 注册资金
                registerCapital: '',
                // 业务范围
                businessScope: '',
                // 公司注册地址
                registerAddress: '',
                serviceId: '',
                serviceName: '',
                // 企业员id
                agentUserId: ''
            },
            // 业务员
            auditRules: {
                // agentUserDeptId: [
                //   { required: true, message: "请选择所属部门id", trigger: "blur" },
                // ],
            },
            ruleForm: {
                // 法人名称
                legalman: '',
                // 法人手机号
                legalNo: '',
                // 法人身份证号
                legalIdCard: '',
                // 联系人姓名
                linkman: '',
                // 联系人手机
                linkno: '',
                // 联系人邮箱
                linkEmail: '',
                // 联系人身份证
                linkIdNo: '',
                // 联系人地址
                linkAddress: '',
                // 开户名称
                bankAccountName: '',
                // 对公账户
                bankNo: '',
                // 开户银行
                openBank: ''
            },
            // 基本
            rulesInfo: {
                businessLincenseImg: [
                    { required: true, message: '请上传营业执照', trigger: 'blur' }
                ],
                companyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
                companyShotName: [{ required: true, message: '请输入企业简称', trigger: 'blur' }],
                taxNo: [{ required: true, message: '请输入信用代码', trigger: 'blur' }],
                registerCapital: [{ required: true, message: '请输入注册资金', trigger: 'blur' }],
                businessScope: [{ required: true, message: '请输入业务范围', trigger: 'blur' }],
                registerAddress: [
                    { required: true, message: '请输入公司注册地址', trigger: 'blur' }
                ],
                agentUserId: [{ required: true, message: '请选择业务员ID', trigger: 'change' }],
                serviceId: [{ required: true, message: '请选择服务商', trigger: 'change' }]
            },
            rules: {
                legalman: [{ required: true, message: '请输入法人名称', trigger: 'blur' }],
                linkman: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
                linkno: [{ required: true, message: '请输入联系人手机号', trigger: 'blur' }],
                linkEmail: [{ required: true, message: '请输入联系人邮箱', trigger: 'blur' }],
                linkIdNo: [{ required: true, message: '请输入联系人身份证', trigger: 'blur' }],
                linkAddress: [{ required: true, message: '请输入联系人地址', trigger: 'blur' }],
                bankAccountName: [{ required: true, message: '请输入开户名称', trigger: 'blur' }],
                bankNo: [{ required: true, message: '请输入对公账户', trigger: 'blur' }],
                openBank: [{ required: true, message: '请输入开户银行', trigger: 'blur' }]
            },
            // 产品名称
            channelInfo: []
        };
    },

    mounted() {
        this.serviceInfo(); //服务商
        // this.baoguo();
    },

    methods: {
        onSelectedDrug(e) {
            let obj = {};
            obj = this.serviceList.find(item => {
                //这里的userList就是上面遍历的数据源
                return item.serviceId === e; //筛选出匹配数据
            });
            this.ruleFormInfo.serviceName = obj.serviceName;
        },
        serviceInfo() {
            post(api.queryServiceList).then(res => {
                if (res.code == 0) {
                    this.form.serviceId = res.data[0].serviceId;
                    this.serviceList = res.data;
                    this.queryPageData();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 表格
        renderHeader(h, data) {
            return h('span', [
                h('el-tooltip'),
                {
                    attrs: {
                        class: 'item',
                        effect: 'dark',
                        content: data.column.label
                    }
                },
                [h('span', data.column.label)]
            ]);
        },
        // 最大值限制
        maxInfo(e) {
            if (e > 100) {
                this.$message.error('服务费费率最大值为100');
            }
        },
        // 签约提交
        addSubmitSign(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    post(api.createAgentCompanySign, this.releaseForm).then(d => {
                        if (d.code == 0) {
                            this.$message.success(d.message);
                            this.addAgentStatus = 1;
                            this.queryPageData();
                            // 签约
                            this.releaseForm = {
                                // 产品id
                                productId: '',
                                consultUrl: '',
                                // 服务费PLUS收取类型
                                addedValueRate: '',
                                // 产品名称
                                productName: '',
                                // 列表id
                                companyTempId: '',
                                remark: ''
                            };
                        } else {
                            this.$message.error(d.message);
                        }
                    });
                }
            });
        },
        // 签约取消
        cancelSign() {
            this.addAgentStatus = 1;
            this.queryPageData();
        },
        // 业务员
        changetree(data, lst) {
            if (lst.checkedKeys.length > 0) {
                //这里的treeForm是你el-tree命名的ref的值
                this.$refs.widgetTree.setCheckedKeys([data.deptId]);
            }
        },
        handleCloseRen(key) {
            this.diquData.splice(key, 1);
            this.ruleFormInfo.agentUserId = '';
        },
        // 增加商户确定按钮
        onConfirmed(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    var arrInfo = this.$refs.widgetTree.getCheckedNodes();
                    let arr = this.$refs.widgetTree.getCheckedNodes();
                    this.diquData = arr;
                    for (let index = 0; index < arr.length; index++) {
                        const element = arr[index];
                        this.ruleFormInfo.agentUserId = element.deptId;
                    }
                    this.isShowDialog = false;
                    var arrayInfo = [];
                    for (var i = 0; i < arr.length; i++) {
                        arrayInfo.push(arr[i].deptId);
                    }
                    this.auditForm.agentUserDeptId = arrayInfo.join(',');
                    this.dialogVisibled = false;
                } else {
                    return false;
                }
            });
        },
        handleCheck(a, b) {
            //a为传递给 data 属性的数组中该节点所对应的对象；b为树目前的选中状态对象
            if (b.checkedKeys.length > 0) {
                this.$refs.widgetTree.setCheckedKeys([a.id]);
            }
        },
        cancelInfo() {
            this.addAgentStatus = 1;
            this.queryPageData();
        },
        salesman() {
            this.dialogVisibled = true;
            this.baoguo();
        },
        //树节点 disabled 设置已加入清单的为禁用状态  addSyncData为已经加入清单的数据
        disabledFun(data, node) {
            const that = this;
            let disabled = false;
            that.widgetTreed.filter(item => {
                if (item.isDepartment === data.isDepartment) {
                    disabled = true;
                }
            });
            return disabled;
        },
        //组织结构
        deptTreeCurrentChangebenmen(data, checked, indeterminate) {},
        // 部门菜单树选中
        deptTreeCurrentChangeHandle(data, checked, indeterminate) {
            if (checked) {
                this.$refs.widgetTree.setCheckedKeys([data.deptId]);
            }
        },
        onClickInput() {
            this.checkedList = [];
            (this.auditForm = {
                // 部门id
                deptId: ''
            }),
                (this.isCreateItem = true);
            this.openDialog();
            this.$nextTick(() => {
                this.$refs.widgetTree.setCheckedKeys([]);
            });
        },
        handleCloseed() {
            this.dialogVisibled = false;
        },
        // 带用户树形结构
        baoguo() {
            post(api.queryAgentDepartment).then(
                d => {
                    if (d.code === 0) {
                        this.widgetTreed = d.data;
                    } else {
                        this.$message.error(d.message);
                    }
                },
                err => {}
            );
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisibled = false;
        },
        // 上传签约合同
        beforeUploadedSign() {},
        handleAvatarSuccessedSign(res, file, files, key, val) {
            if (res.code == 0) {
                this.releaseForm.consultUrl = res.data;
                this.$forceUpdate();
            }
        },
        // 删除上传签约合同
        handleRemove(file, fileList) {
            this.releaseForm.consultUrl = '';
            this.$forceUpdate();
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        // 产品名称
        channelInfopen() {
            post(api.getAgentProduct, { serviceId: this.serviceId }).then(d => {
                if (d.code == 0) {
                    this.channelInfo = d.data;
                } else {
                    this.$message.error(d.message);
                }
            });
        },
        // 签约状态
        signInfo(type, row) {
            if (type == 1) {
                this.serviceId = row.serviceId;
                this.channelInfopen();
                this.titleSign = '产品';
                this.addAgentStatus = 3;
                // 签约
                this.releaseForm = {
                    // 产品id
                    productId: '',
                    consultUrl: '',
                    // 服务费PLUS收取类型
                    addedValueRate: '',
                    // 产品名称
                    productName: '',
                    // 列表id
                    companyTempId: '',
                    remark: ''
                };
                this.releaseForm.companyTempId = row.id;
            } else {
                this.serviceId = row.serviceId;
                this.channelInfopen();
                this.addAgentStatus = 3;
                this.titleSign = '查看产品';
                post(api.queryAgentMerchantTempSign, { companyId: row.id }).then(d => {
                    if (d.code == 0) {
                        // 签约
                        this.releaseForm = {
                            // 产品id
                            productId: d.data.productId,
                            consultUrl: d.data.consultUrl,
                            // 服务费PLUS收取类型
                            addedValueRate: d.data.addedValueRate,
                            // 产品名称
                            productName: d.data.productName,
                            // 列表id
                            companyTempId: d.data.companyTempId,
                            remark: d.data.remark
                        };
                    } else {
                        this.$message.error(d.message);
                    }
                });
            }
        },
        // 上传职业营照
        beforeUploaded(file) {},
        handleAvatarSuccessed(res, file, files, key, val) {
            if (res.code == 0) {
                this.$message.warning(res.data.msg);
                this.file = files;
                this.ruleFormInfo.businessLincenseImg = res.data.uploadUrl;
                this.ruleFormInfo['companyName'] = res.data.companyName;
                this.ruleFormInfo['taxNo'] = res.data.socialCreditCode;
                this.ruleForm['legalman'] = res.data.legalPerson;
                this.ruleFormInfo['registerCapital'] = res.data.registerCapital;
                this.ruleFormInfo['businessScope'] = res.data.businessScope;
                this.ruleFormInfo['registerAddress'] = res.data.address;
                this.$forceUpdate();
                if (res.data.businessType == 1) {
                    this.ruleForm.businessTerm = '';
                } else {
                    this.form.businessTerm = res.data.validPeriod;
                }
            } else {
                this.$message.error(res.data.msg);
            }
        },
        // 列表搜索
        search() {
            this.queryPageData();
        },
        // 列表重置
        reset() {
            this.form = {
                checkStatus: '',
                companyName: '',
                serviceId: this.form.serviceId
            };
            this.queryPageData();
        },
        // 渠道商商户列表
        queryPageData(size) {
            if (true) {
                const loading = this.$loading({
                    lock: true,
                    spinner: 'loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                post(api.agentCompanyPage, {
                    currentPage: size || 1,
                    pageSize: this.pageSzie,
                    checkStatus: this.form.checkStatus,
                    companyName: this.form.companyName,
                    serviceId: this.form.serviceId
                }).then(
                    d => {
                        loading.close();
                        if (d.code === 0) {
                            this.total = d.data.count;
                            this.tableData = d.data.rows;
                            this.currentPage = d.data.currentPage;
                        } else {
                        }
                    },
                    err => {}
                );
            }
        },
        // 增加商户状态
        addAgent(type, row) {
            if (type == 0) {
                this.disableTrue = false;
                this.titleInfo = '新增商户';
                this.diquData = '';
                this.addAgentStatus = 2;
                this.submitAdd = true;
                this.ruleForm = {
                    // 法人名称
                    legalman: '',
                    // 法人手机号
                    legalNo: '',
                    // 法人身份证号
                    legalIdCard: '',
                    // 联系人姓名
                    linkman: '',
                    // 联系人手机
                    linkno: '',
                    // 联系人邮箱
                    linkEmail: '',
                    // 联系人身份证
                    linkIdNo: '',
                    // 联系人地址
                    linkAddress: '',
                    // 开户名称
                    bankAccountName: '',
                    // 对公账户
                    bankNo: '',
                    // 开户银行
                    openBank: ''
                };
                // 基本
                this.ruleFormInfo = {
                    id: '',
                    // 营业执照地址
                    businessLincenseImg: '',
                    // 企业名称
                    companyName: '',
                    // 企业简称
                    companyShotName: '',
                    // 信用代码
                    taxNo: '',
                    // 注册资金
                    registerCapital: '',
                    // 业务范围
                    businessScope: '',
                    // 公司注册地址
                    registerAddress: '',
                    serviceId: '',
                    serviceName: '',
                    // 企业员id
                    agentUserId: ''
                };
            } else if (type == 1) {
                this.titleInfo = '编辑商户';
                this.disableTrue = true;
                this.addAgentStatus = 2;
                this.submitAdd = true;
                post(api.queryAgentMerchant, { id: row.id }).then(d => {
                    this.diquData = [{ deptName: d.data.userName }];
                    // 基本
                    this.ruleFormInfo = {
                        // 营业执照地址
                        businessLincenseImg: d.data.businessLicensePhoto,
                        // 企业名称
                        companyName: d.data.companyName,
                        // 企业简称
                        companyShotName: d.data.companySimpleName,
                        // 信用代码
                        taxNo: d.data.taxNo,
                        // 注册资金
                        registerCapital: d.data.registerCapital,
                        // 业务范围
                        businessScope: d.data.businessScope,
                        // 公司注册地址
                        registerAddress: d.data.registerAddress,
                        serviceId: d.data.serviceId,
                        serviceName: d.data.serviceName,
                        // 企业员id
                        agentUserId: d.data.agentUserId
                    };
                    // 法人
                    this.ruleForm = {
                        // 法人名称
                        legalman: d.data.legalman,
                        // 法人手机号
                        legalNo: d.data.legalNo,
                        // 法人身份证号
                        legalIdCard: d.data.legalIdNo,
                        // 联系人姓名
                        linkman: d.data.linkman,
                        // 联系人手机
                        linkno: d.data.linkNo,
                        // 联系人邮箱
                        linkEmail: d.data.linkEmail,
                        // 联系人身份证
                        linkIdNo: d.data.linkIdNo,
                        // 联系人地址
                        linkAddress: d.data.linkAddress,
                        // 开户名称
                        bankAccountName: d.data.bankAccountName,
                        // 对公账户
                        bankNo: d.data.bankNo,
                        // 开户银行
                        openBank: d.data.openBank
                    };
                    this.ruleFormInfo.id = row.id;
                });
            } else {
                this.disableTrue = false;
                this.titleInfo = '查看商户';
                this.addAgentStatus = 2;
                this.submitAdd = false;
                post(api.queryAgentMerchant, { id: row.id }).then(d => {
                    this.diquData = [{ deptName: d.data.userName }];
                    // 基本
                    this.ruleFormInfo = {
                        // 营业执照地址
                        businessLincenseImg: d.data.businessLicensePhoto,
                        // 企业名称
                        companyName: d.data.companyName,
                        // 企业简称
                        companyShotName: d.data.companySimpleName,
                        // 信用代码
                        taxNo: d.data.taxNo,
                        // 注册资金
                        registerCapital: d.data.registerCapital,
                        // 业务范围
                        businessScope: d.data.businessScope,
                        // 公司注册地址
                        registerAddress: d.data.registerAddress,
                        serviceId: d.data.serviceId,
                        serviceName: d.data.serviceName,
                        // 企业员id
                        agentUserId: d.data.agentUserId
                    };
                    // 法人
                    this.ruleForm = {
                        // 法人名称
                        legalman: d.data.legalman,
                        // 法人手机号
                        legalNo: d.data.legalNo,
                        // 法人身份证号
                        legalIdCard: d.data.legalIdNo,
                        // 联系人姓名
                        linkman: d.data.linkman,
                        // 联系人手机
                        linkno: d.data.linkNo,
                        // 联系人邮箱
                        linkEmail: d.data.linkEmail,
                        // 联系人身份证
                        linkIdNo: d.data.linkIdNo,
                        // 联系人地址
                        linkAddress: d.data.linkAddress,
                        // 开户名称
                        bankAccountName: d.data.bankAccountName,
                        // 对公账户
                        bankNo: d.data.bankNo,
                        // 开户银行
                        openBank: d.data.openBank
                    };
                });
            }
        },
        // 列表分页
        handleSizeChange(val) {
            this.pageSzie = val;
            this.queryPageData();
        },
        handleCurrentChange(size) {
            this.queryPageData(size);
        },
        // 返回上一层
        back() {
            this.addAgentStatus = 1;
        },
        backAngent() {
            this.addAgentStatus = 1;
        },
        // 增加商户
        addSubmit(ruleFormInfo, ruleForm) {
            this.$refs[ruleFormInfo].validate(valid => {
                if (valid) {
                    this.$refs[ruleForm].validate(valid => {
                        if (valid) {
                            if (this.titleInfo == '新增商户') {
                                post(api.createAgentCompany, {
                                    businessLincenseImg: this.ruleFormInfo.businessLincenseImg,
                                    companyName: this.ruleFormInfo.companyName,
                                    companyShotName: this.ruleFormInfo.companyShotName,
                                    taxNo: this.ruleFormInfo.taxNo,
                                    registerCapital: this.ruleFormInfo.registerCapital,
                                    businessScope: this.ruleFormInfo.businessScope,
                                    serviceId: this.ruleFormInfo.serviceId,
                                    serviceName: this.ruleFormInfo.serviceName,
                                    registerAddress: this.ruleFormInfo.registerAddress,
                                    legalman: this.ruleForm.legalman,
                                    legalNo: this.ruleForm.legalNo,
                                    legalIdCard: this.ruleForm.legalIdCard,
                                    linkman: this.ruleForm.linkman,
                                    linkno: this.ruleForm.linkno,
                                    linkEmail: this.ruleForm.linkEmail,
                                    linkIdNo: this.ruleForm.linkIdNo,
                                    linkAddress: this.ruleForm.linkAddress,
                                    bankAccountName: this.ruleForm.bankAccountName,
                                    bankNo: this.ruleForm.bankNo,
                                    openBank: this.ruleForm.openBank,
                                    agentUserId: this.ruleFormInfo.agentUserId,
                                    agentUserDeptId: this.auditForm.agentUserDeptId
                                }).then(d => {
                                    if (d.code == 0) {
                                        this.$message.success(d.message);
                                        this.ruleForm = {
                                            // 法人名称
                                            legalman: '',
                                            // 法人手机号
                                            legalNo: '',
                                            // 法人身份证号
                                            legalIdCard: '',
                                            // 联系人姓名
                                            linkman: '',
                                            // 联系人手机
                                            linkno: '',
                                            // 联系人邮箱
                                            linkEmail: '',
                                            // 联系人身份证
                                            linkIdNo: '',
                                            // 联系人地址
                                            linkAddress: '',
                                            // 开户名称
                                            bankAccountName: '',
                                            // 对公账户
                                            bankNo: '',
                                            // 开户银行
                                            openBank: ''
                                        };
                                        // 基本
                                        this.ruleFormInfo = {
                                            // 营业执照地址
                                            businessLincenseImg: '',
                                            // 企业名称
                                            companyName: '',
                                            // 企业简称
                                            companyShotName: '',
                                            // 信用代码
                                            taxNo: '',
                                            // 注册资金
                                            registerCapital: '',
                                            // 业务范围
                                            businessScope: '',
                                            // 公司注册地址
                                            registerAddress: '',
                                            serviceId: '',
                                            serviceName: '',
                                            // 企业员id
                                            agentUserId: ''
                                        };
                                        this.addAgentStatus = 1;
                                        this.queryPageData();
                                    } else {
                                        this.$message.error(d.message);
                                    }
                                });
                            } else if (this.titleInfo == '编辑商户') {
                                post(api.editAgentMerchant, {
                                    id: this.ruleFormInfo.id,
                                    businessLincenseImg: this.ruleFormInfo.businessLincenseImg,
                                    companyName: this.ruleFormInfo.companyName,
                                    companyShotName: this.ruleFormInfo.companyShotName,
                                    taxNo: this.ruleFormInfo.taxNo,
                                    registerCapital: this.ruleFormInfo.registerCapital,
                                    businessScope: this.ruleFormInfo.businessScope,
                                    serviceId: this.ruleFormInfo.serviceId,
                                    serviceName: this.ruleFormInfo.serviceName,
                                    registerAddress: this.ruleFormInfo.registerAddress,
                                    legalman: this.ruleForm.legalman,
                                    legalNo: this.ruleForm.legalNo,
                                    legalIdCard: this.ruleForm.legalIdCard,
                                    linkman: this.ruleForm.linkman,
                                    linkno: this.ruleForm.linkno,
                                    linkEmail: this.ruleForm.linkEmail,
                                    linkIdNo: this.ruleForm.linkIdNo,
                                    linkAddress: this.ruleForm.linkAddress,
                                    bankAccountName: this.ruleForm.bankAccountName,
                                    bankNo: this.ruleForm.bankNo,
                                    openBank: this.ruleForm.openBank,
                                    agentUserId: this.ruleFormInfo.agentUserId,
                                    agentUserDeptId: this.auditForm.agentUserDeptId
                                }).then(d => {
                                    if (d.code == 0) {
                                        this.$message.success(d.message);
                                        this.ruleForm = {
                                            // 法人名称
                                            legalman: '',
                                            // 法人手机号
                                            legalNo: '',
                                            // 法人身份证号
                                            legalIdCard: '',
                                            // 联系人姓名
                                            linkman: '',
                                            // 联系人手机
                                            linkno: '',
                                            // 联系人邮箱
                                            linkEmail: '',
                                            // 联系人身份证
                                            linkIdNo: '',
                                            // 联系人地址
                                            linkAddress: '',
                                            // 开户名称
                                            bankAccountName: '',
                                            // 对公账户
                                            bankNo: '',
                                            // 开户银行
                                            openBank: ''
                                        };
                                        // 基本
                                        this.ruleFormInfo = {
                                            // 营业执照地址
                                            businessLincenseImg: '',
                                            // 企业名称
                                            companyName: '',
                                            // 企业简称
                                            companyShotName: '',
                                            // 信用代码
                                            taxNo: '',
                                            // 注册资金
                                            registerCapital: '',
                                            // 业务范围
                                            businessScope: '',
                                            // 公司注册地址
                                            registerAddress: '',
                                            serviceId: '',
                                            serviceName: '',
                                            // 企业员id
                                            agentUserId: ''
                                        };
                                        this.addAgentStatus = 1;
                                        this.queryPageData();
                                    } else {
                                        this.$message.error(d.message);
                                    }
                                });
                            }
                        }
                    });
                }
            });
        }
    },
    // 分页
    components: {
        pagination: pagination
    }
};
</script>
