<style lang="less" scoped>
.service {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  text-align: left;
  .box {
    width: 100%;
    padding-top: 12px;
    .main_button {
      margin-left: 36px;
    }
    .wrap {
      width: 100%;
      margin-top: 22px;
      padding: 12px;
      box-sizing: border-box;
      .wraped {
        width: 100%;
      }
    }
  }
  .block {
    width: 100%;
    .main {
      width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      .grid-content {
        width: 100%;
        margin-bottom: 20px;
        display: inline-block;
      }
      .grid-left {
        float: left;
        margin-bottom: 20px;
      }
      .grid-img {
        float: left;
        width: 160px;
        height: 97px;
        margin-right: 22px;
      }
      .wrap {
        width: 100%;
        margin-top: 22px;
        padding: 30px;
        box-sizing: border-box;
      }
      .footer {
        padding: 30px;
      }
      .col {
        width: 102%;
        height: 10px;
        background-color: #f2f2f2;
        margin-left: -12px;
      }
    }
  }
  .addConflict {
    float: right;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
</style>
<style>
.el-table th.is-leaf,
.el-table th {
  background-color: #f5f5f5;
  border-bottom: none;
}
/* .main .el-tabs__nav-scroll {
  padding-left: 34px !important;
} */
.main .el-tabs__header {
  margin: 0px !important;
}
.service .el-table__fixed-right {
  right: 0 !important;
  border: none;
}
.service .el-.el-upload-list__item-name-right {
  width: 400px !important;
}
</style>
<template>
  <div class="service">
    <div class="box">
      <el-form label-width="120px">
        <el-row>
          <el-col :lg="18"
                  :md="20">
            <el-col :lg="8"
                    :md="12">
              <el-form-item label="服务商名称：">
                <el-input size="small"
                          v-model="serviceInfo.companyName"
                          placeholder="请输入服务商名称"></el-input>
              </el-form-item>
            </el-col>

            <el-col :lg="8"
                    :md="12">
              <el-form-item label="服务商ID：">
                <el-input size="small"
                          v-model="serviceInfo.supplierId"
                          placeholder="请输入服务商ID"></el-input>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :lg="6"
                  :md="8">
            <el-form-item label=" ">
              <el-button type="primary"
                         size="small"
                         @click="search()">查询</el-button>
              <el-button size="small"
                         class="chongzhi"
                         @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- <div class="addConflict">
        <el-button type="primary"
                   size="small">新增渠道商</el-button>
      </div> -->
      <div class="wrap">
        <el-table :data="tableData"
                  border
                  :render-header="renderHeader"
                  fit
                  style="width: 100%">
          <el-table-column fixed
                           prop="companyName"
                           show-overflow-tooltip
                           label="服务商名称"
                           width='300px'>
          </el-table-column>
          <el-table-column prop="supplierId"
                           show-overflow-tooltip
                           label="服务商ID"
                           width="300px">
          </el-table-column>
          <el-table-column prop="licNo"
                           show-overflow-tooltip
                           label="组织信用代码"
                           min-width="150">
          </el-table-column>
          <el-table-column label="操作"
                           fixed="right"
                           min-width="120">
            <template slot-scope="scope">
              <div @click="productList(scope.row)"
                   style="color:#19A9FC;font-size:12px;cursor: pointer;">
                产品列表
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="wraped">
          <pagination :total="total"
                      :currentPage="current"
                      @handleSizeChange="handleSizeChange"
                      @handleCurrentChange="handleCurrentChange"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from '@/components/pagination.vue'
import api from "@/api/api.js";
import { get, post } from "@/api/index.js";
export default {
  // 默认高度
  data() {
    return {
      serviceInfo: {
        companyName: '',
        email: '',
        liaisons: '',
        mobile: '',
        supplierId: '',
      },
      current: 1,
      total: 0,
      pageSize: 10,
      tableData: []
    };
  },

  mounted() {
    // 服务商列表
    this.serviceList()
  },

  methods: {
    // 产品列表
    productList(row) {
      this.$router.push({ path: '/serviceProduct', query: { supplierId: row.supplierId } })
    },
    // 表格
    renderHeader(h, data) {
      return h('span', [
        h('el-tooltip'), {
          attrs: {
            class: 'item',
            effect: 'dark',
            content: data.column.label
          }
        }, [
          h('span', data.column.label)
        ]
      ])
    },
    // 服务商列表
    serviceList(size) {
      const loading = this.$loading({
        lock: true,
        spinner: "loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      post(api.serviceList, {
        current: size || 1,
        pageSize: this.pageSize,
        companyName: this.serviceInfo.companyName,
        email: this.serviceInfo.email,
        liaisons: this.serviceInfo.liaisons,
        mobile: this.serviceInfo.mobile,
        supplierId: this.serviceInfo.supplierId,
      }).then((d) => {
        if (d.code == 0) {
          if (this.$cookies.get('HB-TOKEN-TEST') || this.$cookies.get('HB-TOKEN')) {
            this.tableData = d.data.rows
            this.total = d.data.total
            this.current = d.data.current
          } else {
            this.tableData = []
            this.total = 0
            this.current = 10
          }
          //   this.tableData = d.data.rows

          loading.close();
        } else {
          this.$message.error(d.message)
          loading.close();
        }
      })
    },
    // 搜索
    search() {
      this.serviceList()
    },
    // 重置
    reset() {
      this.serviceInfo = {
        companyName: '',
        supplierId: '',
      }
      this.serviceList()
    },
    // 服务商分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.serviceList();
    },
    handleCurrentChange(size) {
      this.serviceList(size);
    },
  },
  components: {
    pagination: pagination,
  }
};
</script>
