<style lang="less" scoped>
.agentInfomat {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  text-align: left;
  .editInfo {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 10px;
  }
  .box {
    width: 100%;
    padding-top: 12px;
    .main_button {
      margin-left: 36px;
    }
    .wrap {
      width: 100%;
      margin-top: 22px;
      padding: 12px;
      box-sizing: border-box;
      .wraped {
        width: 100%;
      }
    }
  }
  .block {
    width: 100%;
    .main {
      width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      .grid-content {
        width: 100%;
        margin-bottom: 20px;
        display: inline-block;
      }
      .grid-left {
        float: left;
        margin-bottom: 20px;
      }
      .grid-img {
        float: left;
        width: 160px;
        height: 97px;
        margin-right: 22px;
      }
      .wrap {
        width: 100%;
        margin-top: 22px;
        padding: 30px;
        box-sizing: border-box;
      }
      .footer {
        padding: 30px;
      }
      .col {
        width: 102%;
        height: 10px;
        background-color: #f2f2f2;
        margin-left: -12px;
      }
    }
  }
  .addConflict {
    float: right;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
</style>
<style>
.el-table th.is-leaf,
.el-table th {
  background-color: #f5f5f5;
  border-bottom: none;
}
/* .main .el-tabs__nav-scroll {
  padding-left: 34px !important;
} */
.main .el-tabs__header {
  margin: 0px !important;
}
.agentInfomat .el-table__fixed-right {
  right: 0 !important;
  border: none;
}
.agentInfomat .el-.el-upload-list__item-name-right {
  width: 400px !important;
}
</style>
<template>
  <div class="agentInfomat">
    <div class="box">
      <el-form label-width="120px">
        <el-row>
          <el-col :lg="18"
                  :md="20">
            <el-col :lg="8"
                    :md="12">
              <el-form-item label="服务商ID：">
                <el-input size="small"
                          v-model="form.serviceId"
                          placeholder="请输入服务商ID"></el-input>
              </el-form-item>
            </el-col>

            <el-col :lg="8"
                    :md="12">
              <el-form-item label="渠道商名称：">
                <el-input size="small"
                          v-model="form.agentName"
                          placeholder="请输入渠道商名称"></el-input>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :lg="6"
                  :md="8">
            <el-form-item label=" ">
              <el-button type="primary"
                         size="small"
                         @click="search()">查询</el-button>
              <el-button size="small"
                         class="chongzhi"
                         @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="addConflict"
           @click="bindingService()">
        <el-button type="primary"
                   size="small">绑定服务商</el-button>
      </div>
      <div class="wrap">
        <el-table :data="tableData"
                  border
                  :render-header="renderHeader"
                  fit
                  style="width: 100%">
          <el-table-column fixed
                           show-overflow-tooltip
                           prop="serviceName"
                           label="服务商名称"
                           min-width="130">
          </el-table-column>
          <el-table-column prop="serviceId"
                           show-overflow-tooltip
                           label="服务商ID"
                           width="150px">
          </el-table-column>
          <el-table-column prop="createdTime"
                           show-overflow-tooltip
                           label="创建时间"
                           min-width="160">
          </el-table-column>
          <el-table-column prop="aduitStatus"
                           show-overflow-tooltip
                           label="审核状态"
                           min-width="150">
            <template slot-scope="scope">
              {{scope.row.aduitStatus|aduitStatusInfo}}
            </template>
          </el-table-column>
          <el-table-column prop="agentName"
                           label="渠道商名称"
                           min-width="200">
          </el-table-column>
          <el-table-column prop="id"
                           label="渠道商ID"
                           min-width="150">
          </el-table-column>
          <el-table-column label="操作"
                           fixed="right"
                           min-width="150">
            <template slot-scope="scope">
              <div class="editInfo" v-if="scope.row.isEnabled==1">
                <div @click="goAgent(scope.row)"
                     style="color:#19A9FC;font-size:12px;cursor: pointer;margin-left:-10px">
                  进入渠道商
                </div>
                <!-- <div @click="deliveryDetail(scope.row)"
                     style="color:#19A9FC;font-size:12px;cursor: pointer;">
                  查看详情
                </div> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="wraped">
          <pagination :total="total"
                      :currentPage="currentPage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from '@/components/pagination.vue'
import api from "@/api/api.js";
import { get, post } from "@/api/index.js";
import { mapState } from "vuex";
// 进入渠道商
import menuService from "../../menu/index";
export default {
  // 默认高度
  data() {
    return {
      form: {
        serviceId: '',
        agentName: ''
      },
      currentPage: 1,
      total: 0,
      pageSize: 10,
      tableData: []
    };
  },

  mounted() {
    // 查看云服伙伴的渠道商信息列表
    this.partnerAgentPage()
  },
  computed: {
    ...mapState({
      organizationId: state => state.app.organizationId,
      organizationName: state => state.app.organizationName,
      role: state => state.app.role
    }),
  },

  methods: {
    //   搜索
    search() {
      this.partnerAgentPage()
    },
    reset() {
      this.form = {}
      this.partnerAgentPage()
    },
    // //   查看详情
    // deliveryDetail(row) {
    //   if (row.agentType == '0') {
    //     this.$router.push({ path: '/binding', query: { organizationType: row.agentType, id: row.id } })
    //   }
    // },
    //   进入渠道商
    menu() {
      const loading = this.$loading({
        lock: true,
        spinner: "loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      menuService.menu().then(() => {
        localStorage.setItem("store", JSON.stringify(this.$store.state));
        // this.$router.push({
        //   path: "/",
        // });
        // location.reload()
        window.location.href = "/"
      });
      this.$message.success("登录成功");
      loading.close();
    },
    goAgent(row) {
      post(api.partnerLogin, {
        agentId: row.id,
        mobile: localStorage.getItem('mobile'),
        nickName: localStorage.getItem('nickName'),
        organizationId: this.organizationId,
        organizationName: this.organizationName,
        partnerUserId: localStorage.getItem('userId'),
        role: this.role,
      }).then((res) => {
        localStorage.setItem('routeStauts', false)
        this.$forceUpdate()
        // console.log(res)
        this.$cookies.set("esignmerchantsid", res.data.loginKey);
        this.$cookies.set("merchantloginPhone", res.data.mobile);
        this.$cookies.set("merchantloginNickname", res.data.userName);
        this.$cookies.set("mchcompanyId", res.data.agentId);
        this.menu();
      })
    },
    // 表格
    renderHeader(h, data) {
      return h('span', [
        h('el-tooltip'), {
          attrs: {
            class: 'item',
            effect: 'dark',
            content: data.column.label
          }
        }, [
          h('span', data.column.label)
        ]
      ])
    },
    // 查看云服伙伴的渠道商信息列表
    partnerAgentPage(size) {
      const loading = this.$loading({
        lock: true,
        spinner: "loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      post(api.partnerAgentPage, {
        currentPage: size || 1,
        pageSize: this.pageSize,
        partnerOrganizationId: this.organizationId,
        serviceId: this.form.serviceId,
        agentName: this.form.agentName
      }).then((d) => {
        // console.log(d, 520520)
        if (d.code == 0) {
          this.tableData = d.data.rows
          this.total = d.data.count
          this.currentPage = d.data.currentPage
          loading.close();
        } else {
          this.$message.error(d.message)
          loading.close();
        }
      })
    },
    // 绑定服务商
    bindingService() {
      this.$router.push({ path: '/binding', })
    },
  },
  components: {
    pagination: pagination,
  }
};
</script>
