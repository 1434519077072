<style lang="less" scoped>
.delivery {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  text-align: left;
  .box {
    width: 100%;
    padding-top: 12px;
    .main_button {
      margin-left: 36px;
    }
    .wrap {
      width: 100%;
      margin-top: 22px;
      padding: 12px;
      box-sizing: border-box;
      .wraped {
        width: 100%;
      }
    }
  }
  .block {
    width: 100%;
    .main {
      width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      .grid-content {
        width: 100%;
        margin-bottom: 20px;
        display: inline-block;
      }
      .grid-left {
        float: left;
        margin-bottom: 20px;
      }
      .grid-img {
        float: left;
        width: 160px;
        height: 97px;
        margin-right: 22px;
      }
      .wrap {
        width: 100%;
        margin-top: 22px;
        padding: 30px;
        box-sizing: border-box;
      }
      .footer {
        padding: 30px;
      }
      .col {
        width: 102%;
        height: 10px;
        background-color: #f2f2f2;
        margin-left: -12px;
      }
    }
  }
  .addConflict {
    float: right;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
</style>
<style>
.el-table th.is-leaf,
.el-table th {
  background-color: #f5f5f5;
  border-bottom: none;
}
/* .main .el-tabs__nav-scroll {
  padding-left: 34px !important;
} */
.main .el-tabs__header {
  margin: 0px !important;
}
.delivery .el-table__fixed-right {
  right: 0 !important;
  border: none;
}
.delivery .el-.el-upload-list__item-name-right {
  width: 400px !important;
}
</style>
<template>
  <div class="delivery">
    <div class="box">
      <el-form label-width="120px">
        <el-row>
          <el-col :lg="18"
                  :md="20">
            <el-col :lg="8"
                    :md="12">
              <el-form-item label="交付商名称">
                <el-input size="small"
                          v-model="form.mainName"
                          placeholder="请输入交付商名称"></el-input>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :lg="6"
                  :md="8">
            <el-form-item label=" ">
              <el-button type="primary"
                         size="small"
                         @click="search()">查询</el-button>
              <el-button size="small"
                         class="chongzhi"
                         @click="reset()">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- <div class="addConflict">
        <el-button type="primary"
                   size="small">新增渠道商</el-button>
      </div> -->
      <div class="wrap">
        <el-table :data="tableData"
                  border
                  :render-header="renderHeader"
                  fit
                  style="width: 100%">
          <el-table-column fixed
                           prop="mainName"
                           show-overflow-tooltip
                           label="交付商名称"
                           width='300px'>
          </el-table-column>
          <el-table-column prop="id"
                           show-overflow-tooltip
                           label="交付商ID"
                           width="300px">
          </el-table-column>
          <el-table-column prop="supplierCompanyName"
                           show-overflow-tooltip
                           label="服务商名称"
                           min-width="150">
          </el-table-column>
          <el-table-column prop="mainArea"
                           show-overflow-tooltip
                           label="税源地"
                           min-width="150">
            <template slot-scope="scope">
              {{scope.row.mainArea|mainAreaInfo}}
            </template>
          </el-table-column>
          <el-table-column prop="taxEnable"
                           show-overflow-tooltip
                           label="代征资质"
                           min-width="150">
            <template slot-scope="scope">
              {{scope.row.taxEnable|taxEnableInfo}}
            </template>
          </el-table-column>
          <el-table-column prop="status"
                           show-overflow-tooltip
                           label="状态"
                           min-width="150">
            <template slot-scope="scope">
              {{scope.row.status|statusInfo}}
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           fixed="right"
                           min-width="120">
            <template slot-scope="scope">
              <div @click="deliveryDetail(scope.row)"
                   style="color:#19A9FC;font-size:12px;cursor: pointer;">
                查看详情
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="wraped">
          <pagination :total="total"
                      :currentPage="current"
                      @handleSizeChange="handleSizeChange"
                      @handleCurrentChange="handleCurrentChange"></pagination>
        </div>
      </div>
    </div>
    <!-- 交付商详情 -->
    <el-dialog title="查看交付商信息"
               :visible.sync="dialogVisible"
               width="45%"
               :before-close="handleClose">
      <el-form :mode="deliveryForm"
               ref="deliveryForm"
               label-width="100px">
        <el-form-item label="交付商名称">
          <el-input size="small"
                    v-model="deliveryForm.mainName"
                    placeholder="请输入交付商名称"></el-input>
        </el-form-item>
        <el-form-item label="交付商ID">
          <el-input size="small"
                    v-model="deliveryForm.id"
                    placeholder="请输入交付商ID"></el-input>
        </el-form-item>
        <!-- <el-form-item label="服务商名称">
          <el-input size="small"
                    v-model="deliveryForm.supplierCompanyName"
                    placeholder="请输入服务商名称"></el-input>
        </el-form-item> -->
        <el-form-item label="税源地">
          <el-select v-model="deliveryForm.mainArea"
                     size="small"
                     placeholder="请选择税源地"
                     style="width:100%">
            <el-option v-for="(item,index) in areaInfo"
                       :label="item.label"
                       :key="index"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代征资质">
          <el-select v-model="deliveryForm.taxEnable"
                     size="small"
                     placeholder="请选择代征资质"
                     style="width:100%">
            <el-option v-for="(item,index) in taxInfo"
                       :label="item.label"
                       :key="index"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch v-model="deliveryForm.status"
                     v-if="deliveryForm.status==0"
                     inactive-color="#13ce66">
          </el-switch>
          <el-switch v-model="deliveryForm.status"
                     v-else
                     active-color="#ff4949">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!-- <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/components/pagination.vue'
import api from "@/api/api.js";
import { get, post } from "@/api/index.js";
export default {
  // 默认高度
  data() {
    return {
      form: {
        mainName: ''
      },
      // 代征资质
      taxInfo: [
        {
          label: "有",
          value: '10',
        },
        {
          label: "没有",
          value: '20',
        },
      ],
      // 税源地
      areaInfo: [
        {
          label: '河南',
          value: '00'
        },
        {
          label: '山东',
          value: '01'
        },
        {
          label: '江西',
          value: '02'
        },
        {
          label: '湖北',
          value: '03'
        },
      ],
      deliveryForm: {
        mainName: '',
        id: '',
        supplierCompanyName: '',
        mainArea: '',
        taxEnable: '',
        status: '',
      },
      dialogVisible: false,
      current: 1,
      total: 0,
      pageSize: 10,
      tableData: []
    };
  },

  mounted() {
    // 服务商列表
    this.deliveryList()
  },

  methods: {
    // 表格
    renderHeader(h, data) {
      return h('span', [
        h('el-tooltip'), {
          attrs: {
            class: 'item',
            effect: 'dark',
            content: data.column.label
          }
        }, [
          h('span', data.column.label)
        ]
      ])
    },
    // 交付商列表
    deliveryList(size) {
      const loading = this.$loading({
        lock: true,
        spinner: "loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      post(api.deliveryList, {
        current: size || 1,
        pageSize: this.pageSize,
        mainName: this.form.mainName
      }).then((d) => {
        // console.log(d, 999)
        if (d.code == 0) {
           if (this.$cookies.get('HB-TOKEN-TEST') || this.$cookies.get('HB-TOKEN')) {
            this.tableData = d.data.rows
            this.total = d.data.total
            this.current = d.data.current
          } else {
            this.tableData = []
            this.total = 0
            this.current = 10
          }
          //   this.tableData = d.data.rows
          loading.close();
        } else {
          this.$message.error(d.message)
          loading.close();
        }
      })
    },
    // 交付商详情
    deliveryDetail(row) {
      this.dialogVisible = true
      const loading = this.$loading({
        lock: true,
        spinner: "loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      post(api.deliveryDetail, {
        id: row.id
      }).then((d) => {
        if (d.code == 0) {
          this.deliveryForm.mainName = d.data.mainName
          this.deliveryForm.id = d.data.id
          this.deliveryForm.supplierCompanyName = d.data.supplierCompanyName
          this.deliveryForm.mainArea = d.data.mainArea
          this.deliveryForm.taxEnable = d.data.taxEnable
          this.deliveryForm.status = d.data.status
          loading.close();
        } else {
          this.$message.error(d.message)
          loading.close();
        }
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    // 搜索
    search() {
      this.deliveryList()
    },
    // 重置
    reset() {
      this.form = {}
      this.deliveryList()
    },
    // 服务商分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.deliveryList();
    },
    handleCurrentChange(size) {
      this.deliveryList(size);
    },
  },
  components: {
    pagination: pagination,
  }
};
</script>
