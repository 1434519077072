<template>
  <div id="app"
       v-if="navigationBarType">
    <navigationBar></navigationBar>
    <navigationBarLeft v-if="navigationBarLeftType"
                       :activeIndex="activeIndex"></navigationBarLeft>
    <div :class="navigationBarLeftType ? 'main' : 'appMain'">
      <router-view v-if="isRouterAlive"></router-view>
    </div>
  </div>
  <div id="appNo"
       v-else>
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import navigationBar from "@/components/navigationBar";
import navigationBarLeft from "@/components/navigationBarLeft";

export default {
  name: "App",
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      activeIndex: "/taskCenter/delivery",
    //   没有使用左侧栏和头部
      navWhiteList: ["Login","agentIndex","synthesize"],
      navigationBarType: true,
      navigationBarLeftType: true,
      menuLeftArr: [],
      loginKey: "",
      companyId: "",
      ucname: "",
      isRouterAlive: true,
    };
  },
  watch: {
    $route(to, from) {
      if (from.name == "Login") {
        //  this.getMenuPost();
      }
      if (this.navWhiteList.indexOf(to.name) >= 0) {
        this.navigationBarType = false;
      } else {
        this.navigationBarType = true;
      }
    },
  },
  mounted() {
    this.activeIndex = localStorage.getItem("activeIndex") || "0-0";
    //  this.getMenuPost();
    // sessionStorage.clear()
    // this.$router.push({path:'/agentIndex'})
    // window.location.reload()
  },
  created() {
    // 在页面加载时读取localStorage
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }
    // 在页面刷新时将store保存到localStorage里
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });
    if (this.navWhiteList.indexOf(this.$router.history.current.name) >= 0) {
      this.navigationBarType = false;
    } else {
      this.navigationBarType = true;
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
    // getMenuPost() {
    //   post(api.getMenu).then(
    //     (d) => {
    //       if (d.status == 0) {
    //         this.menuLeftArr = d.data;
    //       }
    //     },
    //     (err) => {}
    //   );
    // },
  },
  components: {
    navigationBar: navigationBar,
    navigationBarLeft: navigationBarLeft,
  },
};
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  height: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  .main {
    float: left;
    width: calc(100% - 182px);
    height: calc(100% - 58px);
    // background:rgba(121, 184, 255, 0.3);
    background-color: #f2f2f2;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  .appMain {
    width: 100%;
    height: calc(100% - 58px);
    // background:rgba(121, 184, 255, 0.3);
    // overflow-y: auto;
  }
}
#appNo {
  width: 100%;
  height: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<style>
.loading {
  display: inline-block;
  width: 54px;
  height: 56px;
  background: url(./assets/img/bars.svg);
  background-size: cover;
}
.el-table th {
  /* background-color: rgba(242, 240, 240, 100) !important; */
  background-color: rgb(245, 245, 245) !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 104px;
  height: 104px;
  line-height: 104px !important;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-range-editor.el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
  padding: 0px 10px !important;
}
.el-date-editor--datetimerange.el-input__inner {
  width: 100% !important;
}
.el-dropdown-link {
  cursor: pointer;
}
.el-table .cell {
  white-space: nowrap !important;
}
</style>
