import VueCookie from 'vue-cookie';

export default {
    inserted: (el, binding) => {
        let permissions = VueCookie.get('permissions');
        if (permissions === '*') {
            return;
        }
        const permissionList = permissions.split(',');
        let permission = binding.value;
        if (permissionList.indexOf(permission) === -1) {
            el.parentNode.removeChild(el);
        }
    }
};

