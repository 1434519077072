<template>
  <div class="mainInfo">
    <el-tabs type="border-card">
      <el-tab-pane label="渠道商交易统计" :lazy="true">
        <agent-total></agent-total>
      </el-tab-pane>
      <el-tab-pane label="商户交易统计" :lazy="true">
        <merchant-total></merchant-total>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import agentTotal from './components/agentTotal.vue'
import MerchantTotal from './components/merchantTotal.vue'

export default {
  components: { agentTotal, MerchantTotal }
}
</script>

