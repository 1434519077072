export function workStatusForm(value) {
  const types = {
    '1': "离职",
    '0': "在职",
  };
  return types[value] || '';
}
export function signStatusOption(value) {
  const types = {
    '1': "已签约",
    '0': "未签约",
  };
  return types[value] || '';
}
export function invoiceTypeFilter(val) {
  const map = {
      0: '增值税普通发票',
      1: '增值税专用发票',
      2: '增值税电子普通发票',
      3: '增值税电子专用发票'
  }
  return map[val] || '未知';
}
export function userSourceForm(value) {
  const types = {
    '0': "普通",
    '1': "微信",
    '2': "钉钉",
  };
  return types[value] || '';
}
export function isEnabledInfo(value) {
  const types = {
    '0': "关闭",
    '1': "开启",
  };
  return types[value] || '';
}
export function checkStatusInfo(value) {
  const types = {
    '1': "待审核",
    '2': "审核通过",
    '3': "审核失败"
  };
  return types[value] || '';
}
export function aduitStatusInfo(value) {
  const types = {
    '1': "待审核",
    '2': "审核通过",
    '3': "审核失败"
  };
  return types[value] || '';
}
export function cashStatusInfoTwo(value) {
  const types = {
    '00': "成功",
    '01': "处理中",
    '02': "失败",
    '03': "待处理",
  };
  return types[value] || '';
}
export function channelTypeForm(value) {
  const types = {
    // '0': "渠道",
    // '1': "代理商",
    '2': "集团公司",
  };
  return types[value] || '';
}
export function businessTypeForm(value) {
  const types = {
    '0': "非长期",
    '1': "长期"
  };
  return types[value] || '';
}
// 中间页
export function taxEnableInfo(value) {
  const types = {
    '10': "有",
    '20': "没有"
  };
  return types[value] || '';
}
export function statusInfo(value) {
  const types = {
    '0': "可用",
    '1': "不可用"
  };
  return types[value] || '';
}
export function mainAreaInfo(value) {
  const types = {
    '00': "河南",
    '01': "山东",
    '02': "江西",
    '03': "湖北",
  };
  return types[value] || '';
}
export function spunFenform(number) {
  return isNaN(number) ? 0 : parseFloat((number * 100).toFixed(0));
}

