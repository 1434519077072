<style lang="less" scoped>
.groupInformation {
    width: 100%;
    min-height: 100%;
    padding: 12px;
    box-sizing: border-box;
    background-color: #fff;
    .box {
        width: 100%;
        .main_button {
            text-align: left;
            margin-left: 36px;
        }
        .wrap {
            width: 100%;
            margin-top: 22px;
            box-sizing: border-box;
            .wraped {
                width: 100%;
            }
        }
    }
    .block {
        width: 100%;
        overflow: hidden;
        .list {
            width: 100%;
            text-align: left;
            padding: 20px;
            box-sizing: border-box;
            font-size: 14px;
            color: rgba(16, 16, 16, 100);
            .grid-content {
                width: 100%;
                margin-bottom: 20px;
                display: inline-block;
            }
            .baoguo {
                width: 100%;
                overflow: hidden;
                .zongji {
                    float: left;
                    font-weight: 500;
                    font-size: 22px;
                    color: #303133;
                }
                .anniu {
                    float: right;
                }
            }
            .biankuang {
                width: 336px;
                height: 169px;
                border: 1px solid #6666;
                .dian {
                    background-color: #52c41a;
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-left: 30px;
                }
                .jinqian {
                    font-size: 20px;
                    line-height: 89px;
                    vertical-align: middle;
                    margin-left: 20px;
                }
                .wenzi {
                    margin-left: 89px;
                    font-size: 22px;
                    color: #666;
                }
            }
        }
        .list2 {
            box-sizing: border-box;
            font-size: 14px;
            color: #101010;
            float: left;
            padding-top: 20px;
            .grid-content {
                width: 100%;
                margin-bottom: 20px;
                display: inline-block;
            }
            .button {
                margin: 0 auto;
                margin-top: 24px;
                height: 40px;
                line-height: 40px;
                background: #3083ff;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                letter-spacing: 1px;
                cursor: pointer;
                text-align: center;
            }
            .text {
                margin-top: 56px;
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                text-align: center;
            }
        }
        .col {
            width: 102%;
            height: 10px;
            background-color: #f2f2f2;
            margin-left: -12px;
        }
        .wrap {
            width: 100%;
            margin-top: 50px;
            box-sizing: border-box;
            overflow: hidden;
            .baoguo {
                height: 25px;
                width: 100%;
                margin-top: 10px;
            }
            .wraped {
                width: 100%;
            }
        }
    }
}
</style>
<style>
.groupInformation .el-step__head.is-success {
    color: rgba(48, 131, 255, 1);
    border-color: rgba(48, 131, 255, 1);
}
.groupInformation .el-step__title.is-success {
    color: rgba(0, 0, 0, 0.65);
}
.groupInformation .is-process .el-step__icon.is-text {
    background: rgba(48, 131, 255, 1);
    border: none;
    color: #fff;
}
.groupInformation .el-table__fixed-right {
    right: 0 !important;
}
</style>


<template>
    <div class="groupInformation">
        <div class="box"
             v-show="releaseType">
            <el-form ref="releaseForm"
                     :model="releaseForm"
                     label-width="120px">
                <el-row>
                    <el-col :lg="18"
                            :md="20">
                        <el-row>
                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="渠道名称：">
                                    <el-input v-model="releaseForm.channelName"
                                              placeholder="请输入渠道名称"
                                              size="small"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="渠道类型：">
                                    <el-select clearable
                                               filterable
                                               v-model="releaseForm.channelType"
                                               style="width: 100%"
                                               placeholder="请选择渠道类型"
                                               size="small">
                                        <el-option v-for="item in channeloptions"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="8"
                                    :md="12">
                                <el-form-item label="创建时间：">
                                    <el-date-picker style="width: 100%"
                                                    v-model="releaseForm.createdAt"
                                                    type="daterange"
                                                    range-separator="至"
                                                    start-placeholder="开始日期"
                                                    end-placeholder="结束日期"
                                                    format="yyyy 年 MM 月 dd 日 HH 时 mm 分 ss 秒"
                                                    value-format="yyyy-MM-dd HH:mm:ss">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :md="6"
                            :sm="8">
                        <el-form-item label=" ">
                            <el-button type="primary"
                                       size="small"
                                       icon="el-icon-search"
                                       @click="search()">查询</el-button>
                            <el-button size="small"
                                       icon='el-icon-refresh-left'
                                       class="chongzhi"
                                       @click="reset()">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="toobar">
                <div class="main_button">
                    <el-button type="primary"
                               size="small"
                               @click="clickApply()"><i class="el-icon-plus"></i>&nbsp;&nbsp;新增集团</el-button>
                    <!-- v-permission="'add_group'" -->
                </div>
            </div>
            <div class="wrap">
                <el-table :data="tableData"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                          fit
                          border
                          :render-header="renderHeader"
                          style="width: 100%">
                    <af-table-column fixed
                                     show-overflow-tooltip
                                     prop="channelName"
                                     label="渠道名称">
                    </af-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="channelShortName"
                                     label="渠道简称">
                    </af-table-column>
                    <el-table-column prop="channelType"
                                     show-overflow-tooltip
                                     label="渠道类型">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.channelType | channelTypeForm }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <af-table-column prop="contactName"
                                     show-overflow-tooltip
                                     label="联系人">
                    </af-table-column>
                    <el-table-column prop="contactMobile"
                                     show-overflow-tooltip
                                     label="联系人电话"
                                     min-width="110">
                    </el-table-column>
                    <af-table-column prop="creditCode"
                                     show-overflow-tooltip
                                     label="统一社会信用代码">
                    </af-table-column>
                    <el-table-column prop="businessType"
                                     show-overflow-tooltip
                                     label="营业期限类型"
                                     min-width="120">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.businessType | businessTypeForm }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <af-table-column prop="businessTerm"
                                     show-overflow-tooltip
                                     label="营业期限">
                    </af-table-column>
                    <af-table-column prop="createdAt"
                                     show-overflow-tooltip
                                     label="创建时间">
                    </af-table-column>
                    <el-table-column fixed="right"
                                     label="操作"
                                     min-width="120">
                        <template slot-scope="scope">
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                    更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <el-button @click="clickRow(scope.row)"
                                                   size="mini"
                                                   class="my-2 w_fluid"
                                                   plain>
                                            关联商户
                                        </el-button>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <el-button @click="updateRow(scope.row)"
                                                   size="mini"
                                                   class="my-2 w_fluid"
                                                   plain>
                                            修改集团
                                        </el-button>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wraped">
                    <pagination :total="total"
                                :currentPage="currentPage"
                                @handleSizeChange="handleSizeChange"
                                @handleCurrentChange="handleCurrentChange"></pagination>
                </div>
            </div>
        </div>
        <div class="block"
             v-show="!releaseType">
            <el-page-header @back="goBack"
                            :content="typename"> </el-page-header>
            <div class="list">
                <el-row>
                    <el-col :span="8"><span class="grid-content bg-purple">集团名称：{{ merTradeOrder.channelName }}</span></el-col>
                    <el-col :span="8"><span class="grid-content bg-purple">集团简称：{{ merTradeOrder.channelShortName }}</span></el-col>
                    <el-col :span="8"><span class="grid-content bg-purple">统一社会信用代码：{{ merTradeOrder.creditCode }}</span></el-col>
                </el-row>
            </div>
            <div class="col"></div>
            <div class="list2">
                <el-form ref="leaseForm"
                         :model="leaseForm"
                         :rules="leaserules"
                         label-width="100px"
                         style="display: flex">
                    <el-form-item label="关联商户："
                                  prop="companyId">
                        <el-select @change="onInputChangeRen"
                                   v-model="leaseForm.companyId"
                                   clearable
                                   filterable
                                   remote
                                   reserve-keyword
                                   placeholder="请输入关联的商户"
                                   :remote-method="remoteMethodRen"
                                   :loading="loading"
                                   style="width: 350px">
                            <el-option v-for="item in qiyeRenOptions"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary"
                                   @click="searchBtn('leaseForm')">增加</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="wrap">
                <span style="float: left">已关联的商户</span>
                <div class="baoguo"></div>
                <el-table ref="multipleTable"
                          :data="moenyDataed"
                          fit
                          border
                          :render-header="renderHeader"
                          :header-cell-style="{ background: 'rgb(250, 250, 250)' }">
                    <el-table-column show-overflow-tooltip
                                     prop="creditCode"
                                     :key="1"
                                     label="数量"></el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="companyName"
                                     :key="2"
                                     label="商户名称"></el-table-column>
                    <el-table-column show-overflow-tooltip
                                     prop="legalPersonName"
                                     :key="3"
                                     label="法人姓名"></el-table-column>
                    <af-table-column show-overflow-tooltip
                                     prop="userMobile"
                                     :key="4"
                                     label="法人手机号"></af-table-column>

                    <el-table-column fixed="right"
                                     label="操作"
                                     width="100">
                        <template slot-scope="scope">
                            <el-button @click="deleteChannel(scope.row)"
                                       type="text"
                                       size="small">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="wraped">
                <pagination :total="totalJie"
                            :currentPage="currentPageJie"
                            @handleSizeChange="handleSizeChangeJie"
                            @handleCurrentChange="handleCurrentChangeJie"></pagination>
            </div>
        </div>
        <!-- 新增集团 -->
        <el-dialog :title="disableTitle"
                   :visible.sync="dialogVisible4"
                   width="780px"
                   :before-close="handleClose4">
            <el-form :model="taskForm"
                     :rules="taskRules"
                     ref="taskForm"
                     label-width="150px"
                     class="demo-ruleForm"
                     style="text-align: left">
                <el-form-item label="企业营业执照图："
                              prop="businessLincenseImg">
                    <el-upload style="width: 100px; height: 100px"
                               class="avatar-uploader"
                               :action="uploadUrled"
                               :headers="myHeaders"
                               :show-file-list="false"
                               :on-success="handleAvatarSuccessed"
                               :before-upload="beforeUploaded">
                        <img v-if="taskForm.businessLincenseImg"
                             :src="taskForm.businessLincenseImg"
                             width="100px"
                             height="100px" />
                        <i v-else
                           class="el-icon-plus avatar-uploader-icon baoguo-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="企业名称："
                              prop="channelName">
                    <el-input v-model="taskForm.channelName"
                              placeholder="请输入企业名称"
                              size="small"
                              style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="企业简称："
                              prop="channelShortName">
                    <el-input v-model="taskForm.channelShortName"
                              placeholder="请输入企业简称"
                              size="small"
                              style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="企业类型："
                              prop="channelType">
                    <el-select clearable
                               filterable
                               v-model="taskForm.channelType"
                               style="width: 500px"
                               placeholder="请选择企业类型"
                               size="small">
                        <el-option v-for="item in channeloptions"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="营业期限："
                              prop="businessTerm">
                    <el-date-picker size="small"
                                    style="width: 500px"
                                    v-model="taskForm.businessTerm"
                                    type="date"
                                    placeholder="选择日期"
                                    format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd"
                                    @change="shijianBtn">
                    </el-date-picker>
                    <el-checkbox v-model="typeID"
                                 @change="changqiBtn"
                                 style="margin-left: 26px">长期</el-checkbox>
                </el-form-item>
                <el-form-item label="统一社会信用代码："
                              prop="creditCode">
                    <el-input v-model="taskForm.creditCode"
                              placeholder="请输入统一社会信用代码"
                              size="small"
                              style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="法人姓名："
                              prop="contactName">
                    <el-input v-model="taskForm.contactName"
                              placeholder="请输入法人姓名"
                              size="small"
                              style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="法人手机号："
                              prop="contactMobile">
                    <el-input v-model="taskForm.contactMobile"
                              placeholder="请输入管理员手机号"
                              size="small"
                              style="width: 500px"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary"
                               :loading="shenheLoadinged"
                               @click="submitFormed('taskForm')">确定</el-button>
                    <el-button @click="resetFormed('taskForm')">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import pagination from '../../components/pagination.vue';
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';

export default {
    data() {
        return {
            uploadUrled: api.businessLicense,
            myHeaders: { Authorization: this.$cookies.get('esignmerchantsid') },
            releaseType: true,
            shenheLoading: false,
            dialogVisible4: false,
            shenheLoadinged: false,
            totalJie: null,
            pageSzieJie: 10,
            currentPageJie: 1,
            total: null,
            pageSzie: 10,
            currentPage: 1,
            merTradeOrder: {},
            dialogVisible: false,
            typeID: false,
            disableTitle: '',
            typename: '集团商户关联',
            tableData: [],
            releaseForm: {
                channelName: '',
                channelType: '',
                createdAt: ['', '']
            },
            leaseForm: {
                channelId: '',
                companyId: ''
            },

            leaserules: {
                companyName: [{ required: true, message: '请填写关联商户', trigger: 'blur' }]
            },
            taskForm: {
                id: '',
                businessLincenseImg: '',
                channelType: '',
                channelName: '',
                channelShortName: '',
                contactName: '',
                contactMobile: '',
                creditCode: '',
                businessLincenseImg: '',
                businessType: '',
                businessTerm: ''
            },
            taskRules: {
                businessLincenseImg: [
                    { required: true, message: '请上传企业营业执照图', trigger: 'blur' }
                ],
                channelName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
                channelType: [{ required: true, message: '请输入企业类型', trigger: 'blur' }],
                channelShortName: [{ required: true, message: '请输入企业简称', trigger: 'blur' }],
                businessTerm: [{ required: true, message: '请输入营业期限', trigger: 'blur' }],
                businessType: [{ required: true, message: '请输入营业期限类型', trigger: 'blur' }],
                creditCode: [
                    {
                        required: true,
                        message: '请输入统一社会信用代码',
                        trigger: 'blur'
                    }
                ],
                contactName: [{ required: true, message: '请输入法人姓名', trigger: 'blur' }],
                contactMobile: [{ required: true, message: '请输入法人手机号', trigger: 'blur' }]
            },
            qiyeRenOptions: [],
            moenyDataed: [],
            loading: false,
            channeloptions: [
                {
                    value: '2',
                    label: '集团公司'
                }
            ]
        };
    },
    mounted() {
        this.queryPageData();
    },
    methods: {
        // 表格
        renderHeader(h, data) {
            return h('span', [
                h('el-tooltip'),
                {
                    attrs: {
                        class: 'item',
                        effect: 'dark',
                        content: data.column.label
                    }
                },
                [h('span', data.column.label)]
            ]);
        },
        changqiBtn(val) {
            if (val == true) {
                this.taskForm.businessTerm = '';
                this.taskRules['businessTerm'] = [
                    { required: false, message: '请选择营业期限', trigger: 'blur' }
                ];
            } else {
                this.taskRules['businessTerm'] = [
                    { required: true, message: '请选择营业期限', trigger: 'blur' }
                ];
            }
        },
        shijianBtn(val) {
            if (val != '') {
                this.typeID = false;
                this.taskRules['businessTerm'] = [
                    { required: true, message: '请选择营业期限', trigger: 'blur' }
                ];
            }
        },
        resetFormed(formName) {
            this.taskForm = {
                businessLincenseImg: '',
                channelType: '',
                channelName: '',
                channelShortName: '',
                contactName: '',
                contactMobile: '',
                creditCode: '',
                businessLincenseImg: '',
                businessType: '',
                businessTerm: ''
            };
            this.typeID = false;
            this.$refs[formName].resetFields();
            this.dialogVisible4 = false;
        },
        submitFormed(formName) {
            this.shenheLoadinged = true;
            if (this.typeID == true) {
                this.taskForm['businessType'] = 1;
            } else {
                this.taskForm['businessType'] = 0;
            }
            this.$refs[formName].validate(valid => {
                if (valid) {
                    post(api.saveChannel, this.taskForm).then(
                        d => {
                            this.shenheLoadinged = false;
                            if (d.code === 0) {
                                this.$message.success('保存成功');
                                this.taskForm = {
                                    id: '',
                                    businessLincenseImg: '',
                                    channelType: '',
                                    channelName: '',
                                    channelShortName: '',
                                    contactName: '',
                                    contactMobile: '',
                                    creditCode: '',
                                    businessLincenseImg: '',
                                    businessType: '',
                                    businessTerm: ''
                                };
                                this.typeID = false;
                                this.$refs[formName].resetFields();
                                this.queryPageData();
                                this.dialogVisible4 = false;
                            } else {
                                this.shenheLoadinged = false;
                                this.$message.error(d.message);
                            }
                        },
                        err => {
                            this.shenheLoadinged = false;
                        }
                    );
                } else {
                    // console.log('error submit!!');
                    this.shenheLoadinged = false;
                    return false;
                }
            });
        },
        beforeUploaded(file) {},
        handleAvatarSuccessed(res, file, files, key, val) {
            if (res.code == 0) {
                this.$message.warning(res.data.msg);
                this.file = files;
                this.taskForm.businessLincenseImg = res.data.uploadUrl;
                this.taskForm['channelName'] = res.data.companyName;
                this.taskForm['creditCode'] = res.data.socialCreditCode;
                this.taskForm['contactName'] = res.data.legalPerson;
                if (res.data.businessType == 1) {
                    this.typeID = true;
                    this.taskForm.businessTerm = '';
                    this.taskRules['businessTerm'] = [
                        { required: false, message: '请选择营业期限', trigger: 'blur' }
                    ];
                } else {
                    this.typeID = false;
                    this.taskForm.businessTerm = res.data.validPeriod;
                }
            } else {
                this.$message.error(res.data.msg);
            }
        },
        handleClose4() {
            this.dialogVisible4 = false;
        },
        //任务名称
        onInputChangeRen(val) {
            //向父组件传递选择地值，调用父组件setMajor把选中值赋给父组件
            // // console.log(val, "djdjddjj");
            let obj = {};
            obj = this.qiyeRenOptions.find(item => {
                //model就是上面的数据源
                return item.value === val; //筛选出匹配数据
            });
            // // console.log(obj, "zidiandd")
            this.leaseForm.companyId = obj.value;
        },
        queryPageData(size) {
            const loading = this.$loading({
                lock: true,
                spinner: 'loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            post(api.channels, {
                currentPage: size || 1,
                pageSize: this.pageSzie,
                channelName: this.releaseForm.channelName,
                channelType: this.releaseForm.channelType,
                createTimeStart: this.releaseForm.createdAt[0],
                createTimeEnd: this.releaseForm.createdAt[1]
            }).then(
                d => {
                    loading.close();
                    if (d.code === 0) {
                        if (d.hasOwnProperty('data')) {
                            this.total = d.data.count;
                            this.currentPage = d.data.currentPage;
                            this.tableData = d.data.rows;
                        } else {
                            this.total = 0;
                            this.currentPage = 1;
                            this.pageSzie = 10;
                            this.tableData = [];
                        }
                    } else {
                        this.$message.error(d.message);
                        this.total = 0;
                        this.currentPage = 1;
                        this.pageSzie = 10;
                        this.tableData = [];
                    }
                },
                err => {
                    //error callback
                }
            );
        },
        queRenPost(size) {
            post(api.associatedMerchants, {
                currentPage: size || 1,
                pageSize: this.pageSzieJie,
                channelId: this.merTradeOrder.channelId
            }).then(
                d => {
                    if (d.code === 0) {
                        // // console.log(d,'hdhdhddh')
                        if (d.hasOwnProperty('data')) {
                            this.totalJie = d.data.count;
                            this.currentPageJie = d.data.currentPage;
                            this.moenyDataed = d.data.rows;
                        } else {
                            this.totalJie = 0;
                            this.currentPageJie = 1;
                            this.pageSzieJie = 10;
                            this.moenyDataed = [];
                        }
                    } else {
                        this.totalJie = 0;
                        this.currentPageJie = 1;
                        this.pageSzieJie = 10;
                        this.moenyDataed = [];
                    }
                },
                err => {
                    //error callback
                }
            );
        },
        searchBtn(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$confirm('此操作将关联商户, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        post(api.relationCompany, {
                            channelId: this.leaseForm.channelId,
                            companyId: this.leaseForm.companyId
                        }).then(
                            d => {
                                if (d.code === 0) {
                                    this.leaseForm.companyId = '';
                                    this.$message.success(d.message);
                                    this.queRenPost();
                                } else {
                                    this.$message.error(d.message);
                                }
                            },
                            err => {
                                //error callback
                            }
                        );
                    });
                } else {
                    // console.log('error submit!!');

                    return false;
                }
            });
        },
        //任务名称
        remoteMethodRen(query) {
            if (query !== '') {
                this.qiyeRenOptions = [];
                post(api.channelByChannelName, {
                    companyName: query
                }).then(d => {
                    if (d.code == 0) {
                        if (d.hasOwnProperty('data')) {
                            this.statesRen = d.data;
                            this.listRen = [];
                            if (this.statesRen.length > 0) {
                                this.listRen = this.statesRen.map(item => {
                                    return {
                                        value: `${item.id}`,
                                        label: `${item.companyName}`
                                    };
                                });
                                this.qiyeRenOptions = this.listRen.filter(item => {
                                    return (
                                        item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
                                    );
                                });
                            } else {
                                this.statesRen = [];
                                this.listRen = [];
                            }
                        }
                    } else {
                        this.$message.error(d.message);
                    }
                });
            } else {
                this.qiyeRenOptions = [];
            }
        },
        deleteChannel(row) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                post(api.disassociate, {
                    companyId: row.id,
                    channelId: this.merTradeOrder.channelId
                }).then(
                    d => {
                        if (d.code === 0) {
                            this.$message.success(d.message);
                            this.queRenPost(this.currentPageJie);
                        } else {
                            this.$message.error(d.message);
                        }
                    },
                    err => {}
                );
            });
        },
        updateRow(row) {
            this.taskForm.id = row.id;
            post(api.channelInfo, { id: row.id }).then(
                res => {
                    if (res.code === 0) {
                        this.taskForm.businessLincenseImg = res.data.businessLincenseImg;
                        this.taskForm['channelName'] = res.data.channelName;
                        this.taskForm['creditCode'] = res.data.creditCode;
                        this.taskForm['contactName'] = res.data.contactName;
                        this.taskForm['channelType'] = res.data.channelType;
                        this.taskForm['channelShortName'] = res.data.channelShortName;
                        this.taskForm['contactMobile'] = res.data.contactMobile;
                        if (res.data.businessType == 1) {
                            this.typeID = true;
                            this.taskForm.businessTerm = '';
                            this.taskRules['businessTerm'] = [
                                { required: false, message: '请选择营业期限', trigger: 'blur' }
                            ];
                        } else {
                            this.typeID = false;
                            this.taskForm.businessTerm = res.data.businessTerm;
                        }
                        this.disableTitle = '修改集团';
                        this.dialogVisible4 = true;
                    } else {
                        this.$message.error(d.message);
                    }
                },
                err => {
                    //error callback
                }
            );
        },
        clickApply() {
            this.disableTitle = '新增集团';
            this.taskForm = {
                id: '',
                businessLincenseImg: '',
                channelType: '',
                channelName: '',
                channelShortName: '',
                contactName: '',
                contactMobile: '',
                creditCode: '',
                businessLincenseImg: '',
                businessType: '',
                businessTerm: ''
            };
            this.dialogVisible4 = true;
        },
        handleClose() {
            this.dialogVisible = false;
        },
        goBack() {
            this.releaseType = true;
            this.releaseForm = {
                createdAt: ['', ''],
                channelName: '',
                channelType: ''
            };
        },
        clickRow(row) {
            this.releaseType = false;
            this.leaseForm.channelId = row.id;
            this.merTradeOrder = {
                channelName: row.channelName,
                channelShortName: row.channelShortName,
                creditCode: row.creditCode,
                channelId: row.id
            };
            this.queRenPost();
        },
        search() {
            this.queryPageData();
        },
        reset() {
            this.releaseForm = {
                createdAt: ['', ''],
                channelName: '',
                channelType: ''
            };
            this.queryPageData(1);
        },
        handleSizeChange(val) {
            this.pageSzie = val;
            // // console.log(`每页 ${val} 条aaaaa`);
            this.queryPageData();
        },
        handleCurrentChange(size) {
            // // console.log(size);
            this.queryPageData(size);
        },

        handleSizeChangeJie(val) {
            this.pageSzieJie = val;
            this.queRenPost();
        },
        handleCurrentChangeJie(size) {
            this.queRenPost(size);
        }
    },
    components: {
        pagination: pagination
    }
};
</script>
