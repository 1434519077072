<template>
  <div class="agentManage">
    <div class="icon">
      <el-page-header @back="onBackTitle" content="产品配置">
      </el-page-header>
    </div>
    <!-- 按钮 -->
    <div class="add-pro">
      <el-button type="primary" v-if="agentGradeInfo == 1" @click="addPro"><i
          class="el-icon-plus"></i>&nbsp;新增产品</el-button>
    </div>
    <!-- 表格 -->
    <div>
      <el-table v-loading="tableLoading" :data="tableData" fit border style="width: 100%"
        :header-cell-style="{ background: '#eef1f6' }">
        <el-table-column prop="agentName" label="渠道商名称" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="productId" label="产品ID" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="isEnabled" width="130px" label="状态" align="center">
          <template slot-scope="{ row }">
            <div>
              <el-tag :type="(row.isEnabled) | waitFlagTag">
                {{ row.isEnabled | isEnabledInfo }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" min-width="100" align="center">
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link" style="cursor: pointer">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button @click="editData(scope.row)" size="mini" class="my-2 w_fluid" plain
                    v-if="agentGradeInfo == 1">
                    编辑
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button @click="viewData(scope.row)" size="mini" class="my-2 w_fluid" plain>
                    查看
                  </el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class="wraped">
        <pagination :total="dataTotal" :currentPage="listQuery.currentPage" @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"></pagination>
      </div>
      <!-- <div class="pagination-container">
        <el-pagination background="" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="listQuery.currentPage" :page-sizes="[40, 30, 20, 10]" :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="dataTotal"></el-pagination>
      </div> -->
    </div>

    <!-- 弹框 -->
    <el-dialog :title="voiceMes.title" :visible.sync="dialogVisible" width="880px" :close-on-click-modal='false'
      :before-close="handleClose">
      <el-form :model="tempProduct" :rules="rules" ref="formChannel" :label-position="labelPosition" label-width="200px"
        :disabled="isDialogView">
        <el-form-item label="已开通产品：" prop="productId">
          <el-select v-model="tempProduct.productId" @change="onProductChange" placeholder="请选择已开通产品" style="width: 90%"
            :disabled="voiceMes.title == '编辑产品'">
            <el-option v-for="item in productList" :key="item.productId" :label="item.productName"
              :value="item.productId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="签约完成时间：" prop="signTime">
          <el-date-picker value-format="yyyy-MM-dd" v-model="tempProduct.signTime" type="date" style="width: 90%"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="签约生效时间：" prop="openTime">
          <el-date-picker value-format="yyyy-MM-dd" v-model="tempProduct.openTime" type="date" style="width: 90%"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="tempProduct.remark" placeholder="请输入备注" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="上传合同：">
          <!-- <web-upload-2 v-model="tempProduct.businessLincenseImg"></web-upload-2> -->
          <el-upload class="upload-demo" action="#" :show-file-list="true" :file-list="paperAgreementListNew"
            :on-preview="handlePreviewPaperAgrementNew" :on-remove="handleRemovePaperAgrementNew"
            :on-success="handleSuccessPaperAgrementNew" :on-progress="handleChangePaperAgrementNew"
            :before-remove="beforeRemovePaperAgrementNew" :before-upload="beforeUploadPaperAgrementNew" multiple
            :auto-upload="true" :on-exceed="handleExceedPaperAgrementNew" :limit="10"
            :http-request="paperAgrementUploadNew">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip" style="color: black">
              格式：pdf,png,jpeg,jpg，不能超过10个文件，每个大小不超过5M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="交易金额（元）：" prop="rewardAmt">
          <el-input v-model.trim="tempProduct.rewardAmt" placeholder="请填写交易金额" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="奖金费率（%）：" prop="rewardAmtPer">
          <el-input v-model.trim="tempProduct.rewardAmtPer" placeholder="请填写奖金费率"
            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" style="width: 90%"></el-input>
          <!-- <div v-if="rewardFeeShow" style="color:#ff4949;font-size: 12px;">奖金费率不能大于100%</div> -->
        </el-form-item>
        <el-form-item v-for="(item, index) in tempProduct.profitFeeObjList" :label="'分润梯度（金额 元）：'" :key="item.key"
          :rules="{
            required: true,
            message: '请填写分润梯度',
            trigger: 'blur',
          }">
          <div>
            <el-input style="width: 140px" disabled v-model.trim="item.min"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" placeholder="请输入分润梯度"></el-input>
            <span> ~ </span>
            <el-input style="width: 140px" v-model.trim="item.max"
              :disabled="index === tempProduct.profitFeeObjList.length - 1" @input="onRateProfitChange(index)"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" placeholder="请输入分润梯度"></el-input>
            <span><span style="margin-right: 3px; color: red; margin-left: 30px">*</span>梯度费率（%）：</span>
            <el-input style="width: 100px" v-model.trim="item.profitFee"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"></el-input>
            <i class="el-icon-remove-outline" style="font-size: 20px; color: #f56c6c"
              v-if="isDialogView == false && tempProduct.profitFeeObjList.length > 1"
              @click.prevent="removeProfitFeeObjList(item, index)"></i>
            <i class="el-icon-circle-plus-outline" style="font-size: 20px; color: #67c23a"
              v-if="isDialogView == false && index === tempProduct.profitFeeObjList.length - 1"
              @click="addProfitFeeObjList(item, index)"></i>
          </div>
        </el-form-item>
        <!-- 应发金额 -->
        <div v-for="(itemBig, index) in tempProduct.productMainRelList" :key="index">
          <div style="border:1px solid #DCDFE6;margin-bottom: 20px;"></div>
          <el-form-item label="交付商名称：" :prop="'productMainRelList.' + index + '.mainId'" :rules="rules.mainId">
            <el-select filterable v-model="itemBig.mainId" clearable @change="onProductChangeMain(itemBig, index)"
              placeholder="请选择交付商名称" style="width: 70%"
              :disabled="editNum !== 1 ? voiceMes.title == '编辑产品' && index !== countInfo : false">
              <el-option v-for="item in mainList" :key="item.id" :label="item.aliasMainName + '（' + item.id + '）'"
                :value="item.id">
              </el-option>
            </el-select>
            <!-- v-if="tempProduct.productMainRelList.length > 1 && voiceMes.title !== '编辑产品'" -->
            <i class="el-icon-remove-outline" style="font-size: 20px; color: #f56c6c"
              v-if="voiceMes.title !== '编辑产品' ? tempProduct.productMainRelList.length > 1 : index == countInfo"
              @click.prevent="removeRateFeeObjListMain(itemBig, index)"></i>
            <i class="el-icon-circle-plus-outline" v-if="index === tempProduct.productMainRelList.length - 1"
              style="font-size: 20px; color: #67c23a" @click="addRateFeeObjListMain(itemBig)"></i>
          </el-form-item>
          <div v-if="itemBig.mainId">
            <el-form-item v-for="(item, index) in itemBig.rateFeeObjList" :label="'应发金额梯度（金额 元）：'" :key="item.key"
              :rules="{
                required: true,
                message: '请填写应发金额梯度',
                trigger: 'blur',
              }">
              <div>
                <el-input disabled style="width: 140px" v-model.trim="item.min"
                  onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" placeholder="请输入服务费梯度"></el-input>
                <span> ~ </span>
                <el-input style="width: 140px" :disabled="index === itemBig.rateFeeObjList.length - 1"
                  v-model.trim="item.max" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                  @input="onRateFeeChange(itemBig, index)" placeholder="请输入服务费梯度"></el-input>
                <span><span style="margin-right: 3px; color: red; margin-left: 30px">*</span>服务费费率（%）：</span>
                <el-input style="width: 100px" v-model.trim="item.rateFee"
                  onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"></el-input>
                <i class="el-icon-remove-outline" style="font-size: 20px; color: #f56c6c"
                  v-if="voiceMes.title !== '编辑产品' ? isDialogView == false && itemBig.rateFeeObjList.length > 1 : !item.echo"
                  @click.prevent="removeRateFeeObjList(itemBig, index)"></i>
                <i class="el-icon-circle-plus-outline" style="font-size: 20px; color: #67c23a"
                  v-if="isDialogView == false && index === itemBig.rateFeeObjList.length - 1"
                  @click="addRateFeeObjList(item, itemBig)"></i>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createProduct" v-if="!isDialogView && voiceMes.title == '新增产品'"
          :loading="loading">提 交</el-button>
        <el-button type="primary" @click="updateAgentProduct" v-if="!isDialogView && voiceMes.title == '编辑产品'"
          :loading="loading">更 新</el-button>
      </span>
    </el-dialog>
    <!-- 最新查看详情 -->
    <el-dialog title="查看详情" :visible.sync="dialogVisibleNew" :close-on-click-modal="false" width="600px"
      :before-close="handleCloseNew">
      <div class="descr">
        <div class="flexStyle">
          <div>
            <span class="fontStyle">已开通产品：</span><span class="content">{{ tempProductNew.productName ?
              tempProductNew.productName : '--'
              }}</span>
          </div>
          <div>
            <span class="fontStyle">签约完成时间：</span><span class="content">{{ tempProductNew.signTime ?
              tempProductNew.signTime : '--'
              }}</span>
          </div>
        </div>
        <div class="flexStyle">
          <div>
            <span class="fontStyle">签约生效时间：</span><span class="content">{{ tempProductNew.openTime ?
              tempProductNew.openTime : '--'
              }}</span>
          </div>
          <div>
            <span class="fontStyle">备注：</span><span class="content">{{ tempProductNew.remark ?
              tempProductNew.remark : '--'
              }}</span>
          </div>
        </div>
        <div class="flexStyle">
          <div style="display: flex;">
            <span class="fontStyle">上传合同：</span>
            <div class="content">
              <div style="cursor: pointer;color:#1B6EF3;line-height: 30px;"
                v-for="(item, index) in tempProductNew.businessLincenseImgList" :key="index"
                @click="imgListInfo(item.substring(item.lastIndexOf('/') + 1), item)">{{
                  item.substring(item.lastIndexOf('/') + 1) }}</div>
            </div>
          </div>
        </div>
        <div class="flexStyle">
          <div>
            <span class="fontStyle">交易金额（元）：</span><span class="content">{{ tempProductNew.rewardAmt ?
              tempProductNew.rewardAmt : '0'
              }}</span>
          </div>
          <div>
            <span class="fontStyle">奖金费率（%）：</span><span class="content">{{ tempProductNew.rewardAmtPer ?
              tempProductNew.rewardAmtPer : '0'
              }}</span>
          </div>
        </div>
        <div class="flexStyle" v-for="(item, index1) in tempProductNew.profitFeeObjList" :key="index1">
          <div>
            <span class="fontStyle">分润梯度（金额 元）：</span><span class="content">
              {{ item.min }}~{{ item.max }}
            </span>
          </div>
          <div>
            <span class="fontStyle">梯度费率（%）：</span>
            <span class="content">{{ item.profitFee }}</span>
          </div>
        </div>
        <div v-for="(itemBig, index2) in tempProductNew.productMainRelList" :key="index2 + 'b'">
          <div style="border:1px solid #DCDFE6"></div>
          <div>
            <span class="fontStyle">交付商名称：</span><span class="content">
                {{ itemBig.aliasMainName }}
              </span>
          </div>
          <div class="flexStyle" v-for="(item, index3) in itemBig.rateFeeObjList" :key="index3 + 'a'">
            <div>
              <span class="fontStyle">应发金额梯度（金额 元）：</span><span class="content">
                {{ item.min }}~{{ item.max }}
              </span>
            </div>
            <div>
              <span class="fontStyle">服务费费率（%）：</span><span class="content">
                {{ item.rateFee }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleNew = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 查看上传营业执照图片 -->
    <el-dialog :visible.sync="dialogVisibleImg" append-to-body :close-on-click-modal="false">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import pagination from '../../components/pagination.vue';
import moment from 'moment';
import { f2y, y2f } from '../../util/util.js';
import WebUpload2 from '@/components/webUpload2.vue';
import api from '@/api/api.js';
import { get, post } from '@/api/index.js';
import seePhoto from '@/components/seePhoto.vue';
export default {
  components: {
    WebUpload2,
    pagination,
    seePhoto
  },
  filters: {
    waitFlagTag(code) {
      const statusMap = {
        1: 'success',
        0: 'danger'
      };
      return statusMap[code] || 'info';
    }
  },
  data() {
    return {
      invoiceTypeMap: {
        '0': '增值税普通发票',
        '1': '增值税专用发票'
      },
      editNum: '',
      // 上传合同
      // 数组
      arrayList: [],//传给后端
      dialogVisibleImg: false,
      dialogImageUrl: '',
      // 批量上传营业执照
      paperAgreementListNew: [],
      // 已上传文件名
      uploadAgreementsNamesNew: [],
      // 可上传文件列表
      availableAgreementsNew: [],
      countInfo: '',
      countInfoLay: '',
      // 最新查看详情
      tempProductNew: '',
      columnNumber: 3,
      dialogVisibleNew: false,
      tableLoading: false,
      gradsFlag: false,
      rateFlag: false,
      tableData: [],
      listQuery: {
        agentId: null,
        currentPage: 1,
        pageSize: 10
      },
      dataTotal: 0,
      loading: false,
      labelPosition: 'right',
      voiceMes: {
        title: '新增产品'
      },
      mainProList: [],
      mainList: [],
      dynamicValidateForm: {
        domains: [
          {
            value: ''
          }
        ],
        email: ''
      },
      poiintShow: false,
      detailDis: false,
      tempProduct: {
        agentId: '',
        productId: '',
        productName: '测试产品',
        rateFee: '', //服务费税率
        signTime: '',
        openTime: '',
        remark: '',
        productMainRelList: [
          {
            mainId: '',
            mainName: '',
            rateFeeObjList: [{ min: '0', max: 2147483647, rateFee: 0 }],
          }
        ],
        profitFeeObjList: [{ min: '0', max: '2147483647', profitFee: '' }],
        businessLincenseImgList: '',
        rewardAmt: '',
        rewardAmtPer: '',
      },
      curRateFee: '',
      dialogVisible: false,
      productList: [],
      productId: {
        agentId: null
      },

      rules: {
        addedRate: [
          { required: true, message: '服务费费率为必填项', trigger: 'blur' },
          {
            required: true,
            pattern: /^((\d|[123456789]\d)(\.\d+)?|100)$/,
            message: '服务费费率范围是0~100，请重新输入',
            trigger: 'blur'
          }
        ],
        productId: [{ required: true, message: '请选择交付商产品', trigger: 'blur' }],
        mainId: [{ required: true, message: '请选择交付商名称', trigger: 'change' }],
        signTime: [{ required: true, message: '签约时间为必填项', trigger: 'blur' }],
        openTime: [{ required: true, message: '签约生效时间为必填项', trigger: 'blur' }],
        remark: [{ required: true, message: '备注为必填项', trigger: 'blur' }],
        rateFee: [{ required: true, message: '服务费税率为必填项', trigger: 'blur' }],
        rewardAmt: [
          { required: true, message: '交易金额为必填项', trigger: 'blur' },
          {
            required: true,
            pattern: /^[0-9]+(.?[0-9]{1,2})?$/,
            message: '请输入合法数字'
          }
        ],
        rewardAmtPer: [
          { required: true, message: '奖金费率为必填项', trigger: 'blur' },
          {
            required: true,
            pattern: /^((\d|[123456789]\d)(\.\d+)?|100)$/,
            message: '奖金费率范围是0~100，请重新输入',
            trigger: 'blur'
          }
        ]
      },
      isDialogView: false,
      rewardFeeShow: false,
      power: "0",
      // 判断几级（显示隐藏按钮）
      agentGradeInfo: ''
    };
  },
  mounted() {
    this.agentGradeInfo = this.$route.query.agentGradeInfo;
    this.agentId = this.$route.query.agentId;
    this.tempProduct.agentId = this.agentId;
    this.serviceIdNew = this.$route.query.serviceIdNew;
    this.productPage();
    this.driverSelectBoxNew();//交付商
  },
  methods: {
    imgListInfo(item, url) {
      if (['jpg', 'png', 'jpeg'].includes(item.split('.').slice(-1)[0])) {
        this.dialogImageUrl = url;
        this.dialogVisibleImg = true;
      }
      if (['pdf'].includes(item.split('.').slice(-1)[0])) {
        window.open(url)
      }
    },
    // 上传营业执照
    handleRemovePaperAgrementNew(file, fileList) {
      const index = fileList.indexOf(file);
      if (index > -1) {
        fileList.splice(index, 1);
      }
      // 删除重新赋值
      if (fileList) {
        this.arrayList = [];
        for (var i = 0; i < fileList.length; i++) {
          this.arrayList.push(fileList[i].url)
        }
        this.tempProduct.businessLincenseImgList = this.arrayList
      }
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreviewPaperAgrementNew(file) {
      this.dialogImageUrl = '';
      if (file.raw) {
        if (['jpg', 'png', 'jpeg'].includes(file.name.split('.').slice(-1)[0])) {
          this.dialogImageUrl = this.ossConfig.url + `/paper_agreement/${file.raw.lastModified}/${file.name}`;
          this.dialogVisibleImg = true;
        }
        if (['pdf'].includes(file.name.split('.').slice(-1)[0])) {
          window.open(this.ossConfig.url + `/paper_agreement/${file.raw.lastModified}/${file.name}`)
        }
      } else {
        if (['jpg', 'png', 'jpeg'].includes(file.name.split('.').slice(-1)[0])) {
          this.dialogImageUrl = file.url;
          this.dialogVisibleImg = true;
        }
        if (['pdf'].includes(file.name.split('.').slice(-1)[0])) {
          window.open(file.url)
        }
      }
    },
    handleExceedPaperAgrementNew(files, paperAgreementList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + paperAgreementList.length
        } 个文件`
      );
    },
    beforeRemovePaperAgrementNew(file, paperAgreementList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeUploadPaperAgrementNew(file) { },
    handleSuccessPaperAgrementNew(response, file, fileList) { },
    handleChangePaperAgrementNew(event, file, fileList) { },
    paperAgrementUploadNew(param) {
      this.post2OssNew(param.file);
    },
    paperAgreementValidatorNew(file) {
      const fileName = file.name || '';
      const fileValue = { status: 1, fileName: fileName };

      this.uploadAgreementsNamesNew.push(fileName);

      // 文件上传的类型
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['pdf', 'png', 'jpeg', 'jpg'];
      const isSuffix = whiteList.indexOf(fileSuffix.toLowerCase()) === -1;
      if (isSuffix) {
        this.$message.error('上传文件格式必须为pdf, png, jpeg, jpg');
        fileValue.status = 0;
        this.paperAgreementListNew = [];
        return fileValue;
      }

      // 上传数量验证
      if (this.availableAgreementsNew.length > 10) {
        this.$message.error('上传数量超过10个，请下一批上传');
        fileValue.status = 0;
        this.paperAgreementListNew = [];
        return fileValue;
      }

      // 验证大小
      if (file.size / 1024 / 1024 > 5) {
        this.$message.error('大小超过5MB');
        fileValue.status = 0;
        this.paperAgreementListNew = [];
        return fileValue;
      }

      // 验证成功
      this.availableAgreementsNew.push(fileValue);
      return fileValue;
    },
    // 覆盖默认的上传行为，可以自定义上传的实现
    paperAgrementUploadNew(param) {
      this.post2OssNew(param.file);
    },
    getOssConfig() {
      post(api.ossUploadConfig).then((res) => {
        if (res.code == 0) {
          this.ossConfig = res.data;
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 确认上传
    loadXML(xmlString) {
      var xmlDoc = null;
      if (!window.DOMParser && window.ActiveXObject) {
        var xmlDomVersions = [
          'MSXML.2.DOMDocument.6.0',
          'MSXML.2.DOMDocument.3.0',
          'Microsoft.XMLDOM'
        ];
        for (var i = 0; i < xmlDomVersions.length; i++) {
          try {
            xmlDoc = new ActiveXObject(xmlDomVersions[i]);
            xmlDoc.async = false;
            xmlDoc.loadXML(xmlString);
            break;
          } catch (e) {
          }
        }
      } else if (
        window.DOMParser &&
        document.implementation &&
        document.implementation.createDocument
      ) {
        try {
          var domParser = new DOMParser();
          xmlDoc = domParser.parseFromString(xmlString, 'text/xml');
        } catch (e) {
        }
      } else {
        return null;
      }
      return xmlDoc;
    },
    post2OssNew(file) {
      const fileValue = this.paperAgreementValidatorNew(file);
      if (fileValue.status == 0) {
        return;
      }
      var fileName = file.name.replace(/[#?&=+%^!*'"：:]/g, '_');
      const formData = new FormData();
      formData.append('key', `paper_agreement/${file.lastModified}/${fileName}`);
      formData.append('name', fileName);
      formData.append('Bucket', this.ossConfig.bucket);
      formData.append('policy', this.ossConfig.policy);
      formData.append('OSSAccessKeyId', this.ossConfig.ossAccessKeyId);
      formData.append('success_action_status', this.ossConfig.successActionStatus);
      formData.append('Signature', this.ossConfig.signature);
      formData.append('file', file);
      this.axios({
        url: this.ossConfig.url,
        method: 'POST',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(res => {
          try {
            const xml = this.loadXML(res.data);
            const url =
              xml.documentElement.getElementsByTagName('Location')[0].textContent;
            this.arrayList.push(url)
            this.tempProduct.businessLincenseImgList = this.arrayList
            // 用于回显数据
            this.paperAgreementListNew.push(
              {
                url: url,
                name: url.splice('/').pop(),
              }
            );
          } catch (e) { }
        })
        .catch(err => {
        });
    },
    productPage(size) {
      if (true) {
        const loading = this.$loading({
          lock: true,
          spinner: 'loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        post(api.queryAgentProduct, {
          currentPage: size || 1,
          pageSize: this.listQuery.pageSize,
          agentId: this.agentId,
          serviceId: this.serviceIdNew
        }).then(
          d => {
            loading.close();
            if (d.code === 0) {
              this.tableData = d.data.rows;
              this.dataTotal = d.data.count;
            } else {
            }
          },
          err => { }
        );
      } else {
        //字符
        this.totalProduct = 0;
        this.currentPageProduct = 1;
        this.pageSzie = 10;
        this.productList = [];
      }
    },
    // 最新查看详情
    handleCloseNew() {
      this.dialogVisibleNew = false;
    },
    onRateFeeChange(itemBig, index) {
      // 当前更新的索引
      const curMax = itemBig.rateFeeObjList[index].max;
      if (index < itemBig.rateFeeObjList.length - 1) {
        itemBig.rateFeeObjList[index + 1].min = Number(curMax) + 0.01;
      }
    },
    onRateProfitChange(index) {
      const curMax = this.tempProduct.profitFeeObjList[index].max;
      if (index < this.tempProduct.profitFeeObjList.length - 1) {
        this.tempProduct.profitFeeObjList[index + 1].min = Number(curMax) + 0.01;
      }
    },
    onBackTitle() {
      this.$router.push({
        path: '/agentManage'
      });
    },
    driverSelectBoxNew() {
      get(api.selectBoxNew).then((res) => {
        if (res.code == 0) {
          this.mainList = res.data;
        } else {
          this.$message.error(res.message)
        }
      })
    },
    onProductChangeMain(itemBig, index) {
      if (itemBig.mainId) {
        var obj = {};
        obj = this.mainList.find((item) => {
          return itemBig.mainId == item.id
        })
        this.tempProduct.productMainRelList[index].mainName = obj.mainName
      } else {
        this.tempProduct.productMainRelList = [
          {
            mainId: '',
            mainName: '',
            rateFeeObjList: [{ min: '0', max: '2147483647', rateFee: 0 }],
          }
        ];
      }
    },

    onRewardFeeInput(data) {
      if (data && data > 100) {
        this.rewardFeeShow = true;
      } else {
        this.rewardFeeShow = false;
      }
    },
    onRateFeeInput(data) {
      if (data && data < this.curRateFee) {
        this.poiintShow = true;
      } else {
        this.poiintShow = false;
      }
    },
    // 分润
    addProfitFeeObjList(item) {
      if (Number(item.max) <= 0) {
        this.$message({
          message: '请先输入分润梯度',
          type: 'warning'
        });
        return;
      } else {
        let nextMinValue = '';
        if (item.max && item.max !== '2147483647') {
          nextMinValue = Number(item.max) + 0.01;
        } else {
          this.tempProduct.profitFeeObjList[
            this.tempProduct.profitFeeObjList.length - 1
          ].max = '';
        }
        this.tempProduct.profitFeeObjList.push({
          max: '2147483647',
          min: nextMinValue,
          rateFee: 0
        });
      }
    },
    removeProfitFeeObjList(item, index) {
      // 操作的是最后一项
      if (index === this.tempProduct.profitFeeObjList.length - 1) {
        this.tempProduct.profitFeeObjList.splice(index, 1);
        this.tempProduct.profitFeeObjList[
          this.tempProduct.profitFeeObjList.length - 1
        ].max = '2147483647';
      } else {
        this.tempProduct.profitFeeObjList.splice(index, 1);
      }
    },
    // 应发
    addRateFeeObjList(item, itemBig) {
      if (Number(item.max) <= 0) {
        this.$message({
          message: '请先输入服务费梯度',
          type: 'warning'
        });
        return;
      } else {
        let nextMinValue = '';
        if (item.max && item.max !== '2147483647') {
          nextMinValue = Number(item.max) + 0.01;
        } else {
          // TODO  清空掉当前项目
          itemBig.rateFeeObjList[
            itemBig.rateFeeObjList.length - 1
          ].max = '';
        }
        itemBig.rateFeeObjList.push({
          max: 2147483647,
          min: nextMinValue,
          rateFee: 0
        });
      }
    },
    removeRateFeeObjList(itemBig, index) {
      // 操作的是最后一项
      if (index === itemBig.rateFeeObjList.length - 1) {
        itemBig.rateFeeObjList.splice(index, 1);
        itemBig.rateFeeObjList[itemBig.rateFeeObjList.length - 1].max =
          2147483647;
      } else {
        itemBig.rateFeeObjList.splice(index, 1);
      }
    },
    // 交付商追加删除
    addRateFeeObjListMain(item) {
      this.tempProduct.productMainRelList.push({
        mainId: '',
        mainName: '',
        rateFeeObjList: [
          { min: '0', max: 2147483647, rateFee: 0 }
        ],
      })
      this.$forceUpdate();
    },
    removeRateFeeObjListMain(item, index) {
      // 操作的是最后一项
      if (index === this.tempProduct.productMainRelList.length - 1) {
        this.tempProduct.productMainRelList.splice(index, 1);
      } else {
        this.tempProduct.productMainRelList.splice(index, 1);
      }
      this.$forceUpdate();
    },

    onProductChange() {
      this.tempProduct.productName = this.productList.filter(
        item => item.productId === this.tempProduct.productId
      )[0].productName;

      // 服务费费率
      this.tempProduct.rateFee = this.productList.filter(
        item => item.productId === this.tempProduct.productId
      )[0].rateFee;
      this.curRateFee = this.tempProduct.rateFee;
    },
    // 新增弹框
    addPro() {
      // 清空校验
      if (typeof this.$refs.formChannel !== 'undefined') {
        this.$refs.formChannel.resetFields();
      }
      this.voiceMes.title = '新增产品';
      //批量上传合同
      this.getOssConfig();
      this.tempProduct.businessLincenseImgList = '';
      this.arrayList = [];
      this.paperAgreementListNew = [];
      this.isDialogView = false;
      this.dialogVisible = true;
      this.queryProduct();
      this.onClearValur();
    },
    onClearValur() {
      this.tempProduct.productId = '';
      this.tempProduct.productName = '';
      this.tempProduct.rateFee = '';
      this.tempProduct.signTime = moment().format('YYYY-MM-DD');
      this.tempProduct.openTime = '';
      this.tempProduct.remark = '';
      this.tempProduct.profitFeeObjList = [{ min: '0', max: 2147483647, profitFee: 0 }];
      this.tempProduct.productMainRelList = [
        {
          mainId: '',
          mainName: '',
          rateFeeObjList: [{ min: '0', max: 2147483647, rateFee: 0 }]
        }
      ]
      this.tempProduct.businessLincenseImgList = '';
      this.tempProduct.rewardAmt = 0;
      this.tempProduct.rewardAmtPer = 0;
    },
    // 已开通产品
    queryProduct() {
      post(api.getAgentPidProduct, {
        agentId: this.agentId,
        serviceId: this.serviceIdNew
      }).then(d => {
        if (d.code == 0) {
          this.productList = d.data;
        } else {
          this.$message.error(d.message);
        }
      });
    },

    // 新增产品列表
    createProduct() {
      this.$refs['formChannel'].validate(valid => {
        if (valid) {
          const payload = JSON.parse(JSON.stringify(this.tempProduct));
          this.gradsFlag = false; // 循环前先把状态设置为false
          this.rateFlag = false;
          payload.profitFeeObjList.map(item => {
            item.min = y2f(item.min);
            item.max = y2f(item.max);
            item.profitFee = item.profitFee * 1;
            if (item.profitFee > 100) {
              // 只要有一个不符合条件  就有设置为true  之后循环不要再去更改
              this.gradsFlag = true;
            } else {
              this.gradsFlag = false;
            }
          });

          // 实发金额元转分
          payload.productMainRelList.map((item) => {
            item.rateFeeObjList.map((item1) => {
              item1.min = y2f(item1.min);
              item1.max = y2f(item1.max);
              item1.rateFee = item1.rateFee * 1;
              if (item1.rateFee > 100) {
                this.rateFlag = true;
              } else {
                this.rateFlag = false;
              }
            })
          })
          if (this.gradsFlag) {
            this.$message.warning('梯度费率不能大于100%')
            return false;
          }
          if (this.rateFlag) {
            this.$message.warning('服务费费率不能大于100%')
            return false;
          }
          payload.rewardAmt = y2f(payload.rewardAmt);
          if (payload.rateFee < this.curRateFee) {
            return false;
          }
          this.loading = true;
          post(api.createProduct, payload).then(d => {
            if (d.code == 0) {
              this.$message.success(d.message);
              this.productPage();
              this.releaseForm = [];
              this.dialogVisible = false;
              this.loading = false;
            } else {
              this.$message.error(d.message);
              this.loading = false;
            }
          });
        }
      });
    },
    // 修改渠道商产品
    updateAgentProduct() {
      this.$refs['formChannel'].validate(valid => {
        if (valid) {
          // const tempData = Object.assign({}, this.tempProduct);//浅拷贝不能拷贝引用数据类型
          const tempData = JSON.parse(JSON.stringify(this.tempProduct));
          tempData.profitFeeObjList.map(item => {
            item.min = y2f(item.min);
            item.max = y2f(item.max);
            if (item.profitFee > 100) {
              // 只要有一个不符合条件  就有设置为true  之后循环不要再去更改
              this.gradsFlag = true;
            } else {
              this.gradsFlag = false;
            }
          });
          // 实发金额元转分
          tempData.productMainRelList.map((item) => {
            item.rateFeeObjList.map((item1) => {
              item1.min = y2f(item1.min);
              item1.max = y2f(item1.max);
              item1.rateFee = item1.rateFee * 1;
              if (item1.rateFee > 100) {
                this.rateFlag = true;
              } else {
                this.rateFlag = false;
              }
            })
          })
          if (this.gradsFlag) {
            this.$message.warning('梯度费率不能大于100%')
            return false;
          }
          if (this.rateFlag) {
            this.$message.warning('服务费费率不能大于100%')
            return false;
          }
          tempData.rewardAmt = y2f(tempData.rewardAmt);
          post(api.updateProduct, tempData).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.message)
              this.dialogVisible = false;
              this.queryProduct();
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    // 编辑产品
    editData(row) {
      this.voiceMes.title = '编辑产品';
      // 批量上传合同
      this.getOssConfig();
      this.tempProduct.businessLincenseImgList = '';
      this.arrayList = [];
      this.paperAgreementListNew = [];
      this.isDialogView = false;
      this.queryProduct();
      post(api.queryAgentProductInfo, { id: row.id }).then((res) => {
        if (res.code == 0) {
          this.tempProduct = res.data;
          var dataInfo = res.data.businessLincenseImgList.length > 0 ? res.data.businessLincenseImgList : [];
          this.arrayList = dataInfo;
          for (var i = 0; i < dataInfo.length; i++) {
            this.paperAgreementListNew.push(
              {
                url: dataInfo[i],
                name: dataInfo[i].substring(dataInfo[i].lastIndexOf('/') + 1),
              }
            )
          }
          this.tempProduct.productId = this.tempProduct.productId + '';
          this.tempProduct.rewardAmt = f2y(this.tempProduct.rewardAmt);
          if (typeof this.tempProduct.profitFeeObjList === 'object') {
            this.tempProduct.profitFeeObjList.map(item => {
              item.min = f2y(item.min);
              item.max = f2y(item.max);
              return item;
            });
          }
          // 应发金额
          this.countInfo = this.tempProduct.productMainRelList.length;//用于编辑判断(交付商名称层)
          if (typeof this.tempProduct.productMainRelList === 'object') {
            this.tempProduct.productMainRelList.map((itemBig) => {
              this.countInfoLay = itemBig.rateFeeObjList;//用于编辑判断(梯度里层)
              itemBig.rateFeeObjList.map((item) => {
                if (item.rateFee == 0) {
                  this.editNum = 1
                } else {
                  this.editNum = 2
                }
                item.min = f2y(item.min);
                item.max = f2y(item.max);
                item.echo = true;
                return item;
              })
            })
          }
          this.dialogVisible = true;
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 查看产品
    viewData(row) {
      post(api.queryAgentProductInfo, { id: row.id }).then((res) => {
        if (res.code == 0) {
          this.tempProductNew = res.data;
          this.tempProductNew.businessLincenseImgList = res.data.businessLincenseImgList.length > 0 ? res.data.businessLincenseImgList : [];
          this.tempProductNew.rewardAmt = f2y(this.tempProductNew.rewardAmt);
          if (typeof this.tempProductNew.profitFeeObjList === 'object') {
            this.tempProductNew.profitFeeObjList.map(item => {
              item.min = f2y(item.min);
              item.max = f2y(item.max);
              return item;
            });
          }
          // 应发金额
          if (typeof this.tempProductNew.productMainRelList === 'object') {
            this.tempProductNew.productMainRelList.map((itemBig) => {
              itemBig.rateFeeObjList.map((item) => {
                item.min = f2y(item.min);
                item.max = f2y(item.max);
                return item;
              })
            })
          }
          if (this.tempProductNew) {
            this.dialogVisibleNew = true;
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleClose() {
      if (this.isDialogView) {
        this.dialogVisible = false;
      } else {
        this.$confirm('确定要关闭窗口吗？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.dialogVisible = false;
          })
          .catch(err => {
          });
      }
    },
    // 分页--点击某页码
    handleCurrentChange(val) {
      this.listQuery.currentPage = val;
      this.productPage();
    },
    // 分页--更改每页显示条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.productPage();
    }
  }
};
</script>
<style scoped lang="less">
.agentManage {
  padding: 20px;

  ::-webkit-scrollbar {
    display: none;
  }

  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  text-align: left;
}

.descr {
  .flexStyle {
    display: flex;
    justify-content: space-between;
  }

  .fontStyle {
    color: #7A7A7A;
    font-weight: bold;
    line-height: 40px;
  }

  .content {
    color: #000;
  }
}

.add-pro {
  margin: 20px 0;
}

.icon {
  margin-bottom: 20px;
}
</style>
<style>
/* 最新查看详情 */
.labelClassName {
  font-weight: 500;

  color: #777777;
}

.contentClassName {
  color: #333;
  font-weight: 500;
}
</style>
