import Vue from 'vue';
import VueCookie from 'vue-cookie';
import {
    LOGOUT,
    CHECK_AUTHENTICATION,
    LOGIN,
    GET_AUTHENTICATION,
} from '../../mutation-types';

export default {
    [CHECK_AUTHENTICATION](state) {
        // state.authenticated = !!Vue.$http.defaults.headers.common['X-Auth-Token'];
    },

    // [EXTEND_EXPIRES]() {
    //     state.authenticated = !!Vue.cookie.get('userid');
    // },

    [LOGIN](state, user) {
        state.authenticated = true;
        // Vue.$http.defaults.headers.common['X-Auth-Token'] = user.data['X-Auth-Token'];
        VueCookie.set('account', JSON.stringify({
            userId: user.data.userInfo.userId,
            realName: user.data.userInfo.realName,
            username: user.data.userInfo.username,
            busiCompanyName: user.data.userInfo.busiCompanyName,
        }));
        VueCookie.set('token', user.data['X-Auth-Token']);
    },

    [GET_AUTHENTICATION](state, authentications) {
        state.authentications = authentications;
        VueCookie.set('resource', authentications.join(','));
    },

    [LOGOUT](state) {
        state.authenticated = false;
        state.authentications = [];
        VueCookie.delete('resource');
        VueCookie.delete('account');
        VueCookie.delete('esignmerchantsid');
        VueCookie.delete('roleResources');
        VueCookie.delete('permissions');
        // Vue.$http.defaults.headers.common['X-Auth-Token'] = '';
    },
};
