import {
    GET_MENU,
    CLEAR_MENU,
} from '../../mutation-types';

export default {
    [GET_MENU](state, menus) {
        state.menus = menus;
        state.requested = true;
    },
    [CLEAR_MENU](state) {
        state.menus = [];
        state.requested = false;
    },
};
