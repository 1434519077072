<template>
  <div class="navigationBarLeft">
    <!-- <div class="logo"></div> -->
    <el-menu ref="menu"
             :default-active="$route.path"
             :router="true"
             class="el-menu-vertical-demo"
             background-color="#001529"
             text-color="#fff"
             active-text-color="#28CFCA"
             :unique-opened="true"
             @select="handleSelectLeft">
      <el-menu-item v-show="isshow"
                    style="text-align: left"
                    @click="index($event)">
        <i class="el-icon-s-home"
           style="color:#909399"></i>
        <span id="index"
              ref="index"
              style="font-size: 14px">首页</span>
      </el-menu-item>
      <el-submenu v-for="(item, key) in menuList"
                  :key="key"
                  :index="String(key)">
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{ item.name }}</span>
        </template>
        <el-menu-item v-for="(list, index) in item.childrens"
                      :key="index"
                      :index="list.path"
                      :indexpath="list.path">{{ list.name }}
          <!-- <router-link :to="list.path" style="display: inline-block;width:100%;height:100%">{{list.name}}</router-link> -->
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  inject: ["reload"], //注入reload方法
  // [{
  // 	"childrens": [{
  // 		"childrens": [{
  // 			"childrens": [],
  // 			"icon": "icon",
  // 			"id": "101100",
  // 			"level": 3,
  // 			"menu": "1",
  // 			"name": "渠道商管理列表",
  // 			"parentId": "101000",
  // 			"path": "/",
  // 			"resourceCode": "agent_list",
  // 			"status": "1"
  // 		}],
  // 		"icon": "icon",
  // 		"id": "101000",
  // 		"level": 2,
  // 		"menu": "1",
  // 		"name": "渠道商管理",
  // 		"parentId": "100000",
  // 		"path": "/agentManage",
  // 		"resourceCode": "agent_management",
  // 		"status": "1"
  // 	}],
  // 	"icon": "el-icon-s-management",
  // 	"id": "100000",
  // 	"level": 1,
  // 	"menu": "1",
  // 	"name": "渠道商中心",
  // 	"parentId": "0",
  // 	"path": "",
  // 	"resourceCode": "el-icon-s-home",
  // 	"status": "1"
  // }]

  name: "navigationBarLeft",
  data() {
    return {
      // 首页判断
      isshow: '',
      menuLeftArr: [
        {
          name: "渠道商中心",
          icon: "el-icon-s-platform",
          team: [{ name: "渠道商管理", path: "/agentManage" }],
        },
        {
          name: "商户中心",
          icon: "el-icon-s-platform",
          team: [
            { name: "集团信息管理", path: "/information" },
            { name: "商户信息管理", path: "/merchant" },
          ],
        },
        {
          name: "任务中心",
          icon: "el-icon-s-platform",
          team: [
            { name: "我发布的需求", path: "/" },
            { name: "投递人员信息", path: "/delivery" },
          ],
        },
        {
          name: "合同中心",
          icon: "el-icon-s-shop",
          team: [{ name: "签约管理", path: "/signing" }],
        },
        {
          name: "交易中心",
          icon: "el-icon-s-management",
          team: [
            { name: "工单管理", path: "/repairOrder" },
            { name: "发薪管理", path: "/salary" },
            { name: "交易管理", path: "/trading" },
            { name: "资金账户", path: "/capitalAccount" },
            { name: "资金流水", path: "/capitalFlow" },
          ],
        },
      ],
      menuList: [],
    };
  },
  watch: {
    $route() {
      let i = this.$route.path;
      // console.log(i,'路由')
      // setTimeout(() => {
      //   //路由跳转
      //   this.$refs.menu.activeIndex = i;
      // }, 100);
    },
  },
  props: {
    // menuLeftArr:Array,
    activeIndex: String,
  },
  created() {
    // console.log(this.menuList, '44444444');
  },
  mounted() {
    this.routerInfo()
  },
  methods: {
    routerInfo() {
      // window.addEventListener("routeStauts", handler);
      if (localStorage.getItem('routerStatus') == 'true') {
        this.isshow = false
        this.menuList = [{
          childrens: [{
            icon: "icon",
            id: "101000",
            level: 2,
            menu: "1",
            name: "渠道商信息",
            parentId: "100000",
            path: "/agentInfomat",
            resourceCode: "",
            status: "1"
          },
          {
            icon: "icon",
            id: "101000",
            level: 2,
            menu: "1",
            name: "服务商信息",
            parentId: "100000",
            path: "/serviceInfomat",
            resourceCode: "",
            status: "2"
          },
          {
            icon: "icon",
            id: "101000",
            level: 2,
            menu: "1",
            name: "交付商信息",
            parentId: "100000",
            path: "/deliveryInfomat",
            resourceCode: "",
            status: "3"
          },
          {
            icon: "icon",
            id: "101000",
            level: 2,
            menu: "1",
            name: "基本信息",
            parentId: "100000",
            path: "/agentIndex",
            resourceCode: "",
            status: "3"
          }],
          icon: "el-icon-s-management",
          id: "",
          level: 1,
          menu: "1",
          name: "渠道商中心",
          parentId: "0",
          path: "/agentIndex",
          resourceCode: "",
          status: "1"
        }]
        localStorage.removeItem('routeStauts')
      } else {
        this.menuList = JSON.parse(localStorage.getItem("store")).menu.menus;
        // console.log(this.menuList,6666)
        this.isshow = true
        localStorage.removeItem('routeStauts')
      }
      // function handler() {
      //   location.reload();
      // }

      // console.log(localStorage.getItem('routeStauts'), 969369)
    },
    index(event) {
      this.$router.push({ path: "/" });
    },
    // // 路由跳转
    // routerInfo(){
    //   console.log(this.$route.path,888)
    // },
    handleSelectLeft(key, keyPath) {
      // console.log(key, keyPath)
      // localStorage.setItem('activeIndex',key)
      // let arr = key.split("-")
      // let i = arr[0]
      // let k = arr[1]
      // console.log(this.menuLeftArr[i].childrens[k].path)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only --> 
<style lang="less" scoped>
.navigationBarLeft {
  float: left;
  width: 182px;
  height: calc(100% - 58px);
  background: #001529;
  overflow-y: scroll;
  white-space: nowrap;
  a {
    text-decoration: none;
    color: #333333;
  }
  .logo {
    width: 100%;
    height: 153px;
    background-color: #d8d8d8;
  }
}
.navigationBarLeft::-webkit-scrollbar {
  width: 0 !important;
}
</style>

<style>
.navigationBarLeft .el-submenu .el-menu-item {
  box-sizing: border-box;
  min-width: 100%;
  font-size: 12px;
}
.navigationBarLeft .el-menu {
  border-right: none;
}
.navigationBarLeft .el-menu-item.is-active a {
  color: #409eff;
}
.navigationBarLeft .el-submenu__title {
  text-align: left;
}
</style>