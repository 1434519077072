/**
 * 小程序配置文件
 */

// 此处主机域名是腾讯云解决方案分配的域名
// 小程序后台服务解决方案：https://www.qcloud.com/solution/la

/* 本地配置 */
// var host = 'https://xggpc.gongsibao.com'

var host = ''
// var host = 'https://mch.rongketech.com'
// if (process.env.NODE_ENV === "development") {
//   console.log("开发环境");
//   host = 'http://test.bpo-merchant.bpo'
//   // host = 'https://mch.rongketech.com'
// }else {
//   console.log("生产环境")
//   host = 'https://mch.rongketech.com'
// }

let env = process.env.NODE_ENV
let flag = process.env.VUE_APP_FLAG
if (env == 'production') {
  if (flag == 'dist') { //测试环境
    host = 'https://agenttest.rongketech.com'
  } else if (flag == 'prod') { //生产环境
    host = 'https://agent.htangcloud.com' //待定
  } else { //灰度
    host = 'https://agent-pre.rongketech.com'
  }
} else { //开发环境
  // host = 'http://192.168.18.16:9041'
  // 测试
  host = 'http://rk.agent.api'
}

var config = {
  // 短信验证码登录
  // /bpo-agent/agentUser/sendLoginSMS
  sendLoginSMS: `${host}/bpo-agent/agentUser/sendLoginSMS`,
  // 登录
  login: `${host}/bpo-agent/agentUser/login`,
  // 密码登录
  pwdLogin: `${host}/bpo-agent/agentUser/pwdLogin`,
  // 当前用户登录可以访问的资源
  currentResources: `${host}/bpo-agent/agentResource/currentResources`,
  // 获取所有的菜单（角色）
  getAllResource: `${host}/bpo-agent/agentResource/getAllResource`,
  // 获取商户下的角色分页
  getRolePageList: `${host}/bpo-agent/agentResource/getRolePageList`,
  // 删除渠道商下的角色
  deleteCompanyRole: `${host}/bpo-agent/agentResource/deleteCompanyRole`,
  // 根据角色id，查询该角色可以访问的资源Id
  getIdsByRoleId: `${host}/bpo-agent/agentResource/getIdsByRoleId`,
  // 增加部门节点
  addAgentDepartment: `${host}/bpo-agent/agentDept/addAgentDepartment`,
  // 查看渠道商一级部门
  getAgentOneDepartment: `${host}/bpo-agent/agentDept/getAgentOneDepartment`,
  // 查看组织结构带用户
  queryAgentDepartment: `${host}/bpo-agent/agentDept/queryAgentDepartment`,
  // 查询组织结构不带用户
  queryYetAgentDepartment: `${host}/bpo-agent/agentDept/queryYetAgentDepartment`,
  // 增加部门节点
  addAgentDepartment: `${host}/bpo-agent/agentDept/addAgentDepartment`,
  // 删除部门节点
  deleteAgentDepartment: `${host}/bpo-agent/agentDept/deleteAgentDepartment`,
  // 保存角色与资源的关系
  saveRoleResources: `${host}/bpo-agent/agentResource/saveRoleResources`,
  // 获取渠道商下角色id
  getRoleList: `${host}/bpo-agent/agentResource/getRoleList`,
  // 添加渠道商下的角色
  addMerUser: `${host}/bpo-agent/agentResource/addMerUser`,
  // 获取渠道商下用户分页
  getUserPageList: `${host}/bpo-agent/agentResource/getUserPageList`,
  // 修改渠道商下的用户
  updateMerUserRole: `${host}/bpo-agent/agentResource/updateMerUserRole`,
  // 查询当前用户下的渠道商
  getAgentList: `${host}/bpo-agent/agentUser/getAgentList`,
  // 切换渠道商替换token
  reToken: `${host}/bpo-agent/agentUser/reToken`,
  // 查询渠道商列表
  queryAgentPage: `${host}/bpo-agent/agent/queryAgentPage`,
  // 创建组织渠道商
  createAgentForOrganization: `${host}/bpo-agent/agent/createAgentForOrganization`,
  // 创建个人渠道商
  createAgentForPersonal: `${host}/bpo-agent/agent/createAgentForPersonal`,
  //上传图片
  uploadOss: `${host}/bpo-agent/agent/uploadOss`,
  //上传营业执照
  businessLicense: `${host}/bpo-agent/agent/businessLicense`,
  // 产品列表
  queryAgentProduct: `${host}/bpo-agent/agent/product/queryAgentProduct`,
  // 渠道商列表编辑
  updateAgent: `${host}/bpo-agent/agent/updateAgent`,
  // 根据id获取渠道商信息
  queryAgentInfo: `${host}/bpo-agent/agent/queryAgentInfo`,
  // 新增产品
  createProduct: `${host}/bpo-agent/agent/product/createProduct`,
  // 更新产品
  updateProduct: `${host}/bpo-agent/agent/product/updateProduct`,
  // 产品详情
  queryAgentProductInfo: `${host}/bpo-agent/agent/product/queryAgentProductInfo`,
  // 新增产品交付商下拉
  // queryServiceProviders: `${host}/bpo-agent/agent/product/queryServiceProviders`,
  // 渠道商商户列表
  agentCompanyPage: `${host}/bpo-agent/agentMerchant/agentCompanyPage`,
  // 创建渠道商商户
  createAgentCompany: `${host}/bpo-agent/agentMerchant/createAgentCompany`,
  // 编辑渠道商商户
  editAgentMerchant: `${host}/bpo-agent/agentMerchant/editAgentMerchant`,
  // 查看渠道商产品列表
  getAgentProduct: `${host}/bpo-agent/agentMerchant/getAgentProduct`,
  getAgentPidProduct: `${host}/bpo-agent/agentMerchant/getAgentPidProduct`,
  // 新增集团
  saveChannel: `${host}/bpo-agent/channel/save`,
  // 商户中心
  channels: `${host}/bpo-agent/channel/channels`,
  // 关联商户
  associatedMerchants: `${host}/bpo-agent/channel/associatedMerchants`,
  // 模糊搜索商户
  channelByChannelName: `${host}/bpo-agent/channel/companysByName`,
  // 增加商户
  relationCompany: `${host}/bpo-agent/channel/relationCompany`,
  // 删除商户
  disassociate: `${host}/bpo-agent/channel/disassociate`,
  // 修改商户信息
  channelInfo: `${host}/bpo-agent/channel/channelInfo`,
  // 添加商户签约
  createAgentCompanySign: `${host}/bpo-agent/agentMerchant/createAgentCompanySign`,
  // 渠道商商户查看
  queryAgentMerchant: `${host}/bpo-agent/agentMerchant/queryAgentMerchant`,
  // 渠道商商户列表
  agentRealCompanyPage: `${host}/bpo-agent/agentMerchant/agentRealCompanyPage`,
  // 渠道商商户签约查看（商户列表）
  queryAgentMerchantSign: `${host}/bpo-agent/agentMerchant/queryAgentMerchantSign`,
  // 商户管理查看签约
  queryAgentMerchantTempSign: `${host}/bpo-agent/agentMerchant/queryAgentMerchantTempSign`,
  // 渠道商交易统计查询
  agentTransCountPage: `${host}/bpo-agent/agentCount/agentTransCountPage`,
  // 统计渠道商直拓商户交易
  countTransByAgent: `${host}/bpo-agent/agentCount/countTransByAgent`,
  // 渠道商直拓商户交易统计
  transCountAgent: `${host}/bpo-agent/agentCount/transCountAgent`,
  // 渠道商直拓商户交易明细
  transDetailByAgent: `${host}/bpo-agent/agentCount/transDetailByAgent`,
  // 渠道商账户信息
  queryAccount: `${host}/bpo-agent/agentAccountInfo/queryAccount`,
  // 获取渠道商账户配置列表
  getAccountInfoList: `${host}/bpo-agent/agentAccountInfo/getAccountInfoList`,
  // 配置渠道商账户信息
  addAccountInfo: `${host}/bpo-agent/agentAccountInfo/addAccountInfo`,
  // 渠道商提现申请
  apply: `${host}/bpo-agent/agentCashApply/apply`,
  // 渠道商提现申请分页列表
  applyPage: `${host}/bpo-agent/agentCashApply/applyPage`,
  // 渠道商提现订单分页列表
  cashOrderPage: `${host}/bpo-agent/agentCashOrder/cashOrderPage`,
  // 渠道商月分润明细
  profitDetailMonth: `${host}/bpo-agent/agentProfit/profitDetailMonth`,
  // 渠道商天分润明细
  profitDetailDay: `${host}/bpo-agent/agentProfit/profitDetailDay`,
  // 删除渠道商账户信息
  deleteAccountInfo: `${host}/bpo-agent/agentAccountInfo/deleteAccountInfo`,
  // 修改渠道商账户信息
  updateAccountInfo: `${host}/bpo-agent/agentAccountInfo/updateAccountInfo`,
  // 发送短信验证码-修改登录密码
  pwdSmsCode: `${host}/bpo-agent/agentUser/pwdSmsCode`,
  // 修改登录密码-短信验证码方式
  pwdSmsReset: `${host}/bpo-agent/agentUser/pwdSmsReset`,
  // 首页总计
  homeList: `${host}/bpo-agent/home/list`,
  // 首页折线图
  homeLineList: `${host}/bpo-agent/home/homeLineList`,
  // 首页柱形图
  homeColumnList: `${host}/bpo-agent/home/homeColumnList`,
  // 身份证ocr识别
  idCardOcr: `${host}/bpo-agent/upload/idCardOcr`,
  // 服务商模糊查询
  queryServiceList: `${host}/bpo-agent/agent/queryServiceList`,
  // 根据渠道ID查询所有的下级渠道商（包括自己）
  selectNameAgent: `${host}/bpo-agent/agent/selectName`,
  // 根据渠道ID查询所有的下级渠道商（不包括自己）
  selectNameAgentNew: `${host}/bpo-agent/agent/selectNameLower`,
  // 获取渠道商类型
  getAgentType: `${host}/bpo-agent/agent/getAgentType`,
  // 消息通知列表
  messageListMessage: `${host}/bpo-agent/message/center/listMessage`,
  // 消息通知已读列表
  messageReadMessage: `${host}/bpo-agent/message/center/readMessage`,
  // 消息通知未读列表
  messageForceRemindList: `${host}/bpo-agent/message/center/forceRemindList`,
  // 前端上传获取配置
  ossUploadConfig: `${host}/bpo-agent/agent/product/ossUploadConfig`,
  // 交付商下拉
  selectBoxNew: `${host}/bpo-agent/agent/product/selectBoxNew`,



  // 中间页
  // 服务商信息列表
  serviceList: `${host}/bpo-agent/agent/partner/info/page`,
  // 交付商信息列表
  deliveryList: `${host}/bpo-agent/agent/partner/info/signMainQueryList`,
  // 查看交付商详情
  deliveryDetail: `${host}/bpo-agent/agent/partner/info/signMainInfo`,
  // 查看服务商产品列表
  supplierProductlist: `${host}/bpo-agent/agent/partner/info/supplierProductlist`,
  // 查看服务商产品详情
  supplierProductDetail: `${host}/bpo-agent/agent/partner/info/supplierProductInfo`,
  // 查看云服伙伴的渠道商信息列表
  partnerAgentPage: `${host}/bpo-agent/agent/partner/info/partnerAgentPage`,
  // 获取伙伴端用户
  hbuser: `${host}/bpo-agent/agent/partner/user/hbuser`,
  // 查看云服伙伴的渠道基础信息详情
  selectByIdPartnerInfo: `${host}/bpo-agent/agent/partner/info/selectByIdPartnerInfo`,
  // 创建云服伙伴的渠道基础信息
  createAgentForPartner: `${host}/bpo-agent/agent/partner/info/createAgentForPartner`,
  //  更新云服伙伴的渠道基础信息
  updatePartnerInfo: `${host}/bpo-agent/agent/partner/info/updatePartnerInfo`,
  //   创建伙伴端组织渠道商
  createAgentForPartnerInfo: `${host}/bpo-agent/agent/createAgentForPartner`,
  // 伙伴端个人创建渠道商
  createAgentPartnerForPersonal: `${host}/bpo-agent/agent/createAgentPartnerForPersonal`,
  // 伙伴端登录(进入渠道商)
  partnerLogin: `${host}/bpo-agent/agent/partner/user/partnerLogin`,
  // 查看服务商下拉
  supplierInfolist: `${host}/bpo-agent/agent/partner/info/supplierInfolist`,
  // 查看服务商产品下拉
  productlistInfo: `${host}/bpo-agent/agent/partner/info/productlist`,
  // 查看服务商产品详情
  supplierProductInfo: `${host}/bpo-agent/agent/partner/info/supplierProductInfo`,
  // 根据ID获取渠道商信息(中间件)
  queryAgentInfoMiddle: `${host}/bpo-agent/agent/partner/info/queryAgentInfo`,
  // 切换组织机构
  changeOrg: `${host}/bpo-agent/agent/partner/org/changeOrg`,
  // 创建基础信息
  createInfo: `${host}/bpo-agent/agentPartnerInformation/createInfo`,
  // 发送短信验证码
  sendLoginSMSMiddle: `${host}/bpo-agent/agentPartnerInformation/sendLoginSMS`,
  // 企业名称列表
  companyList: `${host}/bpo-agent/agentMerchant/selectByCompanyName`,
  // 交易明细导出
  exportList: `${host}/bpo-agent/agentCount/exportList`,
  // 渠道商月分润明细导出
  agentExportList: `${host}/bpo-agent/agentProfit/exportList`,
  // 渠道商发票申请记录
  invoiceList: `${host}/bpo-agent/invoice/apply/detail`,
  // 渠道商发票申请统计
  statistics: `${host}/bpo-agent/statistic/invoice`,
  // 渠道商发票申请统计详情查询
  statisticsList: `${host}/bpo-agent/statistic/invoice/page`,
  // 获取表头信息
  getHeader: `${host}/bpo-agent/account/dynamic/getHeader`,
  // 设置表头信息
  editHeader: `${host}/bpo-agent/account/dynamic/editHeader`,
  // 查看发票申请详情
  invoiceDetail: `${host}/bpo-agent/invoice/invoiceDetail`,
  // 商户交易统计查询
  agentMerchantCountPage: `${host}/bpo-agent/agentCount/agentMerchantCountPage`,
  // 商户交易统计交付商列表
  selectNameMainIdList: `${host}/bpo-agent/agentCount/getMainList`,
  // 商户交易统计导出
  merchantCountExport: `${host}/bpo-agent/agentCount/merchantCountExport`,


  // 伙伴端死token
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiZmNfcmVzIiwidWNfcmVzIiwiYXV0aF9yZXMiXSwiZXhwIjoxNjM0NDQxMTU1LCJ1c2VyX25hbWUiOiJ7XCJuaWNrTmFtZVwiOlwi54Sm57OW55m-5LmQ5YawXCIsXCJ1c2VySWRcIjpcIjE0MzQ3NjI3ODM1MTA1NjA3NzBcIixcInVzZXJuYW1lXCI6XCIxNjYzMjIzMDIyMVwifSIsImp0aSI6IjJhM2M4MWVmLWYxOTQtNDI5OC04ZTI0LTA2YjE2NjRmZWNlZiIsImNsaWVudF9pZCI6InBhcnRuZXIiLCJzY29wZSI6WyJBTEwiXX0.ph5ncBQZPMNcnbAx3DxbHkbOSzET_o68LbKOFfNaWqw


}

module.exports = config