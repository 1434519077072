import {
    GET_ACCOUNT,
} from '../../mutation-types';

export default {
    [GET_ACCOUNT](state, account) {
        state.userId = account.userId;
        state.username = account.username;
        state.realName = account.realName;
        state.busiCompanyName = account.busiCompanyName;
    },


};
